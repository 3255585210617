<template>
  <el-select
    v-model="type_id"
    placeholder="Selecciona un tipo de cliente"
    style="width: 100%"
    @input="$emit('update:type_id', $event.target.value)"
  >
    <el-option
      v-for="type in client_types"
      :key="type.id"
      :label="type.name"
      :value="type.id"
    >
    </el-option>
  </el-select>
</template>
<script>
import { getData } from "../../request/request.js";
import { ElNotification } from "element-plus";

export default {
  name: "SelectClientType",
  setup() {},
  data() {
    return {
      client_types: [],
      type_id: "",
    };
  },
  mounted() {
    this.getClientTypes();
  },
  methods: {
    async getClientTypes() {
      await getData("api/v1/clientTypes", {}, true)
        .then((res) => {
          if (res.error == true || res.error == undefined) {
            ElNotification({
              title: "Error",
              message: res.message,
              type: "error",
            });
          } else {
            this.client_types = res.data;
          }
        })
        .catch(() => {
          ElNotification({
            title: "Error",
            message: "Ocurrió un error al hacer la petición",
            type: "error",
          });
        });
    },
  },
};
</script>
