<template>
  <el-select
    v-model="user_id"
    placeholder="Buscar Usuario"
    no-data-text="No hay coincidencias."
    loading-text="Buscando..."
    filterable
    remote
    reserve-keyword
    :remote-method="searchUser"
    :loading="loading"
    style="width: 100%"
    @input="$emit('update:user_id', $event.target.value)"
  >
    <el-option
      v-for="user in users"
      :key="user.full_name"
      :label="user.full_name"
      :value="user.id"
    >
    </el-option>
  </el-select>
</template>
<script>
import { getData } from "../../request/request.js";
import { ElNotification } from "element-plus";

export default {
  name: "SelectUser",
  data() {
    return {
      users: [],
      user_id: "",
      loading: false,
    };
  },
  methods: {
    async getUsers() {
      this.loading = true;
      await getData(
        "api/v1/users/prefix",
        {
          query: this.query,
        },
        true
      )
        .then((res) => {
          this.loading = false;

          if (res.error == true || res.error == undefined) {
            ElNotification({
              title: "Error",
              message: res.message,
              type: "error",
            });
            return;
          }

          this.users = res.data;
        })
        .catch(() => {
          ElNotification({
            title: "Error",
            message: "Ocurrió un error al hacer la petición",
            type: "error",
          });
          this.loading = false;
        });
    },
    searchUser(query) {
      query = query.trim();
      if (query === "" || query.length < 3) {
        return;
      }

      this.query = query;
      this.getUsers();
    },
  },
};
</script>
