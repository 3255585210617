import { ElNotification } from "element-plus";

const regexTypeFile=/(\.jpg|\.docx|\.xlsx|\.txt|\.png|\.csv|\.pdf)$/i;


export const validateDocument = function(file,fileList) {
  var index = fileList.indexOf(file);
  if (file.size / 1024 / 1024 > 25) {
    if (index > -1) {
      fileList.splice(index, 1);
    }
    ElNotification({
      title: "Tamaño del archivo inválido",
      message: "El tamaño del archivo no puede ser mayor a 25MB",
      type: "warning",
    });
    return false;
  }

  if (!regexTypeFile.exec(file.name)) {
    if (index > -1) {
      fileList.splice(index, 1);
    }
    ElNotification({
      title: "Tipo de archivo inválido",
      message:
            "Los tipos de archivos soportados son: pdf,txt,docx,xlsx,csv,png,jpg",
      type: "warning",
    });
    return false;
  }
  return true;
};