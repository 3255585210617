<template>
  <div class="container_card" :style="`width:${size}%`">
    <div class="card animate__animated animate__fadeInRight">
      <el-tooltip content="Ver documento" placement="right" effect="light">
        <img
          class="icon"
          src="../../assets/ionicons/document-outline.svg"
          alt=""
          @click="getDocument()"
        />
      </el-tooltip>
      <div class="content">
        <h5 class="date">
          {{ document.user_name }} {{ formatDateAndHour(document.created_at) }}
        </h5>
        <h3>{{ document.type }}</h3>
        <p>{{ document.description }}<br /></p>
      </div>
    </div>
  </div>
</template>
<script>
import { getRawData } from "@/request/request.js";
import { ElNotification } from "element-plus";
import { formatDateAndHour } from "../../config/Date.js";

export default {
  name: "DocumentCard",
  props: {
    document: {
      id: Number,
      type: String,
      description: String,
      created_at: Date,
      user_name: String,
    },
    size: String,
  },
  setup() {
    return {
      formatDateAndHour,
    };
  },
  methods: {
    async getDocument() {
      await getRawData(`api/v1/documents/getUrl/${this.document.id}`)
        .then((data) => {
          if (data === "") {
            ElNotification({
              title: "Error",
              message: "Ocurrió un problema al solicitar el documento",
              type: "error",
            });
          }
          window.open(data, "_blank");
        })
        .catch(() => {
          ElNotification({
            title: "Error",
            message: "Ocurrió un error al hacer la petición",
            type: "error",
          });
        });
    },
  },
};
</script>
<style scoped>
.icon {
  width: 50px;
  font-weight: 100;
  cursor: pointer;
  filter: brightness(0) saturate(100%) invert(14%) sepia(29%) saturate(1793%)
    hue-rotate(163deg) brightness(102%) contrast(97%);
}

.content {
  width: 80%;
  padding: 5px;
  box-sizing: border-box;
  cursor: default;
  text-align: center;
}

.content h3 {
  font-weight: 800 !important;
  margin-bottom: 0px !important;
  margin-top: 0px !important;
  text-align: left;
}

p {
  margin-top: 0px;
  font-weight: 100 !important;
  color: gray;
  line-break: anywhere;
  text-align: left;
}

.date {
  font-size: 10px;
  text-align: end;
  margin: 0;
  color: gray;
}

.container_card {
  display: inline-block !important;
}

.card {
  height: 95%;
  position: relative;
  font-size: 14px;
  background: white;
  padding: 2%;
  box-sizing: border-box;
  display: flex;
  margin: 1.5%;
  border-radius: 7px;
  transition: 0.3s;
}

.card:hover {
  -webkit-box-shadow: 8px 8px 24px 0px rgba(66, 68, 90, 0.57);
  -moz-box-shadow: 8px 8px 24px 0px rgba(66, 68, 90, 0.57);
  box-shadow: 8px 8px 24px 0px rgba(66, 68, 90, 0.57);

  transition: 0.3s;
}
</style>
