<template>
  <el-container>
    <br /><br />
    <el-header>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/generalquotations' }"
          ><h2 class="title">Cotizador</h2></el-breadcrumb-item
        >
      </el-breadcrumb>
    </el-header>
    <el-main>
      <GeneralQuotationList v-if="hasPermits('general_quotation.list')" />
    </el-main>
  </el-container>
</template>

<script>
import GeneralQuotationList from "../components/GeneralQuotation/GeneralQuotationList.vue";
import { hasPermits } from "@/config/Auth.js";
export default {
  setup() {
    return {
      hasPermits,
    };
  },
  name: "GeneralQuotation",
  components: {
    GeneralQuotationList,
  },
};
</script>

<style scoped>
.title {
  color: black;
  text-align: left;
}
</style>
