<template>
  <div>
    <el-dialog v-model="dialogVisible" width="65%" :title="title">
      <el-form
        ref="model"
        label-position="top"
        :model="model"
        v-loading="loading"
      >
        <el-row>
          <el-col :span="24">
            <el-form-item label="Ingresa una observacion">
              <el-input v-model="model.observation"></el-input>
            </el-form-item> </el-col
        ></el-row>
      </el-form>
      <br />
      <div class="button" align="center">
        <el-button type="primary" @click="sendObservation()">Aceptar</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "GenerateObservation",
  props: {
    title: String,
    event: String,
  },
  setup() {
    return {};
  },
  data() {
    return {
      model: {
        observation: "",
      },
      loading: false,
      dialogVisible: false,
    };
  },
  methods: {
    openModal() {
      this.dialogVisible = true;
    },
    sendObservation() {
      this.emitter.emit(this.event, this.model.observation);
      this.dialogVisible = false;
      this.cleanForm();
    },
    cleanForm() {
      let form = this.$refs["model"];
      if (form) {
        form.resetFields();
      }
      this.model.observation = "";
    },
  },
};
</script>

<style scoped></style>
