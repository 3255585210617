<template>
  <el-form ref="model" label-position="top" :model="model" :rules="rules">
    <el-row>
      <el-col :span="22">
        <el-form-item label="" prop="price_id">
          <el-select
            v-model="model.price_id"
            placeholder="Buscar insumo"
            no-data-text="No hay coincidencias."
            loading-text="Buscando..."
            filterable
            remote
            reserve-keyword
            :remote-method="searchPrice"
            :loading="loading"
            style="width: 100%"
            v-on:change="selectedItem"
            class="select-price"
          >
            <el-option
              v-for="price in prices"
              :key="price.id"
              :value="price.id"
              :label="
                price.supply_name +
                ' - ' +
                toCurrency(parseInt(price.price)) +
                ' - ' +
                price.city_name
              "
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <AddPrice event="add-price-to-select" :city="this.city_id" />

      <el-col :span="2" class="price-add">
        <el-button class="btn-plus" type="text" @click="openAddModal()"
          ><i class="el-icon-plus"></i
        ></el-button>
      </el-col>
    </el-row>
  </el-form>
</template>

<script>
import { ElNotification } from "element-plus";
import { getData } from "../../request/request.js";
import AddPrice from "./AddPrice.vue";
import { toCurrency } from "../../config/Money.js";
import { postData } from "../../request/request.js";

export default {
  name: "SelectPrice",
  components: { AddPrice },
  props: {
    contract_id: Number,
    city_id: Number,
    event_id: Number,
  },
  setup() {
    return {
      toCurrency,
    };
  },
  mounted() {
    this.emitter.off("add-price-to-select");
    this.emitter.on("add-price-to-select", (model) => {
      this.modelCache = model;
      this.savePrice(model);
    });
    this.query = "";
    this.getPrices();
  },
  data() {
    return {
      loading: false,
      prices: [],
      modelCache: null,
      model: {
        price_id: "",
      },
      rules: {
        price_id: [
          {
            required: true,
            message: "Por favor ingresa el insumo",
            trigger: "change",
          },
          {
            required: true,
            message: "Por favor ingresa el insumo",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    searchPrice(query) {
      query = query.trim();
      if (query === "" || query.length < 2) {
        return;
      }

      this.query = query;
      this.getPrices();
    },

    async getPrices() {
      this.loading = true;
      await getData(`api/v1/prices/prefix/${this.event_id}`, {
        query: this.query,
      })
        .then((res) => {
          if (res.error === true || res.error === undefined) {
            ElNotification({
              title: "Error",
              message: res.message,
              type: "error",
            });
            return;
          }
          this.prices = res.data;
          this.loading = false;
        })
        .catch(() => {
          ElNotification({
            title: "Error",
            message: "Ocurrió un error al hacer la petición",
            type: "error",
          });
        });
      this.loading = false;
    },

    openAddModal() {
      this.emitter.emit("open-add-modal");
    },

    validateSelect() {
      let res;
      this.$refs["model"].validate((valid) => {
        res = valid;
      });
      return res;
    },

    cleanForm(formName) {
      this.$refs[formName].resetFields();
    },

    selectedItem() {
      if (this.model.price_id != null && this.model.price_id != undefined) {
        let res = this.searchSupply(this.model.price_id);
        let supply = {
          price_id: this.model.price_id,
          name: res.supply_name,
        };
        return supply;
      }
      return null;
    },

    searchSupply(supplyID) {
      let data;
      this.prices.forEach((supply) => {
        if (supply.id === supplyID) {
          data = supply;
          return;
        }
      });
      return data;
    },

    async savePrice(model) {
      let newPrice = {
        supply_id: model.supply_id,
        price: model.price,
        city_id: model.city_id,
      };
      await postData(
        `api/v1/prices/${this.contract_id}`,
        { add: [newPrice], edit: [], delete: [] },
        true
      )
        .then((res) => {
          if (res.error === true || res.data === undefined) {
            ElNotification({
              title: "Error",
              message: res.message,
              type: "error",
            });
            return;
          }
          ElNotification({
            title: "Precio guardado",
            message: res.message,
            type: "success",
          });

          this.emitter.emit("approbe-reload-prices");
          this.loading = false;
        })
        .catch(() => {
          ElNotification({
            title: "Error",
            message: "Ocurrió un error al hacer la petición",
            type: "error",
          });
        });
      this.loading = false;
    },
  },
};
</script>

<style scoped>
.price-add {
  display: flex;
  align-content: space-between;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}
.select-price {
  margin-top: 5px;
  margin-bottom: 13px;
}
</style>
