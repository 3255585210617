<template>
  <div>
    <el-popover
      v-if="showButton"
      placement="right-start"
      title="Crear estado"
      :width="200"
      trigger="hover"
      content="Abrir formulario para agregar un estado"
    >
      <template #reference>
        <el-button class="btn-plus" type="text" @click="openModal()"
          ><i class="el-icon-plus"></i
        ></el-button>
      </template>
    </el-popover>
    <el-dialog
      v-model="show"
      title="Agregar Estado"
      width="40%"
      :before-close="handleClose"
      center
    >
      <span>
        <el-form
          label-position="top"
          ref="model"
          :model="model"
          :rules="rules"
        >
          <el-row>
            <el-col :span="24">
              <el-form-item label="Nombre del Estado" prop="name">
                <el-input type="text" v-model="model.name"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </span>

      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="saveStatus('model')"
            >Agregar</el-button
          >
          <el-button @click="show = false">Cancelar</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { postData } from "@/request/request.js";
import { ElMessageBox, ElNotification, ElMessage, ElLoading } from "element-plus";
import { event_status_state } from "@/state/event_status.js";

export default {
  name: "AddStatus",
  created() {
    event_status_state.init(this);
  },
  mounted() {
    this.emitter.off("add-event-status");
    this.emitter.on("add-event-status", () => {
      this.openModal();
    });
  },
  props: {
    showButton: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      loading: false,
      show: false,
      model: {
        name: "",
      },
      rules: {
        name: [
          {
            required: true,
            message: "Por favor agrega un estado",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    openModal() {
      this.openForm("model");
    },
    handleClose(done) {
      ElMessageBox.confirm(
        "¿Estas seguro que deseas salir del formulario de agregar tarifas?",
        {
          showCancelButton: true,
          confirmButtonText: "Confirmar",
          cancelButtonText: "Cancelar",
        }
      )
        .then((res) => {
          if (res == "confirm") {
            this.show = false;
            this.cleanForm();
            done();
          }
        })
        .catch(() => {}); // this action is call when user click in cancel button
    },

    async saveStatus(formName) {
      if (!this.validateForm(formName)) {
        ElMessage.error("¡ Error !, Por favor verifica todos los campos");
        return;
      }

      this.loading = ElLoading.service();
      await postData("api/v1/status", this.model, true)
        .then((res) => {
          if (res.error === true || res.error === undefined) {
            ElNotification({
              title: "Error",
              message: res.message,
              type: "error",
            });
            return;
          }
          ElNotification({
            title: "Estado guardado",
            message: res.message,
            type: "success",
          });

          event_status_state.addItem(res.data);
          this.show = false;
          this.cleanForm();
        })
        .catch(() => {
          ElNotification({
            title: "Error",
            message: "Ocurrió un error al hacer la petición",
            type: "error",
          });
        });
      this.loading.close();
    },

    openForm(formName) {
      this.cleanForm(formName);
      this.show = true;
    },

    validateForm(formName) {
      let res;
      this.$refs[formName].validate((valid) => {
        res = valid;
      });
      return res;
    },
    cleanForm(formName) {
      if (this.$refs[formName] != undefined) {
        this.$refs[formName].resetFields();
      }
      this.model.name = "";
    },
  },
};
</script>
