<template>
  <div class="items">
    <el-popover
      placement="right-start"
      title="Crear  bitácora"
      :width="300"
      trigger="hover"
      content="Abrir formulario para crear una bitácora"
    >
      <template #reference>
        <el-button class="btn-plus" type="text" @click="openModal()"
          ><i class="el-icon-plus add-icon"></i
        ></el-button>
      </template>
    </el-popover>

    <el-dialog
      v-model="dialogVisible"
      title="Crear bitácora"
      width="80%"
      :before-close="handleClose"
      center
    >
      <span>
        <el-form
          label-position="top"
          ref="model"
          :model="model"
          :rules="rules"
          :loading="loadingCreate"
        >
          <el-form-item label="Contenido" prop="content">
            <el-input type="textarea" v-model="model.content"></el-input>
          </el-form-item>
        </el-form>
      </span>

      <template #footer>
        <span class="dialog-footer">
          <el-button
            :loading="loadingCreate"
            type="primary"
            @click="save('model')"
            >Agregar</el-button
          >
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { ElMessage, ElMessageBox, ElNotification } from "element-plus";
import { postData } from "../../request/request.js";

export default {
  components: {},
  name: "NotesCreate",
  props: {
    idEvent: Number,
  },
  setup() {
    const handleClose = (done) => {
      ElMessageBox.confirm(
        "¿Está Seguro que quiere salir de la creación de la bitácora?",
        {
          showCancelButton: true,
          confirmButtonText: "Confirmar",
          cancelButtonText: "Cancelar",
        }
      )
        .then(() => {
          done();
        })
        .catch(() => {
          // catch error
        });
    };
    return {
      handleClose,
    };
  },

  data() {
    return {
      dialogVisible: false,
      loadingCreate: false,
      model: {
        content: "",
      },
      rules: {
        content: [
          {
            required: true,
            message: "Por favor ingresa el contenido",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    openModal() {
      this.cleanForm();
      this.dialogVisible = true;
    },
    validateForm(formName) {
      let res;
      this.$refs[formName].validate((valid) => {
        res = valid;
      });
      return res;
    },
    save(formName) {
      if (!this.validateForm(formName)) {
        ElMessage.error("¡ Error !, Por favor verifica todos los campos");
        return;
      }
      this.loadingCreate = true;
      let newModel = Object.assign({}, this.model);

      postData("api/v1/events/" + this.idEvent + "/notes", newModel, true)
        .then((res) => {
          this.loadingCreate = false;
          if (res.error == true || res.error == undefined) {
            ElNotification({
              title: "Error",
              message: res.message,
              type: "error",
            });
            return;
          }
          ElNotification({
            title: "Bitácora guardada",
            message: res.message,
            type: "success",
          });
          this.dialogVisible = false;
          this.emitter.emit("reload-list-of-notes");
        })
        .catch(() => {
          ElNotification({
            title: "Error",
            message: "Ocurrió un error al hacer la petición",
            type: "error",
          });
          this.loadingCreate = false;
        });
    },
    cleanForm() {
      let form = this.$refs["model"];
      if (form) {
        form.resetFields();
      }
      this.step = 0;
      this.model = {
        content: "",
      };
    },
  },
};
</script>
<style scoped>
.el-button--success {
  background: #003149;
  border: none;
}

.el-button--primary {
  background: var(--blue) !important;
  border: 1px solid var(--blue) !important;
}

.el-button--text {
  color: var(--blue) !important;
  font-weight: bold;
  font-size: 25px;
}

.add-icon {
  color: rgb(2, 138, 13);
}

textarea {
  box-sizing: border-box;
  height: 44px;
  border: none;
  max-width: 100%;
  min-width: 100%;
  width: 100%;
  resize: none;
}
</style>
