<template>
  <div class="items">
    <el-dialog
      v-model="show"
      title="Editar solicitud de cotización"
      width="80%"
      :before-close="handleClose"
      center
    >
      <span>
        <el-form
          label-position="top"
          ref="model"
          :model="model"
          :rules="rules"
          :loading="loading"
        >
          <el-form-item label="Proveedor" prop="subsidiarie_id">
            <select-provider-by-city
              v-model="model.subsidiarie_id"
              :city_id="event.city_id"
            />
          </el-form-item>

          <select-supply-usage
            event="edit-quotation-usage"
            :idEvent="event.id"
          />

          <table class="table">
            <thead>
              <tr>
                <th>Insumo</th>
                <th>Descripción</th>
                <th :key="i" v-for="(date, i) in dates">
                  {{ date.substring(5) }}
                </th>
              </tr>
            </thead>

            <tbody>
              <template v-for="(row, i) in tableData">
                <tr :key="i" v-if="row.tag !== 'delete'">
                  <td align="center">
                    <el-tooltip
                      content="Eliminar"
                      placement="right"
                      effect="light"
                    >
                      <i
                        @click.prevent="deleteRow(i, row)"
                        class="el-icon-delete"
                      ></i> </el-tooltip
                    >{{ row.supply.name }}
                  </td>
                  <td>
                    <textarea
                      v-model="row.description"
                      cols="60"
                      rows="5"
                      @change="editRow(row)"
                    ></textarea>
                  </td>
                  <td align="center" :key="j" v-for="(date, j) in dates">
                    {{ row.usages[date] ? row.usages[date] : 0 }}
                  </td>
                </tr>
              </template>
            </tbody>
          </table>

          <br />
          <br />

          <el-form-item label="Observaciones" prop="request_notes">
            <el-input type="textarea" v-model="model.request_notes"></el-input>
          </el-form-item>
        </el-form>
      </span>

      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="addQuotation()"
            >Guardar cambios</el-button
          >
          <el-button @click="show = false">Cancelar</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { ElMessage, ElMessageBox, ElNotification } from "element-plus";
import { putData, getData } from "../../request/request.js";
import { hasPermits } from "@/config/Auth.js";
import { generateDates } from "@/config/Helper.js";
import SelectProviderByCity from "../providers/SelectProviderByCity.vue";
import SelectSupplyUsage from "./SelectSupplyUsage.vue";

export default {
  components: { SelectProviderByCity, SelectSupplyUsage },
  name: "QuotationEdit",
  props: {
    event: Object,
  },
  setup() {
    return {
      hasPermits,
      generateDates,
    };
  },
  mounted() {
    this.dates = generateDates(
      this.event.start_date,
      this.event.finish_date,
      this.event.consuption_previous_day,
      this.event.consuption_next_day
    );

    this.emitter.off("edit-quotation-usage");
    this.emitter.on("edit-quotation-usage", (usages) => {
      if (this.existUsage(usages.supply.id)) {
        ElMessage.error("El consumo ya fue registrado.");
        return;
      }

      this.tableData.push({
        tag: "add",
        supply: usages.supply,
        description: "",
        event_price_id: usages.event_price_id,
        usages: this.buildUsages(usages.usages),
      });
    });
  },
  data() {
    return {
      loading: false,
      show: false,
      id: 0,
      model: {
        subsidiarie_id: "",
        request_notes: "",
      },
      dates: [],
      tableData: [],
      rules: {
        subsidiarie_id: [
          {
            required: true,
            message: "Por favor selecciona un proveedor",
            trigger: "change",
          },
        ],
      },
    };
  },
  methods: {
    existUsage(supply_id) {
      let exist = false;
      this.tableData.forEach((row) => {
        if (row.supply.id == supply_id && row.tag != "delete") {
          exist = true;
          return;
        }
      });

      return exist;
    },
    editRow(row) {
      if (row.tag === "add") {
        return;
      }

      row.tag = "edit";
    },
    buildUsages(usages) {
      let resultUsages = {};
      usages.forEach((usage) => {
        resultUsages[usage.date] = usage.amount;
      });

      return resultUsages;
    },
    handleClose(done) {
      ElMessageBox.confirm(
        "¿Estas seguro que deseas salir del formulario de crear solicitud de cotización?",
        {
          showCancelButton: true,
          confirmButtonText: "Confirmar",
          cancelButtonText: "Cancelar",
        }
      )
        .then((res) => {
          if (res == "confirm") {
            this.show = false;
            this.cleanFormAdd();
            done();
          }
        })
        .catch(() => {}); // this action is call when user click in cancel button
    },

    deleteRow(index, row) {
      ElMessageBox.confirm(
        "¿Seguro de querer eliminar el consumo?",
        "Advertencia",
        {
          confirmButtonText: "si",
          cancelButtonText: "no",
          type: "warning",
        }
      ).then(() => {
        if (row.tag === "add") {
          this.tableData.splice(index, 1);
        }

        row.tag = "delete";
      });
    },

    openForm(id) {
      this.cleanFormAdd();
      this.loadData(id);
      this.show = true;
    },
    loadData(id) {
      this.loadingInformation = true;
      this.id = id;
      getData(`api/v1/quotations/${id}/all`)
        .then((res) => {
          if (res.error == true || res.error == undefined) {
            ElNotification({
              title: "Error",
              message: res.message,
              type: "error",
            });

            return;
          }

          this.model.request_notes = res.data.request_notes;
          this.model.subsidiarie_id = res.data.subsidiarie_id;

          res.data.details.forEach((detail) => {
            this.tableData.push({
              supply: { id: detail.supply.id, name: detail.supply.name },
              description: detail.description,
              id: detail.id,
              usages: this.buildUsages(detail.dates),
            });
          });

          this.loadingInformation = false;
        })
        .catch(() => {
          ElNotification({
            title: "Error",
            message: "Ocurrió un error al hacer la petición",
            type: "error",
          });
        });
    },

    validateForm() {
      let res;
      this.$refs["model"].validate((valid) => {
        res = valid;
      });
      return res;
    },
    cleanFormAdd() {
      if (this.$refs.model === undefined) {
        return;
      }

      this.$refs.model.resetFields();

      this.model = {
        subsidiarie_id: "",
        request_notes: "",
      };
      this.tableData = [];
    },
    addQuotation() {
      if (!this.validateForm()) {
        ElMessage.error("Ingrese los datos requeridos.");
        return;
      }

      if (this.tableData.lenght <= 0) {
        ElMessage.error("Debes seleccionar al menos un consumo.");
        return;
      }

      this.loading = true;

      let usagesfiltered = [];
      this.tableData.forEach((data) => {
        if (data.tag !== undefined) {
          usagesfiltered.push(data);
        }
      });

      let usages = usagesfiltered.map(
        ({ event_price_id, id, tag, supply, description }) => ({
          id: id,
          tag: tag,
          supply_id: supply.id,
          event_price_id: event_price_id,
          description: description,
        })
      );

      if (this.tableData.lenght == 0) {
        ElMessage.error("Debes seleccionar al menos un consumo.");
        return;
      }

      putData(
        `api/v1/quotations/${this.id}`,
        {
          event_id: this.event.id,
          subsidiarie_id: this.model.subsidiarie_id,
          request_notes: this.model.request_notes,
          usages: usages,
        },
        true
      )
        .then((res) => {
          this.loadingSave = false;

          if (res.error == true || res.error == undefined) {
            ElNotification({
              title: "Error",
              message: res.message,
              type: "error",
            });
            return;
          }

          ElNotification({
            title: "Solicitud de cotización actualizada.",
            message: res.message,
            type: "success",
          });

          this.emitter.emit("reload-list-of-quotations");
          this.show = false;
        })
        .catch(() => {
          ElNotification({
            title: "Error",
            message: "Ocurrió un error al hacer la petición",
            type: "error",
          });
          this.loadingSave = false;
        });
    },
  },
};
</script>
<style scoped>
.table {
  width: -webkit-fill-available;
  font-size: 16px;
}

.table th {
  color: var(--blue);
  padding: 3px;
  border: 1px solid gray;
}

.table td {
  margin: 8px;
  border: 1px solid gray;
}

i {
  cursor: pointer;
  font-size: 18px;
}
.items {
  display: inline;
}

.el-icon-delete,
::v-deep(.el-table__empty-text) {
  color: red !important;
}

.el-icon-delete {
  float: left;
  margin-left: 8px;
}

.el-button--danger {
  background: #bc0304;
  border: none;
}

textarea {
  box-sizing: border-box;
  height: 44px;
  border: none;
  max-width: 100%;
  min-width: 100%;
  width: 100%;
  resize: none;
}
</style>
