<template>
  <el-dialog
    v-model="dialogVisible"
    width="45%"
    :before-close="handleClose"
    title="Agregar consumo"
  >
    <span>
      <el-form
        label-position="top"
        ref="model"
        :model="model"
        :rules="rules"
        :loading="loading"
      >
        <el-row>
          <el-col :span="24">
            <SelectPrice
              :contract_id="this.contract_id"
              :event_id="this.event_id"
              :city_id="this.city_id"
              ref="selectPrice"
            />
          </el-col>
          <el-col :span="24">
            <el-form-item label="Fecha de consumo" prop="date">
              <el-date-picker
                v-model="model.date"
                type="date"
                value-format="YYYY-MM-DD"
                placeholder="Selecciona una fecha"
                style="width: 100%"
                :disabled-date="checkDates"
                :default-value="this.model.date"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="Cantidad" prop="amount">
              <el-input type="Number" v-model="model.amount"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <div class="button" align="center">
          <el-button type="primary" @click="save('model')"
            >Agregar Consumo</el-button
          >
        </div>
      </el-form>
    </span>
  </el-dialog>
</template>
<script>
import { ElMessage, ElMessageBox, ElNotification } from "element-plus";
import { hasPermits } from "@/config/Auth.js";
import SelectPrice from "../Price/SelectPrice.vue";
import { postData } from "../../request/request";
export default {
  name: "AddUsages",
  components: {
    SelectPrice,
  },
  props: {
    contract_id: Number,
    event_id: Number,
    city_id: Number,
    startDate: String,
    finishDate: String,
  },
  mounted() {
    this.emitter.off("open-add-usages");
    this.emitter.on("open-add-usages", () => {
      this.openModal();
    });
  },
  setup() {
    const handleClose = (done) => {
      ElMessageBox.confirm(
        "¿Está Seguro que quiere salir de la creación del consumo?",
        {
          showCancelButton: true,
          confirmButtonText: "Confirmar",
          cancelButtonText: "Cancelar",
        }
      )
        .then(() => {
          done();
        })
        .catch(() => {
          // catch error
        });
    };
    return {
      handleClose,
      hasPermits,
    };
  },
  data() {
    return {
      dialogVisible: false,
      loading: false,
      usages: [],
      model: {
        date: this.startDate,
        amount: "",
        price_id: "",
      },
      rules: {
        date: [
          {
            required: true,
            message: "Por favor selecciona una fecha",
            trigger: "change",
          },
        ],
        amount: [
          {
            required: true,
            message: "Por favor agrega una cantidad",
            trigger: "blur",
          },
        ],
      },
    };
  },

  methods: {
    openModal() {
      this.cleanForm("model");
      this.dialogVisible = true;
    },

    checkDates(date) {
      if (date == null && date == undefined) {
        return true;
      }
      let tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
      date = new Date(date - tzoffset);

      let start = new Date(this.startDate);
      let finish = new Date(this.finishDate);
      start = new Date(start - tzoffset);
      finish = new Date(finish - tzoffset);

      finish.setHours(finish.getHours() + 24);
      if (
        date.getTime() >= start.getTime() &&
        date.getTime() <= finish.getTime()
      ) {
        return false;
      } else {
        return true;
      }
    },

    validateForm(formName) {
      let res;
      this.$refs[formName].validate((valid) => {
        res = valid;
      });
      return res;
    },

    async save(formName) {
      let selected = this.$refs.selectPrice.selectedItem();
      this.model.price_id = selected.price_id;
      this.model.name = selected.name;
      let newModel = Object.assign({}, this.model);
      this.loading = true;
      let validatePrice = this.$refs.selectPrice.validateSelect();
      if (!this.validateForm(formName) || !validatePrice) {
        ElMessage.error(
          "¡ Error !, Por favor verifica que todos los campos esten llenos"
        );
        this.loading = false;
        return;
      }

      this.loading = true;
      await postData(`api/v1/usages/${this.event_id}`, newModel, true)
        .then((res) => {
          if (res.error == true || res.error == undefined) {
            ElNotification({
              title: "Error",
              message: res.message,
              type: "error",
            });
            this.loading = false;
            return;
          }
          ElNotification({
            title: "Consumo guardado correctamente",
            message: res.message,
            type: "success",
          });
          this.loading = false;
          this.dialogVisible = false;
          newModel.id = res.data.id;
          newModel.event_prices_id = res.data.event_prices_id;
          newModel.price_id = res.data.event_price.price_id;
          newModel.supply = res.data.event_price.price.supply.name;

          this.emitter.emit("add-usage-table", newModel);
          this.emitter.emit("reload-list-of-notes");
        })
        .catch(() => {
          ElNotification({
            title: "Error",
            message: "Ocurrió un error al hacer la petición",
            type: "error",
          });
        });
      this.loading = false;
    },

    cleanForm(formName) {
      if (this.$refs[formName] != undefined) {
        this.$refs[formName].resetFields();
      }

      if (this.$refs.selectPrice != undefined) {
        this.$refs.selectPrice.cleanForm(formName);
      }

      this.model.date = this.startDate;
      this.model.amount = "";
    },
  },
};
</script>
<style scoped>
.el-button--success {
  background: #003149;
  border: none;
}

.el-button--primary {
  background: var(--blue) !important;
  border: 1px solid var(--blue) !important;
}

.el-button--text {
  color: var(--blue) !important;
  font-weight: bold;
  font-size: 25px;
}
</style>
