<template>
  <el-container class="container">
    <ShowProvider ref="showModalComponent" />
    <el-row>
      <el-col :span="20">
        <div
          style="margin-top: 15px; margin-bottom: 15px"
          class="animate__animated animate__fadeIn"
        >
          <el-input
            v-model="query"
            placeholder="Escriba la búsqueda"
            class="input-with-select"
            v-on:keyup.enter="search"
          >
            <template #prepend>
              <el-select
                v-model="type"
                placeholder="Select"
                style="width: 150px"
              >
                <el-option
                  label="Identificación"
                  value="identification"
                  selected
                ></el-option>
                <el-option label="Nombre" value="full_name"></el-option>
                <el-option label="Ciudad" value="city"></el-option>
                <el-option label="Tipo" value="provider_type"></el-option>
              </el-select>
            </template>
            <template #append>
              <el-button @click="search" icon="el-icon-search"></el-button>
            </template>
          </el-input>
        </div>
      </el-col>
      <el-col class="paginate animate__animated animate__backInRight" :span="4">
        <el-button
          icon="el-icon-arrow-left"
          @click="prev"
          :disabled="prevEnable"
        >
        </el-button>
        <label style="margin: 0 15px 0 15px"> {{ page }} </label>
        <el-button
          icon="el-icon-arrow-right"
          @click="next"
          :disabled="nextEnable"
        >
        </el-button>
      </el-col>
      <el-col
        :span="24"
        class="animate__animated animate__fadeIn animate__delay-1s"
      >
        <el-table
          v-loading="loading"
          :data="tableData"
          :empty-text="'No hay registros'"
          style="border-radius: 10px"
          stripe
        >
          <el-table-column
            prop="identification"
            label="Identificación"
            width="120"
          />
          <el-table-column prop="full_name" label="Nombre" />
          <el-table-column prop="cities" label="Ciudades" width="215">
            <template #default="scope">
              <b :key="i" v-for="(v, i) in scope.row.cities.split(',')">
                {{ v }}<br />
              </b>
            </template>
          </el-table-column>
          <el-table-column prop="provider_type" label="Tipo" width="200">
            <template #default="scope">
              {{ getProviderType(scope.row) }}
            </template>
          </el-table-column>
          <el-table-column prop="address" label="Dirección" width="220" />
          <el-table-column prop="phone" label="Teléfono" width="145" />
          <el-table-column label="Acciones" align="center" width="160">
            <template #default="scope">
              <el-tooltip
                v-if="hasPermits('providers.show')"
                content="Mostrar Proveedor"
                placement="left"
                effect="light"
              >
                <i @click="showProvider(scope.row.id)" class="el-icon-view"></i>
              </el-tooltip>
              <el-tooltip content="Editar" placement="top" effect="light">
                <i
                  class="el-icon-edit properties"
                  @click="openForm(scope.row.id)"
                ></i>
              </el-tooltip>
            <template v-if="hasPermits('providers.delete')">
              <Delete
                :endpoint="'api/v1/providers/' + scope.row.id"
                event="reload-list-of-providers"
                :key="scope.row.id"
              />
            </template>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
  </el-container>
</template>
<script>
import Delete from "../Delete.vue";
import { getData } from "../../request/request.js";
import { hasPermits } from "@/config/Auth.js";
import ShowProvider from "./ShowProvider.vue";
import "animate.css";

export default {
  name: "ProviderList",
  setup() {
    return {
      hasPermits,
    };
  },
  components: { Delete, ShowProvider },
  data() {
    return {
      tableData: [],
      loading: false,
      prevEnable: true,
      nextEnable: false,
      query: "",
      type: "identification",
      page: 1,
    };
  },
  methods: {
    loadData() {
      this.loading = true;
      getData("api/v1/providers", {
        page: this.page,
        type: this.type,
        query: this.query,
      })
        .then((res) => {
          this.tableData = res.data;
          this.page = res.current_page;
          this.prevEnable = res.prev_page_url == null;
          this.nextEnable = res.next_page_url == null;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    search() {
      this.page = 1;
      this.loadData();
    },
    prev() {
      this.page--;
      this.loadData();
    },
    next() {
      this.page++;
      this.loadData();
    },
    showProvider(id) {
      this.$refs.showModalComponent.openForm(id);
    },
    openForm(id) {
      this.emitter.emit("open-modal-edit-provider", id);
    },
    getProviderType(provider) {
      if (provider.provider_type_id == null) {
        return provider.other_provider_type;
      }

      return provider.provider_type_name;
    },
  },
  mounted() {
    this.loadData();
    this.emitter.off("reload-list-of-providers");
    this.emitter.on("reload-list-of-providers", () => {
      this.loadData();
    });
  },
};
</script>
<style scoped>
.container {
  width: -webkit-fill-available;
  height: fit-content !important;
}

.el-table {
  border-radius: 10px;
}

.el-icon-view {
  color: var(--blue);
  margin-right: 5px;
  font-size: 19px;
  cursor: pointer;
}

.paginate {
  margin: auto;
  text-align: end;
}

.el-row {
  width: inherit;
}

.el-button {
  background: var(--blue);
  color: white;
}

.is-disabled {
  background: #8080809c;
}

.is-disabled:hover {
  background: #8080809c;
}

::v-deep(.el-table thead) {
  color: var(--blue) !important;
}

.el-select {
  background: var(--blue);
  color: white;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.el-select-dropdown__item.selected {
  color: var(--blue);
}
.properties {
  color: darkorange;
  margin: 0 10px 0 10px;
  font-size: 20px;
  cursor: pointer;
}
</style>
