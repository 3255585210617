<template>
  <el-container>
    <el-row>
      <el-col :span="24">
        <br /><br />
        <el-header>
          <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/reports' }">
              Reportes
            </el-breadcrumb-item>
            <el-breadcrumb-item> Reporte de estados </el-breadcrumb-item>
          </el-breadcrumb>
        </el-header>
      </el-col>
    </el-row>

    <el-form
      ref="model"
      class="animate__animated animate__fadeIn"
      :model="model"
      label-position="top"
      :rules="rules"
    >
      <el-row>
        <el-col :span="11">
          <el-form-item label="Selecciona un proyecto" prop="contractID">
            <SelectContract v-model="model.contractID" />
          </el-form-item>
        </el-col>

        <el-col :span="7" :offset="2">
          <el-form-item label="Selecciona los estados en ejecución">
            <SelectStatus multiple v-model="selectedStates" />
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <div class="search-button">
            <el-button type="primary" @click="search()"
              >Obtener reporte</el-button
            >
          </div>
        </el-col>
      </el-row>
    </el-form>

    <div class="information" v-if="this.reports != null">
      <el-row>
        <el-col class="center-icon" :span="1">
          <el-tooltip content="Descargar pdf" placement="top" effect="light">
            <i
              @click="download('pdf', 'model')"
              class="fa-regular fa-file-pdf icon-download-pdf"
            ></i>
          </el-tooltip>
        </el-col>
        <el-col class="center-icon" :span="1">
          <el-tooltip content="Descargar excel" placement="top" effect="light">
            <i
              @click="download('excel', 'model')"
              class="fa-regular fa-file-excel icon-download-excel"
            ></i> </el-tooltip
        ></el-col>
      </el-row>

      <el-table
        :data="reports"
        :border="parentBorder"
        row-class-name="state-row"
        highlight-current-row="true"
        header-align="center"
        align="center"
        class="table"
        v-if="this.reports != null"
      >
        <el-table-column type="expand">
          <template #default="props">
            <el-table
              :data="props.row.events"
              header-align="center"
              style="width: 100%"
            >
              <el-table-column label="ID" prop="consecutive" width="300" />
              <el-table-column label="Evento" prop="event_name" width="500" />
              <el-table-column label="Proyecto" prop="contract" width="500" />
              <el-table-column label="Valor evento" width="200">
                <template #default="props1">
                  {{ toCurrency(parseInt(props1.row.value)) }}
                </template>
              </el-table-column>

              <el-table-column label="Valor factura" width="200">
                <template #default="props1">
                  {{ toCurrency(parseInt(props1.row.invoice_value)) }}
                </template>
              </el-table-column>
            </el-table>
          </template>
        </el-table-column>

        <el-table-column label="Estado" prop="status" />
        <el-table-column label="Cantidad" prop="quantity" />
        <el-table-column label="Valor total">
          <template #default="props">
            {{ toCurrency(parseInt(props.row.total_value)) }}
          </template>
        </el-table-column>
      </el-table>
    </div>
  </el-container>
</template>

<script>
import SelectContract from "../contracts/SelectContract.vue";
import { ElLoading, ElMessage, ElNotification } from "element-plus";
import SelectStatus from "../events/Status/SelectStatus.vue";
import { toCurrency } from "@/config/Money.js";
import { postData } from "../../request/request";
import { downloadDataPOST } from "../../request/request";

export default {
  name: "States",
  components: { SelectContract, SelectStatus },
  data() {
    return {
      loading: false,
      selectedStates: null,
      reports: null,
      model: {
        contractID: "",
      },
      rules: {
        contractID: [
          {
            required: true,
            message: "Por favor selecciona un proyecto",
            trigger: "blur",
          },
        ],
      },
    };
  },
  setup() {
    return {
      toCurrency,
    };
  },
  methods: {
    validateForm(formName) {
      let res;
      this.$refs[formName].validate((valid) => {
        res = valid;
      });
      return res;
    },

    async search() {
      if (!this.validateForm("model") || this.selectedStates.length == 0) {
        ElMessage.error("¡ Error !, Por favor verifica todos los campos");
        return;
      }
      this.loading = ElLoading.service();
      await postData(
        `api/v1/reports/${this.model.contractID}/states`,
        { states: this.selectedStates },
        true
      )
        .then((res) => {
          if (res.error === true || res.error === undefined) {
            ElNotification({
              title: "Error",
              message: res.message,
              type: "error",
            });
            return;
          }
          if (
            res.data !== undefined &&
            res.data !== null &&
            res.data.length === 0
          ) {
            this.reports = [];
            return;
          }
          this.reports = res.data;
        })
        .catch(() => {
          ElNotification({
            title: "Error",
            message: "Ocurrió un error al hacer la petición",
            type: "error",
          });
        });
      this.loading.close();
    },

    download(type, formName) {
      if (!this.validateForm(formName)) {
        ElMessage.error("¡ Error !, Por favor verifica todos los campos");
        return;
      }

      this.loading = ElLoading.service();
      downloadDataPOST(
        `api/v1/reports/${this.model.contractID}/states/${type}`,
        {
          states: this.selectedStates,
        },
        "Reporte de estados." + (type == "excel" ? "xls" : "pdf")
      )
        .then(this.loading.close())
        .catch(() => {
          ElMessage({
            title: "Error",
            message: "Ocurrió un error al hacer la petición",
            type: "error",
          });
        });
    },
  },
};
</script>
<style scoped>
.el-breadcrumb {
  font-size: 20px;
}

.el-container {
  display: block;
  margin: 0 10px;
  padding: 0 10px;
}

.search-button {
  margin-left: 13px;
  margin-top: 50px;
}

.table {
  margin-top: 2%;
}

::v-deep(.state-row) {
  background-color: #eaeaea;
  border-bottom: var(--blue);
}

::v-deep(.state-row td) {
  background-color: #eaeaea;
  border-bottom: 1px solid var(--blue);
}

.center-icon {
  display: flex;
  justify-content: left;
  align-items: flex-end;
}

.information i {
  font-size: 25px;
}

/* Works on Chrome, Edge, and Safari */
::v-deep(.el-table__body-wrapper::-webkit-scrollbar) {
  width: 10px !important;
  height: 8px !important;
}

::v-deep(.el-table__body-wrapper::-webkit-scrollbar-track) {
  background-color: rgb(233, 231, 231) !important;

  border-radius: 10px !important;
}

::v-deep(.el-table__body-wrapper::-webkit-scrollbar-thumb) {
  background-color: rgb(163, 163, 163) !important;
  border-radius: 10px !important;
}
</style>
