<template>
  <el-form
    label-position="top"
    ref="model"
    :model="model"
    :rules="rules"
    :loading="loading"
  >
    <el-form-item label="Selecciona un departamento" prop="department_id">
      <el-select
        v-model="model.department_id"
        filterable
        placeholder="Selecciona un departamento"
        v-on:change="getCities($event)"
        style="width: 100%"
        ref="selectDepartament"
      >
        <el-option
          v-for="department in departments"
          :key="department.id"
          :value="department.id"
          :label="department.name"
        >
        </el-option>
      </el-select>
    </el-form-item>

    <el-form-item label="Selecciona una ciudad" prop="city_id">
      <el-select
        v-model="model.city_id"
        filterable
        placeholder="Selecciona una ciudad"
        style="width: 100%"
        ref="selectCity"
      >
        <el-option
          v-for="city in cities"
          :key="city.id"
          :value="city.id"
          :label="city.name"
        >
        </el-option>
      </el-select>
    </el-form-item>
  </el-form>
</template>

<script>
import { getData } from "../request/request";
import { ElNotification } from "element-plus";

export default {
  name: "SelectDepartmentsCities",
  created() {
    this.getDepartments();
  },
  data() {
    return {
      loading: false,
      departments: [],
      cities: [],
      model: {
        department_id: "",
        city_id: "",
      },
      rules: {
        department_id: [
          {
            required: true,
            message: "Por favor selecciona un departamento",
            trigger: "change",
          },
        ],
        city_id: [
          {
            required: true,
            message: "Por favor selecciona una ciudad",
            trigger: "change",
          },
        ],
      },
    };
  },
  methods: {
    async getDepartments() {
      await getData("api/v1/departments", {}, true)
        .then((res) => {
          if (res.error == true || res.error == undefined) {
            ElNotification({
              title: "Error",
              message: res.message,
              type: "error",
            });
          } else {
            this.departments = res.data;
          }
        })
        .catch(() => {
          ElNotification({
            title: "Error",
            message: "Ocurrió un error al hacer la petición",
            type: "error",
          });
        });
    },
    async getCities(departmentID) {
      this.cities = [];
      await getData(`api/v1/cities/${departmentID}`, {}, true)
        .then((res) => {
          if (res.error == true || res.error == undefined) {
            ElNotification({
              title: "Error",
              message: res.message,
              type: "error",
            });
          } else {
            this.cities = res.data;
          }
        })
        .catch(() => {
          ElNotification({
            title: "Error",
            message: "Ocurrió un error al hacer la petición",
            type: "error",
          });
        });
    },
    async getDepartamentbyCity(cityID) {
      await getData("api/v1/cities/departament/" + cityID)
        .then((data) => {
          if (data.error == true) {
            ElNotification({
              title: "Error",
              message: "Ocurrió un error al solicitar el departamento",
              type: "error",
            });
          } else {
            this.model.department_id = data.data.id;
            this.getCities(this.model.department_id);
            this.model.city_id = cityID;
          }
        })
        .catch(() => {
          ElNotification({
            title: "Error",
            message: "Ocurrió un error al hacer la petición",
            type: "error",
          });
        });
    },
    selectCity(cityID) {
      if (cityID != null || cityID != undefined) {
        this.getDepartamentbyCity(cityID);
      }
    },
    selectedItem() {
      return this.$refs.selectCity.selectedLabel;
    },
    getInformation() {
      return {
        departament_id: this.model.department_id,
        departament_name: this.$refs.selectDepartament.selectedLabel,
        city_id: this.model.city_id,
        city_name: this.$refs.selectCity.selectedLabel,
      };
    },
    validateForm(formName) {
      let res;
      this.$refs[formName].validate((valid) => {
        res = valid;
      });
      return res;
    },
    cleanForm(formName) {
      if (this.$refs[formName] != undefined) {
        this.$refs[formName].resetFields();
      }
    },
  },
};
</script>

<style scoped></style>
