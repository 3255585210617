<template>
  <div>
    <el-popover
      placement="right-start"
      title="Agregar consumo"
      :width="200"
      trigger="hover"
      content="Abrir formulario para seleccionar un consumo"
    >
      <template #reference>
        <el-button class="btn-plus" type="text" @click="dialogVisible = true"
          ><i class="el-icon-plus"></i
        ></el-button>
      </template>
    </el-popover>

    <el-dialog v-model="dialogVisible" width="65%" title="Seleccionar consumo">
      <el-form
        ref="model"
        label-position="top"
        :model="model"
        v-loading="loading"
      >
        <el-row>
          <el-col :span="24">
            <el-form-item label="Insumo" prop="event_price_id">
              <el-select
                v-model="model.event_price_id"
                placeholder="Buscar consumo"
                no-data-text="No hay coincidencias."
                loading-text="Buscando..."
                filterable
                remote
                reserve-keyword
                :remote-method="searchSupplyUsages"
                :loading="loading"
                style="width: 100%"
              >
                <el-option
                  v-for="supply in supplyUsages"
                  :key="supply.name"
                  :label="supply.name"
                  :value="supply.id"
                >
                </el-option>
              </el-select>
            </el-form-item> </el-col
        ></el-row>
      </el-form>
      <br />
      <div class="button" align="center">
        <el-button type="primary" @click="getUsages()"
          >Cargar consumo</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getData } from "../../request/request.js";
import { ElNotification } from "element-plus";

export default {
  name: "SelectSupplyUsage",
  props: {
    idEvent: Number,
    event: String,
  },
  data() {
    return {
      supplyUsages: [],
      model: {
        event_price_id: "",
      },
      loading: false,
      dialogVisible: false,
      query: "",
    };
  },
  mounted() {
    this.getSupplyUsages();
  },
  methods: {
    async getSupplyUsages() {
      await getData(
        `api/v1/events/${this.idEvent}/usages/prefix`,
        { query: this.query },
        true
      )
        .then((res) => {
          if (res.error == true || res.error == undefined) {
            ElNotification({
              title: "Error",
              message: res.message,
              type: "error",
            });
          }
          this.supplyUsages = res.data;
        })
        .catch(() => {
          ElNotification({
            title: "Error",
            message: "Ocurrió un error al hacer la petición",
            type: "error",
          });
        });
    },

    getUsages() {
      if (this.event_price_id == "") {
        ElNotification({
          title: "Error",
          message: "Debe de seleccionar un consumo",
          type: "error",
        });
        return;
      }

      this.loading = true;
      getData(
        `api/v1/events/${this.idEvent}/usages/${this.model.event_price_id}`,
        {},
        true
      )
        .then((res) => {
          if (res.error == true || res.error == undefined) {
            ElNotification({
              title: "Error",
              message: res.message,
              type: "error",
            });
          }

          this.emitter.emit(this.event, {
            event_price_id: this.model.event_price_id,
            supply: {
              id: res.data.supply.id,
              name: res.data.supply.name,
            },
            usages: res.data.usages,
          });
          this.loading = false;
          this.dialogVisible = false;
        })
        .catch(() => {
          ElNotification({
            title: "Error",
            message: "Ocurrió un error al hacer la petición",
            type: "error",
          });
          this.loading = false;
        });
    },
    searchSupplyUsages(query) {
      query = query.trim();
      if (query === "" || query.length < 2) {
        return;
      }
      this.query = query;
      this.getSupplyUsages();
    },
  },
};
</script>

<style scoped>
.status-add {
  display: flex;
  align-content: space-between;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
}
</style>
