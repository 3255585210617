<template>
  <el-container class="container">
    <el-row>
      <el-col :span="20" class="animate__animated animate__fadeInRight">
        <div style="margin-top: 15px; margin-bottom: 15px">
          <el-input
            v-model="query"
            placeholder="Escriba la búsqueda"
            class="input-with-select"
            v-on:keyup.enter="search"
          >
            <template #prepend>
              <el-select
                v-model="type"
                placeholder="Select"
                style="width: 150px"
              >
                <el-option
                  label="Contenido"
                  value="content"
                  selected
                ></el-option>
              </el-select>
            </template>
            <template #append>
              <el-button @click="search" icon="el-icon-search"></el-button>
            </template>
          </el-input>
        </div>
      </el-col>
      <el-col class="paginate animate__animated animate__fadeInRight" :span="4">
        <el-button
          icon="el-icon-arrow-left"
          @click="prev"
          :disabled="prevEnable"
        >
        </el-button>
        <label style="margin: 0 15px 0 15px"> {{ page }} </label>
        <el-button
          icon="el-icon-arrow-right"
          @click="next"
          :disabled="nextEnable"
        >
        </el-button>
      </el-col>
      <el-col
        :span="24"
        class="animate__animated animate__fadeInRight animate__delay-1s"
      >
        <el-table
          v-loading="loading"
          :data="tableData"
          :empty-text="'No hay registros'"
          style="border-radius: 10px"
          stripe
        >
          <el-table-column prop="date" label="Fecha" width="170">
            <template #default="scope">
              {{ formatDateAndHour(scope.row.created_at) }}
            </template>
          </el-table-column>
          <el-table-column prop="user_name" label="Usuario" width="200" />
          <el-table-column prop="content" label="Contenido" />
        </el-table>
      </el-col>
    </el-row>
  </el-container>
</template>
<script>
import { getData } from "../../request/request.js";
import { formatDateAndHour } from "../../config/Date.js";
export default {
  name: "NotesList",
  props: {
    event: Object,
  },
  setup() {
    return {
      formatDateAndHour,
    };
  },
  components: {},
  data() {
    return {
      tableData: [],
      loading: false,
      prevEnable: true,
      nextEnable: false,
      query: "",
      type: "content",
      page: 1,
    };
  },
  mounted() {
    this.getNotes();
    this.emitter.off("reload-list-of-notes");
    this.emitter.on("reload-list-of-notes", () => {
      this.getNotes();
    });
  },
  methods: {
    getNotes() {
      this.loading = true;
      getData("api/v1/events/" + this.event.id + "/notes", {
        page: this.page,
        type: this.type,
        query: this.query,
      })
        .then((res) => {
          this.tableData = res.data;
          this.page = res.current_page;
          this.prevEnable = res.prev_page_url == null;
          this.nextEnable = res.next_page_url == null;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    search() {
      this.page = 1;
      this.getNotes();
    },
    prev() {
      this.page--;
      this.getNotes();
    },
    next() {
      this.page++;
      this.getNotes();
    },
  },
};
</script>
<style scoped>
.container {
  width: -webkit-fill-available;
  height: fit-content !important;
}

.el-table {
  border-radius: 10px;
}

.el-icon-view {
  color: var(--blue);
  margin-right: 5px;
  font-size: 19px;
  cursor: pointer;
}

.paginate {
  margin: auto;
  text-align: end;
}

.el-row {
  width: inherit;
}

.el-button {
  background: var(--blue);
  color: white;
}

.is-disabled {
  background: #8080809c;
}

.is-disabled:hover {
  background: #8080809c;
}

::v-deep(.el-table thead) {
  color: var(--blue) !important;
}

.el-select {
  background: var(--blue);
  color: white;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.el-select-dropdown__item.selected {
  color: var(--blue);
}
.properties {
  color: darkorange;
  margin: 0 10px 0 10px;
  font-size: 20px;
}
</style>
