<template>
  <div class="navigation">
    <ul>
      <li>
        <router-link tag="a" :to="{ name: 'ChangePassword' }">
          <span class="icon"> <ion-icon name="person-outline"></ion-icon></span>
          <span class="title">Mi perfil</span>
        </router-link>
      </li>
      <li
        v-for="item in items"
        :key="item.label"
        v-bind:class="{ selected: isSelected(item.prefixPath) }"
      >
        <!-- TODO: import icons from local svg-->
        <router-link tag="a" :to="{ name: item.pathName }" :data-cy="item.prefixPath">
          <span class="icon">
            <ion-icon :name="item.iconName"></ion-icon>
          </span>
          <span class="title">{{ item.label }} </span>
        </router-link>
      </li>

      <li>
        <a href="" v-on:click.prevent="logOut()">
          <span class="icon"><ion-icon name="log-out-outline"></ion-icon></span>
          <span class="title">Cerrar Sesión</span>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import { postData } from "@/request/request.js";
import { ElNotification } from "element-plus";

export default {
  name: "Sidenav",
  mounted() {
    this.items = JSON.parse(localStorage.getItem("sidenav-items"));
  },
  methods: {
    isSelected(prefix) {
      let path = this.$route.fullPath;
      //if path begin with prefix
      if (path.lastIndexOf(prefix, 1) === 1) {
        return true;
      }
      return false;
    },
    async logOut() {
      await postData("api/v1/auth/logout", {}, true)
        .then((data) => {
          if (data.error == true) {
            ElNotification({
              title: "Error",
              message: data.message,
              type: "error",
            });
          } else {
            ElNotification({
              title: "Sesión Cerrada",
              message: "Sesión cerrada con éxito",
              type: "success",
            });
          }
          this.emitter.emit("logout-success");
        })
        .catch(() => {
          ElNotification({
            title: "Error",
            message: "Ocurrió un error al hacer la petición",
            type: "error",
          });
          this.emitter.emit("logout-success");
        });
    },
  },
  data() {
    return {
      items: [],
    };
  },
};
</script>

<style scoped>
* {
  padding: 0;
  margin: 0;
}
:root {
  --blue: #083249;
  --red: #c20d0e;
  --white: #e7eae1;
}
body {
  min-height: 100vh;
  overflow-x: hidden;
}
.navigation {
  width: 230px;
}

.navigation ul {
  top: 0;
  left: 0;
  padding-left: 10px;
}
.navigation ul li {
  position: relative;
  width: 100%;
  list-style: none;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.navigation ul li:hover {
  background: var(--white);
}
.navigation ul li:nth-child(1) {
  margin-bottom: 50px;
}

.navigation ul li a {
  position: relative;
  display: block;
  width: 100%;
  display: flex;
  text-decoration: none;
  color: white;
}
.navigation ul li.selected a,
.navigation ul li:hover a {
  color: var(--blue);
}
.navigation ul li a .icon {
  position: relative;
  display: block;
  min-width: 60px;
  height: 60px;
  line-height: 73px;
  text-align: center;
}
.navigation ul li a .icon ion-icon {
  font-size: 1.75em;
}
.navigation ul li a .title {
  position: relative;
  display: block;
  padding: 0 10px;
  height: 60px;
  line-height: 60px;
  text-align: start;
  white-space: nowrap;
}
.navigation ul li.selected a::before,
.navigation ul li:hover a::before {
  transition: 1.3s;
  content: "";
  position: absolute;
  right: 0;
  top: -50px;
  width: 50px;
  height: 50px;
  background: transparent;
  border-radius: 20px;
  box-shadow: 35px 30px 0 10px var(--white);
  pointer-events: none;
}
.navigation ul li.selected a::after,
.navigation ul li:hover a::after {
  transition: 1.3s;
  content: "";
  position: absolute;
  right: 0;
  bottom: -50px;
  width: 50px;
  height: 50px;
  background: transparent;
  border-radius: 20px;
  box-shadow: 35px -35px 0 10px var(--white);
  pointer-events: none;
}
.selected {
  transition: 1.3s;
  background: var(--white);
  color: var(--blue) !important;
}
</style>
