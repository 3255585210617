<template>
  <div>
    <el-switch
      v-model="currentStatus"
      active-color="#13ce66"
      inactive-color="#ff4949"
      :before-change="changeStatus"
      :loading="loading"
    />
  </div>
</template>
<script>
import { ElMessage } from "element-plus";
import { postData } from "../request/request.js";
export default {
  name: "Switch",
  props: {
    initStatus: Boolean,
    endpoint: String,
  },
  data() {
    return {
      currentStatus: true,
      loading: false,
    };
  },
  mounted() {
    this.currentStatus = this.initStatus;
  },
  methods: {
    async changeStatus() {
      this.loading = true;
      return new Promise((resolve) => {
        try {
          postData(this.endpoint, { status: !this.currentStatus }, true)
            .then((res) => {
              this.loading = false;
              if (res.error) {
                ElMessage.error("No se logró cambiar el estado");
                return resolve(false);
              }

              ElMessage.success(res.message);
              return resolve(true);
            })
            .catch(() => {
              this.loading = false;
              ElMessage.error("No se logró cambiar el estado");
              return resolve(false);
            });
        } catch (error) {
          this.loading = false;
          ElMessage.error("Ocurrió un error al hacer la petición");
          return resolve(false);
        }
      });
    },
  },
};
</script>
