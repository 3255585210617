<template>
  <el-dialog
    v-model="dialogVisible"
    title="Editar Proveedor"
    width="55%"
    :before-close="handleClose"
  >
    <el-steps :active="step" finish-status="success" align-center simple>
      <el-step title="Proveedor" icon="el-icon-circle-plus"></el-step>
      <el-step title="Contactos" icon="el-icon-user"></el-step>
      <el-step title="Documentos" v-if="hasPermits('documents.delete_file_provider')" icon="el-icon-document-add"></el-step>
    </el-steps>

    <div class="bodyModal" align="center">
      <!-- step 1 -->
      <div v-if="step == 0">
        <el-row>
          <el-col :span="16" :offset="4">
            <el-form
              ref="model"
              :model="model"
              label-position="top"
              :rules="rules"
              v-loading="loading"
            >
              <el-form-item
                label="Tipo de identificación"
                prop="identification_type_id"
              >
                <SelectIdentification v-model="model.identification_type_id" />
              </el-form-item>

              <el-form-item label="No. Identificación" prop="identification">
                <el-input v-model="model.identification"></el-input>
              </el-form-item>

              <el-form-item label="Nombre completo" prop="full_name">
                <el-input v-model="model.full_name"></el-input>
              </el-form-item>

              <SelectProviderType ref="provider_type" />

              <el-form-item label="Dirección" prop="address">
                <el-input v-model="model.address"></el-input>
              </el-form-item>

              <el-form-item label="Teléfono" prop="phone">
                <el-input v-model="model.phone"></el-input>
              </el-form-item>

              <el-form-item label="Correo" prop="email">
                <el-input v-model="model.email"></el-input>
              </el-form-item>

              <subsidiaries ref="subsidiaries" />

              <h3 align="left">Información financiera</h3>
              <el-form-item label="Tipo de pago acordado" prop="payment_type">
                <el-input v-model="model.payment_type"></el-input>
              </el-form-item>
              <el-form-item label="Número de cuenta" prop="account_number">
                <el-input v-model="model.account_number"></el-input>
              </el-form-item>
              <el-form-item label="Banco" prop="bank">
                <select-bank v-model="model.bank" />
              </el-form-item>
              <el-form-item label="Tipo de cuenta" prop="account_type">
                <el-select
                  v-model="model.account_type"
                  class="m-2"
                  style="width: 100%"
                  placeholder="Selecciona un tipo de cuenta"
                >
                  <el-option
                    v-for="item in accountTypeOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                label="Nombre del beneficiario"
                prop="beneficiary_name"
              >
                <el-input v-model="model.beneficiary_name"></el-input>
              </el-form-item>
              <el-form-item
                label="Identificación del beneficiario"
                prop="account_identification"
              >
                <el-input v-model="model.account_identification"></el-input>
              </el-form-item>

              <el-form-item label="Retención" prop="retention">
                <el-input-number
                  v-model="model.retention"
                  :precision="2"
                  :step="0.1"
                  :min="0"
                  :max="100"
                />
              </el-form-item>
            </el-form>
          </el-col>
        </el-row>
        <el-row justify="center">
          <div class="button">
            <el-button type="primary" @click="updateProvider('model')"
              >Actualizar Proveedor</el-button
            >
          </div>
          <div class="button">
            <el-button type="primary" @click="step++"
              >Editar Contactos</el-button
            >
            <el-button id="steps-button" @click="step = 2" type="success"
              >Editar Documentos</el-button
            >
          </div>
        </el-row>
      </div>

      <el-form v-if="step === 1" align="left">
        <Contacts :endpoint="contactsEndpoint" :edit="true" />
        <title>hola</title>
        <div id="boton" justify="end" align="center">
          <el-button id="steps-button" @click="back" type="success"
            >Editar Proveedor</el-button
          >
          <el-button id="steps-button" v-if="hasPermits('documents.delete_file_provider')" @click="next" type="success"
            >Editar Documentos</el-button
          >
        </div>
      </el-form>

      <el-form v-if="step === 2" justify="center">
        <EditDocument :endpoint="documentsEndpoint"  deletePermit="delete_file_provider" />
        <div id="boton" justify="end">
          <el-button id="steps-button" @click="back" type="success"
            >Editar contactos</el-button
          >
        </div>
      </el-form>
    </div>
  </el-dialog>
</template>

<script>
import SelectIdentification from "../SelectIdentification.vue";
import { defineComponent, ref } from "vue";
import { ElMessageBox } from "element-plus";
import { putData, getData } from "../../request/request.js";
import { ElNotification } from "element-plus";
import { ElMessage } from "element-plus";
import SelectProviderType from "./SelectProviderType.vue";
import Subsidiaries from "./Subsidiaries.vue";
import SelectBank from "./SelectBank.vue";
import { hasPermits } from "@/config/Auth.js";

export default defineComponent({
  name: "EditProvider",
  components: {
    SelectProviderType,
    SelectIdentification,
    Subsidiaries,
    SelectBank,
  },
  beforeCreate() {
    this.$options.components.EditDocument =
      require("../documents/EditDocument.vue").default;
    this.$options.components.Contacts =
      require("../../views/Contacts.vue").default;
  },
  mounted() {
    this.emitter.off("open-modal-edit-provider");
    this.emitter.on("open-modal-edit-provider", (id) => {
      this.beforeOpenForm();
      this.idProvider = id;
      this.getProvider();
      this.contactsEndpoint = "api/v1/contacts/provider/" + this.idProvider;
      this.documentsEndpoint = "api/v1/documents/provider/" + this.idProvider;
      this.dialogVisible = true;
    });
    this.emitter.off("document-update-success");
    this.emitter.on("document-update-success", () => {
      this.dialogVisible = false;
    });
  },
  setup() {
    const dialogVisible = ref(false);

    const handleClose = (done) => {
      ElMessageBox.confirm(
        "Estas seguro que quieres salir de editar proveedor?",
        {
          showCancelButton: true,
          confirmButtonText: "Confirmar",
          cancelButtonText: "Cancelar",
        }
      )
        .then(() => {
          done();
        })
        .catch(() => {
          // catch error
        });
    };
    return {
      hasPermits,
      dialogVisible,
      handleClose,
    };
  },
  data() {
    return {
      step: 0,
      idProvider: 0,
      identificationTypes: [],
      accountTypeOptions: [
        {
          value: null,
          label: "",
        },
        {
          value: "ahorros",
          label: "Ahorros",
        },
        {
          value: "corriente",
          label: "Corriente",
        },
      ],
      model: {
        identification_type_id: "",
        identification: "",
        full_name: "",
        address: "",
        phone: "",
        email: "",
        payment_type: "",
        account_number: "",
        retention: "",
        account_identification: "",
        bank: "",
        account_type: "",
        beneficiary_name: "",
      },
      documentsEndpoint: "",
      contactsEndpoint: "",
      loading: false,
      rules: {
        identification_type_id: [
          {
            required: true,
            message: "Por favor ingresa el tipo de identificación",
            trigger: "change",
          },
        ],
        identification: [
          {
            required: true,
            message: "Por favor ingresa una identificación",
            trigger: "blur",
          },
        ],
        full_name: [
          {
            required: true,
            message: "Por favor ingresa un nombre",
            trigger: "blur",
          },
        ],
        address: [
          {
            required: true,
            message: "Por favor ingresa una dirección",
            trigger: "blur",
          },
        ],
        phone: [
          {
            required: true,
            message: "Por favor ingresa un teléfono",
            trigger: "blur",
          },
        ],
        department_id: [
          {
            required: true,
            message: "Por favor selecciona el departamento",
            trigger: "change",
          },
        ],
        email: [
          {
            required: true,
            message: "Por favor ingresa un correo",
            trigger: "blur",
          },
          {
            type: "email",
            message: "Por favor ingresa una dirección de correo válido",
            trigger: "blur",
          },
        ],
        other_provider_type: [
          {
            min: 3,
            message:
              "El otro tipo de proveedor debe contener minimo 3 caracteres",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    next() {
      if (this.step++ > 2) this.step = 0;
    },
    back() {
      if (this.step-- < 0) this.step = 0;
    },
    validateForm(formName) {
      let res;
      this.$refs[formName].validate((valid) => {
        res = valid;
      });
      return res;
    },

    updateOther() {
      this.model.other_provider_type = "";
    },

    async updateProvider(formName) {
      this.loadingCreate = true;

      let v1 = this.validateForm(formName);
      let v2 = this.$refs.provider_type.validateForm();
      let v3 = this.$refs.subsidiaries.validate();

      if (!v1 || !v2 || !v3) {
        ElMessage.error("¡ Error !, Por favor verifica todos los campos");
        this.loadingCreate = false;

        return;
      }

      let providerInformation = this.$refs.provider_type.getInformation();
      this.model.provider_type_id = providerInformation.provider_type_id;
      this.model.other_provider_type = providerInformation.other_provider_type;
      this.model.cities = this.$refs.subsidiaries.getIds();

      await putData(`api/v1/providers/edit/${this.idProvider}`, this.model)
        .then((data) => {
          this.loading = false;
          if (data.error == true || data.error == undefined) {
            ElNotification({
              title: "Error",
              message: data.message,
              type: "error",
            });

            return;
          }

          ElNotification({
            title: "Proveedor actualizado",
            message: data.message,
            type: "success",
          });

          this.emitter.emit("reload-list-of-providers");
        })
        .catch(() => {
          ElNotification({
            title: "Error",
            message: "Ocurrió un error al hacer la petición",
            type: "error",
          });
          this.loading = false;
        });
    },

    async getProvider() {
      this.loading = true;
      await getData(`api/v1/providers/${this.idProvider}`, {}, true)
        .then((res) => {
          if (res.error == true || res.error == undefined) {
            ElNotification({
              title: "Error",
              message: res.message,
              type: "error",
            });
          }
          this.model = res.data;
          this.$refs.provider_type.setInformation(
            res.data.provider_type_id,
            res.data.other_provider_type
          );

          let subsidiaries = new Array();
          this.model.cities.forEach((element) => {
            subsidiaries.push({
              id: element.id,
              city: element.name,
              departament: element.departament.name,
            });
          });
          this.$refs.subsidiaries.setInformation(subsidiaries);
        })
        .catch(() => {
          ElNotification({
            title: "Error",
            message: "Ocurrió un error al hacer la petición clientes",
            type: "error",
          });
        });

      this.loading = false;
    },

    beforeOpenForm() {
      this.step = 0;
      let form = this.$refs["model"];
      if (form) {
        form.resetFields();
      }

      if (this.$refs.subsidiaries) {
        this.$refs.subsidiaries.reset();
      }

      this.model = {
        identification_type_id: "",
        identification: "",
        full_name: "",
        provider_type: "",
        address: "",
        phone: "",
        department_id: "",
        other_provider_type: "",
        email: "",
        payment_type: "",
        account_number: "",
        retention: "",
        account_identification: "",
        bank: "",
        account_type: "",
        beneficiary_name: "",
      };
    },
  },
});
</script>

<style scoped>
.bodyModal {
  margin-top: 10px;
}
.button {
  margin: 10px;
}
.el-button--danger {
  background: #bc0304;
  border: none;
}

.el-button--success {
  background: #003149;
  border: none;
}

.el-button--primary {
  background: var(--blue) !important;
  border: 1px solid var(--blue) !important;
}

.el-button--text {
  color: var(--blue) !important;
  font-weight: bold;
  font-size: 25px;
}
</style>
