export const APP_HOST = process.env.VUE_APP_HOST || "https://goodwind.com.co/";
export const REPORTS_PATH_PREFIX = "reports";

export const MODULE_TO_SIDENAV_ITEM = {
  buyers: {
    label: "Compradores",
    pathName: "Buyers",
    prefixPath: "buyers",
    iconName: "people-outline",
  },

  clients: {
    label: "Clientes",
    pathName: "Clients",
    prefixPath: "clients",
    iconName: "people-outline",
  },
  providers: {
    label: "Proveedores",
    pathName: "Providers",
    prefixPath: "providers",
    iconName: "cube-outline",
  },
  contracts: {
    label: "Proyectos",
    pathName: "Contracts",
    prefixPath: "contracts",
    iconName: "create-sharp",
  },
  users: {
    label: "Usuarios",
    pathName: "Users",
    prefixPath: "users",
    iconName: "person-add-outline",
  },
  supplies: {
    label: "Insumos",
    pathName: "Supplies",
    prefixPath: "supplies",
    iconName: "fast-food-outline",
  },
  events: {
    label: "Eventos",
    pathName: "Events",
    prefixPath: "events",
    iconName: "megaphone-outline",
  },
  payments: {
    label: "Pagos",
    pathName: "Payments",
    prefixPath: "payments",
    iconName: "wallet-outline",
  },
  general_quotation: {
    label: "Cotizador",
    pathName: "GeneralQuotations",
    prefixPath: "general-quotations",
    iconName: "receipt-outline",
  },
  reports: {
    label: "Reportes",
    pathName: "Reports",
    prefixPath: "reports",
    iconName: "stats-chart-outline",
  },
};
