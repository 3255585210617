<template>
  <div class="bg-white animate__animated animate__fadeInRight">
    <el-form>
      <el-row class="margin-input">
        <el-col :span="24">
          <el-form @submit.prevent="" label-position="top">
            <el-form-item label="Observaciones" prop="budget_observation">
              <el-input
                v-model="this.model.budget_observation"
                type="textarea"
              ></el-input>
            </el-form-item>
          </el-form>
          <div class="button" align="center">
            <el-button
              :loading="loadingSave"
              type="primary"
              @click="save('model')"
              >Guardar observación presupuesto</el-button
            >
          </div>
          <br />
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { ElNotification } from "element-plus";
import { putData } from "../../request/request.js";
import "animate.css";

export default defineComponent({
  name: "EditBudgetObservations",
  props: {
    event: Object,
  },
  mounted() {
    this.model = this.event;
  },
  data() {
    return {
      loadingSave: false,
      model: {
        budget_observation: "",
      },
    };
  },
  methods: {
    async save() {
      this.loadingSave = true;
      await putData("api/v1/events/" + this.model.id, this.model, true)
        .then((res) => {
          this.loadingSave = false;

          if (res.error == true || res.error == undefined) {
            ElNotification({
              title: "Error",
              message: res.message,
              type: "error",
            });

            return;
          }
          ElNotification({
            title: "Evento editado correctamente",
            message: res.message,
            type: "success",
          });
          this.emitter.emit("reload-list-of-events");
        })
        .catch(() => {
          ElNotification({
            title: "Error",
            message: "Ocurrió un error al hacer la petición",
            type: "error",
          });
          this.loadingSave = false;
        });
    },
  },
});
</script>

<style scoped>
.bg-white {
  background: white;
  border-radius: 5px;
  border: 0px;
}
.margin-input {
  margin: 10px;
}
.button {
  margin-top: 20px;
}
</style>
