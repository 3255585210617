<template>
  <el-container>
    <el-row>
      <el-col> <br /><br /><br /> </el-col>
      <el-col :xs="0" :sm="0" :md="4" :lg="6" :xl="8"> </el-col>
      <el-col :xs="24" :sm="24" :md="16" :lg="12" :xl="8">
        <div class="card">
          <h1>Mi perfil</h1>
          <el-row>
            <el-col :span="12" class="labels">
              <p>
                Nombre completo:<br />
                Correo:<br />
                Rol:<br />
                Comprador:<br />
              </p>
            </el-col>
            <el-col :span="12" class="values">
              <p>
                {{ user_information.name }}<br />
                {{ user_information.email }}<br />
                {{ user_information.role }}<br />
                {{ user_information.buyer }}<br />
              </p>
            </el-col>
          </el-row>
          <el-row>
            <el-col class="text-center">
              <br /><br />
              <el-button
                v-if="!enable_change_password"
                type="primary"
                @click="enable_change_password = true"
              >
                <i class="fa-solid fa-arrows-rotate"></i> Cambiar contraseña
              </el-button>
              <el-form
                v-else
                v-on:submit.prevent="changePassword"
                ref="form"
                :rules="rules"
                :model="model"
              >
                <el-form-item class="text-left" prop="old_password">
                  <label for="">Contraseña actual</label>
                  <el-input
                    class="border"
                    v-model="model.old_password"
                    placeholder="Contraseña actual"
                    prefix-icon="fa-solid fa-key"
                    type="password"
                  ></el-input>
                </el-form-item>
                <br />
                <el-form-item class="text-left" prop="new_password">
                  <label for="">Nueva contraseña</label>
                  <el-input
                    class="border"
                    v-model="model.new_password"
                    placeholder="Nueva contraseña"
                    prefix-icon="fa-solid fa-key"
                    type="password"
                  ></el-input>
                </el-form-item>
                <br />
                <el-form-item
                  class="text-left"
                  prop="new_password_confirmation"
                >
                  <label for="">Confirmar nueva contraseña</label>
                  <el-input
                    class="border"
                    v-model="model.new_password_confirmation"
                    placeholder="Confirmar nueva contraseña"
                    prefix-icon="fa-solid fa-key"
                    type="password"
                  ></el-input>
                </el-form-item>
                <br />
                <el-form-item>
                  <el-button
                    :loading="loading"
                    class="forgot-button"
                    type="primary"
                    native-type="submit"
                    block
                    ><i class="fa-solid fa-floppy-disk"></i> Guardar nueva
                    contraseña</el-button
                  >
                </el-form-item>
              </el-form>
            </el-col></el-row
          >
        </div>
      </el-col>
    </el-row>
  </el-container>
</template>
<script>
import "element-plus/dist/index.css";
import { getData, postData } from "../request/request";
import { ElNotification } from "element-plus";

export default {
  name: "Profile",
  data() {
    return {
      user_information: {
        name: "",
        email: "",
        role: "",
        buyer: "",
      },
      model: {
        old_password: "",
        new_password: "",
        new_password_confirmation: "",
      },
      loading: false,
      enable_change_password: false,
      rules: {
        old_password: [
          {
            required: true,
            message: "La actual contraseña es requerida",
            trigger: "blur",
          },
        ],
        new_password: [
          {
            required: true,
            message: "La nueva contraseña es requerida",
            trigger: "blur",
          },
        ],
        new_password_confirmation: [
          {
            required: true,
            message: "Debe confirmar la nueva contraseña",
            trigger: "blur",
          },
        ],
      },
    };
  },
  mounted() {
    this.getUserInformation();
  },
  methods: {
    async changePassword() {
      let valid = await this.$refs.form.validate();
      if (valid) {
        this.loading = true;
        await postData("api/v1/auth/change-password", this.model, true)
          .then((res) => {
            if (res.error == true) {
              ElNotification({
                title: "Error",
                message: res.message,
                type: "error",
              });
              return;
            }

            ElNotification({
              title: "Contraseña actualizada",
              message: res.message,
              type: "success",
            });

            this.enable_change_password = false;
            this.model = {
              old_password: "",
              new_password: "",
              new_password_confirmation: "",
            };
          })
          .catch(() => {
            ElNotification({
              title: "Error",
              message: "Ocurrió un error al hacer la petición",
              type: "error",
            });
          });
        this.loading = false;
      }
    },
    getUserInformation() {
      getData("api/v1/auth/me", this.model)
        .then((res) => {
          if (res.error == true) {
            ElNotification({
              title: "Error",
              message: res.message,
              type: "error",
            });

            return;
          }

          this.user_information = {
            name: res.data.full_name,
            email: res.data.email,
            role: res.data.role_name.name,
            buyer: res.data.buyer.name,
          };
        })
        .catch(() => {
          ElNotification({
            title: "Error",
            message: "Ocurrió un error al hacer la petición",
            type: "error",
          });
        });
    },
  },
};
</script>

<style scoped>
h1 {
  font-size: 32px;
  text-align: center;
}

.el-row {
  width: 100%;
  height: fit-content;
}

.el-col {
  height: fit-content;
}

.card {
  background: white;
  border-radius: 10px;
  padding: 24px;
  margin: 12px;
}

.labels {
  line-height: 1.8em;
}

.values {
  line-height: 1.8em;
  text-align: end;
  color: grey;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}
</style>
