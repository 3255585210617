<template>
  <el-container>
    <el-row>
      <el-col class="header" :span="24">
        <br /><br />
        <el-header>
          <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/reports' }">
              Reportes
            </el-breadcrumb-item>
            <el-breadcrumb-item
              :to="{ path: `/${REPORTS_PATH_PREFIX}/graphics` }"
            >
              Gráficas
            </el-breadcrumb-item>
            <el-breadcrumb-item>Estados del proyecto </el-breadcrumb-item>
          </el-breadcrumb>
        </el-header>
      </el-col>
    </el-row>

    <el-form
      ref="model"
      :model="model"
      :rules="rules"
      label-position="top"
      class="animate__animated animate__fadeIn"
    >
      <el-row class="form">
        <el-col :span="10">
          <el-form-item label="Selecciona un proyecto" prop="project_id">
            <el-select
              v-model="model.project_id"
              placeholder="Buscar proyectos"
              no-data-text="No hay proyectos."
              loading-text="Buscando..."
              filterable
              remote
              reserve-keyword
              :remote-method="searchProject"
              :loading="loadingSelect"
              style="width: 100%"
            >
              <el-option
                v-for="project in projects"
                :key="project.id"
                :label="project.name"
                :value="project.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="4">
          <div v-if="model.project_id != null">
            <el-button class="show-button" @click="getStatus" type="primary">
              Ver gráfica
            </el-button>
          </div>
        </el-col>
        <el-col :span="24">
          <el-tooltip
            v-if="status.length > 0"
            content="Generar pdf"
            placement="right"
            effect="light"
          >
            <i @click="generatePDF" class="fa-regular fa-file-pdf icon-pdf"></i>
          </el-tooltip>
        </el-col>
      </el-row>
    </el-form>

    <el-row>
      <el-col :span="24" align="center" v-if="status.length > 0">
        <h2 align="center">ESTADOS POR PROYECTO</h2>
        <div class="chart">
          <canvas id="myChart"></canvas>
          <div class="total">
            <span>Total: {{ total }} </span>
          </div>
        </div>
      </el-col>
    </el-row>
  </el-container>
</template>
<script>
import Chart from "chart.js/auto";
import { ElLoading, ElMessage, ElNotification } from "element-plus";
import { REPORTS_PATH_PREFIX } from "../../../config/constants.js";
import { getData, downloadDataPOST } from "@/request/request.js";
export default {
  name: "StatusChart",
  data() {
    return {
      loading: false,
      loadingSelect: false,
      projects: [],
      status: [],
      chart: null,
      query: "",
      labels: [],
      data: [],
      total: 0,
      model: {
        project_id: null,
      },
      rules: {
        project_id: [
          {
            required: true,
            message: "Por favor selecciona un proyecto",
            trigger: "blur",
          },
        ],
      },
    };
  },
  setup() {
    return {
      REPORTS_PATH_PREFIX,
    };
  },
  mounted() {
    this.cleanForm("model");
    this.getProjects();
  },
  methods: {
    async getProjects() {
      await getData("api/v1/contracts/prefix", { query: this.query })
        .then((res) => {
          if (res.error == true || res.error == undefined) {
            ElNotification({
              title: "Error",
              message: res.message,
              type: "error",
            });
            return;
          }
          this.projects = res.data;
        })
        .catch(() => {
          ElNotification({
            title: "Error",
            message: "Ocurrió un error al hacer la petición",
            type: "error",
          });
        });
    },

    async getStatus() {
      if (await this.validateForm("model")) {
        this.loading = ElLoading.service();
        await getData(`api/v1/charts/${this.model.project_id}/statuses`)
          .then((res) => {
            this.cleanChartData();
            if (this.verifyResult(res)) {
              this.status = res.data;
              this.processStatus();
              setTimeout(() => {
                this.draw(this.total);
                this.loading.close();
              }, 1000);
            }
          })
          .catch(() => {
            ElNotification({
              title: "Error",
              message: "Ocurrió un error al hacer la petición.",
              type: "error",
            });
            this.cleanChartData();
            this.loading.close();
          });
        return;
      }
      ElMessage.error("Error, selecciona un proyecto.");
    },

    generatePDF() {
      this.loading = ElLoading.service();
      let image64 = this.getImage() != null ? this.getImage() : null;
      downloadDataPOST(
        "api/v1/charts/pdf-charts",
        {
          title: "Estados por proyecto",
          total: `TOTAL ESTADOS: ${this.total}`,
          image: image64,
        },
        "Estador por proyectos.pdf"
      );
      this.loading.close();
    },

    searchProject(query) {
      query = query.trim();
      if (query === "" || query.length < 2) {
        return;
      }

      this.query = query;
      this.getProjects();
    },

    verifyResult(res) {
      if (res.error == true || res.error == undefined) {
        ElNotification({
          title: "Error",
          message: res.message,
          type: "error",
        });
        this.loading.close();
        return false;
      }
      if (res.data.length == 0) {
        ElNotification({
          title: "Información",
          message: "No hay estados para mostrar en el proyecto seleccionado.",
          type: "info",
        });
        this.loading.close();
        return false;
      }
      return true;
    },

    draw(total) {
      const ctx = document.getElementById("myChart").getContext("2d");
      const alwasShowTooltip = {
        id: "alwasShowTooltip",
        afterDraw(chart) {
          const { ctx } = chart;
          ctx.save();

          chart.data.datasets.forEach((dataset, i) => {
            chart.getDatasetMeta(i).data.forEach((datapoint, index) => {
              const { x, y } = datapoint.tooltipPosition();
              let percentaje =
                (chart.data.datasets[i].data[index] * 100) / total;
              const text = parseFloat(percentaje.toFixed(2)) + "%";
              let textWidth = ctx.measureText(text).width;

              ctx.fillStyle = "rgba(0,0,0,0.8)";
              ctx.fillRect(
                x - (textWidth + 10) / 2,
                y - 25,
                textWidth + 10,
                20
              );

              //triangle
              ctx.beginPath();
              ctx.moveTo(x, y);
              ctx.lineTo(x - 5, y - 5);
              ctx.lineTo(x + 5, y - 5);
              ctx.fill();
              ctx.restore();

              //text
              ctx.font = "12px";
              ctx.fillStyle = "white";
              ctx.fillText(text, x - textWidth / 2, y - 12);
              ctx.restore();
            });
          });
        },
      };

      this.chart = new Chart(ctx, {
        type: "doughnut",
        data: {
          labels: this.labels,
          datasets: [
            {
              data: this.data,
              backgroundColor: [
                "rgb(255, 73, 73)",
                "rgb(255, 205, 56)",
                "rgb(47, 164, 255)",
                "rgb(0, 255, 221)",
                "rgb(232, 255, 194)",
                "rgb(14, 24, 95)",
                "rgb(255, 0, 117)",
                "rgb(251, 255, 0)",
                "rgb(47, 164, 255)",
                "rgb(0, 255, 171)",
                "rgb(84, 22, 144)",
                "rgb(255, 141, 41)",
                "rgb(29, 185, 195)",
                "rgb(112, 39, 160)",
                "rgb(195, 43, 173)",
                "rgb(245, 111, 173)",
                "rgb(0, 120, 170)",
                "rgb(58, 180, 242)",
                "rgb(242, 223, 58)",
                "rgb(242, 223, 58)",
                "rgb(246, 246, 246)",
              ],
              borderColor: [
                "rgb(255, 73, 73)",
                "rgb(255, 205, 56)",
                "rgb(47, 164, 255)",
                "rgb(0, 255, 221)",
                "rgb(232, 255, 194)",
                "rgb(14, 24, 95)",
                "rgb(255, 0, 117)",
                "rgb(251, 255, 0)",
                "rgb(47, 164, 255)",
                "rgb(0, 255, 171)",
                "rgb(84, 22, 144)",
                "rgb(255, 141, 41)",
                "rgb(29, 185, 195)",
                "rgb(112, 39, 160)",
                "rgb(195, 43, 173)",
                "rgb(245, 111, 173)",
                "rgb(0, 120, 170)",
                "rgb(58, 180, 242)",
                "rgb(242, 223, 58)",
                "rgb(242, 223, 58)",
                "rgb(246, 246, 246)",
              ],
              hoverOffset: 4,
            },
          ],
        },

        options: {
          responsive: true,
          maintainAspectRatio: true,
          plugins: {
            legend: {
              position: "bottom",
              labels: { font: { size: 15.5 } },
            },
            tooltip: {
              enabled: false,
            },
          },
          interaction: {
            intersect: false,
          },
        },
        plugins: [
          {
            afterRender: function (chart) {
              if (chart != null) {
                chart.$rendered = true;
              }
            },
          },
          alwasShowTooltip,
        ],
      });
    },

    async validateForm(formName) {
      let res = false;
      await this.$refs[formName].validate((valid) => {
        res = valid;
      });
      return res;
    },

    processStatus() {
      for (let state of this.status) {
        this.labels.push(state.name + " - " + state.total);
        this.data.push(state.total);
        this.total += state.total;
      }
    },

    cleanChartData() {
      if (this.chart) {
        this.chart.destroy();
        this.chart.$rendered = false;
      }
      this.labels = [];
      this.data = [];
      this.status = [];
      this.total = 0;
    },

    getImage() {
      if (this.chart.$rendered) {
        let canvas = document.getElementById("myChart");
        return canvas.toDataURL();
      }
    },

    cleanForm(formName) {
      if (this.$refs[formName] != undefined) {
        this.$refs[formName].resetFields();
      }
    },
  },
};
</script>

<style scoped>
.el-container {
  display: block;
}
.chart {
  padding: auto;
  height: 40%;
  width: 40%;
  margin-bottom: 3%;
}
.el-breadcrumb {
  font-size: 20px;
}
.total {
  display: flex;
  justify-content: flex-end;
  font-weight: bolder;
}

.form {
  margin: 0 20px;
}

.icon-pdf {
  color: rgb(22, 52, 219);
  margin: 0 10px 0 10px;
  font-size: 20px;
  cursor: pointer;
}

.show-button {
  margin: 50px 27px;
}
</style>
