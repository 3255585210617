<template>
  <el-select
    v-model="status_id"
    placeholder="Buscar estado"
    no-data-text="No hay coincidencias."
    loading-text="Buscando..."
    filterable
    remote
    reserve-keyword
    :remote-method="searchStates"
    :loading="loading"
    style="width: 100%"
    @input="$emit('update:status_id', $event.target.value)"
  >
    <el-option
      v-for="state in states"
      :key="state.name"
      :label="state.name"
      :value="state.id"
    >
    </el-option>
  </el-select>
</template>

<script>
import { getData } from "@/request/request.js";
import { ElNotification } from "element-plus";

export default {
  name: "SelectStatus",
  mounted() {
    this.getStates();
  },
  data() {
    return {
      states: [],
      status_id: "",
      loading: false,
      query: "",
    };
  },
  methods: {
    async getStates() {
      this.loading = true;
      await getData("api/v1/status/prefix", { query: this.query }, true)
        .then((res) => {
          if (res.error == true || res.error == undefined) {
            ElNotification({
              title: "Error",
              message: res.message,
              type: "error",
            });
          }
          this.states = res.data;
          this.loading = false;
        })
        .catch(() => {
          ElNotification({
            title: "Error",
            message: "Ocurrió un error al hacer la petición",
            type: "error",
          });
          this.loading = false;
        });
    },

    searchStates(query) {
      query = query.trim();
      if (query === "" || query.length < 3) {
        return;
      }
      this.query = query;
      this.getStates();
    },
  },
};
</script>

<style scoped>
.status-add {
  display: flex;
  align-content: space-between;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
}
</style>
