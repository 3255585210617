<template>
  <el-container class="container">
    <el-row>
      <el-col :span="20">
        <div
          style="margin-top: 15px; margin-bottom: 15px"
          class="animate__animated animate__fadeIn"
        >
          <el-input
            v-model="query"
            :placeholder="placeHolderValues[type]"
            class="input-with-select"
            v-on:keyup.enter="search"
          >
            <template #prepend>
              <el-select
                v-model="type"
                placeholder="Select"
                style="width: 150px"
              >
                <el-option label="Concepto" value="concept"></el-option>
                <el-option label="Usuario" value="user_name"></el-option>
                <el-option label="Evento" value="event"></el-option>
              </el-select>
            </template>
            <template #append>
              <el-button @click="search" icon="el-icon-search"></el-button>
            </template>
          </el-input>
        </div>
      </el-col>
      <el-col class="paginate" :span="4">
        <div style="margin-top: 15px; margin-bottom: 15px">
          <el-button
            icon="el-icon-arrow-left"
            @click="prev"
            :disabled="prevEnable"
          >
          </el-button>
          <label style="margin: 0 15px 0 15px"> {{ page }} </label>
          <el-button
            icon="el-icon-arrow-right"
            @click="next"
            :disabled="nextEnable"
          >
          </el-button>
        </div>
      </el-col>
      <el-col :span="24" class="animate__animated animate__fadeInRight">
        <el-table
          v-loading="loading"
          :data="tableData"
          style="border-radius: 10px"
          :empty-text="'No hay registros'"
          @sort-change="sort"
          stripe
        >
          <el-table-column
            sortable
            prop="consecutive"
            label="ID"
            width="60"
          />
          <el-table-column sortable prop="user_name" label="Usuario" />
          <el-table-column sortable prop="event_consecutive" label="Id Evento" />
          <el-table-column sortable prop="event" label="Evento Name" />
          <el-table-column sortable min-width="200" prop="concept" label="Concepto" />
          <el-table-column
            sortable
            min-width="100"
            :sort-method="sortNumber('amount')"
            prop="amount"
            label="Valor"
          >
            <template #default="scope">
              {{ toCurrency(parseInt(scope.row.amount)) }}
            </template>
          </el-table-column>
          <el-table-column label="Fechas" width="300">
            <template #default="scope">
              <p>
                <b>Creación: </b> {{ formatDateAndHour(scope.row.created_at)
                }}<br />
                <b>Vencimiento: </b>
                {{ formatDateAndHour(scope.row.expiration_date) }}<br />
                <b>Aprobación compras: </b>
                {{
                  showThisData(
                    formatDateAndHour(scope.row.accept_advance_seller_date),
                    "No aplica"
                  )
                }}<br />
                <b>Aprobación gerencia: </b>
                {{
                  showThisData(
                    formatDateAndHour(scope.row.accept_advance_admin_date),
                    "No aplica"
                  )
                }}<br />
                <b>Carga comprobante: </b>
                {{
                  showThisData(
                    formatDateAndHour(scope.row.upload_payment_date),
                    "No aplica"
                  )
                }}<br />
              </p>
            </template>
          </el-table-column>
          <el-table-column sortable min-width="150" prop="observations" label="Observaciones" />
          <el-table-column
            sortable
            min-width="150"
            :sort-method="sortText('status')"
            label="Estado"
          >
            <template #default="scope">
              {{
                scope.row.status == "autorizado vendedor"
                  ? "Aprobado compras"
                  : scope.row.status == "autorizado administrador"
                  ? "Aprobado gerencia "
                  : scope.row.status
              }}
            </template>
          </el-table-column>
          <el-table-column
            sortable
            min-width="150"
            :sort-method="sortText('provider')"
            label="Proveedor"
          >
            <template #default="scope">
              {{ scope.row.provider }}
            </template>
          </el-table-column>
          <el-table-column label="Acciones" align="center">
            <template #default="scope">
              <el-tooltip
                v-if="
                  hasPermits('events.register_payment') &&
                  scope.row.status == 'autorizado administrador'
                "
                content="Cargar comprobante de pago"
                placement="left"
                effect="light"
              >
                <ion-icon
                  title=""
                  name="cloud-upload-outline"
                  @click="uploadPayment(scope.row.id)"
                  class="icon-upload"
                ></ion-icon>
              </el-tooltip>
              <el-tooltip
                v-if="hasPermits('events.show') && scope.row.status == 'pagado'"
                content="Ver comprobante de pago"
                placement="left"
                effect="light"
              >
                <ion-icon
                  title=""
                  name="glasses-outline"
                  @click="getDocument(scope.row.document_id)"
                  class="icon-see"
                ></ion-icon>
              </el-tooltip>

              <el-tooltip
                v-if="
                  hasPermits('events.approve_advance_sales') &&
                  scope.row.status == 'solicitado'
                "
                content="Aprobar pago compras "
                placement="left"
                effect="light"
              >
                <ion-icon
                  title=""
                  name="checkmark-circle-outline"
                  @click="
                    acceptAdvance(
                      scope.row.event_id,
                      scope.row.id,
                      'acceptseller'
                    )
                  "
                  class="icon-accept"
                ></ion-icon>
              </el-tooltip>
              <el-tooltip
                v-if="
                  hasPermits('events.approve_advance_admin') &&
                  scope.row.status == 'autorizado vendedor'
                "
                content="Aprobar pago gerencia"
                placement="left"
                effect="light"
              >
                <ion-icon
                  title=""
                  name="checkmark-circle-outline"
                  @click="
                    acceptAdvance(
                      scope.row.event_id,
                      scope.row.id,
                      'acceptadmin'
                    )
                  "
                  class="icon-accept"
                ></ion-icon>
              </el-tooltip>
              <el-tooltip
                placement="left"
                content="Abrir evento"
                effect="light"
              >
                <div>
                  <i
                    class="fa-solid fa-arrow-up-right-from-square icon-load-manage-event"
                    @click="loadManageEvent(scope.row.event_id)"
                  ></i>
                </div>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
  </el-container>
</template>


<script>
import { getData, putData, getRawData } from "@/request/request.js";
import { ElMessageBox, ElNotification } from "element-plus";
import { toCurrency } from "@/config/Money.js";
import { formatDateAndHour } from "../../../config/Date.js";
import { hasPermits } from "@/config/Auth.js";
import { showThisData } from "../../../config/Helper.js";

export default {
  name: "ListGeneral",
  setup() {
    return {
      toCurrency,
      formatDateAndHour,
      hasPermits,
      showThisData,
    };
  },
  data() {
    return {
      tableData: [],
      placeHolderValues: {
        "": "Seleciona opción de búsqueda",
        concept: "Ingresa el concepto",
        user_name: "Ingresa el nombre del usuario quién creo el pago",
        event: "ingrese el nombre del evento",
      },
      query: "",
      type: "concept",
      loading: false,
      prevEnable: true,
      nextEnable: false,
      page: 1,
      advanceID: "",
      rol: {
        acceptseller: "aprobado compras",
        acceptadmin: "aprobado gerencia",
      },
      sortConfig: {},
    };
  },
  mounted() {
    this.emitter.off("reload-list-of-advances");
    this.emitter.on("reload-list-of-advances", () => {
      this.page = 1;
      this.getAdvances();
    });
    this.getAdvances();
  },
  methods: {
    async getAdvances() {
      this.loading = true;
      await getData("api/v1/advances", {
        page: this.page,
        type: this.type,
        query: this.query,
        sort: JSON.stringify(this.sortConfig),
      })
        .then((data) => {
          if (data.error == true) {
            ElNotification({
              title: "Error",
              message:
                "Ocurrió un error al solicitar los anticipos de este evento",
              type: "error",
            });
          } else {
            this.tableData = data.data;
            this.page = data.current_page;
            this.prevEnable = data.prev_page_url == null;
            this.nextEnable = data.next_page_url == null;
          }
        })
        .catch(() => {
          ElNotification({
            title: "Error",
            message: "Ocurrió un error al hacer la petición",
            type: "error",
          });
        });
      this.loading = false;
    },
    search() {
      this.page = 1;
      this.getAdvances();
    },
    prev() {
      this.page--;
      this.getAdvances();
    },
    next() {
      this.page++;
      this.getAdvances();
    },
    async acceptAdvance(eventID, advanceID, rolType) {
      ElMessageBox.confirm(
        "¿Seguro desea cambiar el estado del pago a " + this.rol[rolType] + "?",
        "Advertencia",
        {
          confirmButtonText: "Si",
          cancelButtonText: "No",
          type: "warning",
        }
      )
        .then(() => {
          this.loading = true;
          putData(`api/v1/events/${eventID}/advances/${advanceID}/${rolType}`)
            .then((res) => {
              if (res.error == true || res.error == undefined) {
                ElNotification({
                  title: "Error",
                  message: res.message,
                  type: "error",
                });

                return;
              }
              ElNotification({
                title: "Pago actualizado",
                message: res.message,
                type: "success",
              });
              this.getAdvances();
            })
            .catch(() => {
              ElNotification({
                title: "Error",
                message: "Ocurrió un error al hacer la petición",
                type: "error",
              });
            });
        })
        .catch(() => {});
    },
    uploadPayment(pay) {
      this.emitter.emit("advance-upload-payment", pay);
    },
    async getDocument(documentID) {
      await getRawData(`api/v1/documents/getUrl/${documentID}`)
        .then((data) => {
          if (data === "") {
            ElNotification({
              title: "Error",
              message: "Ocurrió un problema al solicitar el documento",
              type: "error",
            });
          }
          window.open(data, "_blank");
        })
        .catch(() => {
          ElNotification({
            title: "Error",
            message: "Ocurrió un error al hacer la petición",
            type: "error",
          });
        });
    },

    async sort(data) {
      if (data.prop === null) {
        this.sortConfig = {};
        await this.getAdvances();
      }

      this.sortConfig = {
        column: data.prop,
        order: data.order,
      };

      await this.getAdvances();
    },

    sortText(prop) {
      return function (a, b) {
        return a[prop].localeCompare(b[prop]);
      };
    },
    sortNumber(prop) {
      return function (a, b) {
        return a[prop] * 1 > b[prop] * 1;
      };
    },
    loadManageEvent(event_id) {
      window.open("/manage_event/" + event_id, "_blank");
    },
  },
};
</script>
<style scoped>
.container {
  width: -webkit-fill-available;
  height: fit-content !important;
}

.el-table {
  border-radius: 10px;
}

.el-icon-view {
  color: var(--blue);
  margin-right: 5px;
  font-size: 19px;
  cursor: pointer;
}

.paginate {
  margin: auto;
  text-align: end;
}

.el-row {
  width: inherit;
}

.el-button {
  background: var(--blue);
  color: white;
}

.is-disabled {
  background: #8080809c;
}

.is-disabled:hover {
  background: #8080809c;
}

::v-deep(.el-table thead) {
  color: var(--blue) !important;
}

.el-select {
  background: var(--blue);
  color: white;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.el-select-dropdown__item.selected {
  color: var(--blue);
}
.properties {
  color: darkorange;
  margin: 0 10px 0 10px;
  font-size: 20px;
}
.icon-accept,
.icon-load-manage-event {
  color: rgb(2, 138, 13);
  margin: 0 10px 0 10px;
  font-size: 20px;
  cursor: pointer;
}

.icon-upload {
  color: rgb(22, 52, 219);
  margin: 0 10px 0 10px;
  font-size: 20px;
  cursor: pointer;
}

.icon-see {
  color: rgb(22, 52, 219);
  margin: 0 10px 0 10px;
  font-size: 20px;
  cursor: pointer;
}

ion-icon {
  color: darkorange;
  margin: 0 10px 0 10px;
  font-size: 20px;
  cursor: pointer;
}

::v-deep(.ionicon) {
  pointer-events: none !important;
}
</style>
