<template>
  <el-config-provider :locale="locale">
    <div id="app">
      <el-container>
        <el-aside
          v-if="!isAuthRoute($route)"
          height="100vh"
          width="20%"
          style="overflow: hidden"
        >
          <Sidenav />
        </el-aside>
        <el-container class="pad">
          <router-view />
        </el-container>
      </el-container>
    </div>
  </el-config-provider>
</template>

<script>
import { isAuthRoute, refreshTokenAuto, getModules } from "@/config/Auth.js";
import Sidenav from "./components/Sidenav.vue";
import { ElConfigProvider } from "element-plus";
import { MODULE_TO_SIDENAV_ITEM } from "./config/constants.js";
import es from "element-plus/lib/locale/lang/es";

export default {
  setup() {
    return {
      isAuthRoute,
      locale: es,
    };
  },
  name: "App",
  components: {
    Sidenav,
    ElConfigProvider,
  },
  mounted() {
    this.emitter.off("login-success");
    this.emitter.on("login-success", () => {
      this.refreshLogin = refreshTokenAuto(this.emitter);

      let modules = Object.keys(getModules());
      let items = [];

      modules.forEach((module) => {
        if (MODULE_TO_SIDENAV_ITEM[module] !== undefined) {
          items.push(MODULE_TO_SIDENAV_ITEM[module]);
        }
      });

      localStorage.setItem("sidenav-items", JSON.stringify(items));

      if (items.length != 0) {
        this.$router.push({ name: "Home" });
      }
    });
    this.emitter.off("logout-success");
    this.emitter.on("logout-success", () => {
      clearInterval(this.refreshLogin);
      localStorage.clear();
      this.$router.push("/");
    });
  },
};
</script>

<style>
:root {
  --blue: #083249;
  --el-button-active-border-color: #083249;
  --red: #c20d0e;
  --white: #e7eae1;
  --el-button-background-color: #083249;
  --el-color-primary: #083249;
  --el-table-row-hover-background-color: #eaeaea;
  --el-header-height: 30px;
}

.icon-download-pdf {
  color: #9a2222;
  font-size: 2.5rem;
  cursor: pointer;
}
.icon-download-excel {
  color: #197331;
  font-size: 2.5rem;
  cursor: pointer;
}

@font-face {
  font-family: "Microsoft YaHei";
  src: local("Microsoft YaHei"),
    url(./assets/fonts/microsoft-yahei.ttf) format("truetype");
}

th,
td,
.cell {
  word-break: break-word !important;
}

#app {
  font-family: "Microsoft YaHei" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #e7eae1;
  height: 100vh;
}
body {
  font-family: "Microsoft YaHei" !important;
  margin: 0;
  padding: 0;
  background: #e7eae1;
  background-size: contain;
  height: 100vh;
}
.el-container {
  height: 100%;
}
.pad {
  padding-left: 8px;

  overflow-y: auto;
}

.navigation {
  width: 100%;
  height: 100%;
  background: var(--blue);
  transition: 0.5s;
  overflow: hidden;
}

.el-button--primary {
  background: var(--blue);
  border: 1px solid var(--blue);
}

.el-button--primary:hover {
  background: var(--blue);
  border: 1px solid var(--blue);
}

.el-button--primary:active{
  background: var(--blue);
  border: 1px solid var(--blue);
}

.el-button--primary:focus{
  background: var(--blue);
  border: 1px solid var(--blue);
}

.el-aside {
  width: auto;
}

.el-breadcrumb{
  font-size: 18px !important;
}
</style>
