<template>
  <el-select
    v-model="status_id"
    size="small"
    style="width: 100%"
    @change="changeStatus"
    filterable
    align="center"
  >
    <el-option
      v-for="state in this.statuses"
      :key="state.name"
      :label="state.name"
      :value="state.id"
      align="center"
    >
      <div v-if="state.id == 'new'" class="plus-icon">
        <ion-icon name="add-circle-outline"></ion-icon>
        <p style="color: black">
          {{ state.name }}
        </p>
      </div>
      <span v-else>{{ state.name }}</span>
    </el-option>
  </el-select>
</template>

<script>
import { postData } from "@/request/request.js";
import { event_status_state } from "@/state/event_status.js";
import { ElNotification, ElLoading } from "element-plus";

export default {
  name: "ChangeStatus",
  props: {
    status: Number,
    event_id: Number,
  },
  created() {
    event_status_state.init(this);
  },
  mounted() {
    this.emitter.on("reload-list-of-event-statuses", () => {
      this.statuses = event_status_state.getItems();
      this.status_id = this.status;
    });
  },
  data() {
    return {
      statuses: event_status_state.getItems(),
      status_id: this.status,
    };
  },
  methods: {
    async changeStatus() {
      if (this.status_id == "new") {
        this.emitter.emit("add-event-status");
        return;
      }

      var loading = ElLoading.service();

      await postData(
        `api/v1/events/${this.event_id}/set-status/${this.status_id}`,
        null,
        true
      )
        .then((res) => {
          if (res.error === true || res.error === undefined) {
            ElNotification({
              title: "Error",
              message: res.message,
              type: "error",
            });
            return;
          }

          ElNotification({
            title: "Estado cambiado",
            message: res.message,
            type: "success",
          });
        })
        .catch(() => {
          ElNotification({
            title: "Error",
            message: "Ocurrió un error al hacer la petición",
            type: "error",
          });
        });
      loading.close();
    },
  },
};
</script>

<style scoped>
.el-select {
  background: white !important;
}

.plus-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.plus-icon ion-icon {
  color: white;
  display: inline-block;
  background: var(--blue);
  font-size: 22px;
  height: 25px;
  border-radius: 50%;
  width: 25px;
  margin-right: 10px;
}

.plus-icon p {
  margin: 0px;
  color: black;
  display: inline-block;
}
</style>
