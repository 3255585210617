<template>
  <el-select
    v-model="role_id"
    placeholder="Selecciona un rol"
    style="width: 100%"
    @input="$emit('update:role_id', $event.target.value)"
  >
    <el-option
      v-for="role in roles"
      :key="role.id"
      :label="role.name"
      :value="role.id"
    >
    </el-option>
  </el-select>
</template>
<script>
import { getData } from "../../request/request.js";
import { ElNotification } from "element-plus";

export default {
  name: "SelectRole",
  setup() {},
  data() {
    return {
      roles: [],
      role_id: "",
    };
  },
  mounted() {
    this.getRoles();
  },
  methods: {
    async getRoles() {
      await getData("api/v1/roles", {}, true)
        .then((data) => {
          if (data.error == true || data.error == undefined) {
            ElNotification({
              title: "Error",
              message: data.message,
              type: "error",
            });
          } else {
            this.roles = data.data;
          }
        })
        .catch(() => {
          ElNotification({
            title: "Error",
            message: "Ocurrió un error al hacer la petición",
            type: "error",
          });
        });
    },
  },
};
</script>
