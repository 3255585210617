<template>
  <el-upload
    ref="upload"
    v-loading="loadingGetDocuments"
    class="document-form"
    :auto-upload="false"
    :on-change="add"
    :file-list="fileList"
    :on-remove="remove"
    :before-remove="beforeRemove"
  >
    <template #tip>
      <div class="el-upload__tip">Agrega todos los documentos deseados</div>
    </template>
    <template #trigger>
      <el-tooltip
        class="item"
        effect="light"
        content="Agregar archivo"
        placement="left"
      >
        <i class="el-icon-document-add add-file"></i>
      </el-tooltip>
    </template>

    <el-tooltip
      class="item"
      effect="light"
      content="Guardar Archivos"
      placement="right"
    >
      <i @click="update" class="el-icon-upload upload-files"></i>
    </el-tooltip>
    <FormDataDocument ref="modal" />
  </el-upload>
</template>
<script>
import FormDataDocument from "./FormDataDocument.vue";
import { ElLoading } from "element-plus";
import { putData, getData, putDocument } from "../../request/request.js";
import { ElNotification } from "element-plus";
import { validateDocument } from "../../config/Document.js";
import { hasPermits } from "@/config/Auth.js";

export default {
  components: { FormDataDocument },
  name: "EditDocument",
  props: {
    endpoint: String,
    deletePermit: String,
  },
  data() {
    return {
      fileList: [],
      currentFile: null,
      showFormData: true,
      filesInfo: {},
      loading: null,
      loadingGetDocuments: false,
      uploadErrorCount: 0,
    };
  },
  mounted() {
    this.emitter.off("edit-document-from-form");
    this.emitter.on("edit-document-from-form", (model) => {
      this.filesInfo[this.currentFile.uid] = Object.assign({}, model);
    });

    this.emitter.off("remove-document-from-form-edit");
    this.emitter.on("remove-document-from-form-edit", () => {
      var index = this.fileList.indexOf(this.currentFile);
      if (index > -1) {
        this.fileList.splice(index, 1);
      }
    });
    this.getDocuments();
  },
  methods: {
    beforeRemove(uploadFile, uploadFiles) {
      if(!hasPermits(`documents.${this.deletePermit}`) && uploadFile.id !== undefined){
        ElNotification({
          title: "Error",
          message: "No tiene permisos para eliminar archivos",
          type: "error",
        });

        return false;
      }

      return true;
    },
    async getDocuments() {
      this.loadingGetDocuments = true;
      await getData(this.endpoint)
        .then((documents) => {
          if (documents.error == true) {
            ElNotification({
              title: "Error",
              message: "Ocurrió un error al solicitar los documentos",
              type: "error",
            });
          } else {
            documents.data.forEach((res) => {
              let newName = res.file.split(".");
              this.fileList.push({
                id: res.id,
                uid: res.id,
                name: res.description + " " + newName[1],
              });
              this.filesInfo[res.id] = {
                id: res.id,
                uid: res.id,
                name: res.description + " " + newName[1],
              };
            });
          }
        })
        .catch(() => {
          ElNotification({
            title: "Error",
            message: "Ocurrió un error al hacer la petición",
            type: "error",
          });
        });
      this.loadingGetDocuments = false;
    },

    update() {
      let key = "id";

      let newFileList = [];
      let oldFileList = [];
      this.loading = ElLoading.service();
      this.fileList.forEach((file) => {
        if (Object.prototype.hasOwnProperty.call(file, key)) {
          newFileList.push(file);
        } else {
          oldFileList.push(file);
        }
      });

      putData(this.endpoint, newFileList)
        .then((res) => {
          if (res.error == true) {
            ElNotification({
              title: "Error",
              message: res.message,
              type: "error",
            });
          } else {
            var temp = 0;
            for (var i = 0; i < this.fileList.length; i++) {
              if (Object.prototype.hasOwnProperty.call(this.fileList[i], key)) {
                this.fileList.splice(temp, 1);
                i--;
                continue;
              }
              temp++;
            }
          }
          this.checkIfUploadFinished();
        })
        .catch((err) => {
          ElNotification({
            title: "Error",
            message: err,
            type: "error",
          });
          this.checkIfUploadFinished();
        }),
      (this.uploadErrorCount = 0);
      oldFileList.forEach((file) => {
        let form = new FormData();
        form.append("file", file.raw);
        form.append("description", this.filesInfo[file.uid].description);
        form.append(
          "document_type_id",
          this.filesInfo[file.uid].document_type_id
        );
        putDocument(this.endpoint, form)
          .then((res) => {
            if (res.error) {
              this.uploadErrorCount++;
              ElNotification({
                title: "Error",
                message: res.message,
                type: "error",
              });
            } else {
              var index = this.fileList.indexOf(file);
              if (index > -1) {
                this.fileList.splice(index, 1);
              }
            }
            this.checkIfUploadFinished();
          }, file)
          .catch(() => {
            this.uploadErrorCount++;
            this.checkIfUploadFinished();
          });
      });
    },
    checkIfUploadFinished() {
      let temp = false;
      if (this.fileList.length - this.uploadErrorCount === 0) {
        if (this.uploadErrorCount == 0) {
          ElNotification({
            title: "Documentos actualizados",
            message: "Todos los documentos fueron actualizados correctamente.",
            type: "success",
          });
          temp = true;
        } else {
          ElNotification({
            title: "Error",
            message:
              "Algunos documentos no pudieron ser guardados correctamente, " +
              "puede reintentarlo o ponerse en contacto para solucionar su problema.",
            type: "error",
          });
        }
        this.loading.close();
        if (temp == true) {
          this.emitter.emit("document-update-success");
        }
      }
    },
    add(file, fileList) {
      if (validateDocument(file, fileList)) {
        this.currentFile = file;
        this.fileList = fileList;
        this.$refs.modal.openForm(2);
      }
    },
    remove(file, fileList) {
      delete this.filesInfo[file.uid];
      this.fileList = fileList;
    },
  },
};
</script>
<style scoped>
.document-form {
  padding-top: 50px;
}

.add-file {
  transition: 0.5s all;
  height: 60px !important;
  width: 60px !important;
  text-align: center;
  color: #083249;
  border: 3px solid #083249;
  border-radius: 45px;
  padding: 12px;
  margin-right: 20px;
  margin-bottom: 20px;
}

.upload-files {
  transition: 0.5s all;
  height: 60px !important;
  width: 60px !important;
  text-align: center;
  color: #4caf50;
  cursor: pointer;
  border: 3px solid #4caf50;
  border-radius: 45px;
  padding: 12px;
  margin-left: 20px;
  margin-bottom: 20px;
}

.add-file:hover {
  transition: 0.5s all;
  background: #083249;
  color: white;
}

.upload-files:hover {
  transition: 0.5s all;
  background: #4caf50;
  color: white;
}

::v-deep(.el-upload-list) {
  text-align: left;
}

.el-upload__tip {
  margin-top: 15px;
  margin-bottom: 15px;
  font-size: 16px;
}

::v-deep(.el-upload-list__item) {
  font-size: 15px;
}

i {
  font-size: 50px;
}
</style>
