<template>
  <el-container>
    <br /><br />
    <el-header>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/clients' }"
          ><h2 class="title">Clientes</h2></el-breadcrumb-item
        >
      </el-breadcrumb>
    </el-header>
    <el-main>
      <CreateClient v-if="hasPermits('clients.create')" />
      <EditClient v-if="hasPermits('clients.edit')" />
      <br />
      <ClientList v-if="hasPermits('clients.list')" />
    </el-main>
  </el-container>
</template>
<script>
import ClientList from "../components/clients/ClientList.vue";
import EditClient from "../components/clients/EditClient.vue";
import CreateClient from "../components/clients/CreateClient.vue";
import { hasPermits } from "@/config/Auth.js";

export default {
  setup() {
    return {
      hasPermits,
    };
  },
  name: "Client",
  components: {
    ClientList,
    EditClient,
    CreateClient,
  },
};
</script>

<style scoped>
.title {
  color: black;
  text-align: left;
}
</style>
