<template>
  <div>
    <quotation-edit :event="event" ref="editQuotation" />
    <quotation-create :event="event" />
    <quotation-prices ref="setPrices" />
    <create-purcharse-order ref="purcharseOrder" />
    <EditPurchaseOrder :event="event" ref="editPurcharseOrder" />
    <GenerateObservation
      :title="this.titleObservation"
      :event="'send-generated-observation'"
      ref="generateObservation"
    />
    <el-tooltip
      content="Descargar comparativo de cotizaciones clientes"
      placement="top"
      effect="light"
    >
      <i @click="generateComparatives('client')">
        <span class="icon"> <ion-icon name="person-sharp"></ion-icon></span>
      </i>
    </el-tooltip>
    <el-tooltip
      content="Descargar comparativo de cotizaciones proveedores"
      placement="top"
      effect="light"
    >
      <i @click="generateComparatives('providers')">
        <span class="icon"><ion-icon name="pricetag-sharp"></ion-icon></span>
      </i>
    </el-tooltip>
    <el-tooltip
      v-if="hasPermits('events.download_preliminary_internal_budget')"
      content="Descargar presupuesto interno preliminar"
      placement="top"
      effect="light"
    >
      <i @click="downloadPreliminaryPdfInternalBudget()">
        <span class="icon"><ion-icon name="download"></ion-icon></span>
      </i>
    </el-tooltip>

    <div class="box">
      <el-table
        v-loading="loading"
        :data="tableData"
        style="border-radius: 10px"
        width="100%"
        :empty-text="'No hay registros'"
        @selection-change="handleSelectionChange"
        class="animate__animated animate__fadeInRight"
      >
        <el-table-column type="selection" width="55" />
        <el-table-column prop="consecutive" label="ID" width="70" />
        <el-table-column label="Proveedor">
          <template #default="scope">
            {{ scope.row.subsidiarie.provider.full_name }}
          </template>
        </el-table-column>
        <el-table-column label="Observaciones">
          <template #default="scope">
            <div v-if="scope.row.purcharse_order != null">
              {{ scope.row.purcharse_order.observations }}
            </div>
            <div v-else>
              {{ scope.row.request_notes }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="Aprobado" align="center">
          <el-table-column label="Cliente" width="100">
            <template #default="scope">
              <div v-if="scope.row.approve_client == true">
                <ion-icon name="checkmark-outline" class="check"></ion-icon>
              </div>
              <div v-else>
                <ion-icon name="close-outline" class="error"></ion-icon>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="Proveedor" width="100">
            <template #default="scope">
              <div v-if="scope.row.approve_provider == true">
                <ion-icon name="checkmark-outline" class="check"></ion-icon>
              </div>
              <div v-else>
                <ion-icon name="close-outline" class="error"></ion-icon>
              </div>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column prop="status.name" label="Estado" align="center" />
        <el-table-column label="Acciones" align="center">
          <template #default="scope">
            <el-dropdown>
              <span class="el-dropdown-link">
                <i class="el-icon-more"></i>
              </span>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item
                    v-if="scope.row.status_id == 1"
                    @click="openFormEdit(scope.row.id)"
                  >
                    <i class="el-icon-edit properties"></i>
                    Editar cotización</el-dropdown-item
                  >
                  <el-dropdown-item
                    v-if="
                      scope.row.approve_provider != true ||
                      scope.row.approve_client != true
                    "
                    @click="openForm(scope.row.id)"
                  >
                    <i class="el-icon-money properties"></i>
                    Asignar precios</el-dropdown-item
                  >
                  <el-dropdown-item
                    v-if="
                      scope.row.approve_provider == true &&
                      scope.row.purcharse_order == null
                    "
                    @click="openFormPurcharseOrder(scope.row.id)"
                  >
                    <i class="el-icon-circle-plus properties"></i>
                    Generar orden de compra</el-dropdown-item
                  >
                  <el-dropdown-item
                    v-if="hasPermits('quotation_event.delete_quotation')"
                    ><Delete
                      :endpoint="`api/v1/quotations/${scope.row.id}`"
                      event="reload-list-of-quotations"
                      :key="scope.row.id"
                      option="true"
                      >Eliminar
                    </Delete>
                  </el-dropdown-item>
                  <el-dropdown-item
                    v-if="
                      hasPermits('quotation_event.reject_quotation') &&
                      scope.row.status_id !== 4
                    "
                    @click="rejectQuotation(scope.row.id)"
                  >
                    <i
                      class="fa-sharp fa-regular fa-circle-xmark reject-icon"
                    ></i>

                    Rechazar cotización
                  </el-dropdown-item>

                  <el-dropdown-item
                    v-if="
                      scope.row.approve_client != true &&
                      hasPermits('quotation_event.approve_client')
                    "
                    @click="approbeQuotation(scope.row.id)"
                  >
                    <ion-icon
                      name="person-sharp"
                      class="icon-accept"
                    ></ion-icon>
                    Aprobar cliente
                  </el-dropdown-item>

                  <el-dropdown-item
                    v-if="
                      scope.row.approve_provider != true &&
                      hasPermits('quotation_event.approve_provider')
                    "
                    @click="approveProvider(scope.row.id)"
                  >
                    <ion-icon
                      name="pricetag-sharp"
                      class="icon-accept"
                    ></ion-icon>

                    Aprobar proveedor
                  </el-dropdown-item>

                  <el-dropdown-item
                    v-if="scope.row.id != null"
                    @click="downloadPdfQuotation(scope.row.id)"
                  >
                    <i class="fa-regular fa-file-pdf icon-download"></i>
                    Solicitud cotización
                  </el-dropdown-item>
                  <el-dropdown-item
                    v-if="scope.row.purcharse_order != null"
                    @click="downloadPdfOrder(scope.row.id)"
                  >
                    <i class="el-icon-download"></i>
                    Descargar orden de compra
                  </el-dropdown-item>
                  <el-dropdown-item
                    v-if="scope.row.purcharse_order != null"
                    @click="editPurchaseOrder(scope.row.purcharse_order)"
                  >
                    <i class="fa-solid fa-pencil icon-edit"></i>
                    Editar orden de compra
                  </el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import {
  getData,
  downloadData,
  postData,
  putData,
  downloadDataNew,
} from "@/request/request.js";
import { ElLoading, ElNotification } from "element-plus";
import QuotationPrices from "./QuotationPrices.vue";
import Delete from "../Delete.vue";
import QuotationCreate from "./QuotationCreate.vue";
import CreatePurcharseOrder from "./CreatePurcharseOrder.vue";
import QuotationEdit from "./QuotationEdit.vue";
import { hasPermits } from "@/config/Auth.js";
import EditPurchaseOrder from "./EditPurcharseOrder.vue";
import GenerateObservation from "./GenerateObservation.vue";
import "animate.css";

export default {
  name: "QuotationList",
  props: {
    event: Object,
  },
  components: {
    QuotationPrices,
    QuotationCreate,
    Delete,
    CreatePurcharseOrder,
    QuotationEdit,
    EditPurchaseOrder,
    GenerateObservation,
  },
  data() {
    return {
      tableData: [],
      loading: false,
      loadingPage: false,
      multipleSelection: [],
      typeComparative: "",
      titleObservation: "",
      model: {
        observation: "",
      },
    };
  },
  setup() {
    return {
      downloadData,
      hasPermits,
    };
  },
  mounted() {
    this.getQuotations();
    this.emitter.off("reload-list-of-quotations");
    this.emitter.on("reload-list-of-quotations", () => {
      this.getQuotations();
      this.emitter.emit("reload-list-of-notes");
    });
    this.emitter.off("send-generated-observation");
    this.emitter.on("send-generated-observation", (observation) => {
      this.model.observation = observation;
      if (this.typeComparative === "client") {
        this.downloadClientPdf();
      } else {
        this.downloadProvidersPdf();
      }
    });
  },
  methods: {
    async getQuotations() {
      this.loading = true;
      await getData("api/v1/events/quotations/" + this.event.id)
        .then((data) => {
          if (data.error == true) {
            ElNotification({
              title: "Error",
              message:
                "Ocurrió un error al solicitar las cotizaciones de este evento",
              type: "error",
            });
          } else {
            this.tableData = data.data;
          }
        })
        .catch(() => {
          ElNotification({
            title: "Error",
            message: "Ocurrió un error al hacer la petición",
            type: "error",
          });
        });
      this.loading = false;
    },
    handleSelectionChange(val) {
      this.multipleSelection = [];
      val.forEach((element) => {
        this.multipleSelection.push(element.id);
      });
    },
    openForm(id) {
      this.$refs.setPrices.openModal(
        id,
        this.event.start_date,
        this.event.finish_date,
        this.event.consuption_previous_day,
        this.event.consuption_next_day
      );
    },
    openFormPurcharseOrder(id) {
      this.$refs.purcharseOrder.openModal(
        id,
        this.event.start_date,
        this.event.finish_date,
        this.event.consuption_previous_day,
        this.event.consuption_next_day
      );
    },
    openFormEdit(id) {
      this.$refs.editQuotation.openForm(id);
    },
    generateComparatives(type) {
      this.titleObservation = "Observación para el comparativo de clientes";
      if (type === "providers") {
        this.titleObservation =
          "Observación para el comparativo de proveedores";
      }
      this.typeComparative = type;
      this.$refs.generateObservation.openModal();
    },

    downloadClientPdf() {
      if (
        this.multipleSelection.length > 4 ||
        this.multipleSelection.length < 1
      ) {
        ElNotification({
          title: "Error",
          message:
            "Para generar un comparativo debe seleccionar entre una y cuatro cotizaciones",
          type: "error",
        });
        return;
      }
      downloadData(
        "api/v1/pdfs/quotations/" + this.event.id,
        {
          list: this.multipleSelection,
          type: "clients",
          observation: this.model.observation,
        },
        "Comparativo cliente.pdf"
      );
    },
    downloadProvidersPdf() {
      if (
        this.multipleSelection.length > 4 ||
        this.multipleSelection.length < 1
      ) {
        ElNotification({
          title: "Error",
          message:
            "Para generar un comparativo debes seleccionar entre una y cuatro cotizaciones",
          type: "error",
        });
        return;
      }
      downloadData(
        "api/v1/pdfs/quotations/" + this.event.id,
        {
          list: this.multipleSelection,
          type: "providers",
          observation: this.model.observation,
        },
        "Comparativo proveedor.pdf"
      );
    },

    downloadPdfQuotation(qoutationID) {
      downloadData(
        `api/v1/pdfs/generate/${qoutationID}`,
        {},
        "Solicitud cotización.pdf"
      );
    },

    downloadPreliminaryPdfInternalBudget() {
      if (this.multipleSelection.length < 1) {
        ElNotification({
          title: "Error",
          message:
            "Para generar un presupuesto interno preliminar debe seleccionar por lo menos una cotización",
          type: "error",
        });
        return;
      }
      downloadData(
        `api/v1/pdfs/preliminary-internal-budget/${this.event.id}`,
        {
          list: this.multipleSelection,
          type: "",
        },
        "Presupuesto interno preliminar.pdf"
      );
    },

    async approbeQuotation(quotationID) {
      this.loadingPage = ElLoading.service();
      await postData(`api/v1/quotations/${quotationID}/approbe`, {}, true)
        .then((res) => {
          if (res.error == true || res.error == undefined) {
            ElNotification({
              title: "Error",
              message: res.message,
              type: "error",
            });
            return;
          }
          ElNotification({
            title: "Cotización aprobada",
            message: res.message,
            type: "success",
          });

          this.getQuotations();
          this.emitter.emit("approbe-reload-prices");
          this.emitter.emit("reload-list-of-notes");
        })
        .catch(() => {
          ElNotification({
            title: "Error",
            message: "Ocurrió un error al hacer la petición",
            type: "error",
          });
        });
      this.loadingPage.close();
    },
    downloadPdfOrder(qoutationID) {
      downloadData(
        `api/v1/pdfs/order/${qoutationID}`,
        {},
        "Orden de compra.pdf"
      );
    },

    async approveProvider(quotationID) {
      this.loadingPage = ElLoading.service();
      await putData(`api/v1/quotations/${quotationID}/approve-provider`, {})
        .then((res) => {
          if (res.error == true || res.error == undefined) {
            ElNotification({
              title: "Error",
              message: res.message,
              type: "error",
            });
            return;
          }
          ElNotification({
            title: "Proveedor aprobado",
            message: res.message,
            type: "success",
          });
          this.getQuotations();
          this.emitter.emit("reload-list-of-notes");
        })
        .catch(() => {
          ElNotification({
            title: "Error",
            message: "Ocurrió un error al hacer la petición",
            type: "error",
          });
        });
      this.loadingPage.close();
    },
    editPurchaseOrder(purchaseOrder) {
      this.$refs.editPurcharseOrder.openModal(
        purchaseOrder,
        this.event.id,
        this.event.start_date,
        this.event.finish_date,
        this.event.consuption_previous_day,
        this.event.consuption_next_day
      );
    },

    async rejectQuotation(quotationID) {
      this.loadingPage = ElLoading.service();
      await putData(`api/v1/quotations/${quotationID}/reject`, {})
        .then((res) => {
          if (res.error == true || res.error == undefined) {
            ElNotification({
              title: "Error",
              message: res.message,
              type: "error",
            });
            return;
          }
          ElNotification({
            title: "Estado cambiado",
            message: res.message,
            type: "success",
          });
          this.getQuotations();
          this.emitter.emit("reload-list-of-notes");
        })
        .catch(() => {
          ElNotification({
            title: "Error",
            message: "Ocurrió un error al hacer la petición",
            type: "error",
          });
        });
      this.loadingPage.close();
    },
  },
};
</script>
<style scoped>
.container {
  width: -webkit-fill-available;
  height: fit-content !important;
}

.el-table {
  border-radius: 10px;
}

.el-icon-view {
  color: var(--blue);
  margin-right: 5px;
  font-size: 19px;
  cursor: pointer;
}

.paginate {
  margin: auto;
  text-align: end;
}

.el-row {
  width: inherit;
}

.el-button {
  background: var(--blue);
  color: white;
}

.is-disabled {
  background: #8080809c;
}

.is-disabled:hover {
  background: #8080809c;
}

::v-deep(.el-table thead) {
  color: var(--blue) !important;
}

.el-select {
  background: var(--blue);
  color: white;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.el-select-dropdown__item.selected {
  color: var(--blue);
}

ion-icon {
  color: var(--blue);
  margin: 0 10px 0 10px;
  font-size: 20px;
  cursor: pointer;
}

.icon-accept {
  color: rgb(2, 138, 13);
  margin: 0 10px 0 10px;
  font-size: 20px;
  cursor: pointer;
}

::v-deep(.ionicon) {
  pointer-events: none !important;
}

.properties {
  color: darkorange;
  margin: 0 10px 0 10px;
  font-size: 20px;
}

.check {
  color: rgb(2, 138, 13);
  margin: 0 35%;
  font-size: 20px;
  cursor: default;
}

.icon-download {
  color: rgb(22, 52, 219);
  margin: 0 10px 0 10px;
  font-size: 20px;
  cursor: pointer;
}

.icon-edit {
  color: darkorange;
  margin: 0 10px 0 8px;
  font-size: 18px;
  cursor: pointer;
}

.error {
  color: red;
  margin: 0 10px 0 10px;
  font-size: 20px;
  margin: 0 35%;
  cursor: default;
}
.icon,
i {
  cursor: pointer;
  font-size: 28px;
}

.el-icon-download {
  color: var(--blue);
  cursor: pointer;
  font-size: 25px;
  margin: 2%;
}

.items {
  display: inline;
}

.el-dropdown-menu__item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.reject-icon {
  margin: 3%;
  font-size: 20px;
  color: red;
}
</style>
