<template>
  <el-select
    v-model="status_id"
    size="small"
    style="width: 100%"
    @change="changeStatus"
    filterable
    align="center"
  >
    <el-option
      v-for="status in this.statuses"
      :key="status.name"
      :label="status.name"
      :value="status.id"
      align="center"
    >
    </el-option>
  </el-select>
</template>

<script>
import { postData } from "@/request/request.js";
import { ElNotification, ElLoading } from "element-plus";

export default {
  name: "ChangeStatus",
  props: {
    status: Number,
    general_quotation_id: Number,
  },
  data() {
    return {
      statuses: [
        {
          id: "Cotizado",
          name: "Cotizado",
        },
        {
          id: "Aprobado",
          name: "Aprobado",
        },
        {
          id: "No aprobado",
          name: "No aprobado",
        },
      ],
      status_id: this.status,
    };
  },
  methods: {
    async changeStatus() {
      var loading = ElLoading.service();
      await postData(
        `api/v1/general-quotations/${this.general_quotation_id}/status`,
        {
          status: this.status_id,
        },
        true
      )
        .then((res) => {
          if (res.error === true || res.error === undefined) {
            ElNotification({
              title: "Error",
              message: res.message,
              type: "error",
            });
            return;
          }

          ElNotification({
            title: "Estado cambiado",
            message: res.message,
            type: "success",
          });
        })
        .catch(() => {
          ElNotification({
            title: "Error",
            message: "Ocurrió un error al hacer la petición",
            type: "error",
          });
        });
      loading.close();
    },
  },
};
</script>

<style scoped>
.el-select {
  background: white !important;
}
</style>
