<template>
  <el-container>
    <el-row>
      <el-col :span="24" align="center">
        <el-upload
          class="upload-demo"
          drag
          :show-file-list="false"
          :on-change="handleAvatarSuccess"
          :auto-upload="false"
          :multiple="false"
        >
          <img v-if="imageUrl" :src="imageUrl" class="avatar" />
          <template v-else>
            <i class="el-icon-document-add add-file"></i>
            <div class="el-upload__text">
              Arrastra la imagen o <em>click para subirla</em>
            </div>
          </template>
          <template #tip>
            <div class="el-upload__tip">
              El logo debe ser subido en <b> PNG y fondo transparente </b>, para
              mayor adaptación al sistema. <b>Tamaño máximo de 5MB</b>
            </div>
          </template>
        </el-upload>
      </el-col>
    </el-row>
  </el-container>
</template>
<script>
import { ElMessage } from "element-plus";
const validImageType = {
  "image/png": true,
  "image/jpeg": true,
  "image/jpg": true
};

export default {
  name: "UploadLogo",
  data() {
    return {
      file: null,
      loading: false,
      imageUrl: null,
    };
  },
  props: {
    src: String,
  },
  setup() {
    return {};
  },
  watch:{
    src(newSrc){
      this.imageUrl = newSrc;
    }
  },
  methods: {
    handleAvatarSuccess(file) {
      if (!file.raw.type || !validImageType[file.raw.type]) {
        ElMessage.error("¡Error! Debes seleccionar una imagen");

        return;
      }

      this.imageUrl = URL.createObjectURL(file.raw);
      this.file = file;
    },
    validate() {
      return this.file != null;
    },
    getImage() {
      if (this.file == null){
        return null;
      }

      return this.file.raw;
    },
    setImageUrl(src){
      this.imageUrl = src;
    }
  },
};
</script>
<style scoped>
.add-file {
  margin: 10%;
  font-size: 64px;
}

.upload-demo {
  text-align: center;
}

.el-row {
  width: 100%;
}

.logo {
  font-size: 30px;
  margin-top: 30px;
  color: rgb(22, 52, 219);
}

.el-container {
  margin-bottom: 30px;
}

.avatar {
  width: 100%;
  height: auto;
}
</style>
