<template>
  <el-dialog
    v-model="show"
    title="Agregar Tarifa"
    width="40%"
    :before-close="handleClose"
    center
  >
    <span>
      <el-form
        label-position="top"
        ref="model"
        :model="model"
        :rules="rules"
        :loading="loading"
      >
        <SelectDepartmentCity
          v-show="city == undefined"
          ref="departmentCities"
        />

        <el-row>
          <el-col :span="20">
            <SelectSupply ref="selectSupply" v-model="model.supply_id" />
          </el-col>
          <el-col
            :span="4"
            v-if="hasPermits('supplies.create')"
            class="supply-add"
          >
            <CreateSupply />
          </el-col>
        </el-row>

        <el-form-item label="Tarifa" prop="price">
          <el-input type="number" v-model="model.price"></el-input>
        </el-form-item>
      </el-form>
    </span>

    <template #footer>
      <span class="dialog-footer">
        <el-button type="primary" @click="addPrice('model')">Agregar</el-button>
        <el-button @click="show = false">Cancelar</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { ElMessageBox } from "element-plus";
import { ElMessage } from "element-plus";
import SelectDepartmentCity from "../SelectDepartmensCities.vue";
import SelectSupply from "../supplies/SelectSupply.vue";
import { hasPermits } from "@/config/Auth.js";
import CreateSupply from "../../components/supplies/CreateSupply.vue";

export default {
  name: "AddPrice",
  components: { SelectDepartmentCity, SelectSupply, CreateSupply },
  props: {
    city: Number,
    event: String
  },
  setup() {
    return {
      hasPermits,
    };
  },
  mounted() {
    this.emitter.off("open-add-modal");
    this.emitter.on("open-add-modal", () => {
      this.openForm({});
    });
  },
  updated() {
    if (this.reset) {
      if (this.model.supply !== undefined) {
        this.$refs.selectSupply.setInformation(this.model.supply.category_id, {
          name: this.model.supply.name,
          id: this.model.supply_id,
        });
      }
    }

    this.reset = false;
  },
  data() {
    return {
      loading: false,
      show: false,
      reset: false,
      supplies: [],
      model: {
        supply_id: "",
        price: "",
        city_id: "",
      },
      rules: {
        supply_id: [
          {
            required: true,
            message: "Por favor selecciona un insumo",
            trigger: "change",
          },
        ],
        price: [
          {
            required: true,
            message: "Por favor agrega una tarifa",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    handleClose(done) {
      ElMessageBox.confirm(
        "¿Estas seguro que deseas salir del formulario de agregar tarifas?",
        {
          showCancelButton: true,
          confirmButtonText: "Confirmar",
          cancelButtonText: "Cancelar",
        }
      )
        .then((res) => {
          if (res == "confirm") {
            this.show = false;
            this.cleanFormAdd();
            done();
          }
        })
        .catch(() => {}); // this action is call when user click in cancel button
    },

    openForm(model) {
      this.cleanFormAdd();
      this.show = true;
      this.model = model;
      this.reset = true;
    },

    validateForm(formName) {
      let res;
      this.$refs[formName].validate((valid) => {
        res = valid;
      });
      return res;
    },

    addPrice(model) {
      this.loading = true;
      let selectCity =
        this.city != undefined ||
        this.$refs.departmentCities.validateForm(model);
      let selectSupply = this.$refs.selectSupply.validateForm();
      if (this.validateForm("model") && selectCity && selectSupply) {
        this.model.city_id = this.city;
        if (this.city == undefined) {
          this.model.city_id = this.$refs.departmentCities.model.city_id;
        }

        this.model.supply_id = this.$refs.selectSupply.model.supply_id;

        let newModel = Object.assign({}, this.model);
        let supplyName = {
          name: this.$refs.selectSupply.getName(),
          category_id: this.$refs.selectSupply.model.category_id,
        };

        Object.defineProperty(newModel, "supply", {
          value: supplyName,
          configurable: true,
          writable: true,
        });

        if (this.city == undefined) {
          let cityName = {
            name: this.$refs.departmentCities.selectedItem(),
          };

          Object.defineProperty(newModel, "city", {
            value: cityName,
            configurable: true,
            writable: true,
          });
        }

        this.emitter.emit(this.event, newModel);
        this.show = false;
        this.cleanFormAdd("model");
      } else {
        ElMessage.error(
          "¡ Error !, Por favor verifica que todos los campos esten llenos"
        );
        this.loading = false;
      }
    },
    cleanFormAdd() {
      if (this.$refs.model === undefined) {
        return;
      }

      this.$refs.model.resetFields();
      if (this.city == undefined) {
        this.$refs.departmentCities.cleanForm("model");
      }
      this.$refs.selectSupply.cleanForm();

      this.model = {
        supply_id: "",
        price: "",
        city_id: "",
      };

      this.$refs.departmentCities.model.city_id = "";
      this.$refs.departmentCities.model.department_id = "";
      this.$refs.selectSupply.model.supply_id = "";
      this.$refs.selectSupply.model.category_id = "";
    },
    cleanFormSave() {
      this.model.supply_id = "";
      this.model.price = "";
      this.$refs.departmentCities.model.city_id = "";
      this.$refs.departmentCities.model.department_id = "";
      this.$refs.selectSupply.model.supply_id = "";
      this.$refs.selectSupply.model.category_id = "";
      this.add = [];
    },
  },
};
</script>
<style scoped></style>
