<template>
  <div>
    <el-dialog
      v-model="dialogVisible"
      width="65%"
      :before-close="handleClose"
      title="Editar evento"
    >
      <!-- steps -->
      <el-steps :active="step" simple>
        <el-step title="Evento" icon="el-icon-edit"></el-step>
      </el-steps>
      <el-form v-if="step === 0">
        <el-row>
          <el-col :span="20" :offset="2">
            <div class="grid-content bg-purple-dark">
              <el-form
                v-loading="loadingGetInformation"
                ref="model"
                label-position="top"
                :model="model"
                :rules="rules"
              >
                <el-row>
                  <el-col :span="11">
                    <el-form-item label="Proyecto" prop="contract_id">
                      <SelectContract
                        ref="SelectContract"
                        v-model="model.contract_id"
                        @updating-intermediation-percentage="
                          updateIntermediationPercentage
                        "
                        @change="onChangeSelectContract($event)"
                      />
                    </el-form-item>

                    <el-form-item label="Tipo de evento" prop="event_type">
                      <el-input v-model="model.event_type"></el-input>
                    </el-form-item>

                    <el-form-item label="Nombre" prop="name">
                      <el-input v-model="model.name"></el-input>
                    </el-form-item>
                    <SelectDepartmentsCities ref="departmentCities" />

                    <el-form-item label="Fecha inicio" prop="start_date">
                      <el-date-picker
                        v-model="model.start_date"
                        type="datetime"
                        format="YYYY-MM-DD hh:mm a"
                        value-format="YYYY-MM-DD HH:mm"
                        placeholder="Selecciona una fecha"
                        style="width: 100%"
                      ></el-date-picker>
                    </el-form-item>

                    <el-form-item label="Fecha fin" prop="finish_date">
                      <el-date-picker
                        v-model="model.finish_date"
                        type="datetime"
                        format="YYYY-MM-DD hh:mm a"
                        value-format="YYYY-MM-DD HH:mm"
                        placeholder="Selecciona una fecha"
                        style="width: 100%"
                      ></el-date-picker>
                    </el-form-item>

                    <el-form-item label="Coordinador" prop="user_id">
                      <SelectUser ref="SelectUser" v-model="model.user_id" />
                    </el-form-item>
                    <el-row>
                      <el-col :span="20">
                        <el-form-item label="Estado" prop="status_id">
                          <SelectStatus
                            ref="SelectStatus"
                            v-model="model.status_id"
                          />
                        </el-form-item>
                      </el-col>
                      <el-col :span="4" class="status-add">
                        <AddStatus />
                      </el-col>
                    </el-row>
                    <el-form-item label="Solicitante" prop="applicant">
                      <el-input v-model="model.applicant"></el-input>
                    </el-form-item>
                    <el-form-item label="Tipología del evento" prop="typology">
                      <el-input v-model="model.typology"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :offset="3" :span="10">
                    <el-form-item
                      v-if="
                        intermediation_percentage !== null &&
                        intermediation_percentage !== 0
                      "
                      label="Valor de intermediación"
                      prop="intermediation_value"
                    >
                      <el-input
                        type="number"
                        v-model="model.intermediation_value"
                      ></el-input>
                    </el-form-item>

                    <el-form-item
                      label="Número de asistentes"
                      prop="assistants_number"
                    >
                      <el-input
                        type="number"
                        v-model="model.assistants_number"
                      ></el-input>
                    </el-form-item>

                    <el-form-item align="left">
                      <el-checkbox
                        v-model="model.consuption_previous_day"
                        label="Consumo desde el día anterior"
                        id="before-day"
                      >
                      </el-checkbox>
                      <el-checkbox
                        v-model="model.consuption_next_day"
                        label="Consumo hasta el día siguiente"
                        id="after-day"
                      >
                      </el-checkbox>
                    </el-form-item>

                    <el-form-item
                      label="Número de solicitud"
                      prop="application_number"
                    >
                      <el-input v-model="model.application_number"></el-input>
                    </el-form-item>
                    <el-form-item
                      label="Fecha de solicitud"
                      prop="application_date"
                    >
                      <el-date-picker
                        v-model="model.application_date"
                        type="date"
                        value-format="YYYY-MM-DD"
                        placeholder="Selecciona una fecha"
                        style="width: 100%"
                        :disabled="!hasPermits('events.edit_request_date')"
                      ></el-date-picker>
                    </el-form-item>
                    <SelectCdpDependency
                      ref="selectCdpDependency"
                      :contract_id="model.contract_id"
                    />

                    <el-form-item
                      label="Responsable cliente"
                      prop="client_contact"
                    >
                      <el-input v-model="model.client_contact"></el-input>
                    </el-form-item>
                    <el-form-item
                      label="Apoyo Logístico"
                      prop="logistic_support"
                    >
                      <el-input v-model="model.logistic_support"></el-input>
                    </el-form-item>
                    <el-form-item label="Lugar del evento" prop="event_site">
                      <el-input v-model="model.event_site"></el-input>
                    </el-form-item>
                    <el-form-item
                      label="Número de factura"
                      prop="invoice_number"
                    >
                      <el-input v-model="model.invoice_number"></el-input>
                    </el-form-item>
                    <el-form-item label="Valor de factura">
                      <el-input
                        type="text"
                        v-model="model.invoice_value"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-form-item label="Observaciones" prop="event_comments">
                  <el-input v-model="model.event_comments"></el-input>
                </el-form-item>
              </el-form>

              <div class="button" align="center">
                <el-button
                  :loading="loadingSave"
                  type="primary"
                  @click="save('model')"
                  >Guardar evento</el-button
                >
              </div>
            </div>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import SelectContract from "../contracts/SelectContract.vue";
import SelectUser from "../users/SelectUser.vue";
import SelectStatus from "./Status/SelectStatus.vue";
import AddStatus from "./Status/AddStatus.vue";
import SelectDepartmentsCities from "../../components/SelectDepartmensCities.vue";
import SelectCdpDependency from "../contracts/SelectCdpDependency.vue";
import { defineComponent } from "vue";
import { ElMessageBox } from "element-plus";
import { ElMessage } from "element-plus";
import { hasPermits } from "@/config/Auth.js";
import { ElNotification } from "element-plus";
import { ref } from "vue";
import { putData, getData } from "../../request/request.js";

export default defineComponent({
  name: "EditEvent",
  components: {
    SelectDepartmentsCities,
    SelectContract,
    SelectUser,
    SelectStatus,
    SelectCdpDependency,
    AddStatus,
  },

  setup() {
    const handleClose = (done) => {
      ElMessageBox.confirm(
        "¿Está Seguro que quiere salir de la edición de evento?",
        {
          showCancelButton: true,
          confirmButtonText: "Confirmar",
          cancelButtonText: "Cancelar",
        }
      )
        .then(() => {
          done();
        })
        .catch(() => {
          // catch error
        });
    };
    return {
      handleClose,
      hasPermits,
    };
  },
  mounted() {
    this.emitter.off("open-modal-edit-event");
    this.emitter.on("open-modal-edit-event", (id) => {
      this.openModal(id);
    });
  },
  data() {
    return {
      dialogVisible: false,
      roles: [],
      loadingSave: false,
      loadingGetInformation: false,
      documentsEndpoint: "",
      pricesEndPoint: "",
      step: 0,
      intermediation_percentage: null,
      model: {
        contract_id: "",
        departament_id: "",
        event_type: "",
        start_date: "",
        finish_date: "",
        assistants_number: 0,
        city_id: "",
        consuption_previous_day: ref(false),
        consuption_next_day: ref(false),
        application_number: "",
        application_date: "",
        dependency_id: "",
        event_comments: "",
        cost_center: "",
        client_contact: "",
        logistic_support: "",
        event_site: "",
        user_id: "",
        status_id: "",
        name: "",
        applicant: "",
        typology: "",
        invoice_number: "",
        invoice_value: "",
        intermediation_value: "",
      },
      rules: {
        applicant: [
          {
            required: true,
            message: "Por favor ingresa el solicitante",
            trigger: "blur",
          },
        ],
        contract_id: [
          {
            required: true,
            message: "Por favor ingresa el número del proyecto",
            trigger: "blur",
          },
        ],
        name: [
          {
            required: true,
            message: "Por favor ingresa el nombre del evento",
            trigger: "blur",
          },
        ],
        event_type: [
          {
            required: true,
            message: "Por favor ingresa el tipo de evento",
            trigger: "blur",
          },
        ],
        start_date: [
          {
            required: true,
            message: "Por favor ingresa la fecha de inicio",
            trigger: "blur",
          },
        ],
        finish_date: [
          {
            required: true,
            message: "Por favor ingresa la fecha de fin",
            trigger: "blur",
          },
        ],
        assistants_number: [
          {
            required: true,
            message: "Por favor ingresa el número de asistentes",
            trigger: "blur",
          },
        ],
        consuption_previous_day: [
          {
            required: true,
            message: "Por favor ingresa el consumo desde el día anterior",
            trigger: "blur",
          },
        ],
        consuption_next_day: [
          {
            required: true,
            message: "Por favor ingresa el consumo hasta el día siguiente",
            trigger: "blur",
          },
        ],
        application_number: [
          {
            required: true,
            message: "Por favor ingresa el número de solicitud",
            trigger: "blur",
          },
        ],
        application_date: [
          {
            required: true,
            message: "Por favor ingresa la fecha de solicitud",
            trigger: "blur",
          },
        ],
        client_contact: [
          {
            required: true,
            message: "Por favor ingresa el número de contacto",
            trigger: "blur",
          },
        ],
        event_site: [
          {
            required: true,
            message: "Por favor ingresa lugar del evento",
            trigger: "blur",
          },
        ],
        user_id: [
          {
            required: true,
            message: "Por favor ingresa el usuario",
            trigger: "blur",
          },
        ],
        status_id: [
          {
            required: true,
            message: "Por favor ingresa el estado",
            trigger: "blur",
          },
        ],
      },
    };
  },
  watch: {
    intermediation_percentage: function () {
      if (
        this.intermediation_percentage === null ||
        this.intermediation_percentage === 0
      ) {
        this.model.intermediation_value = null;
      }
    },
  },
  methods: {
    onChangeSelectContract(contractId) {
      this.$refs.selectCdpDependency.manageSelectors(contractId);
    },
    openModal(id) {
      this.id = id;
      this.cleanForm();
      this.fillData();
      this.dialogVisible = true;
    },
    validateForm(formName) {
      let res;
      this.$refs[formName].validate((valid) => {
        res = valid;
      });
      return res;
    },
    async fillData() {
      this.loadingGetInformation = true;
      await getData("api/v1/events/" + this.id)
        .then((res) => {
          if (res.error == true || res.error == undefined) {
            ElNotification({
              title: "Error",
              message: res.message,
              type: "error",
            });

            return;
          }
          this.intermediation_percentage =
            res.data.contract.intermediation_percentage;
          this.model = Object.fromEntries(
            Object.entries(res.data).filter(([key]) =>
              [
                "contract_id",
                "name",
                "event_type",
                "start_date",
                "finish_date",
                "assistants_number",
                "city_id",
                "depto",
                "consuption_previous_day",
                "consuption_next_day",
                "application_number",
                "dependency_id",
                "application_date",
                "event_comments",
                "cost_center",
                "client_contact",
                "logistic_support",
                "event_site",
                "user_id",
                "status_id",
                "applicant",
                "invoice_number",
                "invoice_value",
                "typology",
                "intermediation_value",
              ].includes(key)
            )
          );
          if (this.model.consuption_previous_day == 1) {
            this.model.consuption_previous_day = ref(true);
          } else {
            this.model.consuption_previous_day = ref(false);
          }
          if (this.model.consuption_next_day == 1) {
            this.model.consuption_next_day = ref(true);
          } else {
            this.model.consuption_next_day = ref(false);
          }

          this.$refs.SelectUser.users = [
            Object.fromEntries(
              Object.entries(res.data.user).filter(([key]) =>
                ["id", "full_name"].includes(key)
              )
            ),
          ];
          this.$refs.SelectStatus.states = [
            Object.fromEntries(
              Object.entries(res.data.status).filter(([key]) =>
                ["id", "name"].includes(key)
              )
            ),
          ];

          this.$refs.SelectContract.contracts = [
            Object.fromEntries(
              Object.entries(res.data.contract).filter(([key]) =>
                ["id", "name", "number"].includes(key)
              )
            ),
          ];
          this.loadingGetInformation = false;
          if (
            res.data.dependency_id !== undefined &&
            res.data.dependency_id !== null
          ) {
            this.$refs.selectCdpDependency.manageSelectors(
              this.model.contract_id,
              res.data.dependency.cdp.id,
              this.model.dependency_id
            );
            return;
          }
          this.$refs.selectCdpDependency.manageSelectors(
            this.model.contract_id,
            null,
            this.model.dependency_id
          );
        })
        .catch(() => {
          ElNotification({
            title: "Error",
            message: "Ocurrió un error al hacer la petición",
            type: "error",
          });
        });
      this.$refs.departmentCities.selectCity(this.model.city_id);
      this.loadingGetInformation = false;
    },

    async save(formName) {
      let selectCity = this.$refs.departmentCities.validateForm(formName);
      let selectDependency = this.$refs.selectCdpDependency.validateForm();
      if (!this.validateForm(formName) || !selectCity || !selectDependency) {
        ElMessage.error("¡ Error !, Por favor verifica todos los campos");
        return;
      }
      this.model.city_id = this.$refs.departmentCities.model.city_id;
      this.model.dependency_id =
        this.$refs.selectCdpDependency.model.dependency_id;
      let newModel = Object.assign({}, this.model);
      let cityName = {
        name: this.$refs.departmentCities.selectedItem(),
      };
      Object.defineProperty(newModel, "city", {
        value: cityName,
        configurable: true,
        writable: true,
      });
      this.loadingSave = true;
      await putData("api/v1/events/" + this.id, newModel, true)
        .then((res) => {
          this.loadingSave = false;

          if (res.error == true || res.error == undefined) {
            ElNotification({
              title: "Error",
              message: res.message,
              type: "error",
            });

            return;
          }

          ElNotification({
            title: "Evento editado correctamente",
            message: res.message,
            type: "success",
          });

          this.emitter.emit("reload-list-of-events");
          this.emitter.emit("reload-list-of-notes");
        })
        .catch(() => {
          ElNotification({
            title: "Error",
            message: "Ocurrió un error al hacer la petición",
            type: "error",
          });
          this.loadingSave = false;
        });
    },

    cleanForm() {
      let form = this.$refs["model"];
      if (form) {
        form.resetFields();
      }
      if (this.$refs.selectCdpDependency) {
        this.$refs.selectCdpDependency.formReset();
      }

      this.step = 0;
      this.model = {
        contract_id: "",
        event_type: "",
        start_date: "",
        finish_date: "",
        assistants_number: 0,
        city_id: "",
        consuption_previous_day: ref(false),
        consuption_next_day: ref(false),
        application_number: "",
        application_date: "",
        dependency_id: "",
        departament_id: "",
        event_comments: "",
        cost_center: "",
        client_contact: "",
        logistic_support: "",
        event_site: "",
        user_id: "",
        status_id: "",
        name: "",
        applicant: "",
        typology: "",
        invoice_number: "",
      };
    },
    updateIntermediationPercentage(newintermediation_percentage) {
      this.intermediation_percentage = newintermediation_percentage;
    },
  },
});
</script>

<style scoped>
.el-button--danger {
  background: #bc0304;
  border: none;
}

.el-button--success {
  background: #003149;
  border: none;
}

.el-button--primary {
  background: var(--blue) !important;
  border: 1px solid var(--blue) !important;
}

.el-button--text {
  color: var(--blue) !important;
  font-weight: bold;
  font-size: 25px;
}
.content {
  margin-top: 30px;
}
.icon-step {
  width: 20px;
}

::v-deep(.el-dialog__body) {
  text-align: center;
}

::v-deep(.el-form--label-top .el-form-item__label) {
  padding: 0;
}

.status-add {
  display: flex;
  align-content: space-between;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
}

.button {
  margin-top: 20px;
}
</style>
