<template>
  <el-container>
    <br /><br />
    <el-header>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/contracts' }"
          ><h2 class="title">Proyectos</h2></el-breadcrumb-item
        >
      </el-breadcrumb>
    </el-header>
    <el-main>
      <CreateContract v-if="hasPermits('contracts.create')" />
      <EditContract v-if="hasPermits('contracts.edit')" />
      <Contractlist v-if="hasPermits('contracts.list')" />
    </el-main>
  </el-container>
</template>
<script>
import { hasPermits } from "@/config/Auth.js";
import Contractlist from "../components/contracts/ContractList.vue";
import CreateContract from "../components/contracts/CreateContract.vue";
import EditContract from "../components/contracts/EditContract.vue";

export default {
  setup() {
    return {
      hasPermits,
    };
  },
  name: "Contract",
  components: { Contractlist, CreateContract, EditContract },
};
</script>

<style scoped>
.title {
  color: black;
  text-align: left;
}
</style>
