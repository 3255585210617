<template>
  <el-container v-if="hasPermits('reports.list_report_asset_availability')">
    <br /><br />
    <el-header>
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/reports' }">
          Reportes
        </el-breadcrumb-item>
        <el-breadcrumb-item> Disponibilidad de recursos </el-breadcrumb-item>
      </el-breadcrumb>
    </el-header>
    <el-form ref="query" label-position="top" :model="query" :rules="rules">
      <el-row>
        <el-col class="centerIcon" :span="1" :offset="1">
          <el-tooltip
            v-if="tableData.length != 0"
            content="Descargar pdf"
            placement="top"
            effect="light"
          >
            <i
              @click="download('pdf', 'query')"
              class="fa-regular fa-file-pdf icon-download-pdf"
            ></i>
          </el-tooltip>
        </el-col>
        <el-col class="centerIcon" :span="1">
          <el-tooltip
            v-if="tableData.length != 0"
            content="Descargar excel"
            placement="top"
            effect="light"
          >
            <i
              @click="download('excel', 'query')"
              class="fa-regular fa-file-excel icon-download-excel"
            ></i> </el-tooltip
        ></el-col>
        <el-col :span="7">
          <el-form-item
            label="Proyecto"
            prop="contract_id"
            @change="tableData = []"
          >
            <SelectContract v-model="query.contract_id" />
          </el-form-item>
        </el-col>
        <el-col :span="7" :offset="1">
          <el-form-item
            label="Seleccione los estados comprometidos"
            prop="status_id"
            @change="tableData = []"
          >
            <SelectStatus multiple v-model="query.status_id" />
          </el-form-item>
        </el-col>
        <el-col class="boxContainer" :span="2" :offset="1">
          <div class="box">
            <el-button
              :loading="loading"
              type="primary"
              @click="search('query')"
              >Generar reporte</el-button
            >
          </div>
        </el-col>
      </el-row>
    </el-form>
    <el-main v-if="tableData.length != 0" v-loading="loading">
      <table>
        <thead>
          <tr>
            <th style="min-width: 150px">No. CDP</th>
            <th style="min-width: 150px">Dependencia</th>
            <th style="min-width: 120px">Valor total</th>
            <th style="min-width: 120px">Valor ejecutado</th>
            <th style="min-width: 100px">% Comprometido</th>
            <th style="min-width: 100px">Cantidad eventos</th>
            <th style="min-width: 100px">Valor disponible</th>
            <th style="min-width: 80px">% Disponible</th>
          </tr>
        </thead>
        <tbody class="scrollit">
          <template v-for="(cdp, key) in tableData.contractData">
            <template v-if="cdp.dependenciesQuantity === 0">
              <tr :key="key">
                <td style="text-align:left;">{{ key }}</td>
                <td style="text-align:center;" class="total">TOTAL</td>
                <td class="total">${{ cdp.cdpAmount }}</td>
                <td class="total">${{ cdp.executedCdp }}</td>
                <td class="total">{{ cdp.percentageExecutedCdp }}%</td>
                <td class="total">
                  {{ cdp.eventsQuantityCdp }}
                </td>
                <td class="total">${{ cdp.availableCdpAmount }}</td>
                <td class="total">{{ cdp.percentageAvailableCdp }}%</td>
              </tr>
            </template>
            <template v-else>
              <tr :key="key">
                <td style="text-align:left;" :rowspan="Object.keys(cdp.dependencies).length + 1">{{ key }}</td>
                <td style="text-align:center;">{{ Object.keys(cdp.dependencies)[0] }}</td>
                <td>${{ cdp.dependencies[Object.keys(cdp.dependencies)[0]].dependencyAmount }}</td>
                <td>${{ cdp.dependencies[Object.keys(cdp.dependencies)[0]].executedAmount }}</td>
                <td>{{ cdp.dependencies[Object.keys(cdp.dependencies)[0]].percentageExecuted }} %</td>
                <td>{{ cdp.dependencies[Object.keys(cdp.dependencies)[0]].eventsQuantity }}</td>
                <td>${{ cdp.dependencies[Object.keys(cdp.dependencies)[0]].availableAmount }}</td>
                <td>{{ cdp.dependencies[Object.keys(cdp.dependencies)[0]].percentageAvailable }} %</td>
              </tr>
              <tr v-for="(dependencie,i) in Object.keys(cdp.dependencies).slice(1)" :key="i">
                <td style="text-align:center;">{{ dependencie }}</td>
                <td>${{ cdp.dependencies[dependencie].dependencyAmount }}</td>
                <td>${{ cdp.dependencies[dependencie].executedAmount }}</td>
                <td>{{ cdp.dependencies[dependencie].percentageExecuted }} %</td>
                <td>{{ cdp.dependencies[dependencie].eventsQuantity }}</td>
                <td>${{ cdp.dependencies[dependencie].availableAmount }}</td>
                <td>{{ cdp.dependencies[dependencie].percentageAvailable }} %</td>
              </tr>

              <tr :key="key">
                <td style="text-align:center;" :key="key" class="total">TOTAL</td>
                <td :key="key" class="total">${{ cdp.cdpAmount }}</td>
                <td :key="key" class="total">${{ cdp.executedCdp }}</td>
                <td :key="key" class="total">{{ cdp.percentageExecutedCdp }}%</td>
                <td :key="key" class="total">
                  {{ cdp.eventsQuantityCdp }}
                </td>
                <td :key="key" class="total">${{ cdp.availableCdpAmount }}</td>
                <td :key="key" class="total">{{ cdp.percentageAvailableCdp }}%</td>
              </tr>

            </template>
          </template>

          <tr class="generalTotal">
            <th colspan="2">TOTAL</th>
            <th>${{ tableData.totalContract.totalAmountContract }}</th>
            <th>${{ tableData.totalContract.executeAmountContract }}</th>
            <th>{{ tableData.totalContract.percentageExecuteContract }}%</th>
            <th>
              {{ tableData.totalContract.eventsQuantityContract }}
            </th>
            <th>${{ tableData.totalContract.availableAmountContract }}</th>
            <th>{{ tableData.totalContract.percentageAvailableContract }}%</th>
          </tr>
        </tbody>
      </table>
      <div class="scrollit">
      </div>
    </el-main>
  </el-container>
</template>
<script>
import { hasPermits } from "@/config/Auth.js";
import { getData, downloadData } from "../../request/request.js";
import SelectContract from "../contracts/SelectContract.vue";
import { ElLoading, ElNotification } from "element-plus";
import { ElMessage } from "element-plus";
import SelectStatus from "../events/Status/SelectStatus.vue";

export default {
  name: "assetavailability",
  components: {
    SelectContract,
    SelectStatus,
  },

  setup() {
    return { hasPermits };
  },
  data() {
    return {
      loading: false,
      loadingDownload: false,
      tableData: [],
      query: {
        contract_id: "",
        status_id: [],
      },
      rules: {
        contract_id: {
          required: true,
          message: "Por favor selecciona un proyecto",
          trigger: "blur",
        },
        status_id: {
          required: true,
          message: "Por favor selecciona el estado de los eventos",
          trigger: "blur",
        },
      },
    };
  },
  methods: {
    validateForm(formName) {
      let res;
      this.$refs[formName].validate((valid) => {
        res = valid;
      });
      return res;
    },
    search(formName) {
      if (!this.validateForm(formName)) {
        ElMessage.error("¡ Error !, Por favor verifica todos los campos");
        return;
      }
      this.loading = true;
      getData("api/v1/reports/asset_availability/" + this.query.contract_id, {
        status: this.query.status_id,
      })
        .then((res) => {
          if (res.error == true || res.error == undefined) {
            ElNotification({
              title: "Error",
              message: res.message,
              type: "error",
            });
            this.tableData = [];
            this.loading = false;
            return;
          }
          if (res.data.contractData === null) {
            ElNotification({
              title: "Vacío",
              message:
                "El proyecto y estados seleccionados no contienen información",
              type: "info",
            });
            this.tableData = [];
            this.loading = false;
            return;
          }
          this.tableData = res.data;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    download(type, formName) {
      if (!this.validateForm(formName)) {
        ElMessage.error("¡ Error !, Por favor verifica todos los campos");
        return;
      }
      this.loadingDownload = ElLoading.service();
      downloadData(
        "api/v1/reports/asset_availability/" +
          this.query.contract_id +
          "/" +
          type,
        {
          status: this.query.status_id,
        },
        "Reporte disponibilidad de recursos." +
          (type == "excel" ? "xls" : "pdf")
      ).then(this.loadingDownload.close());
    },
  },
};
</script>

<style scoped>
.el-breadcrumb {
  font-size: 20px;
}

.title {
  color: black;
  text-align: left;
}
table {
  width: 100%;
  text-align: end;
  font-size: 0.84rem;
}
thead {
  background-color: #083249;
  color: white;
  font-size: 0.82rem;
  text-align: center;
}

thead th {
  padding: 2px;
}
tbody {
  background-color: white;
  color: black;
}
.scrollit {
  border-radius: 5px;
  max-height: 57vh;
  overflow-y: auto;
  overflow-x: auto;
}

::-webkit-scrollbar {
  width: 10px;
  height: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: rgb(163, 163, 163);
  border-radius: 5px;
}
.total {
  background-color: rgb(219, 219, 219);
}
.generalTotal {
  background-color: rgb(141, 139, 139);
}
.boxContainer {
  display: table;
}
.box {
  padding-bottom: 15%;
  display: table-cell;
  text-align: center;
  vertical-align: bottom;
}
.centerIcon {
  display: flex;
  justify-content: left;
  align-items: flex-end;
  padding-bottom: 2.3%;
}
</style>
