<template>
  <el-row>
    <el-col :span="24">
      <div class="grid-content bg-purple-dark">
        <el-card class="box-card" style="width: 100%; border-radius: 10px">
          <el-popover
            placement="right-start"
            title="Guardar cambios"
            :width="250"
            trigger="hover"
            content="Presiona acá si deseas guardar todos los cambios."
          >
            <template #reference>
              <el-button
                class="btn-save"
                type="text"
                :loading="loadingModules"
                @click="saveBuyerModules()"
                ><ion-icon name="save-outline"></ion-icon
              ></el-button>
            </template>
          </el-popover>
          <el-table v-loading="loading" :data="tableData" style="width: 100%">
            <el-table-column label="Módulo" align="center">
              <template
                #default="scope"
                :data-cy="'module-name-' + scope.row.id"
              >
                {{ scope.row.name_spanish }}
              </template>
            </el-table-column>
            <el-table-column label="Estado" align="center">
              <template #default="scope">
                <el-switch
                  :data-cy="'module-switch-' + scope.row.id"
                  v-model="scope.row.active"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                />
              </template>
            </el-table-column>
          </el-table>
        </el-card>
      </div>
    </el-col>
  </el-row>
</template>
<script>
import { postData, getData, putData } from "@/request/request.js";
import { ElNotification } from "element-plus";

export default {
  components: {},
  name: "Modules",
  props: {
    endpoint: String,
  },
  created() {
    this.getModules();
  },
  data() {
    return {
      tableData: [],
      loadingModules: false,
      loading: false,
    };
  },
  mounted() {},
  methods: {
    async getModules() {
      this.loading = true;
      await getData("api/v1/modules", {}, true)
        .then((data) => {
          if (data.error == true || data.error == undefined) {
            ElNotification({
              title: "Error",
              message: data.message,
              type: "error",
            });

            return;
          }

          data.data.forEach((res) => {
            var obj = { active: false };
            res = Object.assign(res, obj);
            this.tableData.push(res);
          });
        })
        .catch(() => {
          ElNotification({
            title: "Error",
            message: "Ocurrió un error al hacer la petición de módulos",
            type: "error",
          });
        });
      await getData(this.endpoint, {}, true)
        .then((data) => {
          if (data.error == true || data.error == undefined) {
            ElNotification({
              title: "Error",
              message: data.message,
              type: "error",
            });
          }
          data.data.forEach((res) => {
            var module = this.tableData.find((module) => module.id === res.id);
            if (module !== undefined) {
              module.active = true;
            }
          });
        })
        .catch(() => {
          ElNotification({
            title: "Error",
            message: "Ocurrió un error al hacer la petición de módulos",
            type: "error",
          });
        });

      this.loading = false;
    },
    async saveBuyerModules() {
      var list = [];
      var listDelete = [];
      this.loadingModules = true;
      this.tableData.forEach((res) => {
        if (res.active == 1) {
          list.push(res);
        } else {
          listDelete.push(res);
        }
      });
      await postData(this.endpoint, list, true)
        .then((data) => {
          if (data.error == true || data.error == undefined) {
            ElNotification({
              title: "Error",
              message: data.message,
              type: "error",
            });
          } else {
            ElNotification({
              title: "Comprador Registrado",
              message: "Módulos del comprador registrados correctamente",
              type: "success",
            });
          }
        })
        .catch(() => {
          ElNotification({
            title: "Error",
            message: "Ocurrió un error al hacer la petición",
            type: "error",
          });
        });
      await putData(this.endpoint, listDelete, true)
        .then((data) => {
          if (data.error == true || data.error == undefined) {
            ElNotification({
              title: "Error",
              message: data.message,
              type: "error",
            });
          } else {
            this.emitter.emit("save-modules-success");
          }
        })
        .catch(() => {
          ElNotification({
            title: "Error",
            message: "Ocurrió un error al hacer la petición",
            type: "error",
          });
        });
      this.loadingModules = false;
    },
  },
};
</script>
<style scoped>
.el-button--success {
  background: #003149;
  border: none;
}
ion-icon {
  pointer-events: none;
}

.btn-plus {
  width: 55px;
  height: 55px;
  color: #003149;
  font-weight: bold !important;
  font-size: 32px;
  text-align: center;
}

.btn-save {
  width: 55px;
  height: 55px;
  padding-bottom: 5px;
  height: 50px;
  color: #003149;
  font-weight: bold !important;
  font-size: 32px;
  text-align: center;
  border: 0;
}
</style>
