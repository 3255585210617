<template>
  <el-select
    v-model="category_id"
    filterable
    placeholder="Selecciona una categoría"
    style="width: 100%"
    @input="$emit('update:category_id', $event.target.value)"
  >
    <el-option
      v-for="category in categories"
      :key="category.id"
      :label="category.name"
      :value="category.id"
    >
    </el-option>
  </el-select>
</template>
<script>
import { getData } from "../../request/request.js";
import { ElNotification } from "element-plus";

export default {
  name: "SelectCategory",
  setup() {},
  data() {
    return {
      categories: [],
      category_id: "",
    };
  },
  mounted() {
    this.getCategories();
  },
  methods: {
    async getCategories() {
      await getData("api/v1/categories", {}, true)
        .then((res) => {
          if (res.error == true || res.error == undefined) {
            ElNotification({
              title: "Error",
              message: res.message,
              type: "error",
            });
          } else {
            this.categories = res.data;
          }
        })
        .catch(() => {
          ElNotification({
            title: "Error",
            message: "Ocurrió un error al hacer la petición",
            type: "error",
          });
        });
    },
  },
};
</script>
