<template>
  <div>
    <el-dialog
      v-model="dialogVisible"
      width="50%"
      :before-close="handleClose"
      title="Editar insumo"
    >
      <el-row>
        <el-col>
          <div class="content">
            <el-row>
              <el-col :span="16" :offset="4">
                <el-form
                  ref="model"
                  label-position="top"
                  :model="model"
                  :rules="rules"
                  v-loading="loading"
                >
                  <el-form-item label="Nombre" prop="name">
                    <el-input v-model="model.name"></el-input>
                  </el-form-item>

                  <el-form-item label="Categoría" prop="category_id">
                    <SelectCategory v-model="model.category_id" />
                  </el-form-item>

                  <el-form-item label="Porcentaje IVA" prop="percent_iva">
                    <el-input v-model="model.percent_iva"></el-input>
                  </el-form-item>
                </el-form>
                <div class="button" align="center">
                  <el-button
                    :loading="loading"
                    type="primary"
                    @click="save('model')"
                    >Actualizar insumo</el-button
                  >
                </div>
              </el-col>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import SelectCategory from "./SelectCategory.vue";
import { defineComponent } from "vue";
import { ElMessageBox } from "element-plus";
import { ElMessage } from "element-plus";
import { hasPermits } from "@/config/Auth.js";
import { ElNotification } from "element-plus";
import { putData, getData } from "../../request/request.js";
export default defineComponent({
  name: "EditSupply",
  components: {
    SelectCategory,
  },

  setup() {
    const handleClose = (done) => {
      ElMessageBox.confirm(
        "¿Está seguro que quiere salir de la edición de insumo?",
        {
          showCancelButton: true,
          confirmButtonText: "Confirmar",
          cancelButtonText: "Cancelar",
        }
      )
        .then(() => {
          done();
        })
        .catch(() => {
          // catch error
        });
    };
    return {
      handleClose,
      hasPermits,
    };
  },
  data() {
    return {
      dialogVisible: false,
      roles: [],
      loading: false,
      id: 0,
      model: {
        name: "",
        category_id: "",
        percent_iva: "",
      },
      rules: {
        name: [
          {
            required: true,
            message: "Por favor ingresa el nombre",
            trigger: "blur",
          },
        ],
        percent_iva: [
          {
            required: true,
            message: "Por favor ingresa el porcentaje de IVA",
            trigger: "blur",
          },
        ],
        category_id: [
          {
            required: true,
            message: "Por favor selecciona una categoría",
            trigger: "blur",
          },
        ],
      },
    };
  },
  mounted() {
    this.emitter.off("open-modal-edit-supply");
    this.emitter.on("open-modal-edit-supply", (id) => {
      this.openForm(id);
    });
  },
  methods: {
    validateForm(formName) {
      let res;
      this.$refs[formName].validate((valid) => {
        res = valid;
      });
      return res;
    },
    get() {
      this.loading = true;
      getData(`api/v1/supplies/${this.id}/all`, {}, true)
        .then((res) => {
          if (res.error == true || res.error == undefined) {
            ElNotification({
              title: "Error",
              message: res.message,
              type: "error",
            });

            return;
          }

          this.model = res.data;
          this.loading = false;
        })
        .catch(() => {
          ElNotification({
            title: "Error",
            message: "Ocurrió un error al hacer la petición",
            type: "error",
          });

          this.loading = false;
        });
    },
    save(formName) {
      this.loading = true;
      if (this.validateForm(formName)) {
        putData("api/v1/supplies/" + this.id, this.model, true)
          .then((res) => {
            if (res.error == true || res.error == undefined) {
              ElNotification({
                title: "Error",
                message: res.message,
                type: "error",
              });

              this.loading = false;
              return;
            }
            ElNotification({
              title: "Insumo actualizado",
              message: res.message,
              type: "success",
            });

            this.dialogVisible = false;
            this.emitter.emit("reload-list-of-supplies");
            this.loading = false;
          })
          .catch(() => {
            ElNotification({
              title: "Error",
              message: "Ocurrió un error al hacer la petición",
              type: "error",
            });
            this.loading = false;
          });
      } else {
        ElMessage.error("¡ Error !, Por favor verifica todos los campos");
        this.loading = false;
      }
    },
    openForm(id) {
      this.cleanForm();
      this.id = id;
      this.get();
      this.dialogVisible = true;
    },
    cleanForm() {
      let form = this.$refs["model"];
      if (form) {
        form.resetFields();
      }

      this.model = {
        name: "",
        category_id: "",
        percent_iva: "",
      };
    },
  },
});
</script>

<style scoped>
.el-button--danger {
  background: #bc0304;
  border: none;
}

.el-button--success {
  background: #003149;
  border: none;
}

.el-button--primary {
  background: var(--blue) !important;
  border: 1px solid var(--blue) !important;
}

.el-button--text {
  color: var(--blue) !important;
  font-weight: bold;
  font-size: 25px;
}
.content {
  margin-top: 30px;
}
.icon-step {
  width: 20px;
}

::v-deep(.el-dialog__body) {
  text-align: center;
}

::v-deep(.el-form--label-top .el-form-item__label) {
  padding: 0;
}
</style>
