import { createApp } from "vue";
import { REPORTS_PATH_PREFIX } from "./config/constants.js";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import { createWebHistory, createRouter } from "vue-router";
import App from "./App.vue";
import mitt from "mitt";
import { isAuthRoute } from "@/config/Auth.js";
import Login from "./views/Auth/Login.vue";
import Home from "./views/Home.vue";
import Forgot from "./views/Auth/Forgot.vue";
import Buyer from "./views/Buyer.vue";
import Client from "./views/Client.vue";
import Provider from "./views/Provider.vue";
import User from "./views/User.vue";
import Contract from "./views/Contract.vue";
import Restore from "./views/Auth/Restore.vue";
import Supplies from "./views/Supplies.vue";
import Event from "./views/Event.vue";
import Payments from "./views/Payments.vue";
import ManageEvent from "./components/events/ManageEvent.vue";
import { ElNotification } from "element-plus";
import GeneralQuotation from "./views/GeneralQuotation.vue";
import Reports from "./views/Reports.vue";
import GraphicCharts from "./components/reports/Graphics/GraphicCharts.vue";
import ProjectChart from "./components/reports/Graphics/ProjectChart.vue";
import StatusChart from "./components/reports/Graphics/StatusChart.vue";
import ClientsChart from "./components/reports/Graphics/ClientsChart.vue";
import ListAssetAvailability from "./components/reports/ListAssetAvailability.vue";
import GeneralBudget from "./components/reports/GeneralBudget.vue";
import States from "./components/reports/States.vue";
import ListEvents from "./components/reports/ListEvents.vue";
import DependencyChart from "./components/reports/Graphics/DependencyChart.vue";
import CdpChart from "./components/reports/Graphics/CdpChart.vue";
import ListAdvances from "./components/reports/ListAdvances.vue";
import Profile from "./components/Profile.vue";


const routes = [
  { path: "/", name: "Login", meta: { spanishTitle: "Inicia sesion | Goodwind" }, component: Login },
  { path: "/profile", name: "ChangePassword", meta: { spanishTitle: "Cambiar contraseña" }, component: Profile },
  { path: "/home", name: "Home", meta: { spanishTitle: "Inicio" }, component: Home },
  { path: "/buyers", name: "Buyers", meta: { spanishTitle: "Compradores" }, component: Buyer },
  { path: "/users", name: "Users", meta: { spanishTitle: "Usuarios" }, component: User },
  { path: "/clients", name: "Clients", meta: { spanishTitle: "Clientes" }, component: Client },
  { path: "/providers", name: "Providers", meta: { spanishTitle: "Proveedores" }, component: Provider },
  { path: "/contracts", name: "Contracts", meta: { spanishTitle: "Proyectos" }, component: Contract },
  { path: "/supplies", name: "Supplies", meta: { spanishTitle: "Insumos" }, component: Supplies },
  { path: "/events", name: "Events", meta: { spanishTitle: "Eventos" }, component: Event },
  { path: "/payments", name: "Payments", meta: { spanishTitle: "Pagos" }, component: Payments },
  { path: "/manage_event/:id", name: "ManageEvent", meta: { spanishTitle: "Administrar evento" }, component: ManageEvent },
  { path: "/forgot", name: "Forgot", meta: { spanishTitle: "Olvide contraseña" }, component: Forgot },
  { path: "/restorepassword/:token", name: "Restore", meta: { spanishTitle: "Reestablecer contraseña" }, component: Restore },
  { path: "/general-quotations", name: "GeneralQuotations", meta: { spanishTitle: "Cotizaciones" }, component: GeneralQuotation },
  { path: `/${REPORTS_PATH_PREFIX}`, name: "Reports", meta: { spanishTitle: "Reportes" }, component: Reports },
  { path: `/${REPORTS_PATH_PREFIX}/assetavailability`, name: "ListAssetAvailability", meta: { spanishTitle: "Disponibilidad de recursos" }, component: ListAssetAvailability },
  { path: `/${REPORTS_PATH_PREFIX}/general-budget`, name: "GeneralBudget", meta: { spanishTitle: "Presupuesto general" }, component: GeneralBudget },
  { path: `/${REPORTS_PATH_PREFIX}/states`, name: "States", meta: { spanishTitle: "Reporte de estados" }, component: States },
  { path: `/${REPORTS_PATH_PREFIX}/events`, name: "ListEvents", meta: { spanishTitle: "Reporte eventos" }, component: ListEvents },
  { path: `/${REPORTS_PATH_PREFIX}/advances`, name: "ListAdvances", meta: { spanishTitle: "Reporte pagos" }, component: ListAdvances },
  { path: `/${REPORTS_PATH_PREFIX}/graphics`, name: "GraphicCharts", meta: { spanishTitle: "Gráficas" }, component: GraphicCharts },
  { path: `/${REPORTS_PATH_PREFIX}/graphics/project-chart`, name: "ProjectChart", meta: { spanishTitle: "Gráfica proyectos" }, component: ProjectChart },
  { path: `/${REPORTS_PATH_PREFIX}/graphics/clients`, name: "ClientsChart", meta: { spanishTitle: "Gráfica Clientes" }, component: ClientsChart },
  { path: `/${REPORTS_PATH_PREFIX}/graphics/status-chart`, name: "StatusChart", meta: { spanishTitle: "Grafica estados del proyecto" }, component: StatusChart },
  { path: `/${REPORTS_PATH_PREFIX}/graphics/dependencies-chart`, name: "DependenciesChart", meta: { spanishTitle: "Gráfica dependencias del proyecto" }, component: DependencyChart },
  { path: `/${REPORTS_PATH_PREFIX}/graphics/cdps-chart`, name: "CdpsChart", meta: { spanishTitle: "Gráfica de cdps del proyecto"}, component: CdpChart},
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  let isAuthenticated = localStorage.getItem("token") != null;
  if (!isAuthRoute(to) && !isAuthenticated) {
    ElNotification({
      title: "Error",
      message: "Su sesión a expirado, por favor inicie sesión de nuevo.",
      type: "error",
    });
    next({ name: "Login" });
  } else {
    document.title = `${to.meta.spanishTitle}`;
    next();
  }
});

const emitter = mitt();
const app = createApp(App);

app.config.globalProperties.emitter = emitter;
app.config.globalProperties.state = {
  event_status: {
    items: [],
    last_update: null
  }
}

app.use(router);
app.use(ElementPlus);
app.mount("#app");
