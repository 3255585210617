<template>
  <el-select
    v-model="contract_id"
    placeholder="Buscar Proyecto"
    no-data-text="No hay coincidencias."
    loading-text="Buscando..."
    filterable
    remote
    reserve-keyword
    :remote-method="searchContract"
    :loading="loading"
    style="width: 100%"
    @change="changeIntermediationPercentage"
  >
    <el-option
      v-for="contract in contracts"
      :key="contract.name"
      :label="contract.name + ' - ' + contract.number"
      :value="contract.id"
    >
    </el-option>
  </el-select>
</template>
<script>
import { getData } from "../../request/request.js";
import { ElNotification } from "element-plus";

export default {
  name: "SelectContract",
  data() {
    return {
      contracts: [],
      contract_id: "",
      loading: false,
      query:""
    };
  },
  mounted() {
    this.getContracts();
  },
  methods: {
    async getContracts() {
      this.loading = true;
      await getData(
        "api/v1/contracts/prefix",
        {
          query: this.query,
        },
        true
      )
        .then((res) => {
          this.loading = false;

          if (res.error == true || res.error == undefined) {
            ElNotification({
              title: "Error",
              message: res.message,
              type: "error",
            });
            return;
          }

          this.contracts = res.data;
        })
        .catch(() => {
          ElNotification({
            title: "Error",
            message: "Ocurrió un error al hacer la petición",
            type: "error",
          });
          this.loading = false;
        });
    },
    searchContract(query) {
      query = query.trim();
      if (query === "" || query.length < 2) {
        return;
      }

      this.query = query;
      this.getContracts();
    },

    changeIntermediationPercentage() {
      if (this.contracts !== []) {
        let contract = this.contracts.find((contract) => contract.id === this.contract_id);
        this.$emit("updating-intermediation-percentage", contract.intermediation_percentage);
      }
    },
  },
};
</script>
