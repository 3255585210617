<template>
  <el-select
    v-model="identification_type_id"
    placeholder="Selecciona el tipo de identificación"
    style="width: 100%"
    @input="$emit('update:identification_type_id', $event.target.value)"
  >
    <el-option
      v-for="identification in identificationTypes"
      :key="identification.id"
      :value="identification.id"
      :label="identification.name"
    >
    </el-option>
  </el-select>
</template>
<script>
import { getData } from "../request/request.js";
import { ElNotification } from "element-plus";

export default {
  name: "SelectIdentification",
  setup() {},
  data() {
    return {
      identificationTypes: [],
      identification_type_id: "",
    };
  },
  mounted() {
    this.getIdentificationTypes();
  },
  methods: {
    async getIdentificationTypes() {
      await getData("api/v1/identificationTypes", {}, true)
        .then((res) => {
          if (res.error == true || res.error == undefined) {
            ElNotification({
              title: "Error",
              message: res.message,
              type: "error",
            });
          }
          this.identificationTypes = res.data;
        })
        .catch(() => {
          ElNotification({
            title: "Error",
            message: "Ocurrió un error al hacer la petición",
            type: "error",
          });
        });
    },
  },
};
</script>
