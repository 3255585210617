import { getData } from "../request/request";
import { ElNotification } from "element-plus";

var app = null;
var synchronizing = false;
const newItem = {
  id: "new",
  name: "Crear estado",
};

const init = function (appIn) {
  app = appIn;
  let stateAux = appIn.state.event_status;

  if (
    (stateAux === null ||
      stateAux === undefined ||
      stateAux.last_update === null) &&
    !synchronizing
  ) {
    reloadData(app);
  }
};

const getItems = function () {
  if (app === null) {
    return [newItem];
  }

  return app.state.event_status.items;
};

const addItem = function (item) {
  app.state.event_status.items.push(item);
};

const reloadData = async function (appIn) {
  synchronizing = true;
  await getData("api/v1/status")
    .then((res) => {
      if (res.error == true || res.error == undefined) {
        ElNotification({
          title: "Error",
          message: res.message,
          type: "error",
        });

        return;
      }

      appIn.state.event_status.items = [newItem]
      appIn.state.event_status.items.push(...res.data);
      appIn.state.event_status.last_update = Date.now();
      appIn.emitter.emit("reload-list-of-event-statuses");
    })
    .catch(() => {
      ElNotification({
        title: "Error",
        message: "Ocurrió un error al obtener los estados para eventos",
        type: "error",
      });
    });
  synchronizing = false;
};

export const event_status_state = {
  init,
  getItems,
  addItem,
  reloadData,
};
