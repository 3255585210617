<template>
  <el-container>
    <br /><br />
    <el-header>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/buyers' }"
          ><h2 class="title">Compradores</h2></el-breadcrumb-item
        >
      </el-breadcrumb>
    </el-header>
    <el-main>
      <EditBuyer v-if="hasPermits('buyers.edit')"/>
      <CreateBuyer v-if="hasPermits('buyers.create')" />
      <br />
      <BuyerList v-if="hasPermits('buyers.list')" />
      <br />
      <ShowBuyer v-if="hasPermits('buyers.show')" />
    </el-main>
  </el-container>
</template>
<script>
import BuyerList from "../components/buyer/BuyerList.vue";
import CreateBuyer from "../components/buyer/CreateBuyer.vue";
import EditBuyer from "../components/buyer/EditBuyer.vue";
import ShowBuyer from "../components/buyer/ShowBuyer.vue";
import { hasPermits } from "@/config/Auth.js";

export default {
  setup() {
    return {
      hasPermits,
    };
  },
  name: "Buyers",
  components: {
    BuyerList,
    CreateBuyer,
    EditBuyer,
    ShowBuyer,
  },
};
</script>

<style scoped>
.title {
  color: black;
  text-align: left;
}
</style>
