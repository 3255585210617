<template>
  <el-row>
    <el-col :offset="1" :span="12">
      <h1 data-cy="home-text-1">Cree y Administre sus proyectos</h1>
      <h4 data-cy="home-text-2">en tiempo real.</h4>
    </el-col>
    <el-col :span="11">
      <img src="../assets/home_gif.gif" alt="home" />
    </el-col>
  </el-row>
</template>
<script>
export default {
  name: "Home",
};
</script>

<style scoped>
h1 {
  font-size: 90px;
  font-weight: 400;
  margin-bottom: 4px;
}

h4 {
  color: var(--blue);
  font-size: 45px;
  font-weight: 300;
  margin-top: 4px;
}

img{
  width: 100%;
  height: auto;
}

.el-row {
  width: -webkit-fill-available;
  display: flex;
  align-content: center;
}
</style>
