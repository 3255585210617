<template>
  <div :style="`width:${size}%`" class="card">
    <ion-icon v-if="!onlyRead" name="id-card-outline"></ion-icon>
    <el-tooltip v-if="!onlyRead" content="Eliminar" placement="left" effect="light">
      <el-popconfirm
        confirm-button-text="si"
        cancel-button-text="no"
        icon="el-icon-warning"
        icon-color="orange"
        title="¿Seguro de querer eliminar este contacto?"
         @confirm="remove"
      >
        <template #reference>
          <i class="el-icon-delete"></i>
        </template>
      </el-popconfirm>
    </el-tooltip>
    <el-tooltip v-if="!onlyRead" content="Editar" placement="left" effect="light">
      <i @click="edit" class="el-icon-edit"></i>
    </el-tooltip>
    <div class="content" :class="{'only_read': onlyRead}">
      <h3>Información de contacto</h3>
      <p>
        <b>Nombre Completo: </b> {{ contact.full_name }}<br />
        <b>Correo: </b> {{ contact.email }}<br />
        <b>Teléfono: </b> {{ contact.phone }}<br />
        <b>Area: </b>{{ contact.area }}<br />
        <b>Observaciones: </b> {{ contact.observations }}<br />
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: "ContactCard",
  props: {
    contact: {
      id: Number,
      uid: Number,
      full_name: String,
      email: String,
      phone: String,
      area: String,
      observations: String,
    },
    size: String,
    onlyRead: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    edit() {
      this.emitter.emit("edit-contact-form", this.contact);
    },
    remove() {
      this.emitter.emit("remove-contact-form", this.contact);
    },
  },

};
</script>
<style scoped>
[aria-label="id card outline"] {
  margin-top: auto;
  margin-bottom: auto;
  font-size: 55px;
  color: var(--blue);
  width: 20%;
  font-weight: 100;
}

.el-icon-delete {
  color: red;
  position: absolute;
  cursor: pointer;
  right: 10px;
  font-size: 20px;
}

.el-icon-edit {
  color: var(--blue);
  position: absolute;
  cursor: pointer;
  right: 10px;
  bottom: 5px;
  font-size: 20px;
}

::v-deep(.icon-inner) {
  background: blue;
  pointer-events: none;
}

.content {
  width: 80%;
  padding: 5px;
  box-sizing: border-box;
}

.only_read{
  width: 100% !important;
  cursor: pointer;
}

.content h3 {
  font-weight: 800 !important;
}

p {
  font-weight: 100 !important;
  color: gray;
}

b {
  font-weight: initial !important;
  color: black;
}

.card {
  position: relative;
  font-size: 14px;
  background: white;
  padding: 10px;
  display: inline-block;
  box-sizing: border-box;
  display: flex;
  margin: 5px;
  border-radius: 7px;
  transition: 0.3s;
}

.card:hover {
  -webkit-box-shadow: 8px 8px 24px 0px rgba(66, 68, 90, 0.57);
  -moz-box-shadow: 8px 8px 24px 0px rgba(66, 68, 90, 0.57);
  box-shadow: 8px 8px 24px 0px rgba(66, 68, 90, 0.57);

  transition: 0.3s;
}
</style>
