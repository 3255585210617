<template>
  <el-container>
    <el-row>
      <el-col :span="24">
        <br /><br />
        <el-header>
          <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/reports' }">
              Reportes
            </el-breadcrumb-item>
            <el-breadcrumb-item
              :to="{ path: `/${REPORTS_PATH_PREFIX}/graphics` }"
            >
              Gráficas
            </el-breadcrumb-item>
            <el-breadcrumb-item>Dependencias </el-breadcrumb-item>
          </el-breadcrumb>
        </el-header>
      </el-col>
    </el-row>

    <el-form
      ref="model"
      :model="model"
      label-position="top"
      :rules="rules"
      class="animate__animated animate__fadeIn"
    >
      <el-row class="form">
        <el-col :span="12">
          <el-form-item label="Selecciona un proyecto">
            <SelectContract v-model="projectID" />
          </el-form-item>
        </el-col>
        <el-col :span="6" :offset="2">
          <el-form-item label="Selecciona los estados en ejecución">
            <SelectStatus multiple v-model="selectedStates" />
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <div v-if="projectID != null">
            <el-button
              class="show-button"
              @click="getDependencies"
              type="primary"
            >
              Ver gráfica
            </el-button>
          </div>
        </el-col>
        <el-col :span="24">
          <el-tooltip
            v-if="dependencies['dependencies'] != null"
            content="Generar pdf"
            placement="right"
            effect="light"
          >
            <i @click="generatePDF" class="fa-regular fa-file-pdf icon-pdf"></i>
          </el-tooltip>
        </el-col>
      </el-row>
    </el-form>

    <el-row>
      <el-col :xs="0" :sm="1" :md="2" :lg="3" :xl="5"></el-col>
      <el-col
        :xs="24"
        :sm="22"
        :md="20"
        :lg="18"
        :xl="14"
        align="center"
        v-if="dependencies['dependencies'] != null"
      >
        <h2 align="center">DEPENDENCIAS DEL PROYECTO</h2>
        <div class="chart">
          <canvas id="myChart"></canvas>
          <div class="total">
            <span>Total proyecto: {{ toCurrency(contractTotal) }} </span>
            <span>Total ejecutado: {{ toCurrency(totalExecuted) }} </span>
            <span
              >Total disponible:
              {{ toCurrency(contractTotal - totalExecuted) }}
            </span>
          </div>
        </div>
      </el-col>
    </el-row>
  </el-container>
</template>
<script>
import Chart from "chart.js/auto";
import { REPORTS_PATH_PREFIX } from "../../../config/constants.js";
import SelectStatus from "@/components/events/Status/SelectStatus.vue";
import { postData, downloadDataPOST } from "@/request/request.js";
import { ElLoading, ElNotification } from "element-plus";
import { toCurrency } from "../../../config/Money";
import SelectContract from "../../contracts/SelectContract.vue";
export default {
  name: "DependencyChart",
  setup() {
    return {
      REPORTS_PATH_PREFIX,
      toCurrency,
    };
  },
  components: {
    SelectContract,
    SelectStatus,
  },
  data() {
    return {
      ctx: null,
      loading: false,
      selectedStates: null,
      projectID: null,
      query: "",
      chart: null,
      dependencies: {},
      labels: [],
      data: [],
      contractTotal: 0,
      totalDependencie: 0,
      totalExecuted: 0,
      backgroundColor: [
        "rgb(255, 73, 73)",
        "rgb(255, 205, 56)",
        "rgb(47, 164, 255)",
        "rgb(0, 255, 221)",
        "rgb(232, 255, 194)",
        "rgb(14, 24, 95)",
        "rgb(255, 0, 117)",
        "rgb(251, 255, 0)",
        "rgb(47, 164, 255)",
        "rgb(0, 255, 171)",
        "rgb(84, 22, 144)",
        "rgb(255, 141, 41)",
        "rgb(29, 185, 195)",
        "rgb(112, 39, 160)",
        "rgb(195, 43, 173)",
        "rgb(245, 111, 173)",
        "rgb(0, 120, 170)",
        "rgb(58, 180, 242)",
        "rgb(242, 223, 58)",
        "rgb(242, 223, 58)",
      ],
    };
  },
  mounted() {
    this.cleanForm("model");
  },
  methods: {
    async validateForm(formName) {
      let res = false;
      await this.$refs[formName].validate((valid) => {
        res = valid;
      });
      return res;
    },

    async getDependencies() {
      if (this.validateForm("model")) {
        this.loading = ElLoading.service();
        await postData(
          `api/v1/charts/${this.projectID}/dependencies`,
          {
            states: this.selectedStates,
          },
          true
        )
          .then((res) => {
            this.cleanChartData();
            if (this.verifyResult(res)) {
              this.dependencies = res.data;
              this.processDependencies();
              setTimeout(() => {
                this.draw();
                this.loading.close();
              }, 1000);
            }
          })
          .catch(() => {
            ElNotification({
              title: "Error",
              message: "Ocurrió un error al hacer la petición.",
              type: "error",
            });
            this.cleanChartData();
            this.loading.close();
          });
        return;
      }
      ElMessage.error("Error, selecciona un proyecto.");
    },

    verifyResult(res) {
      if (res.error == true || res.error == undefined) {
        ElNotification({
          title: "Error",
          message: res.message,
          type: "error",
        });
        this.loading.close();
        return false;
      }
      if (res.data["dependencies"].length == 0) {
        ElNotification({
          title: "Información",
          message: "No hay estados para mostrar en el proyecto seleccionado.",
          type: "info",
        });
        this.loading.close();
        return false;
      }
      return true;
    },

    processDependencies() {
      this.contractTotal = this.dependencies["totalContract"];
      for (let dependencie of this.dependencies["dependencies"]) {
        let color = this.randomColor(0,this.backgroundColor.length-1);
        let complementColor = this.complementColor(this.backgroundColor[color]);
        let executed = dependencie.executed;
        let amount = dependencie.amount;
        let available = amount - executed;

        this.labels.push(
          dependencie.name + " Ejecutado: " + this.toCurrency(executed)
        );
        this.labels.push(
          dependencie.name + " Disponible: " + this.toCurrency(available)
        );
        this.data.push({
          backgroundColor: [this.backgroundColor[color], complementColor],

          data: [
            this.calculatePercentage(amount, executed),
            this.calculatePercentage(amount, available),
          ],
        });
        this.totalDependencie += dependencie.amount;
        this.totalExecuted += dependencie.executed;
      }
    },

    draw() {
      this.ctx = document.getElementById("myChart").getContext("2d");
      const alwasShowTooltip = {
        id: "alwasShowTooltip",
        afterDraw(chart) {
          const { ctx } = chart;
          ctx.save();
          chart.data.datasets.forEach((dataset, i) => {
            chart.getDatasetMeta(i).data.forEach((datapoint, index) => {
              const { x, y } = datapoint.tooltipPosition();
              const text = chart.data.datasets[i].data[index] + "%";
              let textWidth = ctx.measureText(text).width;

              ctx.fillStyle = "rgba(0,0,0,0.8)";
              ctx.fillRect(
                x - (textWidth + 10) / 2,
                y - 25,
                textWidth + 10,
                20
              );

              //triangle
              ctx.beginPath();
              ctx.moveTo(x, y);
              ctx.lineTo(x - 5, y - 5);
              ctx.lineTo(x + 5, y - 5);
              ctx.fill();
              ctx.restore();

              //text
              ctx.font = "5px";
              ctx.fillStyle = "white";
              ctx.fillText(text, x - textWidth / 2, y - 12);
              ctx.restore();
            });
          });
        },
      };

      this.chart = new Chart(this.ctx, {
        type: "pie",
        data: {
          labels: this.labels,
          datasets: this.data,
        },
        options: {
          responsive: true,
          maintainAspectRatio: true,
          plugins: {
            legend: {
              position: "bottom",
              labels: {
                font: { size: 11.5 },
                generateLabels: function (chart) {
                  // Get the default label list
                  const original =
                    Chart.overrides.pie.plugins.legend.labels.generateLabels;
                  const labelsOriginal = original.call(this, chart);

                  // Build an array of colors used in the datasets of the chart
                  let datasetColors = chart.data.datasets.map(function (e) {
                    return e.backgroundColor;
                  });
                  datasetColors = datasetColors.flat();

                  // Modify the color and hide state of each label
                  labelsOriginal.forEach((label) => {
                    // There are twice as many labels as there are datasets. This converts the label index into the corresponding dataset index
                    label.datasetIndex = (label.index - (label.index % 2)) / 2;

                    // The hidden state must match the dataset's hidden state
                    label.hidden = !chart.isDatasetVisible(label.datasetIndex);

                    // Change the color to match the dataset
                    label.fillStyle = datasetColors[label.index];
                  });

                  return labelsOriginal;
                },
              },
              onClick: function (mouseEvent, legendItem, legend) {
                // toggle the visibility of the dataset from what it currently is
                legend.chart.getDatasetMeta(legendItem.datasetIndex).hidden =
                  legend.chart.isDatasetVisible(legendItem.datasetIndex);
                legend.chart.update();
              },
            },
            tooltip: {
              enabled: false,
            },
          },
          interaction: {
            intersect: false,
          },
        },
        plugins: [
          {
            afterRender: function (chart) {
              if (chart != null) {
                chart.$rendered = true;
              }
            },
          },
          alwasShowTooltip,
        ],
      });
    },

    async generatePDF() {
      this.loading = ElLoading.service();
      let image64 = this.getImage() != null ? this.getImage() : null;
      await downloadDataPOST(
        "api/v1/charts/complement-pdf-charts",
        {
          title: "Dependencias del proyecto",
          total: `TOTAL PROYECTO: ${toCurrency(this.contractTotal)}`,
          executed: `TOTAL EJECUTADO: ${toCurrency(this.totalExecuted)}`,
          available: `TOTAL DISPONIBLE: ${toCurrency(
            this.contractTotal - this.totalExecuted
          )} `,
          image: image64,
        },
        "Dependencias proyecto.pdf"
      );
      this.loading.close();
    },

    getImage() {
      if (this.chart.$rendered) {
        let canvas = document.getElementById("myChart");
        return canvas.toDataURL();
      }
    },

    randomColor(min = 0, max = 20) {
      return Math.floor(Math.random() * (max - min + 1) + min);
    },

    complementColor(color) {
      let slice = color.slice(4, color.length - 1);
      let rgb = slice.split(",");
      return `rgb(${rgb[0]},${rgb[1]},${rgb[2]},0.4)`;
    },

    cleanChartData() {
      if (this.chart) {
        this.chart.destroy();
        this.chart.$rendered = false;
      }
      this.labels = [];
      this.data = [];
      this.dependencies = {};
      this.contractTotal = 0;
      this.totalDependencie = 0;
      this.totalExecuted = 0;
    },

    cleanForm(formName) {
      if (this.$refs[formName] != undefined) {
        this.$refs[formName].resetFields();
      }
    },

    calculatePercentage(total, part) {
      let percentage = parseFloat((part * 100) / total);
      return percentage.toFixed(3);
    },
  },
};
</script>
<style scoped>
.el-container {
  display: block;
}
.chart {
  padding: auto;
  height: 100%;
  width: 100%;
  margin-bottom: 3%;
}
.el-breadcrumb {
  font-size: 20px;
}
.total {
  display: flex;
  justify-content: space-between;
  font-weight: bolder;
}

.total span {
  margin: 0 15px;
}

.form {
  margin: 0 20px;
}

.icon-pdf {
  color: rgb(22, 52, 219);
  margin: 0 10px 0 10px;
  font-size: 20px;
  cursor: pointer;
}

.show-button {
  margin: 50px 27px;
}
</style>
