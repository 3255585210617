<template>
  <div class="items">
    <el-dialog
      v-model="show"
      title="Editar solicitud de cotización"
      width="80%"
      :before-close="handleClose"
      center
    >
      <span>
        <el-form
          label-position="top"
          ref="model"
          :model="model"
          :rules="rules"
          :loading="loadingInformation"
        >
          <el-form-item label="Cliente" prop="client_id">
            <SelectClient ref="SelectClient" v-model="model.client_id" />
          </el-form-item>
          <SelectDepartmentCity ref="departmentCities" />
          <el-row>
            <el-col :span="11">
              <el-form-item label="Lugar" prop="event_place">
                <el-input v-model="model.event_place"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="2" />
            <el-col :span="11">
              <el-form-item label="Contacto" prop="contact">
                <el-input v-model="model.contact"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="11">
              <el-form-item label="Correo" prop="email">
                <el-input v-model="model.email"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="2" />
            <el-col :span="11">
              <el-form-item label="Teléfono" prop="phone">
                <el-input v-model="model.phone"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="11">
              <el-form-item label="Fecha inicio" prop="start_date">
                <el-date-picker
                  v-model="model.start_date"
                  type="datetime"
                  format="YYYY-MM-DD hh:mm a"
                  value-format="YYYY-MM-DD HH:mm"
                  placeholder="Selecciona una fecha"
                  style="width: 100%"
                ></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="2" />
            <el-col :span="11">
              <el-form-item label="Fecha fin" prop="finish_date">
                <el-date-picker
                  v-model="model.finish_date"
                  type="datetime"
                  format="YYYY-MM-DD hh:mm a"
                  value-format="YYYY-MM-DD HH:mm"
                  placeholder="Selecciona una fecha"
                  style="width: 100%"
                ></el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="11">
              <el-form-item label="Fecha Cotización" prop="quotation_date">
                <el-date-picker
                  v-model="model.quotation_date"
                  type="datetime"
                  format="YYYY-MM-DD"
                  value-format="YYYY-MM-DD"
                  placeholder="Selecciona una fecha"
                  style="width: 100%"
                ></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="2" />
            <el-col :span="11">
              <el-form-item
                label="Fecha de expiración de la cotización"
                prop="quotation_expiration"
              >
                <el-date-picker
                  v-model="model.quotation_expiration"
                  type="datetime"
                  format="YYYY-MM-DD"
                  value-format="YYYY-MM-DD"
                  placeholder="Selecciona una fecha"
                  style="width: 100%"
                ></el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>

          <add-general-quotation-details
            event="edit-general-quotation-detail"
          />

          <table class="table" v-if="tableData">
            <thead>
              <tr>
                <th>Producto</th>
                <th>Descripción</th>
                <th>Cantidad</th>
                <th>Valor unitario cliente</th>
                <th>Valor unitario interno</th>
                <th>Días</th>
                <th>IVA (%)</th>
              </tr>
            </thead>

            <tbody>
              <template v-for="(row, i) in tableData">
                <tr :key="i" v-if="row.tag !== 'delete'">
                  <td align="center" width="10%">
                    <el-tooltip
                      content="Eliminar"
                      placement="right"
                      effect="light"
                    >
                      <i
                        @click.prevent="deleteRow(i, row)"
                        class="el-icon-delete"
                      ></i> </el-tooltip
                    >{{ row.supply.name }}
                    <UploadSupply
                      :key="i"
                      :src="row.url_image"
                      :id="row.id"
                      event="edit-image-detail"
                      eventDeleteImage="delete-image-detail-edit"
                      :supply_id="row.supply.id"
                    />
                  </td>
                  <td>
                    <textarea
                      v-model="row.description"
                      cols="60"
                      rows="5"
                      @change="editRow(row)"
                      required
                    ></textarea>
                  </td>
                  <td width="8%">
                    <el-input
                      type="number"
                      v-model="row.amount"
                      cols="60"
                      rows="5"
                      @change="editRow(row)"
                      required
                    ></el-input>
                  </td>
                  <td>
                    <el-input
                      type="number"
                      v-model="row.unit_price_client"
                      cols="60"
                      rows="5"
                      @change="editRow(row)"
                      required
                    ></el-input>
                  </td>
                  <td>
                    <el-input
                      type="number"
                      v-model="row.unit_price_provider"
                      cols="60"
                      rows="5"
                      @change="editRow(row)"
                      required
                    ></el-input>
                  </td>
                  <td width="7%">
                    <el-input
                      type="number"
                      v-model="row.days"
                      cols="60"
                      rows="5"
                      @change="editRow(row)"
                      required
                    ></el-input>
                  </td>
                  <td width="7%">
                    <el-input
                      type="number"
                      v-model="row.iva"
                      cols="60"
                      rows="5"
                      @change="editRow(row)"
                      required
                    ></el-input>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>

          <br />
          <br />
          <el-form-item label="Observaciones" prop="observations">
            <el-input type="textarea" v-model="model.observations"></el-input>
          </el-form-item>
        </el-form>
      </span>

      <template #footer>
        <span class="dialog-footer">
          <el-button
            type="primary"
            @click="addGeneralQuotation()"
            :disabled="disableSave"
            >Guardar cambios</el-button
          >
          <el-button @click="show = false">Cancelar</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import SelectDepartmentCity from "../SelectDepartmensCities.vue";
import SelectClient from "../clients/SelectClient.vue";
import { putData, getData } from "../../request/request.js";
import { hasPermits } from "@/config/Auth.js";
import { ElMessage, ElMessageBox, ElNotification } from "element-plus";
import { generateDates } from "@/config/Helper.js";
import AddGeneralQuotationDetails from "./AddGeneralQuotationDetails.vue";

export default {
  components: {
    SelectClient,
    SelectDepartmentCity,
    AddGeneralQuotationDetails,
  },
  name: "GeneralQuotationEdit",
  setup() {
    return {
      hasPermits,
      generateDates,
    };
  },
  beforeCreate() {
    this.$options.components.UploadSupply =
      require("./UploadSupply.vue").default;
  },
  mounted() {
    this.emitter.off("edit-general-quotation-detail");
    this.emitter.on("edit-general-quotation-detail", (details) => {
      if (this.existDetail(details.supply.id)) {
        ElMessage.error("El concepto ya fue registrado.");
        return;
      }
      this.tableData.push({
        tag: "add",
        supply: details.supply,
        description: details.description,
        amount: details.amount,
        unit_price_client: details.unit_price_client,
        unit_price_provider: details.unit_price_provider,
        days: details.days,
        iva: details.iva,
      });
    });
    this.emitter.off("edit-image-detail");
    this.emitter.on("edit-image-detail", (imageData) => {
      for (let data of this.tableData) {
        if (data.id === imageData.id) {
          data.image64 = imageData.file.raw;
          data.tag = data.tag === "add" ? "add" : "edit";
          break;
        }
      }
    });
    this.emitter.off("delete-image-detail-edit");
    this.emitter.on("delete-image-detail-edit", (supply_id) => {
      if (supply_id != undefined) {
        for (let data of this.tableData) {
          if (data.supply.id === supply_id) {
            data.url_image = "";
            delete data.image64;
            data.id == undefined ? (data.tag = "add") : (data.tag = "edit");
            break;
          }
        }
      }
    });
  },
  data() {
    return {
      loading: false,
      show: false,
      id: 0,
      model: {
        id: "",
        observations: "",
        event_place: "",
        city_id: "",
        phone: "",
        departament_id: "",
        email: "",
        contact: "",
        start_date: "",
        finish_date: "",
        quotation_date: "",
        quotation_expiration: "",
        status: "",
        client_id: "",
        url_image: "",
      },
      disableSave: false,
      tableData: [],
      rules: {
        city_id: {
          required: true,
          message: "Por favor selecciona una ciudad",
          trigger: "change",
        },
        event_place: {
          required: true,
          message: "Por favor ingresa un lugar",
          trigger: "change",
        },
        contact: {
          required: true,
          message: "Por favor ingresa un contacto",
          trigger: "change",
        },
        email: [
          {
            required: true,
            message: "Por favor ingresa un correo",
            trigger: "blur",
          },
          {
            type: "email",
            message: "Por favor ingresa una dirección de correo válido",
            trigger: "blur",
          },
        ],
        quotation_date: {
          required: true,
          message: "Por favor ingresa la fecha de la cotización",
          trigger: "blur",
        },
        quotation_expiration: {
          required: true,
          message: "Por favor ingresa la fecha de expiración",
          trigger: "blur",
        },
        client_id: {
          required: true,
          message: "Por favor seleccione un cliente ",
          trigger: "blur",
        },
      },
    };
  },
  methods: {
    existDetail(supply_id) {
      let exist = false;
      this.tableData.forEach((row) => {
        if (row.supply.id == supply_id && row.tag != "delete") {
          exist = true;
          return;
        }
      });

      return exist;
    },
    editRow(row) {
      if (row.tag === "add") {
        return;
      }

      row.tag = "edit";
    },
    buildDetails(details) {
      let resultDetails = {};
      details.forEach((detail) => {
        resultDetails[detail.amount] = detail.amount;
      });

      return resultDetails;
    },
    handleClose(done) {
      ElMessageBox.confirm(
        "¿Estas seguro que deseas salir del formulario de editar cotización?",
        {
          showCancelButton: true,
          confirmButtonText: "Confirmar",
          cancelButtonText: "Cancelar",
        }
      )
        .then((res) => {
          if (res == "confirm") {
            this.show = false;
            this.cleanFormAdd();
            done();
          }
        })
        .catch(() => {}); // this action is call when user click in cancel button
    },

    deleteRow(index, row) {
      ElMessageBox.confirm(
        "¿Seguro de querer eliminar el concepto?",
        "Advertencia",
        {
          confirmButtonText: "Si",
          cancelButtonText: "No",
          type: "warning",
        }
      ).then(() => {
        if (row.tag === "add") {
          this.tableData.splice(index, 1);
        }

        row.tag = "delete";
      });
    },

    openForm(id) {
      this.cleanFormAdd();
      this.loadData(id);
      this.show = true;
    },
    loadData(id) {
      this.loadingInformation = true;
      this.id = id;
      getData(`api/v1/general-quotations/${id}/all`)
        .then((res) => {
          if (res.error == true || res.error == undefined) {
            ElNotification({
              title: "Error",
              message: res.message,
              type: "error",
            });

            return;
          }

          this.model.id = res.data.id;
          this.model.observations = res.data.observations;
          this.model.event_place = res.data.event_place;
          this.model.city_id = res.data.city_id;
          this.model.phone = res.data.phone;
          this.model.departament_id = res.data.departament_id;
          this.model.email = res.data.email;
          this.model.contact = res.data.contact;
          this.model.finish_date = res.data.finish_date;
          this.model.start_date = res.data.start_date;
          this.model.quotation_date = res.data.quotation_date;
          this.model.quotation_expiration = res.data.quotation_expiration;
          this.model.status = res.data.status;
          this.model.client_id = res.data.client_id;
          this.$refs.departmentCities.selectCity(this.model.city_id);

          this.tableData = [];
          res.data.details.forEach((detail) => {
            this.tableData.push({
              description: detail.description,
              amount: detail.amount,
              unit_price_client: detail.unit_price_client,
              unit_price_provider: detail.unit_price_provider,
              days: detail.days,
              iva: detail.iva,
              supply: { id: detail.supply.id, name: detail.supply.name },
              id: detail.id,
              url_image: detail.url_image ? detail.url_image.original : null,
            });
          });
          this.$refs.SelectClient.clients = [
            Object.fromEntries(
              Object.entries(res.data.client).filter(([key]) =>
                ["id", "full_name"].includes(key)
              )
            ),
          ];

          this.loadingInformation = false;
        })
        .catch(() => {
          ElNotification({
            title: "Error",
            message: "Ocurrió un error al hacer la petición",
            type: "error",
          });
        });
    },

    validateForm() {
      let res;
      this.$refs["model"].validate((valid) => {
        res = valid;
      });
      return res;
    },

    async convertToBase64(file) {
      return new Promise((resolve, reject) => {
        if (file == undefined) {
          reject(null);
        }
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    cleanFormAdd() {
      if (this.$refs.model === undefined) {
        return;
      }

      this.$refs.model.resetFields();

      this.model = {
        id: "",
        observations: "",
        event_place: "",
        city_id: "",
        phone: "",
        departament_id: "",
        email: "",
        contact: "",
        start_date: "",
        finish_date: "",
        quotation_date: "",
        quotation_expiration: "",
        status: "",
        client_id: "",
      };
      this.tableData = [];
    },
    validateRows() {
      let validation = true;

      this.tableData.forEach((data) => {
        if (
          data.id == "" ||
          data.description == "" ||
          data.amount == "" ||
          data.unit_price_client == "" ||
          data.days == "" ||
          data.unit_price_provider == "" ||
          data.iva == "" ||
          data.supply == []
        ) {
          validation = false;
          return;
        }
      });
      return validation;
    },
    validateHaveDetails() {
      let validation = false;

      this.tableData.forEach((data) => {
        if (data.tag == "add" || data.tag == "edit" || data.tag == null) {
          validation = true;
          return;
        }
      });
      return validation;
    },
    async addGeneralQuotation() {
      if (!this.validateForm()) {
        ElMessage.error("Ingrese los datos requeridos.");
        return;
      }

      if (!this.validateHaveDetails()) {
        ElMessage.error("Debes ingresar al menos un concepto.");
        return;
      }
      this.model.city_id = this.$refs.departmentCities.model.city_id;
      this.loading = true;

      let detailsFiltered = [];

      if (!this.validateRows()) {
        ElMessage.error("Debes ingresar todos los campos en los conceptos.");
        return;
      }
      this.tableData.forEach((data) => {
        if (data.tag !== undefined) {
          detailsFiltered.push(data);
        }
      });
      let details = [];
      if (detailsFiltered.length > 0) {
        for (let data of detailsFiltered) {
          let image;
          if (data.image64 !== undefined) {
            await this.convertToBase64(data.image64)
              .then((res) => {
                image = res;
              })
              .catch(() => {
                image = null;
              });
          }
          let aux = {
            id: data.id,
            tag: data.tag,
            description: data.description,
            amount: data.amount,
            unit_price_client: data.unit_price_client,
            days: data.days,
            unit_price_provider: data.unit_price_provider,
            iva: data.iva,
            supply_id: data.supply.id,
            image64: image,
            url_image: data.url_image,
          };
          details.push(aux);
        }
      }
      this.disableSave = true;
      putData(
        `api/v1/general-quotations/${this.id}`,
        {
          id: this.model.id,
          observations: this.model.observations,
          event_place: this.model.event_place,
          city_id: this.model.city_id,
          phone: this.model.phone,
          email: this.model.email,
          contact: this.model.contact,
          start_date: this.model.start_date,
          finish_date: this.model.finish_date,
          quotation_date: this.model.quotation_date,
          quotation_expiration: this.model.quotation_expiration,
          status: this.model.status,
          client_id: this.model.client_id,
          details: details,
        },
        true
      )
        .then((res) => {
          this.disableSave = false;

          if (res.error == true || res.error == undefined) {
            ElNotification({
              title: "Error",
              message: res.message,
              type: "error",
            });
            return;
          }

          ElNotification({
            title: "Solicitud de cotización actualizada.",
            message: res.message,
            type: "success",
          });

          this.emitter.emit("reload-list-of-general-quotations");
          this.show = false;
        })
        .catch(() => {
          ElNotification({
            title: "Error",
            message: "Ocurrió un error al hacer la petición",
            type: "error",
          });
          this.disableSave = false;
        });
    },
  },
};
</script>
<style scoped>
.table {
  width: -webkit-fill-available;
  font-size: 16px;
}

.table th {
  color: var(--blue);
  padding: 3px;
  border: 1px solid gray;
}

.table td {
  margin: 8px;
  border: 1px solid gray;
}

i {
  cursor: pointer;
  font-size: 18px;
}
.items {
  display: inline;
}

.el-icon-delete,
::v-deep(.el-table__empty-text) {
  color: red !important;
}

.el-icon-delete {
  float: left;
  margin-left: 8px;
}

.el-button--danger {
  background: #bc0304;
  border: none;
}

textarea {
  box-sizing: border-box;
  height: 44px;
  border: none;
  max-width: 100%;
  min-width: 100%;
  width: 100%;
  resize: none;
}
</style>
