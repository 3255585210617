<template>
  <el-dialog
    v-model="show"
    title="Ver cotización"
    width="90%"
    :before-close="handleClose"
    center
    :loading="!loadingInformation"
  >
    <div v-if="!loadingInformation">
      <el-row>
        <el-col :span="12">
          <p><b>Consecutivo:</b> {{ model.consecutive }}</p>
          <p><b>Cliente:</b> {{ model.client.full_name }}</p>
          <p><b>Nit:</b> {{ model.client.identification }}</p>
          <p><b>Contacto:</b> {{ model.contact }}</p>
          <p><b>Correo:</b> {{ model.email }}</p>
          <p><b>Teléfono:</b> {{ model.phone }}</p>
          <p><b>Ejecutivo:</b> {{ model.user ? model.user.full_name : "" }}</p>
        </el-col>
        <el-col :span="12">
          <p><b>Departamento:</b> {{ model.city.departament.name }}</p>
          <p><b>Ciudad:</b> {{ model.city.name }}</p>
          <p><b>Lugar:</b> {{ model.event_place }}</p>
          <p><b>Fecha cotización:</b> {{ model.quotation_date }}</p>
          <p>
            <b>Fecha y hora inicio:</b>
            {{ formatDateAndHour(model.start_date) }}
          </p>
          <p>
            <b>Fecha y hora fin:</b>
            {{ formatDateAndHour(model.finish_date) }}
          </p>
          <p><b>Fecha vencimiento:</b> {{ model.quotation_expiration }}</p>
        </el-col>
      </el-row>
      <br /><br />
      <table class="table" v-if="tableData">
        <thead>
          <tr>
            <th>Producto</th>
            <th>Descripción</th>
            <th>Cantidad</th>
            <th>Días</th>
            <th>IVA (%)</th>
            <th>Valor unitario cliente</th>
            <th>Valor unitario interno</th>
            <th>Valor total cliente</th>
            <th>Valor total interno</th>
          </tr>
        </thead>

        <tbody>
          <template v-for="(row, i) in tableData">
            <tr :key="i" v-if="row.tag !== 'delete'">
              <td align="center" width="10%">
                {{ row.supply.name }}
                <div>
                  <el-image
                    v-if="row.url_image != null"
                    style="width: 100px; height: 100px"
                    :src="row.url_image"
                    fit="cover"
                    :preview-src-list="[row.url_image]"
                    :initial-index="0"
                  />
                </div>
              </td>
              <td width="20%">
                <p>{{ row.description }}</p>
              </td>
              <td width="8%">
                <p style="text-align: center">{{ row.amount }}</p>
              </td>
              <td width="7%">
                <p style="text-align: center">{{ row.days }}</p>
              </td>
              <td width="7%">
                <p style="text-align: center">{{ row.iva }}</p>
              </td>
              <td>
                <p style="text-align: right">
                  {{ toCurrency(row.unit_price_client) }}
                </p>
              </td>
              <td>
                <p style="text-align: right">
                  {{ toCurrency(row.unit_price_provider) }}
                </p>
              </td>
              <td>
                <p style="text-align: right">
                  {{
                    toCurrency(row.unit_price_client * row.amount * row.days)
                  }}
                </p>
              </td>
              <td>
                <p style="text-align: right">
                  {{
                    toCurrency(row.unit_price_provider * row.amount * row.days)
                  }}
                </p>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
      <br /><br />
      <div>
        <p><b>SUBTOTAL: </b>{{ toCurrency(model.subtotal) }}</p>
        <p><b>IVA: </b>{{ toCurrency(model.iva) }}</p>
        <p><b>TOTAL: </b>{{ toCurrency(model.iva + model.subtotal) }}</p>
        <br />
        <b>Observaciones:</b>
        <p>{{ model.observations }}</p>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { getData } from "../../request/request.js";
import { hasPermits } from "@/config/Auth.js";
import { ElNotification } from "element-plus";
import { generateDates } from "@/config/Helper.js";
import { toCurrency } from "@/config/Money.js";
import { formatDateAndHour } from "../../config/Date.js";

export default {
  name: "GeneralQuotationShow",
  setup() {
    return {
      hasPermits,
      generateDates,
      toCurrency,
      formatDateAndHour,
    };
  },
  data() {
    return {
      loading: false,
      show: false,
      id: 0,
      model: {},
      loadingInformation: true,
      tableData: [],
    };
  },
  methods: {
    buildDetails(details) {
      let resultDetails = {};
      details.forEach((detail) => {
        resultDetails[detail.amount] = detail.amount;
      });

      return resultDetails;
    },

    openForm(id) {
      this.loadData(id);
      this.show = true;
    },
    loadData(id) {
      this.loadingInformation = true;
      this.id = id;
      getData(`api/v1/general-quotations/${id}/all`)
        .then((res) => {
          if (res.error == true || res.error == undefined) {
            ElNotification({
              title: "Error",
              message: res.message,
              type: "error",
            });

            return;
          }

          this.model = res.data;

          this.tableData = [];
          res.data.details.forEach((detail) => {
            this.tableData.push({
              description: detail.description,
              amount: detail.amount,
              unit_price_client: detail.unit_price_client,
              unit_price_provider: detail.unit_price_provider,
              days: detail.days,
              iva: detail.iva,
              supply: { id: detail.supply.id, name: detail.supply.name },
              url_image: detail.url_image ? detail.url_image.original : null,
              id: detail.id,
            });
          });

          this.loadingInformation = false;
        })
        .catch(() => {
          ElNotification({
            title: "Error",
            message: "Ocurrió un error al hacer la petición",
            type: "error",
          });
        });
    },
  },
};
</script>
<style scoped>
.table {
  width: -webkit-fill-available;
  font-size: 16px;
}

.table th {
  color: var(--blue);
  padding: 3px;
  border: 1px solid gray;
}

.table td {
  border: 1px solid gray;
}

i {
  cursor: pointer;
  font-size: 18px;
}

p {
  margin: 0;
}
</style>
