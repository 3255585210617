<template>
  <el-form
    label-position="top"
    ref="model"
    :model="model"
    :rules="rules"
    :loading="loading"
  >
    <el-form-item label="CDP" prop="cdp_id">
      <el-select
        v-model="model.cdp_id"
        filterable
        placeholder="Selecciona un CDP"
        v-on:change="manageSelectors(contract_id, $event)"
        style="width: 100%"
        ref="selectCdp"
      >
        <el-option
          v-for="cdp in cdps"
          :key="cdp.id"
          :value="cdp.id"
          :label="cdp.name"
        >
        </el-option>
      </el-select>
    </el-form-item>

    <el-form-item label="Dependencia" prop="dependency_id">
      <el-select
        v-model="model.dependency_id"
        filterable
        placeholder="Selecciona una dependencia"
        style="width: 100%"
        ref="selectDependency"
      >
        <el-option
          v-for="dependency in dependencies"
          :key="dependency.id"
          :value="dependency.id"
          :label="dependency.name"
        >
        </el-option>
      </el-select>
    </el-form-item>
  </el-form>
</template>

<script>
import { getData } from "../../request/request";
import { ElNotification } from "element-plus";

export default {
  name: "SelectCdpDependency",
  props: {
    contract_id: String,
  },
  data() {
    return {
      loading: false,
      cdps: [],
      dependencies: [],
      model: {
        cdp_id: "",
        dependency_id: "",
      },
      rules: {
        cdp_id: [
          {
            required: true,
            message: "Por favor selecciona un CDP",
            trigger: "blur",
          },
        ],
        dependency_id: [
          {
            required: true,
            message: "Por favor selecciona una dependencia",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    formReset() {
      let form = this.$refs.model;
      if (form) {
        form.resetFields();
      }
    },
    manageSelectors(
      contractId = this.contract_id,
      cdpId = "",
      dependencyId = ""
    ) {
      if (
        contractId !== "" &&
        cdpId !== "" &&
        dependencyId !== "" &&
        cdpId !== null &&
        cdpId !== undefined &&
        dependencyId !== null &&
        dependencyId !== undefined
      ) {
        this.model.cdp_id = cdpId;
        this.model.dependency_id = dependencyId;
        this.getCdps(contractId);
        this.getDependencies(cdpId);        
        return;
      }
      if (
        contractId !== "" &&
        cdpId !== "" &&
        cdpId !== null &&
        cdpId !== undefined &&
        dependencyId !== null &&
        dependencyId !== undefined
      ) {
        this.cleanSelectors(2);
        this.getDependencies(cdpId);        
        return;
      }
      if (contractId !== "") {
        this.cleanSelectors(1);
        this.getCdps(contractId);        
        return;
      }
    },    
    async getCdps(contractId) {
      getData(`api/v1/contracts/${contractId}/cdps`, {}, true)
        .then((res) => {
          if (res.error == true || res.error == undefined) {
            ElNotification({
              title: "Error",
              message: res.message,
              type: "error",
            });
            return;
          }
          this.cdps = res.data;
        })
        .catch(() => {
          ElNotification({
            title: "Error",
            message: "Ocurrió un error al hacer la petición",
            type: "error",
          });
        });
    },
    cleanSelectors(option) {
      switch (option) {
      case 1:
        this.model.cdp_id = "";
        this.model.dependency_id = "";
        this.cdps = [];
        this.dependencies = [];
        break;
      case 2:
        this.model.dependency_id = "";
        this.dependencies = [];
        break;
      }
    },
    async getDependencies(cdpID) {
      await getData(`api/v1/dependencies/${cdpID}`, {}, true)
        .then((res) => {
          if (res.error == true || res.error == undefined) {
            ElNotification({
              title: "Error",
              message: res.message,
              type: "error",
            });
            return;
          }
          this.dependencies = res.data;
        })
        .catch(() => {
          ElNotification({
            title: "Error",
            message: "Ocurrió un error al hacer la petición",
            type: "error",
          });
        });
    },
    validateForm() {
      let res;
      this.$refs.model.validate((valid) => {
        res = valid;
      });
      return res;
    },
  },
};
</script>

<style scoped></style>
