<template>
  <el-form ref="model" label-position="top" :model="model" :rules="rules">
    <el-form-item prop="category_id" label="Selecciona una categoría">
      <SelectCategory ref="selectCategory" v-model="model.category_id" />
    </el-form-item>
    <el-form-item prop="supply_id" label="Selecciona el insumo">
      <el-select
        v-model="model.supply_id"
        placeholder="Buscar insumo"
        no-data-text="No hay coincidencias."
        loading-text="Buscando..."
        filterable
        ref="supply"
        remote
        reserve-keyword
        :remote-method="searchSupplies"
        :loading="loading"
        style="width: 100%"
      >
        <el-option
          v-for="supply in supplies"
          :key="supply.name"
          :label="supply.name"
          :value="supply.id"
        >
        </el-option>
      </el-select>
    </el-form-item>
  </el-form>
</template>
<script>
import SelectCategory from "./SelectCategory.vue";
import { getData } from "../../request/request.js";
import { ElMessage, ElNotification } from "element-plus";

export default {
  name: "SelectSupply",
  components: { SelectCategory },
  data() {
    return {
      supplies: [],
      model: {
        category_id: "",
        supply_id: "",
      },
      rules:{
        supply_id: [
          {
            required: true,
            message: "Por favor selecciona el insumo",
            trigger: "change",
          },
        ]
      },
      loading: false,
    };
  },
  methods: {
    async getSupplies() {
      this.loading = true;
      await getData(
        "api/v1/supplies/prefix",
        {
          query: this.query,
          category_id: this.model.category_id,
        },
        true
      )
        .then((res) => {
          this.loading = false;

          if (res.error == true || res.error == undefined) {
            ElNotification({
              title: "Error",
              message: res.message,
              type: "error",
            });
            return;
          }

          this.supplies = res.data;
        })
        .catch(() => {
          ElNotification({
            title: "Error",
            message: "Ocurrió un error al hacer la petición",
            type: "error",
          });
          this.loading = false;
        });
    },
    searchSupplies(query) {
      if (this.model.category_id === "" || this.model.category_id === undefined) {
        ElMessage.error("¡ Error ! por favor selecciona una categoría");

        return;
      }

      query = query.trim();
      if (query === "" || query.length < 1) {
        return;
      }

      this.query = query;
      this.getSupplies();
    },
    getName() {
      return this.$refs.supply.selectedLabel;
    },
    validateForm() {
      let res;
      this.$refs["model"].validate((valid) => {
        res = valid;
      });
      return res;
    },
    getInformation() {
      if (!this.validateForm()) {
        throw "Debes de seleccionar un insumo";
      }

      return this.model;
    },
    cleanForm() {
      this.$refs["model"].resetFields();
    },
    setInformation(category_id,supply){
      this.model.category_id = category_id;
      this.supplies = [supply];
      this.model.supply_id = supply.id;
    }
  },
};
</script>
