<template>
  <div>
    <el-dialog
      v-model="dialogVisible"
      width="65%"
      :before-close="handleClose"
      title="Editar proyecto"
    >
      <!-- steps -->
      <el-steps :active="step" simple>
        <el-step
          @click="step = 0"
          title="Proyecto"
          icon="el-icon-edit"
        ></el-step>
        <el-step
          @click="step = 1"
          title="CDPs"
          icon="el-icon-tickets"
        ></el-step>
        <el-step
          @click="step = 2"
          title="Tarifas"
          icon="el-icon-tickets"
        ></el-step>
        <el-step
          @click="step = 3"
          title="Documentos"
          icon="el-icon-upload"
        ></el-step>
      </el-steps>
      <!-- content step 1 -->
      <el-form v-if="step === 0">
        <el-row>
          <el-col :span="20" :offset="2">
            <div class="grid-content bg-purple-dark">
              <el-form
                v-loading="loadingGetInformation"
                ref="model"
                label-position="top"
                :model="model"
                :rules="rules"
              >
                <el-form-item label="Número" prop="number">
                  <el-input v-model="model.number"></el-input>
                </el-form-item>

                <el-form-item label="Nombre" prop="name">
                  <el-input v-model="model.name"></el-input>
                </el-form-item>

                <el-row>
                  <el-col :span="20">
                    <el-form-item label="Cliente" prop="client_id">
                      <SelectClient
                        ref="SelectClient"
                        v-model="model.client_id"
                      />
                    </el-form-item>
                  </el-col>
                  <el-col :span="4" class="client-add">
                    <CreateClient v-if="hasPermits('clients.create')" />
                  </el-col>
                </el-row>

                <el-form-item label="Valor" prop="price">
                  <el-input type="number" v-model="model.price"></el-input>
                </el-form-item>

                <el-form-item prop="goal" label="Objeto">
                  <el-input
                    class="border"
                    v-model="model.goal"
                    placeholder="Ingrese el objeto del proyecto"
                    type="textarea"
                  ></el-input>
                </el-form-item>

                <el-form-item label="Fecha inicio" prop="start_date">
                  <el-date-picker
                    v-model="model.start_date"
                    type="date"
                    value-format="YYYY-MM-DD"
                    placeholder="Selecciona una fecha"
                    style="width: 100%"
                  ></el-date-picker>
                </el-form-item>

                <el-form-item label="Fecha suscripción" prop="sign_date">
                  <el-date-picker
                    v-model="model.sign_date"
                    type="date"
                    value-format="YYYY-MM-DD"
                    placeholder="Selecciona una fecha"
                    style="width: 100%"
                  ></el-date-picker>
                </el-form-item>

                <el-form-item label="Fecha fin" prop="finish_date">
                  <el-date-picker
                    v-model="model.finish_date"
                    type="date"
                    value-format="YYYY-MM-DD"
                    placeholder="Selecciona una fecha"
                    style="width: 100%"
                  ></el-date-picker>
                </el-form-item>
                <el-form-item
                  prop="intermediation_percentage"
                  label="Porcentage de intermediación"
                >
                  <el-input
                    v-model="model.intermediation_percentage"
                    type="number"
                  ></el-input>
                </el-form-item>
                <el-form-item
                  prop="finances_observations"
                  label="Observaciones financieras"
                >
                  <el-input
                    class="border"
                    v-model="model.finances_observations"
                    placeholder="Ingrese las observaciones financieras"
                    type="textarea"
                  ></el-input>
                </el-form-item>
              </el-form>

              <div class="button" align="center">
                <el-button
                  :loading="loadingSave"
                  type="primary"
                  @click="save('model')"
                  >Guardar proyecto</el-button
                >
              </div>
            </div>
          </el-col>
        </el-row>
      </el-form>
      <!-- step 2 -->
      <el-form v-if="step === 1">
        <ManageCdps :endpoint="cdpsEndPoint" />
      </el-form>
      <!-- step 3 -->
      <el-form v-if="step === 2">
        <div class="button" align="center">
          <Price :endpoint="pricesEndPoint" />
        </div>
      </el-form>
      <el-form v-if="step === 3">
        <EditDocument :endpoint="documentsEndpoint"  deletePermit="delete_file_contract" />
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import EditDocument from "../documents/EditDocument.vue";
import ManageCdps from "./ManageCdps.vue";
import SelectClient from "../clients/SelectClient.vue";
import { defineComponent } from "vue";
import { ElMessageBox } from "element-plus";
import { ElMessage } from "element-plus";
import { hasPermits } from "@/config/Auth.js";
import { ElNotification } from "element-plus";
import CreateClient from "../clients/CreateClient.vue";
import { putData, getData } from "../../request/request.js";
import Price from "../../views/Price.vue";
export default defineComponent({
  name: "EditContract",
  components: {
    SelectClient,
    EditDocument,
    CreateClient,
    Price,
    ManageCdps,
  },

  setup() {
    const handleClose = (done) => {
      ElMessageBox.confirm(
        "¿Está seguro que quiere salir de la edición de proyecto?",
        {
          showCancelButton: true,
          confirmButtonText: "Confirmar",
          cancelButtonText: "Cancelar",
        }
      )
        .then(() => {
          done();
        })
        .catch(() => {
          // catch error
        });
    };
    return {
      handleClose,
      hasPermits,
    };
  },
  mounted() {
    this.emitter.off("open-modal-edit-contract");
    this.emitter.on("open-modal-edit-contract", (id) => {
      this.openModal(id);
    });

    this.emitter.off("document-update-success");
    this.emitter.on("document-update-success", () => {
      this.dialogVisible = false;
    });
  },
  updated() {
    this.emitter.off("saved-prices");
    this.emitter.on("saved-prices", () => {
      this.step++;
    });
  },
  data() {
    return {
      dialogVisible: false,
      roles: [],
      loadingSave: false,
      loadingGetInformation: false,
      cdpsEndpoint: "",
      documentsEndpoint: "",
      pricesEndPoint: "",
      step: 0,
      model: {
        number: "",
        name: "",
        goal: "",
        price: "",
        start_date: "",
        finish_date: "",
        sign_date: "",
        finances_observations: "",
        client_id: "",
        intermediation_percentage: "",
      },
      rules: {
        number: [
          {
            required: true,
            message: "Por favor ingresa el número del proyecto",
            trigger: "blur",
          },
        ],
        name: [
          {
            required: true,
            message: "Por favor ingresa el nombre del proyecto",
            trigger: "blur",
          },
        ],
        goal: [
          {
            required: true,
            message: "Por favor ingresa el objeto del proyecto",
            trigger: "blur",
          },
        ],
        price: [
          {
            required: true,
            message: "Por favor ingresa el valor del proyecto",
            trigger: "blur",
          },
        ],
        start_date: [
          {
            required: true,
            message: "Por favor ingresa la fecha de inicio",
            trigger: "blur",
          },
        ],
        finish_date: [
          {
            required: true,
            message: "Por favor ingresa la fecha de fin",
            trigger: "blur",
          },
        ],
        finances_observations: [
          {
            required: true,
            message: "Por favor ingresa las observaciones financieras",
            trigger: "blur",
          },
        ],
        client_id: [
          {
            required: true,
            message: "Por favor selecciona el cliente",
            trigger: "blur",
          },
        ],
        intermediation_percentage: [
          {
            type: "number",
            asyncValidator: (rule, value) => {
              return new Promise((resolve, reject) => {
                if (value < 0 || value > 100) {
                  reject("El porcentaje debe estar entre cero(0) y cien(100)");
                } else {
                  resolve(true);
                }
              });
            },
          },
        ],
      },
    };
  },
  methods: {
    openModal(id) {
      this.id = id;
      this.cdpsEndPoint = `api/v1/contracts/${this.id}/cdps`;
      this.pricesEndPoint = "api/v1/prices/" + this.id;
      this.documentsEndpoint = "api/v1/documents/contract/" + this.id;
      this.cleanForm();
      this.fillData();
      this.dialogVisible = true;
    },
    async validateForm(formName) {
      let res;
      await this.$refs[formName].validate((valid) => {
        res = valid;
      });
      return res;
    },
    fillData() {
      this.loadingGetInformation = true;
      getData("api/v1/contracts/" + this.id)
        .then((res) => {
          this.loadingGetInformation = false;

          if (res.error == true || res.error == undefined) {
            ElNotification({
              title: "Error",
              message: res.message,
              type: "error",
            });

            return;
          }

          this.model = Object.fromEntries(
            Object.entries(res.data).filter(([key]) =>
              [
                "number",
                "name",
                "goal",
                "price",
                "start_date",
                "finish_date",
                "sign_date",
                "finances_observations",
                "client_id",
                "intermediation_percentage",
              ].includes(key)
            )
          );

          this.$refs.SelectClient.clients = [
            Object.fromEntries(
              Object.entries(res.data.client).filter(([key]) =>
                ["id", "full_name"].includes(key)
              )
            ),
          ];
        })
        .catch(() => {
          ElNotification({
            title: "Error",
            message: "Ocurrió un error al hacer la petición",
            type: "error",
          });
          this.loadingGetInformation = false;
        });
    },
    async save(formName) {
      let validateForm = await this.validateForm(formName);
      if (!validateForm) {
        ElMessage.error("¡ Error !, Por favor verifica todos los campos");
        return;
      }

      this.loadingSave = true;
      putData("api/v1/contracts/" + this.id, this.model, true)
        .then((res) => {
          this.loadingSave = false;

          if (res.error == true || res.error == undefined) {
            ElNotification({
              title: "Error",
              message: res.message,
              type: "error",
            });

            return;
          }

          ElNotification({
            title: "Proyecto editado",
            message: res.message,
            type: "success",
          });

          this.step = 1;
          // TODO: set the prices endpoint
          this.documentsEndpoint = "api/v1/documents/contract/" + res.data.id;
          this.emitter.emit("reload-list-of-contracts");
        })
        .catch(() => {
          ElNotification({
            title: "Error",
            message: "Ocurrió un error al hacer la petición",
            type: "error",
          });
          this.loadingSave = false;
        });
    },
    cleanForm() {
      let form = this.$refs["model"];
      if (form) {
        form.resetFields();
      }

      this.step = 0;
      this.model = {
        number: "",
        name: "",
        goal: "",
        price: "",
        start_date: "",
        finish_date: "",
        sign_date: "",
        finances_observations: "",
        client_id: "",
        intermediation_percentage: "",
      };
    },
  },
});
</script>

<style scoped>
.el-button--danger {
  background: #bc0304;
  border: none;
}

.el-button--success {
  background: #003149;
  border: none;
}

.el-button--primary {
  background: var(--blue) !important;
  border: 1px solid var(--blue) !important;
}

.el-button--text {
  color: var(--blue) !important;
  font-weight: bold;
  font-size: 25px;
}
.content {
  margin-top: 30px;
}
.icon-step {
  width: 20px;
}

::v-deep(.el-dialog__body) {
  text-align: center;
}

::v-deep(.el-form--label-top .el-form-item__label) {
  padding: 0;
}

.client-add {
  display: flex;
  align-content: space-between;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
}

.button {
  margin-top: 20px;
}

.el-step {
  cursor: pointer;
}
</style>
