<template>
  <div>
    <AddUsages
      :event_id="this.event.id"
      :city_id="this.event.city_id"
      :contract_id="this.event.contract_id"
      :startDate="this.start"
      :finishDate="this.finish"
      ref="addUsages"
    />
    <section class="add-button">
      <el-popover
        placement="top"
        title="Registrar consumo"
        :width="200"
        trigger="hover"
        content="Abrir formulario para agregar un consumo"
      >
        <template #reference>
          <el-button class="btn-plus" type="text" @click="openModal"
            ><i class="el-icon-plus add-icon"></i
          ></el-button>
        </template>
      </el-popover>
      <slot></slot>
    </section>

    <el-row>
      <el-col
        :span="24"
        class="content-table animate__animated animate__fadeInRight"
      >
        <table class="table">
          <th class="amounts">Insumo</th>
          <th class="amounts" v-for="(date, index) in dates" :key="index">
            {{ date }}
          </th>
          <th class="total-field">Total</th>
          <tbody>
            <tr v-for="(usage, i) in usages" :key="i">
              <td class="usage-field">
                <template v-if="hasPermits('events.delete_usages')">
                  <Delete
                    :endpoint="'api/v1/usages/' + usage.event_prices_id"
                    event="reload-usages-table"
                    :key="usage.id"
                  />
                </template>

                {{ usage.supply }}
              </td>
              <td class="amounts" v-for="(date, j) in dates" :key="j">
                <template v-if="usage.usages[date] != undefined">
                  <input
                    class="price_input"
                    @change="editAmount(usage.usages[date])"
                    type="number"
                    min="0"
                    v-model="usage.usages[date].amount"
                  />
                </template>
                <template v-else>
                  <input
                    class="price_input"
                    @change="addAmount(usage, date, $event)"
                    type="number"
                    min="0"
                    :value="0"
                  />
                </template>
              </td>

              <td class="total-field">
                {{ updateTotal(usage) }}
              </td>
            </tr>
          </tbody>
        </table>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { ElNotification, ElMessage } from "element-plus";
import { getData, postData, putData } from "../../request/request";
import { generateDates } from "@/config/Helper.js";
import AddUsages from "../Usages/AddUsages.vue";
import { hasPermits } from "@/config/Auth.js";
import Delete from "../Delete.vue";
export default {
  name: "ListUsages",
  props: {
    event: Object,
  },
  components: {
    AddUsages,
    Delete,
  },
  setup() {
    return {
      generateDates,
      hasPermits,
    };
  },
  mounted() {
    this.getUsages();
    this.emitter.off("add-usage-table");
    this.emitter.on("add-usage-table", (newUsage) => {
      this.appendUsage(newUsage);
    });

    this.emitter.off("reload-usages-table");
    this.emitter.on("reload-usages-table", () => {
      this.getUsages();
    });
  },
  data() {
    return {
      start: "",
      finish: "",
      loading: false,
      dates: [],
      usages: [],
      addModel: {},
    };
  },
  methods: {
    async getUsages() {
      this.loading = true;
      await getData(`api/v1/usages/${this.event.id}`, {})
        .then((res) => {
          if (res.error == true || res.error == undefined) {
            ElNotification({
              title: "Error",
              message: res.message,
              type: "error",
            });

            return;
          }

          this.processData(res.data);
          this.loading = false;
        })
        .catch(() => {
          ElNotification({
            title: "Error",
            message: "Ocurrió un error al hacer la petición",
            type: "error",
          });
        });

      this.loading = false;
    },

    openModal() {
      this.emitter.emit("open-add-usages");
    },

    processData(data) {
      this.setDates(this.event.start_date, this.event.finish_date);

      this.usages = [];

      data.forEach((row) => {
        this.appendUsage(row);
      });
    },

    appendUsage(usageData) {
      let usageRow = this.searchUsage(usageData.event_prices_id);
      if (usageRow == null) {
        let usage = {
          supply: usageData.supply,
          supply_id: usageData.supply_id,
          price_id: usageData.price_id,
          event_prices_id: usageData.event_prices_id,
          usages: [],
        };

        usage.usages[usageData.date] = {
          id: usageData.id,
          amount: usageData.amount,
        };

        this.usages.push(usage);
      } else {
        usageRow.usages[usageData.date] = {
          id: usageData.id,
          amount: usageData.amount,
        };
      }
    },

    updateTotal(usage) {
      let total = 0;
      for (let usageDate in usage.usages) {
        if (
          usage.usages[usageDate].amount === undefined ||
          usage.usages[usageDate].amount === null ||
          usage.usages[usageDate].amount === ""
        ) {
          continue;
        }

        total += parseInt(usage.usages[usageDate].amount);
      }

      return total;
    },

    searchUsage(event_prices_id) {
      let usageResult = null;
      this.usages.forEach((usage) => {
        if (usage.event_prices_id === event_prices_id) {
          usageResult = usage;
          return;
        }
      });

      return usageResult;
    },

    updateUsage(event_prices_id, date, amount) {
      let usageRow = this.searchUsage(event_prices_id);
      if (usageRow != null) {
        usageRow[date] = amount;
      }
    },

    setDates(aStart, aFinish) {
      this.dates = generateDates(
        aStart,
        aFinish,
        this.event.consuption_previous_day,
        this.event.consuption_next_day
      );

      this.start = this.dates[0] != undefined ? this.dates[0] : null;
      let leng = this.dates.length - 1;
      this.finish = this.dates[leng] != undefined ? this.dates[leng] : null;
    },

    editAmount(usage) {
      if (
        !usage ||
        usage.amount === undefined ||
        event.target.value === "" ||
        usage.amount < 0
      ) {
        ElMessage.error("Debe ingresar un valor válido");

        return;
      }

      putData(`api/v1/usages/${usage.id}`, {
        amount: usage.amount,
      })
        .then((res) => {
          if (res.error == true || res.error == undefined) {
            ElNotification({
              title: "Error",
              message: res.message,
              type: "error",
            });
            this.loading = false;
            return;
          }
          ElNotification({
            title: "Consumo actualizado correctamente",
            message: res.message,
            type: "success",
          });

          this.emitter.emit("reload-list-of-notes");

          this.updateUsage(
            res.data.event_prices_id,
            res.data.date,
            res.data.amount
          );
        })
        .catch(() => {
          ElNotification({
            title: "Error",
            message: "Ocurrió un error al hacer la petición",
            type: "error",
          });
        });
    },

    addAmount(usage, date, event) {
      if (
        !event ||
        event.target.value === undefined ||
        event.target.value === "" ||
        event.target.value < 0
      ) {
        ElMessage.error("Debe ingresar un valor válido");

        return;
      }

      this.addModel = {};

      this.addModel.supply = usage.supply;
      this.addModel.supply_id = usage.supply_id;
      this.addModel.price_id = usage.price_id;
      this.addModel.event_prices_id = usage.event_prices_id;

      this.addModel.date = date;
      this.addModel.amount = event.target.value;

      postData(`api/v1/usages/${this.event.id}`, this.addModel, true)
        .then((res) => {
          if (res.error == true || res.error == undefined) {
            ElNotification({
              title: "Error",
              message: res.message,
              type: "error",
            });
            this.loading = false;
            return;
          }
          ElNotification({
            title: "Consumo guardado correctamente",
            message: res.message,
            type: "success",
          });

          this.addModel.id = res.data.id;

          this.appendUsage(JSON.parse(JSON.stringify(this.addModel)));
        })
        .catch(() => {
          ElNotification({
            title: "Error",
            message: "Ocurrió un error al hacer la petición",
            type: "error",
          });
        });
    },
  },
};
</script>

<style scoped>
.table-body {
  margin-top: 2%;
  padding: 5px;
}

.add-button {
  margin-top: -1%;
}

.el-button--danger {
  background: #bc0304;
  border: none;
}

.el-button--success {
  background: #003149;
  border: none;
}

.el-button--primary {
  background: var(--blue) !important;
  border: 1px solid var(--blue) !important;
}

.el-button--text {
  color: var(--blue) !important;
  font-weight: bold;
  font-size: 25px;
}

ion-icon {
  color: darkorange;
  margin: 0 10px 0 10px;
  font-size: 20px;
  cursor: pointer;
}

::v-deep(.ionicon) {
  pointer-events: none !important;
}

.amounts {
  text-align: center;
  min-width: 130px;
}

.edit-icon {
  color: darkorange;
  margin: 0 10px 0 10px;
  font-size: 20px;
  cursor: pointer;
}

.properties {
  color: darkorange;
  margin: 0 10px 0 10px;
  font-size: 20px;
  cursor: pointer;
  text-align: end;
}

.table {
  border-radius: 5px;
  border: 0px;
  margin-top: 2%;
  background-color: white;
  width: 100%;
  padding: 2px;
  font-size: 20px;
  text-align: center;
}

thead {
  padding: 20px 20px;
  font-weight: bold;
}

thead th {
  padding: 10px;
}

tbody {
  font-size: 18px;
}

.table tr:nth-child(even) {
  background-color: #eee;
}

.total-field {
  white-space: nowrap;
  min-width: 80px;
}

.usage-field {
  text-align: left;
  white-space: nowrap;
}

.add-icon {
  color: rgb(2, 138, 13);
}

.price_input {
  text-align: center;
  font-size: 16px;
  height: -webkit-fill-available;
  width: -webkit-fill-available;
  border: none;
  outline: none;
  background-color: transparent;
}

.el-icon-delete {
  color: red;
}

.content-table {
  overflow-x: auto;
}

/* Works on Chrome, Edge, and Safari */
::-webkit-scrollbar {
  width: 10px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background-color: rgb(233, 231, 231);

  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: rgb(163, 163, 163);
  border-radius: 10px;
}
</style>
