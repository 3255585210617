<template>
  <el-select
    v-model="bank_id"
    placeholder="Selecciona el banco"
    reserve-keyword
    style="width: 100%"
    @input="$emit('update:bank_id', $event.target.value)"
  >
    <el-option
      v-for="bank in banks"
      :key="bank"
      :label="bank"
      :value="bank"
    >
    </el-option>
  </el-select>
</template>
<script>

export default {
  name: "SelectBank",
  data() {
    return {
      banks: [
        null,
        "BANCO DE BOGOTÁ",
        "BANCO POPULAR S.A.",
        "ITAÚ CORPBANCA COLOMBIA ",
        "BANCOLOMBIA ",
        "CITIBANK",
        "BANCO GNB SUDAMERIS",
        "BBVA COLOMBIA ",
        "BANCO  DE  OCCIDENTE ",
        "BANCO CAJA SOCIAL ",
        "BANCO DAVIVIENDA ",
        "SCOTIABANK COLPATRIA ",
        "BANCO AGRARIO ",
        "BANCO AV VILLAS",
        "BANCAMÍA S.A.",
        "BANCOOMEVA",
        "BANCO FINANDINA ",
        "BANCO FALABELLA S.A.",
        "NEQUI",
        "DAVIPLATA",
      ],
      bank_id: ""
    };
  },
};
</script>
