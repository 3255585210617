<template>
  <el-container>
    <br /><br />
    <el-header>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/payments' }"
          ><h2 class="title">Pagos</h2></el-breadcrumb-item
        >
      </el-breadcrumb>
    </el-header>
    <el-main>
      <list-general />
    </el-main>
  </el-container>
</template>

<script>
import ListGeneral from "../components/events/advance/ListGeneral.vue";
export default {
  components: { ListGeneral },
  name: "Payments",
};
</script>

<style scoped>
.title {
  color: black;
  text-align: left;
}

.el-header {
  --el-header-padding: 0 0px;
  --el-header-height: 50px;
}

.el-main {
  --el-main-padding: 5px;
}
</style>
