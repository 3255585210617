<template>
  <div>
    <el-popover
      placement="right-start"
      title="Crear comprador"
      :width="200"
      trigger="hover"
      content="Abrir formulario para agregar un comprador"
    >
      <template #reference>
        <el-button class="btn-plus" type="text" @click="openForm"
          ><i class="el-icon-plus"></i
        ></el-button>
      </template>
    </el-popover>

    <!-- modal -->
    <el-dialog
      v-model="dialogVisible"
      title=""
      width="82%"
      :before-close="handleClose"
    >
      <!-- steps -->
      <el-steps :active="step" simple>
        <el-step title="Comprador" icon="el-icon-edit"></el-step>
        <el-step title="Administrador" icon="el-icon-user"></el-step>
        <el-step title="Módulos" icon="el-icon-user"></el-step>
        <el-step
          v-if="hasPermits('buyers.upload_file')"
          title="Documentos"
          icon="el-icon-upload"
        ></el-step>
      </el-steps>
      <!-- content step 1 -->
      <el-form v-show="step === 0">
        <el-row>
          <el-col :span="24">
            <div class="grid-content bg-purple-dark">
              <el-form
                ref="model"
                :model="model"
                label-position="top"
                :rules="rules"
              >
                <br /><br />
                <el-row>
                  <UploadLogo ref="uploadLogo" />
                  <el-col :span="18" :offset="3">
                    <el-form-item
                      class="item-form"
                      label="Nombre Completo"
                      prop="name"
                    >
                      <el-input v-model="model.name"></el-input>
                    </el-form-item>

                    <el-form-item
                      label="Número de identificación"
                      prop="identification"
                    >
                      <el-input
                        v-model="model.identification"
                        :controls="false"
                      ></el-input>
                    </el-form-item>

                    <el-form-item
                      label="Tipo de identificación"
                      prop="identification_type_id"
                    >
                      <SelectIdentification
                        v-model="model.identification_type_id"
                      />
                    </el-form-item>

                    <el-form-item class="item-form" label="Correo" prop="email">
                      <el-input v-model="model.email"></el-input>
                    </el-form-item>

                    <el-form-item
                      class="item-form"
                      label="Dirección"
                      prop="address"
                    >
                      <el-input v-model="model.address"></el-input>
                    </el-form-item>

                    <el-form-item
                      class="item-form"
                      label="Teléfono"
                      prop="phone"
                    >
                      <el-input v-model="model.phone"></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form>

              <div id="boton" justify="end">
                <el-button
                  id="steps-button"
                  @click="next('model')"
                  type="success"
                  >Crear Usuario</el-button
                >
              </div>
            </div>
          </el-col>
        </el-row>
      </el-form>
      <!-- step 2 -->
      <el-form v-if="step === 1">
        <el-row>
          <el-col :span="24">
            <div class="grid-content bg-purple-dark">
              <el-form
                ref="model"
                :model="model.admin"
                label-position="top"
                :rules="rules"
              >
                <br /><br />
                <el-row>
                  <el-col :span="18" :offset="3">
                    <el-form-item
                      class="item-form"
                      label="Nombre Completo"
                      prop="full_name"
                    >
                      <el-input v-model="model.admin.full_name"></el-input>
                    </el-form-item>

                    <el-form-item class="item-form" label="Correo" prop="email">
                      <el-input v-model="model.admin.email"></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form>
              <div id="boton" justify="end">
                <el-button id="steps-button" @click="before" type="danger"
                  >Atrás</el-button
                >
                <el-button
                  id="steps-button"
                  :loading="loading"
                  @click="saveBuyerForm('model')"
                  type="success"
                  >Guardar</el-button
                >
              </div>
            </div>
          </el-col>
        </el-row>
      </el-form>

      <el-form v-if="step === 2">
        <Modules :endpoint="modulesEndpoint" />
      </el-form>
      <el-form v-if="step === 3 && hasPermits('buyers.upload_file')">
        <CreateDocument :endpoint="documentsEndpoint" />
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import SelectIdentification from "../SelectIdentification.vue";
import { ref } from "vue";
import { ElMessageBox } from "element-plus";
import { putDocument } from "@/request/request.js";
import { ElNotification } from "element-plus";
import { ElMessage } from "element-plus";
import { hasPermits } from "@/config/Auth.js";
import UploadLogo from "./UploadLogo.vue";

export default {
  name: "CreateBuyer",
  components: { SelectIdentification, UploadLogo },
  beforeCreate() {
    this.$options.components.CreateDocument =
      require("../documents/CreateDocument.vue").default;
    this.$options.components.Modules =
      require("../modules/Modules.vue").default;
  },
  setup() {
    const dialogVisible = ref(false);

    const handleClose = (done) => {
      ElMessageBox.confirm(
        "¿Estas seguro que deseas salir de la creación de comprador?",
        {
          showCancelButton: true,
          confirmButtonText: "Confirmar",
          cancelButtonText: "Cancelar",
        }
      )
        .then((res) => {
          if (res == "confirm") {
            done();
            this.cleanForm();
          }
        })
        .catch(() => {}); // this action is call when user click in cancel button
    };
    return {
      dialogVisible,
      handleClose,
      hasPermits,
    };
  },
  data() {
    return {
      model: {
        email: "",
        name: "",
        identification: "",
        address: "",
        phone: "",
        identification_type_id: "",
        admin: {
          full_name: "",
          email: "",
        },
      },
      modulesEndpoint: "",
      documentsEndpoint: "",
      step: 0,
      id: 0,
      loading: false,
      formData: "",

      rules: {
        email: [
          {
            required: true,
            message: "Por favor ingresa un correo",
            trigger: "blur",
          },
          {
            type: "email",
            message: "Por favor ingresa una dirección de correo válido",
            trigger: "blur",
          },
        ],
        name: [
          {
            required: true,
            message: "Por favor ingresa un nombre",
            trigger: "blur",
          },
        ],
        full_name: [
          {
            required: true,
            message: "Por favor ingresa un nombre",
            trigger: "blur",
          },
        ],
        identification: [
          {
            required: true,
            message: "Por favor ingresa un número de identificación",
            trigger: "blur",
          },
        ],
        address: [
          {
            required: true,
            message: "Por favor ingresa una dirección",
            trigger: "blur",
          },
        ],
        phone: [
          {
            required: true,
            message: "Por favor ingresa un teléfono",
            trigger: "blur",
          },
        ],
        identification_type_id: [
          {
            required: true,
            message: "Por favor ingresa el tipo de identificación",
            trigger: "change",
          },
        ],
      },
    };
  },

  mounted() {
    this.emitter.off("save-documents-success");
    this.emitter.on("save-documents-success", () => {
      this.dialogVisible = false;
    });
    this.emitter.off("save-modules-success");
    this.emitter.on("save-modules-success", () => {
      if (this.step++ > 3) this.step = 0;
    });
  },
  methods: {
    next(formName) {
      let upload = this.$refs.uploadLogo.validate();
      if (this.validateForm(formName) && upload) {
        if (this.step++ > 3) this.step = 0;
      } else {
        ElMessage.error(
          "¡ Error ! por favor llena todos los campos del formulario"
        );
      }
    },
    before() {
      if (this.step-- < 0) this.step = 2;
    },

    validateForm(formName) {
      let res;
      this.$refs[formName].validate((valid) => {
        res = valid;
      });
      return res;
    },

    saveBuyerForm(formName) {
      let upload = this.$refs.uploadLogo.validate();
      if (this.validateForm(formName) && upload) {
        this.loading = true;
        this.saveBuyer(formName, (res) => {
          this.loading = false;
          if (!hasPermits("buyers.upload_file")) {
            this.dialogVisible = false;
            return;
          }

          if (res) {
            if (this.step++ > 3) this.step = 0;
          }
        });
      } else {
        ElMessage.error(
          "¡ Error ! por favor llena todos los campos del formulario"
        );
      }
    },
    saveBuyer(formName, next) {
      let form = new FormData();
      form.append("email", this.model.email);
      form.append("name", this.model.name);
      form.append("identification", this.model.identification);
      form.append("address", this.model.address);
      form.append("phone", this.model.phone);
      form.append("identification_type_id", this.model.identification_type_id);
      form.append("admin_full_name", this.model.admin.full_name);
      form.append("admin_email", this.model.admin.email);
      form.append("file", this.$refs.uploadLogo.getImage());
      putDocument("api/v1/buyers", form, true)
        .then((data) => {
          if (data.error == true || data.error == undefined) {
            ElNotification({
              title: "Error",
              message: data.message,
              type: "error",
            });
            next(false);
          } else {
            ElNotification({
              title: "Comprador Registrado",
              message: "Comprador y usuario registrado correctamente",
              type: "success",
            });
            this.$refs[formName].resetFields();
            if (hasPermits("buyers.upload_file")) {
              this.emitter.emit("reload-list-of-buyers");
              this.id = data.data.id;
              this.documentsEndpoint = "api/v1/documents/buyer/" + data.data.id;
              this.modulesEndpoint = "api/v1/buyers/modules/" + data.data.id;
            }
            next(true);
          }
        })
        .catch(() => {
          ElNotification({
            title: "Error",
            message: "Ocurrió un error al hacer la petición",
            type: "error",
          });
          next(false);
        });
    },

    openForm() {
      this.dialogVisible = true;
      this.step = 0;
      let form = this.$refs["model"];
      if (form) {
        form.resetFields();
      }

      this.model = {
        email: "",
        name: "",
        identification: "",
        address: "",
        phone: "",
        identification_type_id: "",
        admin: {
          full_name: "",
          email: "",
        },
      };
    },
  },
};
</script>

<style scoped>
html body {
  background: white;
}
/* card */
.text {
  font-size: 14px;
}

.item {
  padding: 18px 0;
}

.box-card {
  margin: 5px;
}

#steps-button {
  margin: 10px;
}

label {
  margin-left: 50px;
  padding: 50px;
}

h1 {
  color: white;
}

.btn-plus {
  width: 55px;
  height: 55px;
  color: #003149;
  font-weight: bold !important;
  font-size: 32px;
  border-radius: 50%;
  text-align: center;
}

.el-form-item {
  margin-bottom: 15px;
}

::v-deep(.el-dialog__body) {
  text-align: center;
}

::v-deep(.el-form--label-top .el-form-item__label) {
  padding: 0;
}

.el-button--success {
  background: #003149;
  border: none;
}

.el-button--danger {
  background: #bc0304;
  border: none;
}

.el-button--primary {
  background: var(--blue) !important;
  border: 1px solid var(--blue) !important;
}
</style>
