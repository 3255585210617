<template>
  <el-dialog
    v-model="showModal"
    v-loading="loadingInformation"
    width="95%"
    :before-close="handleClose"
    title="Orden de compra cotización"
  >
    <h3>Información solicitud</h3>
    <div v-if="!loadingInformation">
      <p><b>Estado: </b>{{ quotation.status.name }}</p>
      <p>
        <b>Ciudad: </b>{{ quotation.subsidiarie.city.name }} -
        {{ quotation.subsidiarie.city.departament.name }}
      </p>
      <p><b>Proveedor: </b>{{ quotation.subsidiarie.provider.full_name }}</p>
      <p><b>Observaciones:</b></p>
      <p>{{ quotation.request_notes }}</p>
    </div>
    <br />
    <h3>Precios cotización</h3>
    <table class="table" v-if="!loadingInformation">
      <thead>
        <tr>
          <th :key="i" v-for="(header, i) in headers">
            {{ header.title }}
          </th>
        </tr>
      </thead>

      <tbody>
        <tr :key="i" v-for="(row, i) in tableData">
          <td align="center" :key="j" v-for="(header, j) in headers">
            <template v-if="header.name == 'unit_provider_price'">
              {{ toCurrency(row.unit_provider_price) }}
            </template>
            <template v-else-if="header.name.includes('price')">
              {{ toCurrency(row[header.name]) }}
            </template>
            <template v-else-if="header.name.includes('-')">
              <input
                class="amount_input"
                @change="changeAmount(row)"
                type="number"
                v-model="row[header.name]"
              />
            </template>
            <template v-else>
              {{
                row[header.name] == undefined ? "" : row[header.name]
              }}</template
            >
          </td>
        </tr>
      </tbody>
    </table>
    <br /><br />
    <el-form ref="model" label-position="top" :model="model">
      <el-form-item label="Observaciones" prop="observations">
        <el-input type="textarea" v-model="model.observations"></el-input>
      </el-form-item>
    </el-form>
    <div class="button" align="center">
      <el-button
        :loading="loadingSave"
        type="primary"
        @click="savePurcharseOrder"
        >Guardar orden de compra</el-button
      >
    </div>
  </el-dialog>
</template>

<script>
import { getData, postData } from "../../request/request.js";
import { toCurrency } from "../../config/Money.js";
import { ElNotification } from "element-plus";
import { generateDates } from "@/config/Helper.js";

export default {
  name: "QuotationPrices",
  setup() {
    return {
      toCurrency,
    };
  },
  data() {
    return {
      showModal: false,
      loadingInformation: false,
      loadingSave: false,
      headers: [
        {
          name: "supply_name",
          title: "Insumo",
        },
      ],
      model: {
        observations: "",
      },
      quotation: {},
      details: [],
      tableData: [],
      quotation_id: null,
      dates: [],
    };
  },
  methods: {
    openModal(id, start_date, end_date, previous_day, next_day) {
      this.headers = [
        {
          name: "supply_name",
          title: "Insumo",
        },
        {
          name: "description",
          title: "Descripción",
        },
      ];
      this.tableData = [];

      this.dates = generateDates(start_date, end_date, previous_day, next_day);

      this.dates.forEach((date) => {
        this.headers.push({
          name: date,
          title: date.substring(5),
        });
      });

      this.headers.push(
        ...[
          {
            name: "total_amount",
            title: "Cantidad total",
          },
          {
            name: "unit_provider_price",
            title: "Valor unitario",
          },
          {
            name: "total_provider_price",
            title: "Valor total",
          },
        ]
      );

      this.quotation_id = id;
      this.loadData(id);
      this.showModal = true;
    },
    loadData(id) {
      this.loadingInformation = true;
      getData(`api/v1/quotations/${id}/all`)
        .then((res) => {
          if (res.error == true || res.error == undefined) {
            ElNotification({
              title: "Error",
              message: res.message,
              type: "error",
            });

            return;
          }

          this.quotation = res.data;
          this.details = res.data.details;

          this.manageInformation();

          this.loadingInformation = false;
        })
        .catch(() => {
          ElNotification({
            title: "Error",
            message: "Ocurrió un error al hacer la petición",
            type: "error",
          });
        });
    },
    manageInformation() {
      this.details.forEach((detail) => {
        let row = [];
        row["total_amount"] = 0;
        row["id"] = detail.id;
        row["description"] = detail.description;
        row["unit_provider_price"] = detail.unit_provider_price;
        row["supply_id"] = detail.supply_id;

        detail.dates.forEach((date) => {
          row[date.date] = date.amount;
          row["total_amount"] += date.amount;
        });

        row["total_provider_price"] =
          row["total_amount"] * row["unit_provider_price"];
        row.supply_name = detail.supply.name;
        this.tableData.push(row);
      });
    },
    changeAmount(row) {
      let total = 0;
      for (let k in row) {
        if (k.includes("-")) {
          total += row[k];
        }
      }

      row.total_amount = total;
      row.total_provider_price = row.unit_provider_price * row.total_amount;
    },
    savePurcharseOrder() {
      this.loadingSave = true;
      let details = [];
      this.tableData.forEach((row) => {
        details.push(
          Object.fromEntries(
            Object.entries(row).filter(
              ([key]) =>
                !["total_provider_price", "id", "total_amount"].includes(key)
            )
          )
        );
      });

      postData(
        `api/v1/quotations/${this.quotation_id}/purcharse-order`,
        {
          details: details,
          observations: this.model.observations,
        },
        true
      )
        .then((res) => {
          this.loadingSave = false;

          if (res.error == true || res.error == undefined) {
            ElNotification({
              title: "Error",
              message: res.message,
              type: "error",
            });
            return;
          }

          ElNotification({
            title: "Orden de compra generada",
            message: res.message,
            type: "success",
          });

          this.emitter.emit("reload-list-of-quotations");
          this.showModal = false;
        })
        .catch(() => {
          ElNotification({
            title: "Error",
            message: "Ocurrió un error al hacer la petición",
            type: "error",
          });
          this.loadingSave = false;
        });
    },
  },
};
</script>

<style scoped>
.table {
  width: -webkit-fill-available;
  font-size: 16px;
}

.table th {
  color: var(--blue);
  padding: 3px;
  border: 1px solid gray;
}

.table td {
  margin: 8px;
  border: 1px solid gray;
}

.amount_input {
  text-align: center;
  font-size: 16px;
  width: 60px;
  border: none;
  outline: none;
}

h3 {
  color: var(--blue);
}
</style>
