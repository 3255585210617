<template>
  <el-dialog
    v-model="centerDialogVisible"
    title="Comprobante de pago"
    width="30%"
    center
  >
    <el-container>
      <el-row>
        <el-col :span="24" align="center">
          <el-upload
            class="upload-demo"
            drag
            :on-change="add"
            :auto-upload="false"
          >
            <i class="el-icon-document-add add-file"></i>
            <div class="el-upload__text">
              Arrastra la imagen o <em>click para subirla</em>
            </div>
            <template #tip>
              <div class="el-upload__tip">
                Archivos jpg/png/pdf con tamaño máximo de 5MB
              </div>
            </template>
          </el-upload>
        </el-col>
      </el-row>
    </el-container>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="centerDialogVisible = false">Cancelar</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script>
import { ref } from "vue";
import { putDocument } from "../../request/request.js";
import { ElNotification, ElLoading } from "element-plus";

const centerDialogVisible = ref(false);
export default {
  name: "UploadPayment",
  mounted() {
    this.emitter.off("advance-upload-payment");
    this.emitter.on("advance-upload-payment", (pay) => {
      this.assignEndpoint(pay);
      this.openModal();
    });
  },
  setup() {
    return {
      centerDialogVisible,
    };
  },
  data() {
    return {
      file: "",
      endPoint: "",
    };
  },
  methods: {
    add(file) {
      if (file == undefined) {
        ElNotification({
          title: "Error",
          message: "Debes seleccionar un archivo.",
          type: "error",
        });
        return;
      }
      this.loading = ElLoading.service();
      let form = new FormData();
      form.append("file", file.raw);
      form.append("description", "información pago");
      form.append("document_type_id", 6);
      putDocument(this.endPoint, form)
        .then((res) => {
          if (res.error == true || res.error == undefined) {
            ElNotification({
              title: "Error",
              message: res.message,
              type: "error",
            });
            return;
          }
          ElNotification({
            title: "Pago actualizado",
            message: res.message,
            type: "success",
          });
          this.closeModal();
          this.emitter.emit("reload-list-of-advances");
        })
        .catch(() => {
          ElNotification({
            title: "Error",
            message: "Ocurrió un error al hacer la petición",
            type: "error",
          });
        });
      this.file = "";
      this.loading.close();
    },

    openModal() {
      this.centerDialogVisible = true;
    },
    closeModal() {
      this.centerDialogVisible = false;
    },

    assignEndpoint(pay) {
      switch (pay.operation) {
        case "uploadPayment":
          this.endPoint = `api/v1/documents/advance/${pay.id}/upload-payment`;
          break;
        case "updatePayment":
          this.endPoint = `api/v1/documents/advance/${pay.id}/update-payment`;
          break;
        case "uploadInvoice":
          this.endPoint = `api/v1/documents/advance/${pay.id}/upload-invoice`;
          break;
        case "updateInvoice":
          this.endPoint = `api/v1/documents/advance/${pay.id}/update-invoice`;
          break;
      }
    },
  },
};
</script>
<style scoped>
.add-file {
  margin: 10%;
  font-size: 64px;
}

.upload-demo {
  text-align: center;
}

.el-row {
  width: 100%;
}
</style>
