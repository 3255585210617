<template>
  <el-dialog
    v-model="show"
    title="Información de documento"
    width="30%"
    :before-close="handleClose"
  >
    <span>
      <el-form
        label-position="top"
        class="form-document"
        ref="form"
        :rules="rules"
        :model="model"
      >
        <el-form-item label="Tipo de documento" prop="document_type_id">
          <el-select
            v-model="model.document_type_id"
            placeholder="Seleccione el tipo de documento"
            style="width: 100%"
          >
            <el-option
              v-for="document in documentTypes"
              :key="document.id"
              :value="document.id"
              :label="document.name"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="description" label="Descripción">
          <el-input
            class="border"
            v-model="model.description"
            placeholder="Ingrese una breve descripción"
            type="textarea"
          ></el-input>
        </el-form-item>
      </el-form>
    </span>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="cancel">Cancelar</el-button>
        <el-button type="primary" @click="addDocument">Agregar</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script>
import { ElMessageBox } from "element-plus";
import { getData } from "../../request/request.js";
import { ElNotification } from "element-plus";
export default {
  name: "FormDataDocument",
  created() {
    this.getDocumentTypes();
  },
  data() {
    return {
      type: null,
      show: false,
      model: {
        description: "",
        document_type_id: "",
      },
      rules: {
        documentTypes: [],
        description: [
          {
            required: true,
            message: "Se debe ingresar una breve descripción",
            trigger: "blur",
          },
        ],
        document_type_id: [
          {
            required: true,
            message: "Se debe seleccionar el tipo de documento",
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  methods: {
    addDocument() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.type == 1) {
            this.emitter.emit("add-document-from-form", this.model);
          }
          if (this.type == 2) {
            this.emitter.emit("edit-document-from-form", this.model);
          }
          this.show = false;
        }
      });
    },
    openForm(type) {
      this.type = type;
      let form = this.$refs.form;
      if (form) {
        form.resetFields();
      }
      this.model = {
        description: "",
        document_type_id: "",
      };
      this.show = true;
    },
    handleClose(done) {
      ElMessageBox.confirm(
        "¿Estas seguro que deseas salir de agregar documento?",
        {
          showCancelButton: true,
          confirmButtonText: "Confirmar",
          cancelButtonText: "Cancelar",
        }
      )
        .then((res) => {
          if (res == "confirm") {
            if (this.type == 1) {
              this.emitter.emit("remove-document-from-form-add", true);
            }

            if (this.type == 2) {
              this.emitter.emit("remove-document-from-form-edit", true);
            }
            this.show = false;
            done();
          }
        })
        .catch(() => {}); // this action is call when user click in cancel button
    },

    async getDocumentTypes() {
      await getData("api/v1/documentsType", {}, true)
        .then((data) => {
          if (data.error == true || data.error == undefined) {
            ElNotification({
              title: "Error",
              message: data.message,
              type: "error",
            });
          }
          this.documentTypes = data.data;
        })
        .catch(() => {
          ElNotification({
            title: "Error",
            message:
              "Ocurrió un error al hacer la petición de tipos de documentos",
            type: "error",
          });
        });
    },
    cancel() {
      this.show = false;
      if(this.type == 1){
        this.emitter.emit("remove-document-from-form-add", true);
      }
      if(this.type == 2){
        this.emitter.emit("remove-document-from-form-edit", true);
      }
    },
  },
};
</script>
