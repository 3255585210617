<template>
  <el-card class="box-card" width="100%">
    <template #header>
      <div class="card-header">
        <span>ID del evento: {{ eventDate.consecutive }}</span>
        <slot></slot>
      </div>
    </template>
    <div class="text item">
      <el-descriptions title="Datos del Evento" :column="2" border width="100%">
        <template #extra>
          <p><b>Estado: </b> {{ eventDate.status.name }} <br /></p>
        </template>
        <el-descriptions-item
          label="Proyecto"
          width="100px"
          label-align="left"
          align="center"
          >{{ eventDate.contract.name }} -
          {{ eventDate.contract.number }}</el-descriptions-item
        >
        <el-descriptions-item
          label="Fecha de creación"
          label-align="left"
          width="100px"
          align="center"
          >{{ formatDateAndHour(eventDate.created_at) }}</el-descriptions-item
        >
        <el-descriptions-item
          label="Número de factura"
          label-align="left"
          align="center"
          width="100px"
          >{{ eventDate.invoice_number }}</el-descriptions-item
        >

        <el-descriptions-item
          label="Valor de factura"
          label-align="left"
          align="center"
          width="100px"
        >
          {{ toCurrency(eventDate.invoice_value) }}
        </el-descriptions-item>

        <el-descriptions-item
          label="Nombre de evento"
          label-align="left"
          align="center"
          width="100px"
          >{{ eventDate.name }}</el-descriptions-item
        >
        <el-descriptions-item
          label="Fecha de solicitud"
          label-align="left"
          align="center"
          width="100px"
          >{{ eventDate.application_date }}</el-descriptions-item
        >
        <el-descriptions-item
          label="Sede"
          width="100px"
          label-align="left"
          align="center"
          >{{ eventDate.city.name }} -
          {{ eventDate.city.departament.name }}</el-descriptions-item
        >
        <el-descriptions-item
          label="CDP"
          width="100px"
          label-align="left"
          align="center"
        >
          {{ eventDate.dependency ? eventDate.dependency.cdp.name : "Sin CDP" }}
        </el-descriptions-item>
        <el-descriptions-item
          v-if="eventDate.dependency"
          label="Dependencia"
          width="100px"
          label-align="left"
          align="center"
          >{{ eventDate.dependency.name }}</el-descriptions-item
        >
        <el-descriptions-item
          label="Número de solicitud"
          label-align="left"
          width="100px"
          align="center"
          >{{ eventDate.application_number }}</el-descriptions-item
        >
        <el-descriptions-item
          label="Fecha de inicio"
          label-align="left"
          width="100px"
          align="center"
          >{{ formatDateAndHour(eventDate.start_date) }}</el-descriptions-item
        >
        <el-descriptions-item
          label="Lugar del evento"
          label-align="left"
          align="center"
          >{{ eventDate.event_site }}</el-descriptions-item
        >
        <el-descriptions-item
          label="Fecha fin"
          label-align="left"
          width="100px"
          align="center"
          >{{ formatDateAndHour(eventDate.finish_date) }}</el-descriptions-item
        >
        >
        <el-descriptions-item
          label="Tipo de evento"
          label-align="left"
          width="100px"
          align="center"
          >{{ eventDate.event_type }}</el-descriptions-item
        >
        >
        <el-descriptions-item
          label="Tipología del evento"
          label-align="left"
          align="center"
          width="100px"
          >{{ eventDate.typology }}</el-descriptions-item
        >
        <el-descriptions-item
          label="Coordinador"
          label-align="left"
          width="100px"
          align="center"
          >{{ eventDate.user.full_name }}</el-descriptions-item
        >
        <el-descriptions-item
          label="Solicitante"
          label-align="left"
          width="100px"
          align="center"
          >{{ eventDate.applicant }}</el-descriptions-item
        >
        <el-descriptions-item
          label="Responsable cliente"
          label-align="left"
          align="center"
          >{{ eventDate.client_contact }}</el-descriptions-item
        >

        <el-descriptions-item
          label="Número de asistentes"
          label-align="left"
          width="100px"
          align="center"
          >{{ eventDate.assistants_number }}</el-descriptions-item
        >

        <el-descriptions-item
          label="Apoyo logístico"
          label-align="left"
          width="100px"
          align="center"
          >{{ eventDate.logistic_support }}</el-descriptions-item
        >

        <el-descriptions-item
          label="Consumos desde el día anterior"
          label-align="left"
          width="100px"
          align="center"
        >
          <el-tag
            v-if="eventDate.consuption_previous_day == 1"
            class="mx-1"
            size="large"
            type="success"
            >Aplica</el-tag
          >
          <el-tag v-else class="mx-1" size="large" type="danger"
            >No aplica</el-tag
          >
        </el-descriptions-item>

        <el-descriptions-item
          label="Consumos hasta el día siguiente"
          label-align="left"
          width="100px"
          align="center"
        >
          <el-tag
            v-if="eventDate.consuption_next_day == 1"
            class="mx-1"
            size="large"
            type="success"
            >Aplica</el-tag
          >
          <el-tag v-else class="mx-1" size="large" type="danger"
            >No aplica</el-tag
          >
        </el-descriptions-item>

        <el-descriptions-item
          label="Comentarios del evento"
          label-align="left"
          align="center"
          width="100px"
          >{{ eventDate.event_comments }}</el-descriptions-item
        >
        <el-descriptions-item
          v-if="eventDate.intermediation_value !== null"
          label="Valor de intermediación"
          label-align="left"
          align="center"
          width="100px"
          >{{
            toCurrency(parseFloat(eventDate.intermediation_value))
          }}</el-descriptions-item
        >
      </el-descriptions>
    </div>
  </el-card>
</template>
<script>
import { formatDateAndHour } from "../../config/Date.js";
import { toCurrency } from "../../config/Money.js";
export default {
  name: "ShowEvent",
  setup() {
    return {
      formatDateAndHour,
      toCurrency,
    };
  },
  props: {
    event: {},
  },
  mounted() {
    this.eventDate = this.event;
  },
  data() {
    return {
      var: "",
      eventDate: {
        contract: {},
        user: {},
        status: {},
        city: {
          departament: {},
        },
        dependency: {
          cdp: {},
        },
      },
    };
  },
};
</script>
<style scoped>
::v-deep(.el-descriptions__label.el-descriptions__cell.is-bordered-label) {
  font-weight: 700;
  color: white;
  background: var(--blue);
}
::v-deep(.el-card) {
  background-color: transparent;
}
::v-deep(.el-descriptions__body
    .el-descriptions__table
    .el-descriptions__cell.is-center) {
  background: var(--el-descriptions-item-bordered-label-background);
  color: black;
}
</style>
