<template>
  <el-container>
    <br /><br />
    <el-header>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/events' }"
          ><h2 class="title">Eventos</h2></el-breadcrumb-item
        >
      </el-breadcrumb>
    </el-header>
    <el-main>
      <!-- content -->
      <create-event />
      <event-list />
    </el-main>
  </el-container>
</template>

<script>
import { hasPermits } from "@/config/Auth.js";
import EventList from '../components/events/EventList.vue';
import CreateEvent from '../components/events/CreateEvent.vue';
export default {
  setup() {
    return {
      hasPermits,
    };
  },
  name: "Event",
  components: {EventList,CreateEvent },
};
</script>

<style scoped>
.title {
  color: black;
  text-align: left;
}
</style>
