<template>
  <div>
    <el-dialog
      v-model="dialogVisible"
      width="65%"
      :before-close="handleClose"
      title="Editar pago"
    >
      <!-- steps -->
      <el-steps :active="step" simple>
        <el-step title="Pago" icon="el-icon-edit"></el-step>
      </el-steps>
      <el-form v-if="step === 0">
        <el-row>
          <el-col :span="20" :offset="2">
            <div class="grid-content bg-purple-dark">
              <el-form
                v-loading="loadingGetInformation"
                ref="model"
                label-position="top"
                :model="model"
                :rules="rules"
              >
                <el-row>
                  <el-col :span="20" :offset="2">
                    <el-form-item label="Proveedor" prop="provider_id">
                      <SelectProvider
                        v-model="model.provider_id"
                        ref="selectProvider"
                      />
                    </el-form-item>

                    <el-form-item label="Concepto" prop="concept">
                      <el-input v-model="model.concept"></el-input>
                    </el-form-item>
                    <el-form-item label="Valor" prop="amount">
                      <el-input type="number" v-model="model.amount"></el-input>
                    </el-form-item>
                    <el-form-item
                      label="Fecha de vencimiento"
                      prop="expiration_date"
                    >
                      <el-date-picker
                        v-model="model.expiration_date"
                        type="date"
                        value-format="YYYY-MM-DD"
                        placeholder="Selecciona una fecha"
                        :disabled-date="checkDates"
                        style="width: 100%"
                      ></el-date-picker>
                    </el-form-item>
                    <el-form-item label="Observaciones" prop="observations">
                      <el-input
                        type="textarea"
                        v-model="model.observations"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form>

              <div class="button" align="center">
                <el-button
                  :loading="loadingSave"
                  type="primary"
                  @click="save('model')"
                  >Actualizar pago</el-button
                >
              </div>
            </div>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import SelectProvider from "../../providers/SelectProvider.vue";
import { ElMessageBox } from "element-plus";
import { ElMessage } from "element-plus";
import { hasPermits } from "@/config/Auth.js";
import { putData, getData } from "../../../request/request.js";
import { ElNotification } from "element-plus";

export default {
  name: "EditAdvance",
  props: {
    idEvent: Number,
  },
  components: { SelectProvider },
  setup() {
    const handleClose = (done) => {
      ElMessageBox.confirm(
        "¿Está Seguro que quiere salir de la edición de pago?",
        {
          showCancelButton: true,
          confirmButtonText: "Confirmar",
          cancelButtonText: "Cancelar",
        }
      )
        .then(() => {
          done();
        })
        .catch(() => {
          // catch error
        });
    };
    return {
      handleClose,
      hasPermits,
    };
  },
  mounted() {
    this.emitter.off("open-modal-edit-advance");
    this.emitter.on("open-modal-edit-advance", (id) => {
      this.openModal(id);
    });
  },
  data() {
    return {
      dialogVisible: false,
      loadingSave: false,
      step: 0,
      model: {
        provider_id: "",
        event_id: "",
        concept: "",
        amount: 0,
        expiration_date: "",
        observations: "",
      },
      rules: {
        provider_id: [
          {
            required: true,
            message: "Por favor selecciona el proveedor",
            trigger: "blur",
          },
        ],
        concept: [
          {
            required: true,
            message: "Por favor ingresa el concepto",
            trigger: "blur",
          },
        ],
        amount: [
          {
            required: true,
            message: "Por favor ingresa el valor",
            trigger: "blur",
          },
        ],
        expiration_date: [
          {
            required: true,
            message: "Por favor ingresa la fecha de vencimiento",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    openModal(id) {
      this.id = id;
      this.cleanForm();
      this.fillData();
      this.dialogVisible = true;
    },
    validateForm(formName) {
      let res;
      this.$refs[formName].validate((valid) => {
        res = valid;
      });
      return res;
    },
    async fillData() {
      this.loadingGetInformation = true;
      await getData("api/v1/events/" + this.idEvent + "/advances/" + this.id)
        .then((res) => {
          this.loadingGetInformation = false;

          if (res.error == true || res.error == undefined) {
            ElNotification({
              title: "Error",
              message: res.message,
              type: "error",
            });

            return;
          }
          this.model = Object.fromEntries(
            Object.entries(res.data).filter(([key]) =>
              [
                "provider_id",
                "event_id",
                "concept",
                "amount",
                "expiration_date",
                "observations",
              ].includes(key)
            )
          );

          this.$refs.selectProvider.providers = [
            Object.fromEntries(
              Object.entries(res.data.provider).filter(([key]) =>
                ["id", "full_name"].includes(key)
              )
            ),
          ];
        })
        .catch(() => {
          ElNotification({
            title: "Error",
            message: "Ocurrió un error al hacer la petición",
            type: "error",
          });
        });
      this.loadingGetInformation = false;
    },

    async save(formName) {
      if (!this.validateForm(formName)) {
        ElMessage.error("¡ Error !, Por favor verifica todos los campos");
        return;
      }

      let newModel = Object.assign({}, this.model);
      this.loadingSave = true;
      await putData(
        "api/v1/events/" + this.idEvent + "/advances/" + this.id,
        newModel,
        true
      )
        .then((res) => {
          this.loadingSave = false;
          if (res.error == true || res.error == undefined) {
            ElNotification({
              title: "Error",
              message: res.message,
              type: "error",
            });
            return;
          }
          ElNotification({
            title: "Pago editado correctamente",
            message: res.message,
            type: "success",
          });

          this.dialogVisible = false;
          this.emitter.emit("reload-list-of-advances");
        })
        .catch(() => {
          ElNotification({
            title: "Error",
            message: "Ocurrió un error al hacer la petición",
            type: "error",
          });
          this.loadingSave = false;
        });
    },
    cleanForm() {
      let form = this.$refs["model"];
      if (form) {
        form.resetFields();
      }
      this.model = {
        provider_id: "",
        event_id: "",
        concept: "",
        amount: 0,
        expiration_date: "",
        observations: "",
      };
      this.step = 0;
    },
  },
};
</script>

<style scoped>
.el-button--danger {
  background: #bc0304;
  border: none;
}

.el-button--success {
  background: #003149;
  border: none;
}

.el-button--primary {
  background: var(--blue) !important;
  border: 1px solid var(--blue) !important;
}

.el-button--text {
  color: var(--blue) !important;
  font-weight: bold;
  font-size: 25px;
}
</style>
