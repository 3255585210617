<template>
  <div>
    <el-popover
      placement="right-start"
      title="Crear proyecto"
      :width="200"
      trigger="hover"
      content="Abrir formulario para agregar un proyecto"
    >
      <template #reference>
        <el-button class="btn-plus" type="text" @click="openModal()"
          ><i class="el-icon-plus add"></i
        ></el-button>
      </template>
    </el-popover>

    <el-dialog
      v-model="dialogVisible"
      width="65%"
      :before-close="handleClose"
      title="Crear proyecto"
    >
      <!-- steps -->
      <el-steps :active="step" simple>
        <el-step title="Proyecto" icon="el-icon-edit"></el-step>
        <el-step title="CDPs" icon="el-icon-tickets"></el-step>
        <el-step title="Tarifas" icon="el-icon-tickets"></el-step>
        <el-step title="Documentos" icon="el-icon-upload"></el-step>
      </el-steps>
      <!-- content step 1 -->
      <el-form v-if="step === 0">
        <el-row>
          <el-col :span="20" :offset="2">
            <div class="grid-content bg-purple-dark">
              <el-form
                ref="model"
                label-position="top"
                :model="model"
                :rules="rules"
              >
                <el-form-item label="Número" prop="number">
                  <el-input v-model="model.number"></el-input>
                </el-form-item>

                <el-form-item label="Nombre" prop="name">
                  <el-input v-model="model.name"></el-input>
                </el-form-item>

                <el-row>
                  <el-col :span="20">
                    <el-form-item label="Cliente" prop="client_id">
                      <SelectClient v-model="model.client_id" />
                    </el-form-item>
                  </el-col>
                  <el-col :span="4" class="client-add">
                    <CreateClient v-if="hasPermits('clients.create')" />
                  </el-col>
                </el-row>

                <el-form-item label="Valor" prop="price">
                  <el-input type="number" v-model="model.price"></el-input>
                </el-form-item>

                <el-form-item prop="goal" label="Objeto">
                  <el-input
                    class="border"
                    v-model="model.goal"
                    placeholder="Ingrese el objeto del proyecto"
                    type="textarea"
                  ></el-input>
                </el-form-item>

                <el-form-item label="Fecha inicio" prop="start_date">
                  <el-date-picker
                    v-model="model.start_date"
                    type="date"
                    value-format="YYYY-MM-DD"
                    placeholder="Selecciona una fecha"
                    style="width: 100%"
                  ></el-date-picker>
                </el-form-item>

                <el-form-item label="Fecha suscripción" prop="sign_date">
                  <el-date-picker
                    v-model="model.sign_date"
                    type="date"
                    value-format="YYYY-MM-DD"
                    placeholder="Selecciona una fecha"
                    style="width: 100%"
                  ></el-date-picker>
                </el-form-item>

                <el-form-item label="Fecha fin" prop="finish_date">
                  <el-date-picker
                    v-model="model.finish_date"
                    type="date"
                    value-format="YYYY-MM-DD"
                    placeholder="Selecciona una fecha"
                    style="width: 100%"
                  ></el-date-picker>
                </el-form-item>
                <el-form-item
                  label="Porcentaje de intermediación"
                  prop="intermediation_percentage"
                >
                  <el-input
                    type="number"
                    v-model="model.intermediation_percentage"
                  ></el-input>
                </el-form-item>
                <el-form-item
                  prop="finances_observations"
                  label="Observaciones financieras"
                >
                  <el-input
                    class="border"
                    v-model="model.finances_observations"
                    placeholder="Ingrese las observaciones financieras"
                    type="textarea"
                  ></el-input>
                </el-form-item>
              </el-form>

              <div class="button" align="center">
                <el-button
                  :loading="loadingCreate"
                  type="primary"
                  @click="save('model')"
                  >Crear proyecto</el-button
                >
              </div>
            </div>
          </el-col>
        </el-row>
      </el-form>
      <!-- step 2 -->
       <el-form v-if="step === 1">
        <ManageCdps :endpoint="cdpsEndpoint">
          <el-button type="primary" @click="step++"
            >Agregar tarifas</el-button
          >
        </ManageCdps>
      </el-form>
      <!-- step 3 -->
      <el-form v-if="step === 2">
        <div class="button" align="center">
          <Price :endpoint="pricesEndpoint" />
        </div>
        <div class="button" align="center">
          <el-button type="primary" @click="step++"
            >Agregar documentos</el-button
          >
        </div>
      </el-form>
      <el-form v-if="step === 3">
        <CreateDocument :endpoint="documentsEndpoint" />
        <div class="button" align="center">
          <el-button type="primary" @click="step--">Editar tarifas</el-button>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import CreateDocument from "../documents/CreateDocument.vue";
import ManageCdps from "./ManageCdps.vue";
import SelectClient from "../clients/SelectClient.vue";
import { defineComponent } from "vue";
import { ElMessageBox } from "element-plus";
import { ElMessage } from "element-plus";
import { hasPermits } from "@/config/Auth.js";
import { ElNotification } from "element-plus";
import CreateClient from "../clients/CreateClient.vue";
import { postData } from "../../request/request.js";
import Price from "../../views/Price.vue";
export default defineComponent({
  name: "CreateContract",
  components: {
    SelectClient,
    ManageCdps,
    CreateDocument,
    CreateClient,
    Price,
  },

  setup() {
    const handleClose = (done) => {
      ElMessageBox.confirm(
        "¿Está seguro que quiere salir de la creación de proyecto?",
        {
          showCancelButton: true,
          confirmButtonText: "Confirmar",
          cancelButtonText: "Cancelar",
        }
      )
        .then(() => {
          done();
        })
        .catch(() => {
          // catch error
        });
    };
    return {
      handleClose,
      hasPermits,
    };
  },
  mounted() {
    this.emitter.off("save-documents-success");
    this.emitter.on("save-documents-success", () => {
      this.dialogVisible = false;
    });
  },
  updated() {
    this.emitter.off("saved-prices");
    this.emitter.on("saved-prices", () => {
      this.step++;
    });
  },

  data() {
    return {
      dialogVisible: false,
      roles: [],
      loadingCreate: false,
      cdpsEndpoint: "",
      documentsEndpoint: "",
      pricesEndpoint: "",
      step: 0,
      id: 0,
      model: {
        number: "",
        name: "",
        goal: "",
        price: "",
        start_date: "",
        sign_date: "",
        finish_date: "",
        finances_observations: "",
        client_id: "",
        intermediation_percentage: "",
      },
      rules: {
        number: [
          {
            required: true,
            message: "Por favor ingresa el número del proyecto",
            trigger: "blur",
          },
        ],
        name: [
          {
            required: true,
            message: "Por favor ingresa el nombre del proyecto",
            trigger: "blur",
          },
        ],
        goal: [
          {
            required: true,
            message: "Por favor ingresa el objeto del proyecto",
            trigger: "blur",
          },
        ],
        price: [
          {
            required: true,
            message: "Por favor ingresa el valor del proyecto",
            trigger: "blur",
          },
        ],
        start_date: [
          {
            required: true,
            message: "Por favor ingresa la fecha de inicio",
            trigger: "blur",
          },
        ],
        finish_date: [
          {
            required: true,
            message: "Por favor ingresa la fecha de fin",
            trigger: "blur",
          },
        ],
        finances_observations: [
          {
            required: true,
            message: "Por favor ingresa las observaciones financieras",
            trigger: "blur",
          },
        ],
        client_id: [
          {
            required: true,
            message: "Por favor selecciona el cliente",
            trigger: "blur",
          },
        ],
        intermediation_percentage: [
          {
            type: "number",
            asyncValidator: (rule, value) => {
              return new Promise((resolve, reject) => {
                if (value < 0 || value > 100) {
                  reject("El porcentaje debe estar entre cero(0) y cien(100)");
                } else {
                  resolve(true);
                }
              });
            },
          },
        ],
      },
    };
  },
  methods: {
    openModal() {
      this.cleanForm();
      this.dialogVisible = true;
    },
    async validateForm (formName) {
      let res = false;
      await this.$refs[formName].validate((valid) => {
        res = valid;
      });
      return res;
    },
    async save(formName) {
      let validateForm = await this.validateForm(formName);
      if (!validateForm) {
        ElMessage.error("¡ Error !, Por favor verifica todos los campos");
        return;
      }

      this.loadingCreate = true;
      postData("api/v1/contracts", this.model, true)
        .then((res) => {
          this.loadingCreate = false;

          if (res.error == true || res.error == undefined) {
            ElNotification({
              title: "Error",
              message: res.message,
              type: "error",
            });
            return;
          }

          ElNotification({
            title: "Proyecto guardado",
            message: res.message,
            type: "success",
          });

          this.cdpsEndpoint = `api/v1/contracts/${res.data.id}/cdps`;
          this.documentsEndpoint = "api/v1/documents/contract/" + res.data.id;
          this.pricesEndpoint = "api/v1/prices/" + res.data.id;
          this.emitter.emit("reload-list-of-contracts");
          this.step = 1;
        })
        .catch(() => {
          ElNotification({
            title: "Error",
            message: "Ocurrió un error al hacer la petición",
            type: "error",
          });
          this.loadingCreate = false;
        });
    },
    cleanForm() {
      let form = this.$refs["model"];
      if (form) {
        form.resetFields();
      }

      this.step = 0;
      this.model = {
        number: "",
        name: "",
        goal: "",
        price: "",
        start_date: "",
        sign_date: "",
        finish_date: "",
        finances_observations: "",
        client_id: "",
        intermediation_percentage: "",
      };
    },
  },
});
</script>

<style scoped>
.el-button--danger {
  background: #bc0304;
  border: none;
}

.el-button--success {
  background: #003149;
  border: none;
}

.add {
  color: rgb(2, 138, 13);
}

.el-button--primary {
  background: var(--blue) !important;
  border: 1px solid var(--blue) !important;
}

.el-button--text {
  color: var(--blue) !important;
  font-weight: bold;
  font-size: 25px;
}
.content {
  margin-top: 30px;
}
.icon-step {
  width: 20px;
}

::v-deep(.el-dialog__body) {
  text-align: center;
}

::v-deep(.el-form--label-top .el-form-item__label) {
  padding: 0;
}

.client-add {
  display: flex;
  align-content: space-between;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
}
.button {
  margin-top: 20px;
}
</style>
