<template>
  <div class="container">
    <div class="section">
      <h1 class="title">Para acceder a la plataforma, identifícate con tus credenciales.</h1>
      <div class="footer">
        <div class="links"> 
          <a :href="getContact" class="whatsapp"> Whatsapp</a>
          <a :href="getInfo" class="info"> Información</a>
          <a :href="getSupport" class="support"> Soporte</a>
        </div>
        <p> Copyright &copy; {{ currentYear }} Goodwind S.A.S. <br> Todos los derechos reservados. </p>
      </div>
    </div>
    <div class="banner">
      <slot></slot>
    </div>
  </div>
</template>
<script>

export default {
  name: "AuthLayout",
  data() {
    return {
      currentYear: new Date().getFullYear(),
      getContact: "https://wa.me/573176483806",
      getInfo: "mailto:info@goodwind.com.co",
      getSupport: "mailto:soporte@goodwind.com.co",
    }
  },
};
</script>

<style scoped>
div {
  text-align: unset;
}
.container {
  height: 100vh;
  background: #e7eae1cc;
  width: 100%;
}

.banner {
  margin: 10vh 10% 10vh 10%;
  height: 80vh;
  width: 80%;
  border-radius: 15px;
  background: white;
  display: flex;
  justify-content: end;
  align-items: center;
}

.section {
  box-sizing: border-box;
  position: fixed;
  top: 5vh;
  left: 22%;
  width: 35%;
  height: 90vh;
  background: #003149;
  text-align: center;
  padding: 5%;
  padding-top: 22vh;
}



.title {
  margin-top: 15vh;
  color: white;
  font-weight: 400;
  font-size: 200%;
  font-family: "Times New Roman";
}

.footer {
  box-sizing: border-box;
  position: fixed;
  top: 64vh;
  left: 22.2%;
  width: 35%;
  height: 90vh;
  text-align: center;
  padding: 5%;
  padding-top: 22vh;
}

.footer > p {
  margin: 0;
  color: white;
  font-weight: 400;
  font-size:95%;
  font-family: "Times New Roman";
}

.footer > .links {
  margin-bottom: 1vh;
}

.footer > .links > a {
  color: white;
  font-weight: 400;
  font-family: "Times New Roman";
  transition: transform 0.3s ease-in-out;
  cursor: pointer;
  text-decoration: none;
  margin: 0 6px 0 6px;
}

.footer > .links > a:hover {
  text-decoration: underline;
}
.footer > .links > .info {
  border-right: 1px solid white;
  border-left: 1px solid white;
  padding: 0 10px 0 10px;
}



</style>
