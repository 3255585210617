import { getData, postData } from "../request/request.js";
import { ElNotification } from "element-plus";

export const isAuthRoute = function(route) {
  return (
    route.name == "Login" || route.name == "Forgot" || route.name == "Restore"
  );
};

export const setModules = function() {
  return new Promise((resolve, reject) => {
    getData("api/v1/auth/me")
      .then((res) => {
        localStorage.setItem("full_name", res.data.full_name);
        localStorage.setItem("email", res.data.email);
        localStorage.setItem("modules", JSON.stringify(res.data.modules));
        resolve();
      })
      .catch(() => {
        reject();
      });
  });
};

export const hasPermits = function(permits) {
  try {
    let modules = getModules();
    let eachStep = permits.split(".");
    let hasPermits = false;
        
    eachStep.forEach((step) => {    
      modules = modules[step];     
      if (modules == undefined) {
        hasPermits = false;
        
        return;
      }

      if (modules == true) {
        hasPermits = true;

        return;
      }
    });

    return hasPermits;
  } catch (error) {
    return false;
  }
};

export const getModules = function() {
  let modules = localStorage.getItem("modules");
  if (modules != null) {
    return JSON.parse(modules);
  }

  throw "No existe esa información";
};

export const refreshTokenAuto = function(emitter) {
  return setInterval(function() {
    refreshToken()
      .then(() => {
        setModules(); // for refresh actions
      })
      .catch((res) => {
        ElNotification({
          title: "Sesión expirada",
          message: res,
          type: "warning",
        });
        emitter.emit("logout-success");
      });
  }, 1000 * 60 * 25); // 25 minutes
};

const refreshToken = function() {
  return new Promise((resolve, reject) => {
    postData("api/v1/auth/refresh", {}, true)
      .then((res) => {
        if(res.error){
          reject(res.message);
        }
        localStorage.setItem("token", res.data.token);
        resolve();
      })
      .catch(() => {
        reject("Su sesión ha expirado.");
      });
  });
};
