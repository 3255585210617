<template>
  <el-popover
    placement="right-start"
    title="Crear cotización"
    :width="200"
    trigger="hover"
    content="Abrir formulario para agregar un cotización"
  >
    <template #reference>
      <el-button class="btn-plus" type="text" @click="openModal"
        ><i class="el-icon-plus add"></i
      ></el-button>
    </template>
  </el-popover>

  <el-dialog
    v-model="dialogVisible"
    title="Crear cotización"
    width="80%"
    :before-close="handleClose"
  >
    <span>
      <el-form ref="model" label-position="top" :model="model" :rules="rules">
        <el-row>
          <el-col :span="20" :offset="2">
            <el-form-item label="Selecciona un cliente" prop="client_id">
              <SelectClient v-model="model.client_id" ref="selectClient" />
            </el-form-item>

            <SelectDepartmentCity ref="departmentCities" />

            <el-form-item label="Forma de pago" prop="payment_form">
              <el-input v-model="model.payment_form"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="9" :offset="2">
            <el-form-item label="Lugar del evento" prop="event_place">
              <el-input v-model="model.event_place"></el-input>
            </el-form-item>

            <el-form-item label="Correo" prop="email">
              <el-input v-model="model.email"></el-input>
            </el-form-item>

            <el-form-item label="Fecha inicio">
              <el-date-picker
                v-model="model.start_date"
                type="datetime"
                format="YYYY-MM-DD hh:mm a"
                value-format="YYYY-MM-DD HH:mm"
                placeholder="Selecciona una fecha"
                style="width: 100%"
              ></el-date-picker>
            </el-form-item>

            <el-form-item label="Fecha cotización" prop="quotation_date">
              <el-date-picker
                v-model="model.quotation_date"
                type="date"
                format="YYYY-MM-DD"
                value-format="YYYY-MM-DD"
                placeholder="Selecciona una fecha"
                style="width: 100%"
              ></el-date-picker>
            </el-form-item>
          </el-col>

          <el-col :span="9" :offset="2">
            <el-form-item label="Contacto" prop="contact">
              <el-input v-model="model.contact"></el-input>
            </el-form-item>

            <el-form-item label="Teléfono" prop="phone">
              <el-input v-model="model.phone"></el-input>
            </el-form-item>

            <el-form-item label="Fecha fin">
              <el-date-picker
                v-model="model.finish_date"
                type="datetime"
                format="YYYY-MM-DD hh:mm a"
                value-format="YYYY-MM-DD HH:mm"
                placeholder="Selecciona una fecha"
                style="width: 100%"
              ></el-date-picker>
            </el-form-item>

            <el-form-item label="Fecha vencimiento" prop="quotation_expiration">
              <el-date-picker
                v-model="model.quotation_expiration"
                type="date"
                format="YYYY-MM-DD"
                value-format="YYYY-MM-DD"
                placeholder="Selecciona una fecha"
                style="width: 100%"
              ></el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>

        <add-general-quotation-details event="add-general-quotation-detail" />

        <table class="table">
          <th>Servicio y/o bien</th>
          <th>Descripción</th>
          <th>Cantidad</th>
          <th>Días</th>
          <th>Valor cliente</th>
          <th>Total cliente</th>
          <th>Valor interno</th>
          <th>Total interno</th>
          <th>IVA</th>

          <tbody>
            <tr v-for="(row, i) in dataTable" :key="i">
              <td align="center" width="10%">
                <div class="first-th">
                  <div class="content-delete">
                    <el-tooltip
                      content="Eliminar"
                      placement="top"
                      effect="light"
                    >
                      <i
                        @click.prevent="deleteRow(i, row)"
                        class="el-icon-delete"
                        style="margin-right: 10px"
                      ></i>
                    </el-tooltip>
                  </div>
                  <div>
                    {{ row.supply.name }}
                    <UploadSupply
                      :key="i"
                      :src="row.url_image"
                      :id="row.id"
                      event="add-image-detail"
                    />
                  </div>
                </div>
              </td>
              <td>
                <textarea
                  v-model="row.description"
                  cols="45"
                  rows="3"
                  autosize="false"
                  required
                ></textarea>
              </td>
              <td width="7%">
                <el-input
                  type="number"
                  v-model="row.amount"
                  cols="60"
                  rows="5"
                  required
                  :min="0"
                  :max="100"
                ></el-input>
              </td>
              <td width="7%">
                <el-input
                  type="number"
                  v-model="row.days"
                  cols="60"
                  rows="5"
                  required
                ></el-input>
              </td>
              <td width="9%">
                <el-input
                  type="number"
                  v-model="row.unit_price_client"
                  cols="60"
                  rows="5"
                  required
                  :min="0"
                  :max="100"
                ></el-input>
              </td>
              <td width="9%">
                {{
                  toCurrency(
                    updateTotal(row.days, row.amount, row.unit_price_client)
                  )
                }}
              </td>
              <td>
                <el-input
                  type="number"
                  v-model="row.unit_price_provider"
                  cols="60"
                  rows="5"
                  required
                  :min="0"
                  :max="100"
                ></el-input>
              </td>
              <td width="7%">
                {{
                  toCurrency(
                    updateTotal(row.days, row.amount, row.unit_price_provider)
                  )
                }}
              </td>
              <td>
                <el-input
                  type="number"
                  v-model="row.iva"
                  cols="60"
                  rows="5"
                  required
                  :min="0"
                  :max="100"
                ></el-input>
              </td>
            </tr>
          </tbody>
        </table>

        <el-form-item label="Observaciones">
          <el-input type="textarea" v-model="model.observations"></el-input>
        </el-form-item>
      </el-form>
    </span>
    <div class="button" align="center">
      <el-button
        :loading="loadingCreate"
        type="primary"
        @click="saveQuotation('model')"
        >Crear cotización</el-button
      >
    </div>
  </el-dialog>
</template>
<script>
import { ElMessage, ElMessageBox, ElNotification } from "element-plus";
import { hasPermits } from "@/config/Auth.js";
import SelectClient from "../clients/SelectClient.vue";
import SelectDepartmentCity from "../SelectDepartmensCities.vue";
import AddGeneralQuotationDetails from "./AddGeneralQuotationDetails.vue";
import { postData } from "../../request/request.js";
import { toCurrency } from "@/config/Money.js";
export default {
  name: "CreateGeneralQuotation",
  components: {
    SelectClient,
    SelectDepartmentCity,
    AddGeneralQuotationDetails,
  },
  beforeCreate() {
    this.$options.components.UploadSupply =
      require("./UploadSupply.vue").default;
  },
  mounted() {
    this.emitter.off("add-general-quotation-detail");
    this.emitter.on("add-general-quotation-detail", (details) => {
      if (this.searchDetail(details.supply.id)) {
        ElMessage.error("¡ Error !, El concepto ya fue registrado");
        this.$refs.generalQuotaion.dialogVisible = false;
        return;
      }
      this.dataTable.push({
        tag: "add",
        supply: details.supply,
        description: details.description,
        amount: details.amount,
        unit_price_client: details.unit_price_client,
        unit_price_provider: details.unit_price_provider,
        days: details.days,
        iva: details.iva,
      });
    });
    this.emitter.off("add-image-detail");
    this.emitter.on("add-image-detail", (imageData) => {
      for (let data of this.dataTable) {
        if (data.id === imageData.id) {
          data.image64 = imageData.file.raw;
          break;
        }
      }
    });
  },
  setup() {
    const handleClose = (done) => {
      ElMessageBox.confirm(
        "¿Está Seguro que quiere salir de la creación de usuario?",
        {
          showCancelButton: true,
          confirmButtonText: "Confirmar",
          cancelButtonText: "Cancelar",
        }
      )
        .then(() => {
          done();
        })
        .catch(() => {
          // catch error
        });
    };
    return {
      handleClose,
      hasPermits,
      toCurrency,
    };
  },
  data() {
    return {
      dialogVisible: false,
      dataTable: [],
      loadingCreate: false,
      model: {
        client_id: "",
        contact: "",
        email: "",
        phone: "",
        observations: "",
        quotation_date: "",
        quotation_expiration: "",
        payment_form: "",
        city_id: "",
        event_place: "",
        start_date: "",
        finish_date: "",
      },
      rules: {
        client_id: [
          {
            required: true,
            message: "Por favor selecciona un cliente",
            trigger: "blur",
          },
        ],
        contact: [
          {
            required: true,
            message: "Por favor ingresa un contacto",
            trigger: "blur",
          },
        ],
        phone: [
          {
            required: true,
            message: "Por favor ingresa un número de teléfono",
            trigger: "blur",
          },
        ],
        email: [
          {
            required: true,
            message: "Por favor ingresa un correo",
            trigger: "blur",
          },
          {
            type: "email",
            message: "Por favor ingresa una dirección de correo válido",
            trigger: "change",
          },
        ],
        quotation_date: [
          {
            required: true,
            message: "Por favor ingresa la fecha de cotización",
            trigger: "blur",
          },
          {
            required: true,
            message: "Por favor ingresa la fecha de cotización",
            trigger: "change",
          },
        ],
        quotation_expiration: [
          {
            required: true,
            message: "Por favor ingresa la fecha de expiración",
            trigger: "blur",
          },
          {
            required: true,
            message: "Por favor ingresa la fecha de expiración",
            trigger: "change",
          },
        ],
        payment_form: [
          {
            required: true,
            message: "Por favor ingresa la forma de pago",
            trigger: "blur",
          },
        ],
        event_place: [
          {
            required: true,
            message: "Por favor ingresa el lugar del evento",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    openModal() {
      this.dialogVisible = true;
      this.cleanForm();
    },
    validateForm(formName) {
      let res;
      this.$refs[formName].validate((valid) => {
        res = valid;
      });
      return res;
    },

    async saveQuotation(formName) {
      this.model.city_id = this.$refs.departmentCities.model.city_id;
      let selectCity = this.$refs.departmentCities.validateForm(formName);
      if (!this.validateForm(formName) || !selectCity) {
        ElMessage.error("¡ Error !, Por favor verifica todos los campos");
        return;
      }
      if (this.dataTable.length === 0) {
        ElMessage.error("¡ Error !, Ingresa por lo menos un concepto");
        return;
      }

      let details = [];
      for (let data of this.dataTable) {
        let image;
        await this.convertToBase64(data.image64)
          .then((res) => {
            image = res;
          })
          .catch(() => {
            image = null;
          });
        let aux = {
          tag: data.tag,
          description: data.description,
          amount: data.amount,
          unit_price_client: data.unit_price_client,
          days: data.days,
          unit_price_provider: data.unit_price_provider,
          iva: data.iva,
          supply_id: data.supply.id,
          image64: image,
        };
        details.push(aux);
      }
      this.loadingCreate = true;
      await postData(
        `api/v1/general-quotations`,
        {
          id: this.model.id,
          observations: this.model.observations,
          event_place: this.model.event_place,
          city_id: this.model.city_id,
          phone: this.model.phone,
          email: this.model.email,
          contact: this.model.contact,
          start_date: this.model.start_date,
          finish_date: this.model.finish_date,
          quotation_date: this.model.quotation_date,
          quotation_expiration: this.model.quotation_expiration,
          client_id: this.model.client_id,
          details: details,
        },
        true
      )
        .then((res) => {
          if (res.error == true || res.error == undefined) {
            ElNotification({
              title: "Error",
              message: res.message,
              type: "error",
            });
            this.loadingCreate = false;
            return;
          }
          ElNotification({
            title: "Cotización guardada correctamente",
            message: res.message,
            type: "success",
          });
          this.emitter.emit("reload-list-of-general-quotations");
          this.loadingCreate = false;
          this.dialogVisible = false;
          this.cleanForm();
        })
        .catch(() => {
          ElNotification({
            title: "Error",
            message: "Ocurrió un error al hacer la petición",
            type: "error",
          });
        });
      this.loadingCreate = false;
    },

    async convertToBase64(file) {
      return new Promise((resolve, reject) => {
        if (file == undefined) {
          reject(null);
        }
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },

    searchDetail(detailID) {
      for (let row of this.dataTable) {
        if (row.supply.id === detailID) {
          return true;
        }
      }
      return false;
    },
    deleteRow(index, row) {
      ElMessageBox.confirm(
        "¿Seguro de querer eliminar el concepto?",
        "Advertencia",
        {
          confirmButtonText: "si",
          cancelButtonText: "no",
          type: "warning",
        }
      ).then(() => {
        if (row.tag === "add") {
          this.dataTable.splice(index, 1);
        }
      });
    },

    updateTotal(days, amount, price) {
      let total = 0;
      total = days * price * amount;
      total = parseInt(total);
      return total;
    },

    cleanForm() {
      let form = this.$refs["model"];
      if (form) {
        form.resetFields();
      }
      if (this.$refs.departmentCities) {
        this.$refs.departmentCities.cleanForm("model");
      }
      this.model = {
        client_id: "",
        contact: "",
        email: "",
        phone: "",
        observations: "",
        quotation_date: "",
        quotation_expiration: "",
        payment_form: "",
        city_id: "",
        event_place: "",
        start_date: "",
        finish_date: "",
      };
      this.dataTable = [];
    },
  },
};
</script>
<style scoped>
.el-button--danger {
  background: #bc0304;
  border: none;
}

.add {
  color: rgb(2, 138, 13);
}

.el-button--success {
  background: #003149;
  border: none;
}

.el-button--primary {
  background: var(--blue) !important;
  border: 1px solid var(--blue) !important;
}

.el-button--text {
  color: var(--blue) !important;
  font-weight: bold;
  font-size: 25px;
}
.content {
  margin-top: 30px;
}
.icon-step {
  width: 20px;
}

::v-deep(.el-dialog__body) {
  text-align: center;
}

::v-deep(.el-form--label-top .el-form-item__label) {
  padding: 0;
}

.table-body {
  margin-top: 2%;
  padding: 5px;
}

.add-button {
  margin-top: -1%;
}

.el-button--danger {
  background: #bc0304;
  border: none;
}

.el-button--success {
  background: #003149;
  border: none;
}

.el-button--primary {
  background: var(--blue) !important;
  border: 1px solid var(--blue) !important;
}

.el-button--text {
  color: var(--blue) !important;
  font-weight: bold;
  font-size: 25px;
}

ion-icon {
  color: darkorange;
  margin: 0 10px 0 10px;
  font-size: 20px;
  cursor: pointer;
}

::v-deep(.ionicon) {
  pointer-events: none !important;
}

.amounts {
  text-align: end;
}

.edit-icon {
  color: darkorange;
  margin: 0 10px 0 10px;
  font-size: 20px;
  cursor: pointer;
}

.properties {
  color: darkorange;
  margin: 0 10px 0 10px;
  font-size: 20px;
  cursor: pointer;
  text-align: end;
}

.table {
  border-radius: 5px;
  border: 0px;
  margin-top: 1%;
  margin-bottom: 2%;
  background-color: white;
  width: 100%;
  padding: 2px;
  font-size: 15px;
  text-align: center;
}

thead {
  padding: 20px 20px;
  font-weight: bold;
  font-size: 10px;
}

thead th {
  padding: 10px;
}

tbody {
  font-size: 14px;
}

.table tr:nth-child(even) {
  background-color: #eee;
}

.total-field {
  white-space: nowrap;
}

.usage-field {
  text-align: left;
  white-space: nowrap;
}

.add-icon {
  color: rgb(2, 138, 13);
}

.price_input {
  text-align: center;
  font-size: 16px;
  height: -webkit-fill-available;
  width: -webkit-fill-available;
  border: none;
  outline: none;
  background-color: transparent;
}

.el-icon-delete {
  color: red;
}

.content-table {
  overflow-x: auto;
}

/* Works on Chrome, Edge, and Safari */
::-webkit-scrollbar {
  width: 10px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background-color: rgb(233, 231, 231);

  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: rgb(163, 163, 163);
  border-radius: 10px;
}

.first-th {
  display: flex;
}
.content-delete {
  align-self: center;
  margin-left: 8px;
}
</style>
