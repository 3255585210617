<template>
  <div class="container" width="100%">
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: "ContactList",
};
</script>
<style scoped>
.container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-content: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
}
</style>
