<template>
  <div>
    <!-- modal -->
    <el-dialog
      v-model="dialogVisible"
      title=""
      width="78%"
      :before-close="handleClose"
    >
      <!-- steps -->
      <el-steps :action="step" finish-status="success" simple>
        <el-step title="Editar Comprador" icon="el-icon-edit"></el-step>
        <el-step title="Editar Módulos" icon="el-icon-user"></el-step>
        <el-step title="Editar Documentos" icon="el-icon-upload"></el-step>
      </el-steps>
      <!-- content step 1 -->
      <el-form v-if="step === 0">
        <el-row>
          <el-col :span="24">
            <div class="grid-content bg-purple-dark">
              <el-form
                v-loading="loadingGetInformation"
                ref="model"
                :model="model"
                label-position="top"
              >
                <br /><br />
                <el-row>
                  <UploadLogo :src="model.url_logo" ref="uploadLogo" />
                  <el-col :span="18" :offset="3">
                    <el-form-item
                      class="item-form"
                      label="Nombre Completo"
                      prop="name"
                    >
                      <el-input v-model="model.name"></el-input>
                    </el-form-item>

                    <el-form-item
                      label="Número de identificación"
                      prop="identification"
                    >
                      <el-input
                        v-model="model.identification"
                        :controls="false"
                      ></el-input>
                    </el-form-item>

                    <el-form-item
                      label="Tipo de identificación"
                      prop="identification_type_id"
                    >
                      <SelectIdentification
                        v-model="model.identification_type_id"
                      />
                    </el-form-item>

                    <el-form-item class="item-form" label="Correo" prop="email">
                      <el-input v-model="model.email"></el-input>
                    </el-form-item>

                    <el-form-item
                      class="item-form"
                      label="Dirección"
                      prop="address"
                    >
                      <el-input v-model="model.address"></el-input>
                    </el-form-item>

                    <el-form-item
                      class="item-form"
                      label="Teléfono"
                      prop="phone"
                    >
                      <el-input v-model="model.phone"></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form>

              <div id="boton" justify="end">
                <el-button
                  :loading="loadingUpdate"
                  id="steps-button"
                  @click="updateBuyer('model')"
                  type="success"
                  >Guardar cambios</el-button
                >
                <el-button id="steps-button" @click="editModules" type="success" data-cy="edit-module"
                  >Editar módulos</el-button
                >
                <el-button
                  id="steps-button"
                  @click="editDocuments"
                  type="success"
                  >Editar documentos</el-button
                >
              </div>
            </div>
          </el-col>
        </el-row>
      </el-form>
      <el-form v-if="step === 1">
        <Modules :endpoint="modulesEndpoint" />
        <div id="boton" justify="end" align="center">
          <el-button id="steps-button" @click="editBuyer" type="success" data-cy="edit-module"
            >Editar comprador</el-button
          >
          <el-button id="steps-button" @click="editDocuments" type="success"
            >Editar documentos</el-button
          >
        </div>
      </el-form>
      <el-form v-if="step === 2">
        <EditDocument :endpoint="documentsEndpoint" deletePermit="delete_file_buyer" />
        <div id="boton" justify="end">
          <el-button id="steps-button" @click="editBuyer" type="success"
            >Editar comprador</el-button
          >
          <el-button id="steps-button" @click="editModules" type="success" data-cy="edit-module"
            >Editar módulos</el-button
          >
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import SelectIdentification from "../SelectIdentification.vue";
import { ref } from "vue";
import { ElMessageBox } from "element-plus";
import { putFormData, getData } from "@/request/request.js";
import { ElNotification } from "element-plus";
import UploadLogo from "./UploadLogo.vue";

export default {
  name: "EditBuyer",
  components: { SelectIdentification, UploadLogo },
  beforeCreate() {
    this.$options.components.Modules =
      require("../modules/Modules.vue").default;
    this.$options.components.EditDocument =
      require("../documents/EditDocument.vue").default;
  },
  setup() {
    const dialogVisible = ref(false);

    const handleClose = (done) => {
      ElMessageBox.confirm(
        "¿Estas seguro que deseas salir de la actualización de comprador?",
        {
          showCancelButton: true,
          confirmButtonText: "Confirmar",
          cancelButtonText: "Cancelar",
        }
      )
        .then((res) => {
          if (res == "confirm") {
            done();
            this.cleanForm();
          }
        })
        .catch(() => {}); // this action is call when user click in cancel button
    };
    return {
      dialogVisible,
      handleClose,
    };
  },
  data() {
    return {
      model: {
        email: "",
        name: "",
        identification: "",
        address: "",
        phone: "",
        identification_type_id: "",
        admin: {
          full_name: "",
          email: "",
        },
      },

      documentsEndpoint: "",
      modulesEndpoint: "",
      step: 0,
      idBuyer: 0,
      loadingGetInformation: false,
      loadingUpdate: false,
      rules: {
        email: [
          {
            required: true,
            message: "Por favor ingresa un correo",
            trigger: "blur",
          },
          {
            type: "email",
            message: "Por favor ingresa una dirección de correo válido",
            trigger: "blur",
          },
        ],
        name: [
          {
            required: true,
            message: "Por favor ingresa un nombre",
            trigger: "blur",
          },
        ],
        full_name: [
          {
            required: true,
            message: "Por favor ingresa un nombre",
            trigger: "blur",
          },
        ],
        identification: [
          {
            required: true,
            message: "Por favor ingresa un número de identificación",
            trigger: "blur",
          },
        ],
        address: [
          {
            required: true,
            message: "Por favor ingresa una dirección",
            trigger: "blur",
          },
        ],
        phone: [
          {
            required: true,
            message: "Por favor ingresa un teléfono",
            trigger: "blur",
          },
        ],
        identification_type_id: [
          {
            required: true,
            message: "Por favor ingresa el tipo de identificación",
            trigger: "change",
          },
        ],
      },
    };
  },
  mounted() {
    this.emitter.off("open-modal-edit-buyer");
    this.emitter.on("open-modal-edit-buyer", (id) => {
      this.dialogVisible = true;
      this.idBuyer = id;
      this.beforeOpenForm();
      this.getBuyer();
    });
    this.emitter.off("document-update-success");
    this.emitter.on("document-update-success", () => {
      this.dialogVisible = false;
    });
  },

  methods: {
    validateForm(formName) {
      let res;
      this.$refs[formName].validate((valid) => {
        res = valid;
      });
      return res;
    },
    editBuyer() {
      this.step = 0;
    },
    editModules() {
      this.step = 1;
      this.modulesEndpoint = "api/v1/buyers/modules/" + this.idBuyer;
    },
    editDocuments() {
      this.step = 2;
      this.documentsEndpoint = "api/v1/documents/buyer/" + this.idBuyer;
    },

    async getBuyer() {
      this.loadingGetInformation = true;
      await getData("api/v1/buyers/" + this.idBuyer)
        .then((data) => {
          if (data.error == true) {
            ElNotification({
              title: "Error",
              message: "Ocurrió un error al solicitar el comprador",
              type: "error",
            });
          } else {
            this.model = data.data;

            if (this.model.url_logo) {
              this.model.url_logo = this.model.url_logo.original;
            }
          }
        })
        .catch(() => {
          ElNotification({
            title: "Error",
            message: "Ocurrió un error al hacer la petición",
            type: "error",
          });
        });
      this.loadingGetInformation = false;
    },
    async updateBuyer(formName) {
      if (this.validateForm(formName)) {
        this.loadingUpdate = true;
        let form = new FormData();
        form.append("email", this.model.email);
        form.append("name", this.model.name);
        form.append("identification", this.model.identification);
        form.append("address", this.model.address);
        form.append("phone", this.model.phone);
        form.append(
          "identification_type_id",
          this.model.identification_type_id
        );

        let logo = this.$refs.uploadLogo.getImage();
        if (logo != null) {
          form.append("file", logo);
        }

        await putFormData("api/v1/buyers/edit/" + this.idBuyer, form, true)
          .then((data) => {
            if (data.error == true) {
              ElNotification({
                title: "Error",
                message: data.message,
                type: "error",
              });
            } else {
              ElNotification({
                title: "Comprador Actualizado",
                message: "Comprador actualizado correctamente",
                type: "success",
              });
              this.emitter.emit("reload-list-of-buyers");
            }
          })
          .catch((err) => {
            ElNotification({
              title: "Error",
              message: err,
              type: "error",
            });
          });
        this.loadingUpdate = false;
      } else {
        ElNotification({
          title: "Error",
          message: "Por favor llena todos los campos de formulario",
          type: "error",
        });
      }
    },
    beforeOpenForm() {
      this.step = 0;
      let form = this.$refs["model"];
      if (form) {
        form.resetFields();
      }

      this.model = {
        email: "",
        name: "",
        identification: "",
        address: "",
        phone: "",
        identification_type_id: "",
      };
    },
  },
};
</script>

<style scoped>
html body {
  background: white;
}

.text {
  font-size: 14px;
}

.item {
  padding: 18px 0;
}

.box-card {
  margin: 5px;
}

#steps-button {
  margin: 10px;
}

label {
  margin-left: 50px;
  padding: 50px;
}

h1 {
  color: white;
}

.btn-plus {
  width: 55px;
  height: 55px;
  color: #003149;
  font-weight: bold !important;
  font-size: 32px;
  border-radius: 50%;
  text-align: center;
}

.el-form-item {
  margin-bottom: 15px;
}

::v-deep(.el-dialog__body) {
  text-align: center;
}

::v-deep(.el-form--label-top .el-form-item__label) {
  padding: 0;
}

.el-button--success {
  background: #003149;
  border: none;
}

.el-button--primary {
  background: var(--blue) !important;
  border: 1px solid var(--blue) !important;
}
</style>
