<template>
  <el-upload
    ref="upload"
    class="document-form"
    :auto-upload="false"
    :on-change="add"
    :file-list="fileList"
    :on-remove="remove"
  >
    <template #tip>
      <div class="el-upload__tip">Agrega todos los documentos deseados</div>
    </template>
    <template #trigger>
      <el-tooltip
        class="item"
        effect="light"
        content="Agregar archivo"
        placement="left"
      >
        <i class="el-icon-document-add add-file"></i>
      </el-tooltip>
    </template>

    <el-tooltip
      class="item"
      effect="light"
      content="Guardar Archivos"
      placement="right"
    >
      <i @click="save" class="el-icon-upload upload-files"></i>
    </el-tooltip>
    <FormDataDocument ref="modal" />
  </el-upload>
</template>
<script>
import FormDataDocument from "./FormDataDocument.vue";
import { ElLoading } from "element-plus";
import { putDocument } from "../../request/request.js";
import { ElNotification } from "element-plus";
import { validateDocument } from "../../config/Document.js";

export default {
  components: { FormDataDocument },
  name: "CreateDocument",
  props: {
    endpoint: String,
  },
  data() {
    return {
      fileList: [],
      currentFile: null,
      showFormData: true,
      filesInfo: {},
      loading: null,
      uploadErrorCount: 0,
    };
  },
  mounted() {
    this.emitter.off("add-document-from-form");
    this.emitter.on("add-document-from-form", (model) => {
      this.filesInfo[this.currentFile.uid] = Object.assign({}, model);
    });
    this.emitter.off("remove-document-from-form-add");
    this.emitter.on("remove-document-from-form-add", () => {
      var index = this.fileList.indexOf(this.currentFile);
      if (index > -1) {
        this.fileList.splice(index, 1);
      }
    });
  },
  methods: {
    save() {
      if (this.fileList.length == 0) {
        ElNotification({
          title: "Error",
          message: "Debe de seleccionar al menos un archivo.",
          type: "error",
        });
        return;
      }
      this.loading = ElLoading.service();
      this.uploadErrorCount = 0;
      this.fileList.forEach((file) => {
        let form = new FormData();
        form.append("file", file.raw);
        form.append("description", this.filesInfo[file.uid].description);
        form.append(
          "document_type_id",
          this.filesInfo[file.uid].document_type_id
        );
        putDocument(this.endpoint, form)
          .then((res) => {
            if (res.error) {
              this.uploadErrorCount++;
              ElNotification({
                title: "Error",
                message: res.message,
                type: "warning",
              });
            } else {
              var index = this.fileList.indexOf(file);
              if (index > -1) {
                this.fileList.splice(index, 1);
              }
            }
            this.checkIfUploadFinished();
          }, file)
          .catch(() => {
            this.uploadErrorCount++;
            this.checkIfUploadFinished();
          });
      });
    },
    checkIfUploadFinished() {
      if (this.fileList.length - this.uploadErrorCount === 0) {
        if (this.uploadErrorCount == 0) {
          ElNotification({
            title: "Documentos guardados",
            message: "Todos los documentos fueron guardados correctamente.",
            type: "success",
          });
          this.emitter.emit("close-modal-create-buyer");
          this.emitter.emit("close-modal-create-client");
          this.emitter.emit("save-documents-success");
        } else {
          ElNotification({
            title: "Error",
            message:
              "Algunos documentos no pudieron ser guardados correctamente, " +
              "puede reintentarlo o ponerse en contacto para solucionar su problema.",
            type: "error",
          });
        }
        this.loading.close();
      }
    },
    add(file, fileList) {
      if (validateDocument(file, fileList)) {
        this.currentFile = file;
        this.fileList = fileList;
        this.$refs.modal.openForm(1);
      }
    },
    remove(file, fileList) {
      delete this.filesInfo[file.uid];
      this.fileList = fileList;
    },
  },
};
</script>
<style scoped>
.document-form {
  padding-top: 50px;
}
.add-file {
  transition: 0.5s all;
  height: 60px !important;
  width: 60px !important;
  text-align: center;
  color: #083249;
  border: 3px solid #083249;
  border-radius: 45px;
  padding: 12px;
  margin-right: 20px;
  margin-bottom: 20px;
}
.upload-files {
  transition: 0.5s all;
  height: 60px !important;
  width: 60px !important;
  text-align: center;
  color: #4caf50;
  cursor: pointer;
  border: 3px solid #4caf50;
  border-radius: 45px;
  padding: 12px;
  margin-left: 20px;
  margin-bottom: 20px;
}
.add-file:hover {
  transition: 0.5s all;
  background: #083249;
  color: white;
}
.upload-files:hover {
  transition: 0.5s all;
  background: #4caf50;
  color: white;
}
::v-deep(.el-upload-list) {
  text-align: left;
}
.el-upload__tip {
  margin-top: 15px;
  margin-bottom: 15px;
  font-size: 16px;
}
::v-deep(.el-upload-list__item) {
  font-size: 15px;
}
i {
  font-size: 50px;
}
</style>
