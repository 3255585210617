export const diffDate = function (end, start) {
  var startDate = new Date(start);
  var endDate = new Date(end);

  return (endDate.getTime() - startDate.getTime()) / (1000 * 3600 * 24);
};

export const generateDates = function (start_date, end_date, previous_day = false, next_day = false) {
  if (start_date != undefined && end_date != undefined) {
    let dates = [];
    start_date = new Date(start_date);
    end_date = new Date(end_date);

    if (previous_day) {
      start_date.setDate(start_date.getDate() - 1);
    }

    if (next_day) {
      end_date.setDate(end_date.getDate() + 1);
    }

    let tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds

    while (start_date.getTime() <= end_date.getTime()) {
      dates.push(new Date(start_date - tzoffset).toISOString().slice(0, 10));
      start_date.setDate(start_date.getDate() + 1);
    }

    return dates;
  } else {
    return null;
  }
}

export const showThisData = function (data, defaultData = "N.A") {
  if (data === null || data == undefined) {
    return defaultData;
  }
  return data;
}