<template>
  <div class="items">
    <el-popover
      placement="right-start"
      title="Crear solicitud de cotización"
      :width="300"
      trigger="hover"
      content="Abrir formulario para crear una solicitud de cotización"
    >
      <template #reference>
        <el-button class="btn-plus" type="text" @click="openForm()"
          ><i class="el-icon-plus add-icon"></i
        ></el-button>
      </template>
    </el-popover>

    <el-dialog
      v-model="show"
      title="Crear solicitud de cotización"
      width="80%"
      :before-close="handleClose"
      center
    >
      <span>
        <el-form
          label-position="top"
          ref="model"
          :model="model"
          :rules="rules"
          :loading="loading"
        >
          <el-form-item label="Proveedor" prop="subsidiarie_id">
            <select-provider-by-city
              v-model="model.subsidiarie_id"
              :city_id="event.city_id"
            />
          </el-form-item>

          <select-supply-usage
            event="add-quotation-usage"
            :idEvent="event.id"
          />

          <table class="table">
            <thead>
              <tr>
                <th>Insumo</th>
                <th>Descripción</th>
                <th :key="i" v-for="(date, i) in dates">
                  {{ date.substring(5) }}
                </th>
              </tr>
            </thead>

            <tbody>
              <tr :key="i" v-for="(row, i) in tableData">
                <td align="center">
                  <el-tooltip
                    content="Eliminar"
                    placement="right"
                    effect="light"
                  >
                    <i
                      @click.prevent="deleteRow(i)"
                      class="el-icon-delete"
                    ></i> </el-tooltip
                  >{{ row.supply.name }}
                </td>
                <td>
                  <textarea
                    v-model="row.description"
                    cols="60"
                    rows="5"
                  ></textarea>
                </td>
                <td align="center" :key="j" v-for="(date, j) in dates">
                  {{ row.usages[date] ? row.usages[date] : 0 }}
                </td>
              </tr>
            </tbody>
          </table>

          <br />
          <br />

          <el-form-item label="Observaciones" prop="request_notes">
            <el-input type="textarea" v-model="model.request_notes"></el-input>
          </el-form-item>
        </el-form>
      </span>

      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="addQuotation()">Agregar</el-button>
          <el-button @click="show = false">Cancelar</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { ElMessage, ElMessageBox, ElNotification } from "element-plus";
import { postData } from "../../request/request.js";
import { hasPermits } from "@/config/Auth.js";
import { generateDates } from "@/config/Helper.js";
import SelectProviderByCity from "../providers/SelectProviderByCity.vue";
import SelectSupplyUsage from "./SelectSupplyUsage.vue";

export default {
  components: { SelectProviderByCity, SelectSupplyUsage },
  name: "QuotationCreate",
  props: {
    event: Object,
  },
  setup() {
    return {
      hasPermits,
      generateDates,
    };
  },
  mounted() {
    this.dates = generateDates(
      this.event.start_date,
      this.event.finish_date,
      this.event.consuption_previous_day,
      this.event.consuption_next_day
    );

    this.emitter.off("add-quotation-usage");
    this.emitter.on("add-quotation-usage", (usages) => {
      if (this.existUsage(usages.event_price_id)) {
        ElMessage.error("El consumo ya fue registrado.");
        return;
      }

      this.tableData.push({
        supply: usages.supply,
        description: "",
        event_price_id: usages.event_price_id,
        usages: this.buildUsages(usages.usages),
      });
    });
  },
  data() {
    return {
      loading: false,
      show: false,
      model: {
        subsidiarie_id: "",
        request_notes: "",
      },
      dates: [],
      tableData: [],
      rules: {
        subsidiarie_id: [
          {
            required: true,
            message: "Por favor selecciona un proveedor",
            trigger: "change",
          },
        ],
      },
    };
  },
  methods: {
    existUsage(event_price_id) {
      let exist = false;
      this.tableData.forEach((row) => {
        if (row.event_price_id == event_price_id) {
          exist = true;
          return;
        }
      });

      return exist;
    },
    buildUsages(usages) {
      let resultUsages = {};
      usages.forEach((usage) => {
        resultUsages[usage.date] = usage.amount;
      });

      return resultUsages;
    },
    handleClose(done) {
      ElMessageBox.confirm(
        "¿Estas seguro que deseas salir del formulario de crear solicitud de cotización?",
        {
          showCancelButton: true,
          confirmButtonText: "Confirmar",
          cancelButtonText: "Cancelar",
        }
      )
        .then((res) => {
          if (res == "confirm") {
            this.show = false;
            this.cleanFormAdd();
            done();
          }
        })
        .catch(() => {}); // this action is call when user click in cancel button
    },

    deleteRow(index) {
      ElMessageBox.confirm(
        "¿Seguro de querer eliminar el consumo?",
        "Advertencia",
        {
          confirmButtonText: "si",
          cancelButtonText: "no",
          type: "warning",
        }
      ).then(() => {
        this.tableData.splice(index, 1);
      });
    },

    openForm() {
      this.cleanFormAdd();
      this.show = true;
    },

    validateForm() {
      let res;
      this.$refs["model"].validate((valid) => {
        res = valid;
      });
      return res;
    },
    cleanFormAdd() {
      if (this.$refs.model === undefined) {
        return;
      }

      this.$refs.model.resetFields();

      this.model = {
        subsidiarie_id: "",
        request_notes: "",
      };
      this.tableData = [];
    },
    addQuotation() {
      if (!this.validateForm()) {
        ElMessage.error("Ingrese los datos requeridos.");
        return;
      }

      if (this.tableData.lenght <= 0) {
        ElMessage.error("Debes seleccionar al menos un consumo.");
        return;
      }

      this.loading = true;
      let usages = this.tableData.map(
        ({ event_price_id, supply, description }) => ({
          supply_id: supply.id,
          event_price_id: event_price_id,
          description: description,
        })
      );

      if (usages.length <= 0) {
        ElMessage.error("Debes seleccionar al menos un consumo.");
        return;
      }

      postData(
        `api/v1/quotations`,
        {
          event_id: this.event.id,
          subsidiarie_id: this.model.subsidiarie_id,
          request_notes: this.model.request_notes,
          usages: usages,
        },
        true
      )
        .then((res) => {
          this.loadingSave = false;

          if (res.error == true || res.error == undefined) {
            ElNotification({
              title: "Error",
              message: res.message,
              type: "error",
            });
            return;
          }

          ElNotification({
            title: "Solicitud de cotización creada.",
            message: res.message,
            type: "success",
          });

          this.emitter.emit("reload-list-of-quotations");

          this.show = false;
        })
        .catch(() => {
          ElNotification({
            title: "Error",
            message: "Ocurrió un error al hacer la petición",
            type: "error",
          });
          this.loadingSave = false;
        });
    },
  },
};
</script>
<style scoped>
.table {
  width: -webkit-fill-available;
  font-size: 16px;
}

.table th {
  color: var(--blue);
  padding: 3px;
  border: 1px solid gray;
}

.table td {
  margin: 8px;
  border: 1px solid gray;
}

i {
  cursor: pointer;
  font-size: 18px;
}
.items {
  display: inline;
}

.el-icon-delete,
::v-deep(.el-table__empty-text) {
  color: red !important;
}

.el-icon-delete {
  float: left;
  margin-left: 8px;
}

.add-icon {
  color: rgb(2, 138, 13);
}

.el-button--danger {
  background: #bc0304;
  border: none;
}

textarea {
  box-sizing: border-box;
  height: 44px;
  border: none;
  max-width: 100%;
  min-width: 100%;
  width: 100%;
  resize: none;
}
</style>
