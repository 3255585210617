<template>
  <div>
    <el-popover
      placement="right-start"
      title="Crear pago"
      :width="200"
      trigger="hover"
      content="Abrir formulario para agregar un pago"
    >
      <template #reference>
        <el-button class="btn-plus" type="text" @click="openModal()"
          ><i class="el-icon-plus add-icon"></i
        ></el-button>
      </template>
    </el-popover>

    <el-dialog
      v-model="dialogVisible"
      width="65%"
      :before-close="handleClose"
      title="Crear pago"
    >
      <!-- steps -->
      <el-steps :active="step" simple>
        <el-step title="Pago" icon="el-icon-edit"></el-step>
      </el-steps>
      <el-form v-if="step === 0">
        <el-row>
          <el-col :span="20" :offset="2">
            <div class="grid-content bg-purple-dark">
              <el-form
                ref="model"
                label-position="top"
                :model="model"
                :rules="rules"
              >
                <el-form-item label="Proveedor" prop="provider_id">
                  <SelectProvider v-model="model.provider_id" />
                </el-form-item>

                <el-form-item
                  label="Selecciona la orden de compra"
                  prop="purchase_order_id"
                >
                  <el-select
                    v-model="model.purchase_order_id"
                    placeholder="Orden de compra"
                    no-data-text="No hay ordenes de compra."
                    style="width: 100%"
                  >
                    <el-option
                      v-for="order in purchaseOrders"
                      :key="order.quotation_id"
                      :label="
                        'Orden: ' +
                        order.consecutive +
                        ' - ' +
                        toCurrency(parseInt(order.total))
                      "
                      :value="order.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>

                <el-form-item label="Concepto" prop="concept">
                  <el-input v-model="model.concept"></el-input>
                </el-form-item>
                <el-form-item label="Valor" prop="amount">
                  <el-input type="number" v-model="model.amount"></el-input>
                </el-form-item>

                <el-form-item
                  label="Fecha de vencimiento"
                  prop="expiration_date"
                >
                  <el-date-picker
                    v-model="model.expiration_date"
                    type="date"
                    value-format="YYYY-MM-DD"
                    placeholder="Selecciona una fecha"
                    :disabled-date="checkDates"
                    style="width: 100%"
                  ></el-date-picker>
                </el-form-item>
                <el-form-item label="Observaciones" prop="observations">
                  <el-input
                    type="textarea"
                    v-model="model.observations"
                  ></el-input>
                </el-form-item>
              </el-form>

              <div class="button" align="center">
                <el-button
                  :loading="loadingCreate"
                  type="primary"
                  @click="save('model')"
                  >Crear pago</el-button
                >
              </div>
            </div>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import SelectProvider from "../../providers/SelectProvider.vue";
import { ElMessageBox } from "element-plus";
import { ElMessage } from "element-plus";
import { hasPermits } from "@/config/Auth.js";
import { postData, getData } from "../../../request/request.js";
import { ElNotification } from "element-plus";
import { toCurrency } from "@/config/Money.js";

export default {
  name: "CreateAdvance",
  props: {
    idEvent: Number,
  },
  components: { SelectProvider },
  mounted() {
    this.emitter.off("update:provider_id");
    this.emitter.on("update:provider_id", (providerID) => {
      this.model.provider_id = providerID;
      this.getPurchaseOrdes();
    });
  },
  setup() {
    const handleClose = (done) => {
      ElMessageBox.confirm(
        "¿Está Seguro que quiere salir de la creación del pago?",
        {
          showCancelButton: true,
          confirmButtonText: "Confirmar",
          cancelButtonText: "Cancelar",
        }
      )
        .then(() => {
          done();
        })
        .catch(() => {
          // catch error
        });
    };
    return {
      handleClose,
      hasPermits,
      toCurrency,
    };
  },

  data() {
    return {
      dialogVisible: false,
      loadingCreate: false,
      step: 0,
      id: 0,
      purchaseOrders: [],
      model: {
        provider_id: null,
        event_id: "",
        concept: "",
        amount: 0,
        expiration_date: "",
        observations: "",
        purchase_order_id: "",
      },
      rules: {
        provider_id: [
          {
            required: true,
            message: "Por favor ingresa el proveedor",
            trigger: "blur",
          },
        ],
        concept: [
          {
            required: true,
            message: "Por favor ingresa el concepto",
            trigger: "blur",
          },
        ],
        amount: [
          {
            required: true,
            message: "Por favor ingresa el valor",
            trigger: "blur",
          },
        ],
        expiration_date: [
          {
            required: true,
            message: "Por favor ingresa la fecha de vencimiento",
            trigger: "blur",
          },
        ],
        purchase_order_id: [
          {
            required: true,
            message: "Por favor selecciona la orden de compra",
            trigger: "blur",
          },
          {
            required: true,
            message: "Por favor selecciona la orden de compra",
            trigger: "change",
          },
        ],
      },
    };
  },
  methods: {
    openModal() {
      this.cleanForm();
      this.dialogVisible = true;
    },
    validateForm(formName) {
      let res;
      this.$refs[formName].validate((valid) => {
        res = valid;
      });
      return res;
    },
    save(formName) {
      if (!this.validateForm(formName)) {
        ElMessage.error("¡ Error !, Por favor verifica todos los campos");
        return;
      }
      this.loadingCreate = true;
      let newModel = Object.assign({}, this.model);

      postData("api/v1/events/" + this.idEvent + "/advances", newModel, true)
        .then((res) => {
          this.loadingCreate = false;
          if (res.error == true || res.error == undefined) {
            ElNotification({
              title: "Error",
              message: res.message,
              type: "error",
            });
            return;
          }
          ElNotification({
            title: "Pago guardado",
            message: res.message,
            type: "success",
          });
          this.dialogVisible = false;
          this.emitter.emit("reload-list-of-advances");
        })
        .catch(() => {
          ElNotification({
            title: "Error",
            message: "Ocurrió un error al hacer la petición",
            type: "error",
          });
          this.loadingCreate = false;
        });
    },

    async getPurchaseOrdes() {
      if (this.model.provider_id !== null) {
        await getData(`api/v1/events/${this.idEvent}/purchase-orders`, {
          provider_id: this.model.provider_id,
        })
          .then((res) => {
            if (res.error == true || res.error == undefined) {
              ElNotification({
                title: "Error",
                message: res.message,
                type: "error",
              });
              return;
            }
            this.purchaseOrders = res.data;
          })
          .catch(() => {
            ElNotification({
              title: "Error",
              message: "Ocurrió un error al hacer la petición",
              type: "error",
            });
          });
        return;
      }
      ElMessage({
        showClose: true,
        message: "Selecciona un proveedor",
        type: "error",
      });
    },

    checkDates(date) {
      if (date == null && date == undefined) {
        return true;
      }
      let now = new Date();
      if (date.getTime() >= now.getTime()) {
        return false;
      } else if (
        date.getDate() == now.getDate() &&
        date.getMonth() == now.getMonth() &&
        date.getFullYear() == now.getFullYear()
      ) {
        return false;
      } else {
        return true;
      }
    },
    cleanForm() {
      let form = this.$refs["model"];
      if (form) {
        form.resetFields();
      }
      this.step = 0;
      this.model = {
        provider_id: "",
        event_id: "",
        concept: "",
        amount: 0,
        expiration_date: "",
        observations: "",
        purchase_order_id: "",
      };
    },
  },
};
</script>
<style scoped>
.el-button--danger {
  background: #bc0304;
  border: none;
}

.el-button--success {
  background: #003149;
  border: none;
}

.add-icon {
  color: rgb(2, 138, 13);
}

.el-button--primary {
  background: var(--blue) !important;
  border: 1px solid var(--blue) !important;
}

.el-button--text {
  color: var(--blue) !important;
  font-weight: bold;
  font-size: 25px;
}
</style>
