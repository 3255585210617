<template>
  <div>
    <el-dialog
      v-model="selectCityShow"
      width="50%"
      :before-close="handleClose"
      title="Seleccionar ciudad"
    >
      <el-row>
        <el-col>
          <div class="content">
            <el-row>
              <el-col :span="16" :offset="4">
                <select-departmens-cities ref="departmentCities" />

                <div class="button" align="center">
                  <el-button
                    :loading="loadingCreate"
                    type="primary"
                    @click="addSubsidiarie()"
                    >Agregar sucursal</el-button
                  >
                </div>
              </el-col>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </el-dialog>

    <h3 class="title" align="left">
      Sucursales
      <el-popover
        placement="right-start"
        title="Agregar sucursal"
        :width="200"
        trigger="hover"
        content="Abrir formulario para agregar una sucursal"
      >
        <template #reference>
          <el-button class="btn-plus" type="text" @click="openModal"
            ><i class="el-icon-plus"></i
          ></el-button>
        </template>
      </el-popover>
    </h3>
    <el-table align="center" :data="subsidiaries" :empty-text="emptyMessage">
      <el-table-column align="center" prop="city" label="Ciudad" />
      <el-table-column align="center" prop="departament" label="Departamento" />
      <el-table-column align="center" prop="" label="Acción">
        <template #default="scope">
          <el-tooltip content="Eliminar" placement="right" effect="light">
            <i
              @click.prevent="deleteRow(scope.$index)"
              class="el-icon-delete"
            ></i>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { ElMessageBox } from "element-plus";
import SelectDepartmensCities from "../SelectDepartmensCities.vue";

export default {
  components: { SelectDepartmensCities },
  name: "Subsidiaries",
  data() {
    return {
      selectCityShow: false,
      emptyMessage: " ", // hack for not show message
      subsidiaries: [],
    };
  },
  methods: {
    deleteRow(index) {
      ElMessageBox.confirm("¿Seguro de querer eliminar la sucursal?", "Advertencia", {
        confirmButtonText: "si",
        cancelButtonText: "no",
        type: "warning",
      }).then(() => {
        this.subsidiaries.splice(index, 1);
      });
    },
    openModal() {
      if (this.$refs.departmentCities) {
        this.$refs.departmentCities.cleanForm("model");
      }
      this.selectCityShow = true;
    },
    addSubsidiarie() {
      if (!this.$refs.departmentCities.validateForm("model")) {
        return;
      }

      let data = this.$refs.departmentCities.getInformation();

      this.subsidiaries.push({
        id: data.city_id,
        city: data.city_name,
        departament: data.departament_name,
      });

      this.selectCityShow = false;
    },
    getIds() {
      let ids = new Array();
      this.subsidiaries.forEach(element => {
        ids.push(element.id);
      });

      return ids;
    },
    setInformation(data){
      this.subsidiaries = data;
    },
    validate() {
      if (this.subsidiaries.length > 0) {
        return true;
      }

      this.emptyMessage = "Debes agregar al menos una sucursal";

      return false;
    },
    reset() {
      this.emptyMessage = " "; // hack for not show message
      this.subsidiaries = [];
    },
  },
};
</script>

<style scoped>
i {
  cursor: pointer;
  font-size: 18px;
}

.el-icon-delete,
::v-deep(.el-table__empty-text) {
  color: red !important;
}

.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
