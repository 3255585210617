<template>
  <el-container>
    <el-row>
      <el-col :span="24" align="center">
        <el-upload
          class="upload-demo"
          drag
          :show-file-list="false"
          :on-change="handleAvatarSuccess"
          :auto-upload="false"
          :multiple="false"
        >
          <div v-if="imageUrl">
            <el-tooltip
              content="Eliminar imagen"
              placement="right"
              effect="light"
            >
              <i
                class="fa-solid fa-trash delete-icon"
                style="z-index: 1000"
                v-on:click.stop="handleRemove($event)"
              >
              </i>
            </el-tooltip>

            <img :src="imageUrl" class="avatar" />
          </div>
          <template v-else>
            <i class="el-icon-document-add add-file"></i>
            <div class="el-upload__text">
              Arrastra la imagen o <em>click para subirla</em>
            </div>
          </template>
          <template #tip>
            <div class="el-upload__tip"></div>
          </template>
        </el-upload>
      </el-col>
    </el-row>
  </el-container>
</template>
<script>
import { ElMessage } from "element-plus";
const validImageType = {
  "image/png": true,
  "image/jpeg": true,
  "image/jpg": true,
};
export default {
  name: "UploadSupply",
  data() {
    return {
      file: null,
      loading: false,
      imageUrl: null,
      source: "",
    };
  },
  props: {
    src: String,
    id: Number,
    event: String,
    eventDeleteImage: String,
    supply_id: Number,
  },
  mounted() {
    if (this.src != undefined) {
      this.imageUrl = this.src;
    }
  },
  methods: {
    handleAvatarSuccess(file) {
      if (!file.raw.type || !validImageType[file.raw.type]) {
        ElMessage.error("¡Error! Debes seleccionar una imagen");
        return;
      }
      this.imageUrl = URL.createObjectURL(file.raw);
      this.file = file;
      this.emitter.emit(this.event, { id: this.id, file: file });
    },

    handleRemove() {
      this.imageUrl = "";
      this.emitter.emit(this.eventDeleteImage, this.supply_id);
    },

    validate() {
      return this.file != null;
    },
    getImage() {
      if (this.file == null) {
        return null;
      }

      return this.file.raw;
    },
    setImageUrl(src) {
      this.imageUrl = src;
    },
  },
};
</script>
<style scoped>
.add-file {
  margin: 10%;
  font-size: 64px;
}

.upload-demo {
  text-align: center;
}

.el-row {
  width: 100%;
}

.logo {
  font-size: 30px;
  margin-top: 30px;
  color: rgb(22, 52, 219);
}

.avatar {
  width: 100%;
  height: auto;
}

.delete-icon {
  z-index: 1000;
  position: absolute;
  left: 90%;
  top: 80%;
  color: rgba(252, 22, 10, 0.568);
}
</style>
