<template>
  <el-container>
    <br /><br />
    <el-header>
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/reports' }">
          Reportes
        </el-breadcrumb-item>
        <el-breadcrumb-item> Gráficas </el-breadcrumb-item>
      </el-breadcrumb>
    </el-header>
    <el-main>
      <el-container>
        <el-row class="center animate__animated animate__fadeIn">
          <el-col
            v-for="chart in graphics_charts"
            :key="chart.type_chart"
            :span="7"
            class="column"
          >
            <template v-if="chart.hasPermits">
              <el-card
                class="card"
                shadow="hover"
                @click="this.$router.push({ path: `${chart.route}` })"
              >
                <div class="icon"><i :class="chart.icon"></i></div>
                <br /><br />
                <div>{{ chart.type_chart }}</div>
              </el-card>
            </template>
          </el-col>
        </el-row>
      </el-container>
    </el-main>
  </el-container>
</template>

<script>
import { hasPermits } from "@/config/Auth.js";
import { REPORTS_PATH_PREFIX } from "../../../config/constants.js";
export default {
  name: "GraphicCharts",
  components: {},
  setup() {
    return {
      hasPermits,
      graphics_charts: [
        {
          hasPermits: hasPermits("reports.show_contracts_by_value_graphic"),
          icon: "fa-solid fa-pen-to-square",
          type_chart: "Proyectos",
          route: `/${REPORTS_PATH_PREFIX}/graphics/project-chart`,
        },
        {
          hasPermits: hasPermits(
            "reports.show_clients_by_value_total_contracts_graphic"
          ),
          icon: "fa-solid fa-users-line",
          type_chart: "Clientes",
          route: `/${REPORTS_PATH_PREFIX}/graphics/clients`,
        },
        {
          hasPermits: hasPermits(
            "reports.show_cdps_value_and_event_status_graphic"
          ),
          icon: "fa-solid fa-file-invoice-dollar",
          type_chart: "CDP",
          route: `/${REPORTS_PATH_PREFIX}/graphics/cdps-chart`,
        },
        {
          hasPermits: hasPermits(
            "reports.show_dependencies_value_and_event_status_graphic"
          ),
          icon: "fa-solid fa-timeline",
          type_chart: "Dependencias",
          route: `/${REPORTS_PATH_PREFIX}/graphics/dependencies-chart`,
        },
        {
          hasPermits: hasPermits("reports.show_events_by_status_graphic"),
          icon: "fa-solid fa-list-ul",
          type_chart: "Estados",
          route: `/${REPORTS_PATH_PREFIX}/graphics/status-chart`,
        },
      ],
    };
  },
};
</script>

<style scoped>
.title {
  color: black;
  text-align: left;
}
.card {
  text-align: center;
  font-size: 2vh;
  border-radius: 10px;
  width: 40vh;
  height: 30vh;
  cursor: pointer;
}
i {
  font-size: 12vh;
  color: #083249;
}
.center {
  margin-top: 5%;
  justify-content: center;
}
.column {
  display: flex;
  justify-content: center;
  min-width: 40vh;
}
.el-breadcrumb {
  font-size: 20px;
}
</style>
