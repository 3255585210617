<template>
  <el-tooltip v-if="!option" content="Eliminar" placement="top" effect="light">
    <i @click="remove" class="el-icon-delete delete"></i>
  </el-tooltip>
  <div class="delete-option" v-else @click="remove">
    <i class="el-icon-delete"></i>
    <slot></slot>
  </div>
</template>
<script>
import { deleteRequest } from "../request/request.js";
import { ElMessageBox, ElNotification, ElLoading } from "element-plus";

export default {
  name: "Delete",
  props: {
    endpoint: String,
    event: String,
    option: { type: Boolean, default: false },
  },
  data() {
    return {
      loading: null,
    };
  },
  methods: {
    async remove() {
      ElMessageBox.confirm("¿Seguro de querer eliminar?", "Advertencia", {
        confirmButtonText: "si",
        cancelButtonText: "no",
        type: "warning",
      })
        .then(() => {
          this.loading = ElLoading.service();
          deleteRequest(this.endpoint, {}, true)
            .then((res) => {
              if (res.error) {
                ElNotification.error({
                  title: "Error",
                  message: res.message,
                });
                this.loading.close();
                return;
              }
              this.emitter.emit(this.event);
              ElNotification.success({
                title: "Eliminado",
                message: res.message,
              });
              this.loading.close();
            })
            .catch(() => {
              this.loading.close();
              ElNotification.error({
                title: "Error",
                message: "No se logró hacer la petición",
              });
            });
        })
        .catch(() => {});
    },
  },
};
</script>
<style scoped>
i.delete {
  color: red;
  cursor: pointer;
  font-size: 20px;
  padding-right: 5px;
  padding-top: 2px;
}

.el-button el-button--default .el-button--small {
  background: red;
}

.delete-option {
  width: 100%;
}

.delete-option i {
  color: red;
  margin: 0 10px 0 10px;
  font-size: 20px;
}
</style>
