<template>
  <div class="main" v-loading="loading">
    <el-popover
      placement="right-start"
      title="Agregar tarifa"
      :width="200"
      trigger="hover"
      content="Abrir formulario para agregar una tarifa"
    >
      <template #reference>
        <el-button class="btn-plus" type="text" @click="showAdd"
          ><ion-icon name="add-outline" class="add-icon"></ion-icon
        ></el-button>
      </template>
    </el-popover>

    <el-popover
      placement="right-start"
      title="Guardar cambios"
      :width="250"
      trigger="hover"
      content="Presiona acá si deseas guardar todos los cambios."
    >
      <template #reference>
        <el-button class="btn-save" type="text" @click="savePrices"
          ><ion-icon name="save-outline"></ion-icon
        ></el-button>
      </template>
    </el-popover>

    <ListPrice :city="city" :receibeForTable="obtainedPrices" />
    <AddPrice event="add-price-to-list" :city="city" ref="addPrice" />
    <EditPrice :city="city" ref="editPrice" />
  </div>
</template>

<script>
import { ElNotification } from "element-plus";
import { getData, postData } from "../request/request";
import ListPrice from "../components/Price/ListPrice.vue";
import AddPrice from "../components/Price/AddPrice.vue";
import EditPrice from "../components/Price/EditPrice.vue";
export default {
  name: "Price",
  props: {
    endpoint: String,
    city: Number,
  },
  components: {
    ListPrice,
    AddPrice,
    EditPrice,
  },
  mounted() {
    this.getPrices();
    this.emitter.off("add-price-to-list");
    this.emitter.on("add-price-to-list", (newPrice) => {
      this.addPrice(newPrice);
    });
    this.emitter.off("edit-price");
    this.emitter.on("edit-price", (idPrice) => {
      let price = this.getPrice(idPrice);
      this.emitter.emit("open-modal-edit-price", price);
    });
    this.emitter.off("duplicate-price");
    this.emitter.on("duplicate-price", (idPrice) => {
      const price = this.getPrice(idPrice);
      let copy = Object.assign({}, price);
      copy.city = {}; // when clone the prices not need the city
      copy.supply = price.supply;
      this.$refs.addPrice.openForm(copy);
    });
    this.emitter.off("update-price");
    this.emitter.on("update-price", (updatedPrice) => {
      this.processEdit(updatedPrice);
    });
    this.emitter.off("remove-price");
    this.emitter.on("remove-price", (idPrice) => {
      let price = this.getPrice(idPrice);
      this.deletePrice(price);
    });
    this.emitter.off("approbe-reload-prices");
    this.emitter.on("approbe-reload-prices", () => {
      this.initPrices();
    });
  },

  data() {
    return {
      index: 1,
      add: [],
      edit: [],
      delete: [],
      obtainedPrices: [],
      loading: false,
    };
  },
  methods: {
    showAdd() {
      this.$refs.addPrice.openForm({});
    },
    async getPrices() {
      this.loading = true;
      let payload = {};
      if (this.city != undefined) {
        payload = {
          city_id: this.city,
        };
      }

      await getData(this.endpoint, payload)
        .then((res) => {
          if (res.error == undefined || res.error == null) {
            ElNotification({
              title: "Error",
              message: res.message,
              type: "error",
            });
          }
          this.obtainedPrices = res.data;
          this.addIndex();
          this.loading = false;
        })
        .catch(() => {
          ElNotification({
            title: "Error",
            message: "Ocurrió un error al hacer la petición",
            type: "error",
          });
          this.loading = false;
        });
    },

    addPrice(newPrice) {
      if (newPrice != undefined) {
        newPrice.uid = this.index;
        this.index++;
        this.add.push(newPrice);
        this.obtainedPrices.push(newPrice)
      } else {
        ElNotification({
          title: "Error",
          message: "No hay tarifa para agregar al proyecto",
          type: "error",
        });
      }
    },

    getPrice(idPrice) {
      if (idPrice === undefined || idPrice === null) {
        ElNotification({
          title: "Error",
          message: "No hay tarifa para editar el proyecto",
          type: "error",
        });
      }
      let aPrice;
      this.obtainedPrices.forEach((price) => {
        if (price.uid === idPrice) {
          aPrice = price;
          return;
        }
      });
      return aPrice;
    },

    async savePrices() {
      this.loading = true;
      if (
        this.add.length == 0 &&
        this.edit.length == 0 &&
        this.delete.length == 0
      ) {
        ElNotification({
          title: "Error",
          message: "Agrega por lo menos una tarifa al proyecto para guardar",
          type: "error",
        });
        this.loading = false;
      } else {
        await postData(
          this.endpoint,
          { add: this.add, edit: this.edit, delete: this.delete },
          true
        )
          .then((res) => {
            this.loading = false;
            if (res.error == true || res.error == undefined) {
              ElNotification({
                title: "Error",
                message: res.message,
                type: "error",
              });

              return;
            }
            ElNotification({
              title: "Tarifas guardadas",
              message: res.message,
              type: "success",
            });

            this.initPrices();
            this.emitter.emit("saved-prices");
          })
          .catch(() => {
            ElNotification({
              title: "Error",
              message:
                "Ocurrió un error al hacer la petición para guardar tarifas",
              type: "error",
            });

            this.initPrices();
          });
        this.loading = false;
      }
    },

    processEdit(editPrice) {
      this.obtainedPrices.forEach((price, index) => {
        if (editPrice.uid === price.uid) {
          this.obtainedPrices.splice(index, 1);
          return;
        }
      });

      this.add.forEach((price, index) => {
        if (editPrice.uid === price.uid) {
          this.add.splice(index, 1);
          return;
        }
      });

      if (editPrice.id != null || editPrice.id != undefined) {
        this.edit.push(editPrice);
      }else{
        this.add.push(editPrice);
      }

      this.obtainedPrices.push(editPrice);
    },

    deletePrice(deletePrice) {
      this.obtainedPrices.forEach((price, index) => {
        if (deletePrice.uid === price.uid) {
          this.add.splice(index, 1);
          this.obtainedPrices.splice(index, 1);
          return;
        }
      });
      if (deletePrice.id != null || deletePrice.id != undefined) {
        this.delete.push(deletePrice);
      }
    },

    addIndex() {
      let index = 0;
      this.obtainedPrices.forEach((element) => {
        element.uid = index;
        index++;
      });
      this.index = index;
    },

    initPrices() {
      this.index = 1;
      this.add = [];
      this.edit = [];
      this.delete = [];
      this.obtainedPrices = [];
      this.loading = false;

      this.getPrices();
    },
  },
};
</script>

<style scoped>
.main {
  width: 100%;
  height: 100%;
}

.btn-plus {
  width: 55px;
  height: 55px;
  color: #003149;
  font-weight: bold !important;
  font-size: 32px;
  text-align: center;
}

.btn-save {
  width: 55px;
  height: 55px;
  padding-bottom: 5px;
  height: 50px;
  color: #003149;
  font-weight: bold !important;
  font-size: 32px;
  text-align: center;
}

.add-icon {
  color: rgb(2, 138, 13);
}

.button {
  margin-top: 20px;
}
</style>
