<template>
  <el-container>
    <br /><br />
    <el-header>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/users' }"
          ><h2 class="title">Usuarios</h2></el-breadcrumb-item
        >
      </el-breadcrumb>
    </el-header>
    <el-main>
        <CreateUser v-if="hasPermits('users.create')" />
        <UserList v-if="hasPermits('users.list')" />
    </el-main>
  </el-container>
</template>
<script>
import UserList from "../components/users/UserList.vue";

import { hasPermits } from "@/config/Auth.js";
import CreateUser from "../components/users/CreateUser.vue";

export default {
  setup() {
    return {
      hasPermits,
    };
  },
  name: "User",
  components: {
    CreateUser,
    UserList
  },
};
</script>

<style scoped>
.title {
  color: black;
  text-align: left;
}
</style>
