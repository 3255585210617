<template>
  <div>
    <el-popover
      placement="right-start"
      title="Crear usuario"
      :width="200"
      trigger="hover"
      content="Abrir formulario para agregar un usuario"
    >
      <template #reference>
        <el-button class="btn-plus" type="text" @click="openModal()"
          ><i class="el-icon-plus add"></i
        ></el-button>
      </template>
    </el-popover>

    <el-dialog
      v-model="dialogVisible"
      width="50%"
      :before-close="handleClose"
      title="Crear usuario"
    >
      <el-row>
        <el-col>
          <div class="content">
            <el-row>
              <el-col :span="16" :offset="4">
                <el-form
                  ref="model"
                  label-position="top"
                  :model="model"
                  :rules="rules"
                >
                  <el-form-item label="Nombre completo" prop="full_name">
                    <el-input v-model="model.full_name"></el-input>
                  </el-form-item>

                  <el-form-item label="Correo" prop="email">
                    <el-input v-model="model.email"></el-input>
                  </el-form-item>

                  <el-form-item label="Rol" prop="role_id">
                    <SelectRole v-model="model.role_id" />
                  </el-form-item>
                </el-form>
                <div class="button" align="center">
                  <el-button
                    :loading="loadingCreate"
                    type="primary"
                    @click="saveUser('model')"
                    >Crear usuario</el-button
                  >
                </div>
              </el-col>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import SelectRole from "./SelectRole.vue";
import { defineComponent } from "vue";
import { ElMessageBox } from "element-plus";
import { ElMessage } from "element-plus";
import { hasPermits } from "@/config/Auth.js";
import { ElNotification } from "element-plus";
import { postData } from "../../request/request.js";
export default defineComponent({
  name: "CreateUser",
  components: {
    SelectRole,
  },

  setup() {
    const handleClose = (done) => {
      ElMessageBox.confirm(
        "¿Está Seguro que quiere salir de la creación de usuario?",
        {
          showCancelButton: true,
          confirmButtonText: "Confirmar",
          cancelButtonText: "Cancelar",
        }
      )
        .then(() => {
          done();
        })
        .catch(() => {
          // catch error
        });
    };
    return {
      handleClose,
      hasPermits,
    };
  },
  data() {
    return {
      dialogVisible: false,
      roles: [],
      loadingCreate: false,
      model: {
        full_name: "",
        email: "",
        role_id: "",
      },
      rules: {
        full_name: [
          {
            required: true,
            message: "Por favor ingresa el nombre completo",
            trigger: "blur",
          },
        ],
        email: [
          {
            type: "email",
            message: "Por favor ingresa una dirección de correo válido",
            trigger: "blur",
          },
          {
            required: true,
            message: "Por favor ingresa una dirección de correo",
            trigger: "blur",
          },
        ],
        role_id: [
          {
            required: true,
            message: "Por favor seleccione un rol",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    openModal() {
      this.dialogVisible = true;
      this.cleanForm();
    },
    validateForm(formName) {
      let res;
      this.$refs[formName].validate((valid) => {
        res = valid;
      });
      return res;
    },
    saveUser(formName) {
      this.loadingCreate = true;
      if (this.validateForm(formName)) {
        postData("api/v1/users", this.model, true)
          .then((res) => {
            if (res.error == true || res.error == undefined) {
              ElNotification({
                title: "Error",
                message: res.message,
                type: "error",
              });
            } else {
              ElNotification({
                title: "Usuario registrado",
                message: res.message,
                type: "success",
              });

              this.dialogVisible = false;
              this.emitter.emit("reload-list-of-users");
            }
            this.loadingCreate = false;
          })
          .catch(() => {
            ElNotification({
              title: "Error",
              message: "Ocurrió un error al hacer la petición",
              type: "error",
            });
            this.loadingCreate = false;
          });
      } else {
        ElMessage.error("¡ Error !, Por favor verifica todos los campos");
        this.loadingCreate = false;
      }
    },
    cleanForm() {
      let form = this.$refs["model"];
      if (form) {
        form.resetFields();
      }

      this.model = {
        full_name: "",
        email: "",
      };
    },
  },
});
</script>

<style scoped>
.el-button--danger {
  background: #bc0304;
  border: none;
}

.add {
  color: rgb(2, 138, 13);
}

.el-button--success {
  background: #003149;
  border: none;
}

.el-button--primary {
  background: var(--blue) !important;
  border: 1px solid var(--blue) !important;
}

.el-button--text {
  color: var(--blue) !important;
  font-weight: bold;
  font-size: 25px;
}
.content {
  margin-top: 30px;
}
.icon-step {
  width: 20px;
}

::v-deep(.el-dialog__body) {
  text-align: center;
}

::v-deep(.el-form--label-top .el-form-item__label) {
  padding: 0;
}
</style>
