<template>
  <el-dialog
    v-model="showModal"
    v-loading="loadingInformation"
    width="95%"
    :before-close="handleClose"
    title="Asignar precios cotización"
  >
    <h3>Información solicitud</h3>
    <div v-if="!loadingInformation">
      <p><b>Estado: </b>{{ quotation.status }}</p>
      <p>
        <b>Ciudad: </b>{{ quotation.subsidiarie.city.name }} -
        {{ quotation.subsidiarie.city.departament.name }}
      </p>
      <p><b>Proveedor: </b>{{ quotation.subsidiarie.provider.full_name }}</p>
      <p><b>Observaciones:</b></p>
      <p>{{ quotation.request_notes }}</p>
    </div>
    <br />
    <h3>Precios cotización</h3>
    <table class="table">
      <thead>
        <tr>
          <th :key="i" v-for="(header, i) in headers">
            {{ header.title }}
          </th>
        </tr>
      </thead>

      <tbody>
        <tr :key="i" v-for="(row, i) in tableData">
          <td align="center" :key="j" v-for="(header, j) in headers">
            <template v-if="header.name == 'margin'">
              <input
                class="margin_input"
                @change="changeUnitClientPrice(row)"
                type="number"
                v-model="row.margin"
                :min="0.0"
                :step="0.1"
                :max="99.9"
                :step-strictly="true"
              />
            </template>
            <template v-else-if="header.name == 'unit_provider_price'">
              <input
                class="price_input"
                @change="changeUnitProviderPrice(row)"
                type="number"
                v-model="row.unit_provider_price"
              />
            </template>
            <template
              v-else-if="
                header.name.includes('price') || header.name === 'profit'
              "
            >
              {{ toCurrency(row[header.name]) }}
            </template>
            <template v-else>
              {{
                row[header.name] == undefined ? 0 : row[header.name]
              }}</template
            >
          </td>
        </tr>
      </tbody>
    </table>
    <br /><br />
    <div class="button" align="center">
      <el-button :loading="loadingSave" type="primary" @click="savePrices"
        >Guardar precios</el-button
      >
    </div>
  </el-dialog>
</template>

<script>
import { getData, postData } from "../../request/request.js";
import { toCurrency } from "../../config/Money.js";
import { ElNotification } from "element-plus";
export default {
  name: "QuotationPrices",
  setup() {
    return {
      toCurrency,
    };
  },
  data() {
    return {
      showModal: false,
      loadingInformation: false,
      loadingSave: false,
      headers: [
        {
          name: "supply_name",
          title: "Insumo",
        },
      ],
      quotation: {},
      details: [],
      prices: [],
      tableData: [],
      quotation_id: null,
    };
  },
  methods: {
    openModal(id, start_date, end_date, previous_day, next_day) {
      this.headers = [
        {
          name: "supply_name",
          title: "Insumo",
        },
        {
          name: "description",
          title: "Descripción",
        },
      ];
      this.tableData = [];

      start_date = new Date(start_date);
      end_date = new Date(end_date);

      if (previous_day) {
        start_date.setDate(start_date.getDate() - 1);
      }

      if (next_day) {
        end_date.setDate(end_date.getDate() + 1);
      }

      let tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds

      while (start_date.getTime() <= end_date.getTime()) {
        this.headers.push({
          name: new Date(start_date - tzoffset).toISOString().slice(5, 10),
          title: new Date(start_date - tzoffset).toISOString().slice(5, 10),
        });

        start_date.setDate(start_date.getDate() + 1);
      }

      this.headers.push(
        ...[
          {
            name: "total_amount",
            title: "Cantidad total",
          },
          {
            name: "unit_provider_price",
            title: "Valor unitario proveedor",
          },
          {
            name: "total_provider_price",
            title: "Valor total proveedor",
          },
          {
            name: "unit_client_price",
            title: "Valor unitario cliente",
          },
          {
            name: "total_client_price",
            title: "Valor total cliente",
          },
          {
            name: "profit",
            title: "Ganancia",
          },
          {
            name: "margin",
            title: "Margen (%)",
          },
        ]
      );

      this.quotation_id = id;
      this.loadData(id);
      this.showModal = true;
    },
    loadData(id) {
      this.loadingInformation = true;
      getData(`api/v1/quotations/${id}/all`)
        .then((res) => {
          if (res.error == true || res.error == undefined) {
            ElNotification({
              title: "Error",
              message: res.message,
              type: "error",
            });

            return;
          }

          this.quotation = res.data;
          this.details = res.data.details;
          this.manageInformation();

          this.loadingInformation = false;
        })
        .catch(() => {
          ElNotification({
            title: "Error",
            message: "Ocurrió un error al hacer la petición",
            type: "error",
          });
        });
    },
    manageInformation() {
      this.details.forEach((detail) => {
        let row = [];
        row["total_amount"] = 0;
        row["id"] = detail.id;
        row["description"] = detail.description;
        row["unit_client_price"] = detail.unit_client_price;
        row["unit_provider_price"] = detail.unit_provider_price;
        row["margin"] = detail.margin;
        row["profit"] = detail.profit;

        detail.dates.forEach((date) => {
          row[date.date.substring(5)] = date.amount;
          row["total_amount"] += date.amount;
        });

        row["total_client_price"] =
          row["total_amount"] * row["unit_client_price"];
        row["total_provider_price"] =
          row["total_amount"] * row["unit_provider_price"];
        row.supply_name = detail.supply.name;

        this.tableData.push(row);
      });
    },

    changeUnitProviderPrice(row) {
      row.unit_client_price = this.calculateUnitPriceClient(
        row.unit_provider_price,
        row.margin
      );

      this.refreshUnitProviderPriceRounded(row);

      row.total_provider_price = row.unit_provider_price * row.total_amount;
      row.total_client_price = row.unit_client_price * row.total_amount;
      row.profit =
        row.unit_client_price === 0
          ? 0
          : row.total_client_price - row.total_provider_price;
    },

    refreshUnitProviderPriceRounded(row) {
      if (!row) {
        return;
      }
      const detailFound = this.tableData.find((detail) => detail.id === row.id);
      if (!detailFound) {
        return;
      }
      detailFound.unit_provider_price = Math.round(row.unit_provider_price);
    },

    changeUnitClientPrice(row) {
      row.unit_client_price = this.calculateUnitPriceClient(
        row.unit_provider_price,
        row.margin
      );
      row.total_client_price = row.unit_client_price * row.total_amount;
      row.profit =
        row.unit_client_price === 0
          ? 0
          : row.total_client_price - row.total_provider_price;
    },

    savePrices() {
      this.loadingSave = true;
      let payload = this.tableData.map(
        ({ id, unit_client_price, unit_provider_price, profit, margin }) => ({
          id: id,
          unit_client_price: unit_client_price,
          unit_provider_price: unit_provider_price,
          profit: profit,
          margin: margin,
        })
      );

      postData(`api/v1/quotations/${this.quotation_id}/prices`, payload, true)
        .then((res) => {
          this.loadingSave = false;

          if (res.error == true || res.error == undefined) {
            ElNotification({
              title: "Error",
              message: res.message,
              type: "error",
            });
            return;
          }

          ElNotification({
            title: "Precios guardados",
            message: res.message,
            type: "success",
          });
          this.emitter.emit("reload-list-of-quotations");
          this.emitter.emit("reload-list-of-notes");
          this.showModal = false;
        })
        .catch(() => {
          ElNotification({
            title: "Error",
            message: "Ocurrió un error al hacer la petición",
            type: "error",
          });
          this.loadingSave = false;
        });
    },

    calculateUnitPriceClient(providerUnitPrice, percentage) {
      if (percentage === null || percentage.length === 0) {
        return 0;
      }
      percentage = percentage === 100 ? 99.9 : percentage;
      let margin = percentage / 100;
      margin = parseFloat(margin.toFixed(4));
      let result = providerUnitPrice / (1 - margin);
      return Math.round(result);
    },
  },
};
</script>

<style scoped>
.table {
  width: -webkit-fill-available;
  font-size: 16px;
}

.table th {
  color: var(--blue);
  padding: 3px;
  border: 1px solid gray;
}

.table td {
  margin: 8px;
  border: 1px solid gray;
}

.price_input {
  text-align: center;
  font-size: 16px;
  height: -webkit-fill-available;
  width: -webkit-fill-available;
  border: none;
  outline: none;
}
.margin_input {
  text-align: center;
  font-size: 16px;
  height: -webkit-fill-available;
  width: 85px;
  border: none;
  outline: none;
}

h3 {
  color: var(--blue);
}
</style>
