<template>
  <el-container class="container">
    <el-row>
      <el-col :span="24">
        <el-table :data="prices">
          <el-table-column prop="city.name" label="Ciudad" align="center" />
          <el-table-column prop="supply.name" label="Insumo" align="center" />
          <el-table-column label="Tarifa" align="center">
            <template #default="scope">
              {{ toCurrency(parseInt(scope.row.price)) }}
            </template>
          </el-table-column>
          <el-table-column label="IVA" align="center">
            <template #default="scope">
              {{ scope.row.supply.percent_iva }} %
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
  </el-container>
</template>
<script>
import { toCurrency } from "../../config/Money.js";
export default {
  name: "PriceList",
  setup() {
    return {
      toCurrency,
    };
  },
  props: {
    prices: [],
    size: String,
    onlyRead: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style scoped>

::v-deep(table) {
  background: red;
}
</style>
