<template>
  <div>
    <el-dialog
      v-model="modalVisible"
      title="Información del proyecto"
      width="60%"
    >
      <p>
        <b>Número: </b> {{ contract.number }} <br />
        <b>Nombre: </b> {{ contract.name }}
        <br />
        <b> Objeto del proyecto: </b> {{ contract.goal }} <br />
        <b>Valor: </b> {{ toCurrency(parseInt(contract.price)) }} <br />
        <b>Fecha de inicio: </b> {{ contract.start_date }} <br />
        <b>Fecha de fin: </b> {{ contract.finish_date }} <br />
        <b>Fecha de suscripción: </b> {{ contract.sign_date }} <br />
        <b>Porcentaje de intermediación: </b>
        {{
          contract.intermediation_percentage !== null
            ? contract.intermediation_percentage + " %"
            : "No asignado"
        }}
        <br />
        <b>Observaciones Financieras: </b> {{ contract.finances_observations }}
        <br />
      </p>
      <br />

      <el-collapse accordion>
        <el-collapse-item name="1">
          <template #title>
            <h2>
              <i class="el-icon-user" style="margin-right: 20px"></i>Cliente
            </h2>
          </template>
          <div>
            <p>
              <b>Identificación: </b> {{ contract.client.identification }}
              <br />
              <b>Tipo de identificación: </b>
              {{ contract.client.identification_type.name }}
              <br />
              <b>Nombre completo: </b> {{ contract.client.full_name }} <br />
              <b>Tipo de cliente: </b> {{ contract.client.client_type }} <br />
              <b>Dirección: </b> {{ contract.client.address }} <br />
              <b>Teléfono: </b> {{ contract.client.phone }} <br />
              <b>Ciudad y Departamento: </b> {{ contract.client.city.name }} -
              {{ contract.client.city.departament.name }}<br />
            </p>
            <br />
          </div>
        </el-collapse-item>
        <el-collapse-item name="2">
          <template #title>
            <h2><i class="el-icon-tickets" style="margin-right: 20px"></i>CDPs</h2>
          </template>
          <ManageCdps :endpoint="`api/v1/contracts/${contract.id}/cdps`" :readOnly="true" />
        </el-collapse-item>
        <el-collapse-item name="3">
          <template #title>
            <h2>
              <i class="el-icon-coin" style="margin-right: 20px"></i>Tarifas
            </h2>
          </template>
          <PriceList
            v-if="contract.prices.length !== 0"
            :prices="contract.prices"
          />
          <h3 v-else>No hay registros.</h3>
        </el-collapse-item>
        <el-collapse-item name="4">
          <template #title>
            <h2>
              <i class="el-icon-tickets" style="margin-right: 20px"></i
              >Documentos
            </h2>
          </template>
          <div
            v-if="contract.documents.length !== 0"
            class="container"
            width="100%"
          >
            <DocumentCard
              v-for="document in contract.documents"
              :key="document.id"
              :document="{
                id: document.id,
                type: document.document_type.name,
                description: document.description,
                created_at: document.created_at,
                user_name: document.user_name,
              }"
              size="48"
            />
          </div>
          <h3 v-else>No hay registros.</h3>
        </el-collapse-item>
      </el-collapse>
    </el-dialog>
  </div>
</template>
<script>
import { getData } from "../../request/request.js";
import ManageCdps from "./ManageCdps.vue";
import { ElNotification } from "element-plus";
import { toCurrency } from "../../config/Money.js";

export default {
  name: "ShowClient",
  components: { ManageCdps },
  setup() {
    return {
      toCurrency,
    };
  },
  beforeCreate() {
    this.$options.components.PriceList =
      require("../prices/PriceList.vue").default;
    this.$options.components.DocumentCard =
      require("../documents/DocumentCard.vue").default;
  },
  data() {
    return {
      modalVisible: false,
      contract: {},
    };
  },
  methods: {
    openForm(contractId) {
      getData(`api/v1/contracts/${contractId}/all`)
        .then((res) => {
          if (res.error == true || res.error == undefined) {
            ElNotification({
              title: "Error",
              message: res.message,
              type: "error",
            });
          } else {
            this.contract = res.data;
            this.modalVisible = true;
          }
        })
        .catch(() => {
          ElNotification({
            title: "Error",
            message: "Ocurrió un error al hacer la petición",
            type: "error",
          });
        });
    },
  },
};
</script>
<style scoped>
.container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-content: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
}

h2 {
  color: var(--blue);
}

b {
  color: black;
}

::v-deep(.container .el-row) {
  width: inherit !important;
}
</style>
