<template>
  <el-container>
    <br /><br />
    <el-header>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/supplies' }"
          ><h2 class="title">Insumos</h2></el-breadcrumb-item
        >
      </el-breadcrumb>
    </el-header>
    <el-main>
      <!-- content -->
      <CreateSupply v-if="hasPermits('supplies.create')" />
      <EditSupply v-if="hasPermits('supplies.edit')" />
      <ListSupply v-if="hasPermits('supplies.list')" />
    </el-main>
  </el-container>
</template>

<script>
import { hasPermits } from "@/config/Auth.js";
import ListSupply from "../components/supplies/ListSupply.vue";
import CreateSupply from "../components/supplies/CreateSupply.vue";
import EditSupply from "../components/supplies/EditSupply.vue";

export default {
  setup() {
    return {
      hasPermits,
    };
  },
  name: "Supplies",
  components: {
    ListSupply,
    CreateSupply,
    EditSupply,
  },
};
</script>

<style scoped>
.title {
  color: black;
  text-align: left;
}
</style>
