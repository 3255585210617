<template>
  <div class="box">
    <el-card class="box-card" style="width: 100%; border-radius: 10px">
      <el-table
        v-loading="loading"
        :data="tableData"
        style="border-radius: 10px"
        width="100%"
        :empty-text="'No hay registros'"
      >
        <el-table-column prop="email" label="Correo" />
        <el-table-column prop="name" label="Nombre" />

        <el-table-column prop="identification" label="Identificación" />
        <el-table-column prop="phone" label="Teléfono" />
        <el-table-column prop="address" label="Dirección" />
        <el-table-column
          v-if="hasPermits('buyers.change_status')"
          label="Estado"
          align="center"
        >
          <template #default="scope">
            <Switch
              :initStatus="scope.row.status == 1"
              :endpoint="'api/v1/buyers/change-status/' + scope.row.id"
            />
          </template>
        </el-table-column>
        <el-table-column label="Acciones" align="center">
          <template #default="scope">
            <el-tooltip
              v-if="hasPermits('buyers.show')"
              content="Ver comprador"
              placement="top"
              effect="light"
            >
              <img
                class="icon"
                src="../../assets/ionicons/eye-outline.svg"
                circle
                @click="showBuyer(scope.row.id)"
              />
            </el-tooltip>

            <el-tooltip
              v-if="hasPermits('buyers.edit')"
              content="Editar"
              placement="top"
              effect="light"
            >
              <i
                class="el-icon-edit properties"
                @click="openForm(scope.row.id)"
                :data-cy="`list-item-${scope.row.id}`"
              ></i>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
  </div>
</template>

<script>
import { getData } from "@/request/request.js";
import { ElNotification } from "element-plus";
import Switch from "../Switch.vue";
import { hasPermits } from "@/config/Auth.js";

export default {
  name: "BuyerList",

  setup() {
    return {
      hasPermits,
    };
  },
  components: { Switch },
  beforeCreate() {
    this.$options.components.Switch = require("../Switch.vue").default;
  },
  data() {
    return {
      tableData: [],
      loading: false,
    };
  },
  mounted() {
    this.emitter.off("reload-list-of-buyers");
    this.emitter.on("reload-list-of-buyers", () => {
      this.getBuyers();
    });
    this.getBuyers();
  },
  methods: {
    async getBuyers() {
      this.loading = true;
      await getData("api/v1/buyers")
        .then((data) => {
          if (data.error == true) {
            ElNotification({
              title: "Error",
              message: "Ocurrió un error al solicitar los compradores",
              type: "error",
            });
          } else {
            this.tableData = data.data;
          }
        })
        .catch(() => {
          ElNotification({
            title: "Error",
            message: "Ocurrió un error al hacer la petición",
            type: "error",
          });
        });
      this.loading = false;
    },
    openForm(id) {
      this.emitter.emit("open-modal-edit-buyer", id);
    },
    showBuyer(id) {
      this.emitter.emit("open-modal-show-buyer", id);
    },
  },
};
</script>
<style scoped>
.properties {
  color: darkorange;
  font-size: 25px;
}

.icon {
  width: 25px;
  margin: 0px 10px;
  /* color azul */
  filter: brightness(0) saturate(100%) invert(14%) sepia(29%) saturate(1793%)
    hue-rotate(163deg) brightness(102%) contrast(97%);
}
</style>
