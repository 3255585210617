<template>
  <el-container>
    <el-row>
      <el-col :span="24">
        <br /><br />
        <el-header>
          <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/reports' }">
              Reportes
            </el-breadcrumb-item>

            <el-breadcrumb-item> Presupuesto general </el-breadcrumb-item>
          </el-breadcrumb>
        </el-header>
      </el-col>
    </el-row>

    <el-form
      ref="model"
      class="animate__animated animate__fadeIn"
      :model="model"
      label-position="top"
      :rules="rules"
    >
      <el-row>
        <el-col :span="11">
          <el-form-item label="Selecciona un proyecto" prop="contractID">
            <SelectContract v-model="model.contractID" />
          </el-form-item>
        </el-col>

        <el-col :span="7" :offset="2">
          <el-form-item label="Selecciona los estados en ejecución">
            <SelectStatus multiple v-model="selectedStates" />
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <div class="search-button">
            <el-button type="primary" @click="search()"
              >Obtener presupuesto</el-button
            >
          </div>
        </el-col>
      </el-row>
    </el-form>

    <div class="information" v-if="this.reports != null">
      <el-row>
        <el-col class="centerIcon" :span="1">
          <el-tooltip content="Descargar pdf" placement="top" effect="light">
            <i
              @click="download('pdf', 'model')"
              class="fa-regular fa-file-pdf icon-download-pdf"
            ></i>
          </el-tooltip>
        </el-col>
        <el-col class="centerIcon" :span="1">
          <el-tooltip content="Descargar excel" placement="top" effect="light">
            <i
              @click="download('excel', 'model')"
              class="fa-regular fa-file-excel icon-download-excel"
            ></i> </el-tooltip
        ></el-col>
      </el-row>
      <el-row :gutter="24" class="head-cards">
        <el-col :span="8">
          <el-card shadow="hover">
            <p>VALOR CONTRATO</p>
            <div class="value">{{ toCurrency(priceContract.amount) }}</div>
            &nbsp;
          </el-card>
        </el-col>
        <el-col :span="8">
          <el-card shadow="hover">
            <p>EJECUTADO</p>
            <div class="value">{{ toCurrency(executed.amount) }}</div>
            <div class="percentage-executed">
              {{
                calculatePercetage(priceContract.amount, executed.amount) + "%"
              }}
            </div>
          </el-card>
        </el-col>
        <el-col :span="8">
          <el-card shadow="hover">
            <p>DISPONIBLE</p>
            <div class="value">{{ toCurrency(available.amount) }}</div>
            <div class="percentage-available">
              {{
                calculatePercetage(priceContract.amount, available.amount) + "%"
              }}
            </div>
          </el-card>
        </el-col>
      </el-row>

      <el-table
        :data="reports['cdps']"
        :border="parentBorder"
        row-class-name="cdp-row"
        highlight-current-row="true"
        header-align="center"
        align="center"
        class="table"
        v-if="this.reports != null"
      >
        <el-table-column type="expand">
          <template #default="props">
            <el-table
              :data="props.row.cdp.dependencies"
              header-align="center"
              style="width: 100%"
            >
              <el-table-column
                label="Nombre Dependencia"
                prop="name"
                width="600"
              />
              <el-table-column label="Valor">
                <template #default="props1">
                  {{ toCurrency(parseInt(props1.row.amount)) }}
                </template>
              </el-table-column>
              <el-table-column label="Disponible">
                <template #default="props1">
                  {{ toCurrency(parseInt(props1.row.available)) }}
                </template>
              </el-table-column>
              <el-table-column label="Ejecutado">
                <template #default="props1">
                  {{
                    toCurrency(parseInt(props1.row.executed)) +
                    "  (" +
                    props1.row.executed_percentage +
                    "%)"
                  }}
                </template>
              </el-table-column>
            </el-table>
          </template>
        </el-table-column>

        <el-table-column label="Nombre CDP" prop="cdp.name" width="550" />
        <el-table-column label="Valor">
          <template #default="props">
            {{ toCurrency(parseInt(props.row.cdp.amount)) }}
          </template>
        </el-table-column>
        <el-table-column label="Disponible">
          <template #default="props">
            {{ toCurrency(parseInt(props.row.cdp.available)) }}
          </template>
        </el-table-column>
        <el-table-column label="Ejecutado">
          <template #default="props">
            {{
              toCurrency(parseInt(props.row.cdp.executed)) +
              "  (" +
              props.row.cdp.executed_percentage +
              "%)"
            }}
          </template>
        </el-table-column>
      </el-table>
    </div>
  </el-container>
</template>

<script>
import SelectContract from "../contracts/SelectContract.vue";
import { postData, downloadDataPOST } from "../../request/request";
import { ElLoading, ElMessage, ElNotification } from "element-plus";
import SelectStatus from "../events/Status/SelectStatus.vue";
import { toCurrency } from "@/config/Money.js";

export default {
  name: "GeneralBudget",
  components: { SelectContract, SelectStatus },
  data() {
    return {
      loading: false,
      loadingButton: false,
      selectedStates: null,
      reports: null,
      priceContract: { amount: 0, percentage: 0 },
      executed: { amount: 0, percentage: 0 },
      available: { amount: 0, percentage: 0 },
      model: {
        contractID: "",
        statuses: [],
      },
      rules: {
        contractID: [
          {
            required: true,
            message: "Por favor selecciona un proyecto",
            trigger: "blur",
          },
        ],
      },
    };
  },
  setup() {
    return {
      toCurrency,
    };
  },
  methods: {
    validateForm(formName) {
      let res;
      this.$refs[formName].validate((valid) => {
        res = valid;
      });
      return res;
    },

    async search() {
      if (!this.validateForm("model") || this.selectedStates.length == 0) {
        ElMessage.error("¡ Error !, Por favor verifica todos los campos");
        return;
      }
      this.loading = ElLoading.service();
      await postData(
        `api/v1/reports/${this.model.contractID}/general-budget`,
        { statuses: this.selectedStates },
        true
      )
        .then((res) => {
          if (res.error == true || res.error == undefined) {
            ElNotification({
              title: "Error",
              message: res.message,
              type: "error",
            });
            return;
          }
          this.reports = res.data;
          this.priceContract.amount = this.reports["contract_price"];
          this.executed.amount = this.reports["executed"];
          this.available.amount = this.reports["available"];
          this.loading.close();
        })
        .catch(() => {
          ElNotification({
            title: "Error",
            message: "Ocurrió un error al hacer la petición",
            type: "error",
          });
        });
      this.loading.close();
    },

    calculatePercetage(total = 0, part = 0) {
      let percentage = (part * 100) / total;
      return Math.round(percentage * 1000) / 1000;
    },

    download(type, formName) {
      if (!this.validateForm(formName)) {
        ElMessage.error("¡ Error !, Por favor verifica todos los campos");
        return;
      }
      this.loading = ElLoading.service();
      downloadDataPOST(
        `api/v1/reports/general-budget/${this.model.contractID}/${type}`,
        {
          statuses: this.selectedStates,
        },
        "Reporte presupuesto general." + (type == "excel" ? "xls" : "pdf")
      )
        .then(this.loading.close())
        .catch(() => {
          this.loading.close();
          ElNotification({
            title: "Error",
            message: "Ocurrió un error al hacer la petición",
            type: "error",
          });
        });
    },
  },
};
</script>
<style scoped>
.el-breadcrumb {
  font-size: 20px;
}

.el-container {
  display: block;
  margin: 0 10px;
}

.search-button {
  margin-left: 13px;
  margin-top: 50px;
}

.head-cards {
  margin-top: 3%;
}

.percentage-available {
  display: flex;
  justify-content: end;
  align-content: center;
  font-size: 15px;
  font-weight: 650;
  color: rgb(2, 138, 13);
}

.percentage-executed {
  display: flex;
  justify-content: end;
  align-content: center;
  font-size: 15px;
  font-weight: 650;
  color: rgb(11, 109, 221);
}

.value {
  font-size: 45px;
}

.table {
  margin-top: 2%;
}

::v-deep(.el-card__body) p {
  font-size: 12px;
  font-weight: 900;
  color: var(--blue);
  margin: 5px 0px;
}
::v-deep(.el-card__body) {
  padding: 15px;
}

::v-deep(.cdp-row) {
  background-color: #eaeaea;
  border-bottom: var(--blue);
}

::v-deep(.cdp-row td) {
  background-color: #eaeaea;
  border-bottom: 1px solid var(--blue);
}

.centerIcon {
  display: flex;
  justify-content: left;
  align-items: flex-end;
}

.information i {
  font-size: 25px;
}
</style>
