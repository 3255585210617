export const formatDateAndHour = function (date) {
  if (date == null) {
    return null;
  }

  var newDate = new Date(date);

  if (
    date.length > 10
  ) {
    var day = newDate.getDate();
    var month = newDate.getMonth() + 1;
    var year = newDate.getFullYear();
    var hour = newDate.getHours();
    var minute = newDate.getMinutes();
    if (minute < 10) {
      minute = "0" + minute;
    }

    if (month < 10) {
      month = "0" + month;
    }

    if (day < 10) {
      day = "0" + day;
    }

    var suffix = hour >= 12 ? "PM" : "AM";
    hour = ((hour + 11) % 12) + 1;

    if (hour < 10) {
      hour = "0" + hour;
    }

    return (
      year + "-" + month + "-" + day + "  " + hour + ":" + minute + " " + suffix
    );
  }

  return date;
};
