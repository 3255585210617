<template>
  <div>
    <!-- MODAL -->
    <el-dialog
      v-if="!readOnly"
      v-model="dialogVisible"
      width="50%"
      :before-close="handleClose"
      :title="mode[typeModal][modeModal]"
    >
      <el-form
        label-position="top"
        class="form-document"
        ref="form"
        :rules="rules"
        :model="model"
      >
        <el-form-item prop="name" label="Nombre">
          <el-input
            v-model="model.name"
            placeholder="Ingresa el nombre"
          ></el-input>
        </el-form-item>
        <el-form-item prop="amount" label="Valor">
          <el-input
            v-model="model.amount"
            placeholder="Ingresa el valor"
            type="number"
          ></el-input>
        </el-form-item>
      </el-form>
      <br />
      <div class="button" align="center">
        <el-button type="primary" @click="saveModal">Guardar</el-button>
      </div>
    </el-dialog>

    <!-- TABLE -->
    <el-table
      style="margin-left: 10%; margin-right: 10%; width: 80%"
      :data="list"
      row-class-name="cdp-row"
      highlight-current-row="true"
      default-expand-all="true"
      v-loading="loadindData"
    >
      <el-table-column type="expand">
        <template v-if="!readOnly" #header>
          <el-tooltip
            class="box-item"
            effect="light"
            content="Agregar CDP"
            placement="top"
          >
            <i
              class="fa-solid fa-circle-plus add-icon"
              @click="openModal('cdp', 'add')"
            ></i>
          </el-tooltip>
        </template>
        <template #default="props">
          <!-- Dependecies -->
          <el-table :data="props.row.dependencies" style="width: 100%">
            <el-table-column width="45">
              <template #header>
                <el-tooltip
                  v-if="!readOnly"
                  class="box-item"
                  effect="light"
                  content="Agregar dependencia"
                  placement="top"
                >
                  <i
                    class="fa-solid fa-circle-plus add-icon"
                    @click="openModal('dependency', 'add', props.$index)"
                  ></i>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column label="Dependencia" prop="name" />
            <el-table-column label="Valor">
              <template #default="props">
                {{ toCurrency(parseInt(props.row.amount)) }}
              </template>
            </el-table-column>
            <el-table-column v-if="!readOnly" label="Acciones" align="center">
              <template #default="props1">
                <el-tooltip
                  class="box-item"
                  effect="light"
                  content="Editar"
                  placement="top"
                >
                  <i
                    class="fa-solid fa-pen"
                    @click="openModal('dependency', 'edit', props1.row)"
                  ></i> </el-tooltip
                ><el-tooltip
                  class="box-item"
                  effect="light"
                  content="Eliminar"
                  placement="top"
                >
                  <i
                    class="fa-solid fa-trash"
                    @click.prevent="
                      deleteDependency(props.$index, props1.$index, props1.row)
                    "
                  ></i>
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>
          <!-- end dependencies -->
        </template>
      </el-table-column>
      <el-table-column
        label="CDP"
        prop="name"
        :filter-multiple="true"
        :filter-method="filterCdps"
      />
      <el-table-column label="Valor">
        <template #default="props">
          {{ toCurrency(parseInt(props.row.amount)) }}
        </template>
      </el-table-column>
      <el-table-column v-if="!readOnly" label="Acciones" align="center">
        <template #default="props">
          <el-tooltip
            class="box-item"
            effect="light"
            content="Editar"
            placement="top"
          >
            <i
              class="fa-solid fa-pen"
              @click="openModal('cdp', 'edit', props.row)"
            ></i> </el-tooltip
          ><el-tooltip
            class="box-item"
            effect="light"
            content="Eliminar"
            placement="top"
          >
            <i
              class="fa-solid fa-trash"
              @click.prevent="deleteCdp(props.$index, props.row)"
            ></i>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
    <br />
    <div v-if="!readOnly" class="button" align="center">
      <el-button :loading="loadingSave" type="primary" @click="save"
        >Guardar CDPs</el-button
      >
      <slot></slot>
    </div>
  </div>
</template>
<script>
import { getData, postData } from "../../request/request";
import { toCurrency } from "@/config/Money.js";
import { ElNotification, ElMessageBox } from "element-plus";

const mode = {
  cdp: {
    add: "Agregar CDP",
    edit: "Editar CDP",
  },
  dependency: {
    add: "Agregar dependencia",
    edit: "Editar dependencia",
  },
};

export default {
  name: "ManageCdps",
  props: {
    endpoint: String,
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const handleClose = (done) => {
      ElMessageBox.confirm("¿Está seguro que quiere salir?", {
        showCancelButton: true,
        confirmButtonText: "Confirmar",
        cancelButtonText: "Cancelar",
      })
        .then(() => {
          done();
        })
        .catch(() => {
          // catch error
        });
    };
    return {
      handleClose,
      toCurrency,
      mode,
    };
  },
  data() {
    return {
      list: [],
      listToDelete: [],
      typeModal: "cdp",
      modeModal: "add",
      dialogVisible: false,
      model: {
        name: "",
        amount: 0,
      },
      modelCache: {
        name: "",
        amount: 0,
      },
      loadindData: true,
    };
  },
  mounted() {
    this.getInformation();
  },

  methods: {
    deleteCdp(index, row) {
      ElMessageBox.confirm(
        "¿Seguro de querer eliminar el CDP?",
        "Advertencia",
        {
          confirmButtonText: "si",
          cancelButtonText: "no",
          type: "warning",
        }
      )
        .then(() => {
          if (row.tag !== "add") {
            let indexCdpDelete = this.getIndexCdp(this.listToDelete, row.id);
            if (indexCdpDelete == -1) {
              row.tag = "delete";
              row.dependencies = [];
              this.listToDelete.push(row);
            } else {
              this.listToDelete[indexCdpDelete].tag = "delete";
              this.listToDelete[indexCdpDelete].dependencies = [];
            }
          }

          this.list.splice(index, 1);
        })
        .catch(() => {});
    },
    deleteDependency(indexCdp, indexDependency, row) {
      ElMessageBox.confirm(
        "¿Seguro de querer eliminar la dependencia?",
        "Advertencia",
        {
          confirmButtonText: "si",
          cancelButtonText: "no",
          type: "warning",
        }
      )
        .then(() => {
          if (row.tag !== "add") {
            let indexCdpDelete = this.getIndexCdp(
              this.listToDelete,
              this.list[indexCdp].id
            );

            let cdp;
            if (indexCdpDelete == -1) {
              cdp = Object.assign({}, this.list[indexCdp]);
              cdp.dependencies = [];
              this.listToDelete.push(cdp);
            } else {
              cdp = this.listToDelete[indexCdpDelete];
            }

            row.tag = "delete";
            cdp.dependencies.push(row);
          }

          this.list[indexCdp].dependencies.splice(indexDependency, 1);
        })
        .catch(() => {});
    },
    getIndexCdp(list, id) {
      for (let i = 0; i < list.length; i++) {
        if (list[i].id == id) {
          return i;
        }
      }

      return -1;
    },
    openModal(type, mode, data) {
      this.typeModal = type;
      this.modeModal = mode;

      if (mode == "add") {
        this.model = {
          name: "",
          amount: 0,
        };

        if (data != undefined) {
          this.model.index = data;
        }
      }

      if (mode == "edit") {
        this.model = {
          tag: data.tag,
          name: data.name,
          amount: data.amount,
        };

        this.modelCache = data;
      }

      this.dialogVisible = true;
    },
    getInformation() {
      if (this.endpoint === undefined || this.endpoint === "") {
        return;
      }

      this.list = [];
      this.listToDelete = [];
      this.loadindData = true;

      getData(this.endpoint + "-and-dependencies")
        .then((res) => {
          this.loadindData = false;

          if (
            res == undefined ||
            res.error === undefined ||
            res.error != false
          ) {
            ElNotification({
              title: "Error",
              message: res.message,
              type: "error",
            });

            return;
          }

          this.list = res.data;
        })
        .catch(() => {
          this.loadindData = false;
          ElNotification({
            title: "Error",
            message: "Ocurrió un error al hacer la petición",
            type: "error",
          });
        });
    },
    saveModal() {
      if (this.model.name == "") {
        ElNotification({
          title: "Error",
          message: "Debes ingresar un nombre",
          type: "error",
        });
        return;
      }

      if (this.model.amount < 0) {
        ElNotification({
          title: "Error",
          message: "Debes ingresar un valor válido",
          type: "error",
        });

        return;
      }

      if (this.modeModal == "edit") {
        if (this.model.tag != "add") {
          this.modelCache.tag = "edit";
        }

        this.modelCache.name = this.model.name;
        this.modelCache.amount = this.model.amount;

        this.dialogVisible = false;
        return;
      }

      if (this.modeModal == "add") {
        if (this.typeModal == "cdp") {
          this.model.tag = "add";
          this.model.dependencies = [];
          this.list.push(this.model);
        }

        if (this.typeModal == "dependency") {
          this.list[this.model.index].dependencies.push({
            tag: "add",
            name: this.model.name,
            amount: this.model.amount,
          });
        }

        this.dialogVisible = false;
      }
    },
    save() {
      this.loadindData = true;
      let payload = this.getPayload();

      postData(this.endpoint + "/manage", payload, true)
        .then((res) => {
          if (
            res == undefined ||
            res.error === undefined ||
            res.error != false
          ) {
            ElNotification({
              title: "Error",
              message: res.message,
              type: "error",
            });

            this.loadindData = false;

            return;
          }

          ElNotification({
            title: "Logrado",
            message: res.message,
            type: "success",
          });

          this.getInformation();
        })
        .catch(() => {
          ElNotification({
            title: "Error",
            message: "ocurrió un error al hacer la petición",
            type: "error",
          });
          this.loadindData = false;
        });
    },
    getPayload() {
      let listsfiltered = [];
      this.list.forEach((item) => {
        if (item.tag !== undefined || this.hasChanges(item.dependencies)) {
          let dependenciesfiltered = [];
          item.dependencies.forEach((dependency) => {
            if (dependency.tag !== undefined) {
              dependenciesfiltered.push(dependency);
            }
          });

          item.dependencies = dependenciesfiltered;
          listsfiltered.push(item);
        }
      });

      return {
        cdps: listsfiltered.concat(this.listToDelete),
      };
    },
    hasChanges(list) {
      let hasChanges = false;
      list.forEach((element) => {
        if (element.tag !== undefined) {
          hasChanges = true;
          return;
        }
      });

      return hasChanges;
    },
  },
};
</script>
<style scoped>
.container {
  width: -webkit-fill-available;
  height: fit-content !important;
}

.add-icon {
  cursor: pointer;
  color: green;
  margin-right: 4px;
}

::v-deep(.cdp-row) {
  background-color: #eaeaea;
  border-bottom: var(--blue);
}

::v-deep(.cdp-row td) {
  background-color: #eaeaea;
  border-bottom: 1px solid var(--blue);
}

.el-table {
  --el-table-row-hover-background-color: #eaeaea;
}

i {
  margin: 3px;
  cursor: pointer;
}

.fa-trash {
  color: red;
}

.fa-pen {
  color: var(--blue);
}
</style>
