<template>
  <el-container v-if="hasPermits('reports.list_report_events')">
    <br /><br />
    <el-header>
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/reports' }">
          Reportes
        </el-breadcrumb-item>
        <el-breadcrumb-item> Eventos </el-breadcrumb-item>
      </el-breadcrumb>
    </el-header>
    <el-container class="container">
      <el-row>
        <el-col :span="18">
          <div
            style="margin-top: 15px; margin-bottom: 15px"
            class="animate__animated animate__fadeIn"
          >
            <el-input
              v-model="query"
              :placeholder="placeHolderValues[type]"
              class="input-with-select"
              v-on:keyup.enter="search"
            >
              <template #prepend>
                <el-select
                  v-model="type"
                  placeholder="Select"
                  style="width: 200px"
                >
                  <el-option
                    label="Nombre del proyecto"
                    value="name_project"
                  ></el-option>
                  <el-option label="Id del evento" value="event_id"></el-option>
                  <el-option
                    label="Fecha de inicio"
                    value="start_date"
                  ></el-option>
                  <el-option
                    label="Dependencia"
                    value="dependency_name"
                  ></el-option>
                </el-select>
              </template>
              <template #append>
                <el-button @click="search" icon="el-icon-search"></el-button>
              </template>
            </el-input>
          </div>
        </el-col>
        <el-col class="attachments" :span="3">
          <el-tooltip
            v-if="tableData.length != 0"
            content="Descargar pdf"
            placement="top"
            effect="light"
          >
            <i
              @click="downloadPDF"
              class="fa-solid fa-file-pdf icon-download-pdf"
            ></i>
          </el-tooltip>
          <el-tooltip
            v-if="tableData.length != 0"
            content="Descargar excel"
            placement="top"
            effect="light"
          >
            <i
              @click="downloadExcel"
              class="fa-solid fa-file-excel icon-download-excel"
            ></i>
          </el-tooltip>
        </el-col>
        <el-col class="paginate animate__animated animate__fadeIn" :span="3">
          <el-button
            icon="el-icon-arrow-left"
            @click="prev"
            :disabled="prevEnable"
          >
          </el-button>
          <label style="margin: 0 15px 0 15px"> {{ page }} </label>
          <el-button
            icon="el-icon-arrow-right"
            @click="next"
            :disabled="nextEnable"
          >
          </el-button>
        </el-col>
        <el-col
          :span="24"
          class="animate__animated animate__fadeIn animate__delay-1s"
        >
          <el-table
            v-loading="loading"
            :data="tableData"
            :empty-text="'No hay registros'"
            style="border-radius: 10px"
            stripe
            @sort-change="sort"
          >
            <el-table-column
              sortable
              :sort-method="sortNumber('consecutive')"
              prop="consecutive"
              label="ID"
              min-width="55"
            />
            <el-table-column
              sortable
              :sort-method="sortNumber('application_number')"
              prop="application_number"
              label="No. solicitud"
              width="80"
            />
            <el-table-column
              sortable
              prop="contract_name"
              label="Proyecto"
              min-width="230"
            />
            <el-table-column
              sortable
              prop="name"
              label="Nombre"
              min-width="200"
            />
            <el-table-column
              sortable
              prop="cdp_name"
              label="CDP"
              min-width="80"
            />
            <el-table-column
              sortable
              prop="dependency_name"
              label="Dependencia"
              min-width="110"
            />
            <el-table-column label="Lugar" min-width="135">
              <template #default="scope">
                {{ scope.row.department_name }}
                -
                {{ scope.row.city_name }}
              </template>
            </el-table-column>
            <el-table-column
              sortable
              prop="start_date"
              label="Fecha inicio"
              min-width="90"
            >
              <template #default="scope">
                {{ formatDateAndHour(scope.row.start_date) }}
              </template>
            </el-table-column>
            <el-table-column
              sortable
              prop="finish_date"
              label="Fecha fin"
              min-width="90"
            >
              <template #default="scope">
                {{ formatDateAndHour(scope.row.finish_date) }}
              </template>
            </el-table-column>
            <el-table-column
              sortable
              prop="assistants_number"
              label="Asistentes"
              width="89"
            />
            <el-table-column
              sortable
              prop="status"
              label="Estado"
              width="120"
            />
            <el-table-column
              sortable
              prop="invoice_number"
              label="No. Factura"
              width="75"
            />

            <el-table-column
              sortable
              prop="invoice_value"
              label="Valor factura"
              min-width="100"
            >
              <template #default="scope">
                {{ toCurrency(scope.row.invoice_value) }}
              </template>
            </el-table-column>
            <el-table-column label="Valor" width="160">
              <template #default="scope">
                {{ toCurrency(scope.row.total) }}
              </template>
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>
    </el-container>
  </el-container>
</template>
<script>
import { hasPermits } from "@/config/Auth.js";
import { toCurrency } from "@/config/Money.js";
import { getData, downloadData } from "../../request/request.js";
import { diffDate } from "@/config/Helper.js";
import { formatDateAndHour } from "../../config/Date.js";
import { ElLoading, ElNotification } from "element-plus";
import "animate.css";

export default {
  name: "ListEvents",
  setup() {
    return { hasPermits, diffDate, formatDateAndHour, toCurrency };
  },
  mounted() {
    this.loadData();
  },
  data() {
    return {
      tableData: [],
      loadingFull: null,
      loading: false,
      prevEnable: true,
      nextEnable: false,
      placeHolderValues: {
        "": "Seleciona opción de búsqueda",
        name_project: "Ingresa el nombre del proyecto",
        event_id: "Ingresa el Id del evento",
        start_date: "Ingresa la fecha de inicio, Formato: AAAA-MM-DD",
        dependency_name: "Ingrese el nombre de la dependencia",
      },
      query: "",
      type: "name_project",
      page: 1,
      sortConfig: {},
    };
  },
  methods: {
    loadData() {
      this.loading = true;
      getData("api/v1/reports/events", {
        page: this.page,
        type: this.type,
        query: this.query,
        sort: JSON.stringify(this.sortConfig),
      })
        .then((res) => {
          if (res.error) {
            ElNotification.error({
              title: "Error",
              message: res.message,
            });

            this.tableData = [];
            this.page = 1;
            this.prevEnable = false;
            this.nextEnable = false;
            this.loading = false;

            return;
          }

          this.tableData = res.data;
          this.page = res.current_page;
          this.prevEnable = res.prev_page_url == null;
          this.nextEnable = res.next_page_url == null;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    downloadPDF() {
      if (this.tableData.length <= 0) {
        ElNotification.error({
          title: "Error",
          message: "No hay eventos en el listado",
        });
        return;
      }

      this.loadingFull = ElLoading.service();
      downloadData(
        "api/v1/reports/events/pdf",
        {
          page: this.page,
          type: this.type,
          query: this.query,
        },
        "Reporte eventos.pdf"
      )
        .then(() => {
          this.loadingFull.close();
        })
        .catch(() => {
          ElNotification.error({
            title: "Error",
            message: "Ocurrió un error al descargar el pdf",
          });
        });
    },
    downloadExcel() {
      if (this.tableData.length <= 0) {
        ElNotification.error({
          title: "Error",
          message: "No hay eventos en el listado",
        });
        return;
      }

      this.loadingFull = ElLoading.service();
      downloadData(
        "api/v1/reports/events/excel",
        {
          page: this.page,
          type: this.type,
          query: this.query,
        },
        "Reporte eventos.xls"
      )
        .then(() => {
          this.loadingFull.close();
        })
        .catch(() => {
          ElNotification.error({
            title: "Error",
            message: "Ocurrió un error al descargar el excel",
          });
        });
    },
    search() {
      this.page = 1;
      this.loadData();
    },
    prev() {
      this.page--;
      this.loadData();
    },
    next() {
      this.page++;
      this.loadData();
    },

    async sort(data) {
      if (data.prop === null) {
        this.sortConfig = {};
        await this.loadData();
      }
      this.sortConfig = {
        column: data.prop,
        order: data.order,
      };
      await this.loadData();
    },

    sortText(prop) {
      return function (a, b) {
        return a[prop].localeCompare(b[prop]);
      };
    },
    sortNumber(prop) {
      return function (a, b) {
        return a[prop].localeCompare(b[prop], undefined, { numeric: true });
      };
    },
  },
};
</script>

<style scoped>
.el-breadcrumb {
  font-size: 20px;
}

.container {
  width: -webkit-fill-available;
  height: fit-content !important;
  padding-right: 5px;
}

.el-table {
  border-radius: 10px;
}

.el-icon-view {
  color: var(--blue);
  margin-right: 5px;
  font-size: 19px;
  cursor: pointer;
}

.attachments {
  text-align: center;
  margin: auto;
}

.paginate {
  margin: auto;
  text-align: end;
}

.el-row {
  width: inherit;
  height: fit-content;
}

.el-button {
  background: var(--blue);
  color: white;
}

.is-disabled {
  background: #8080809c;
}

.is-disabled:hover {
  background: #8080809c;
}

::v-deep(.el-table thead) {
  color: var(--blue) !important;
}

.el-select {
  background: var(--blue);
  color: white;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.el-select-dropdown__item.selected {
  color: var(--blue);
}

ion-icon {
  color: darkorange;
  margin: 0 10px 0 10px;
  font-size: 20px;
  cursor: pointer;
}

::v-deep(.ionicon) {
  pointer-events: none !important;
}
.properties {
  color: darkorange;
  margin: 0 10px 0 10px;
  font-size: 20px;
  cursor: pointer;
}

ion-icon ::v-deep(svg) {
  pointer-events: none !important;
}

.attachments i {
  padding: 4px;
  border-radius: 4px;
  font-size: 26px;
  margin: 5px;
  cursor: pointer;
  transition: 0.3s all;
}

.attachments i:hover {
  transition: 0.3s all;
}
</style>
