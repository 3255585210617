<template>
  <div>
    <el-dialog
      v-model="modalVisible"
      title="Información del cliente"
      width="60%"
    >
      <p>
        <b>Identificación: </b> {{ client.identification }} <br />
        <b>Tipo de identificación: </b> {{ client.identification_type.name }}
        <br />
        <b>Nombre completo: </b> {{ client.full_name }} <br />
        <b>Razón social: </b> {{ client.company_name }} <br />
        <b>Tipo de cliente: </b> {{ client.client_type.name }} <br />
        <b>Dirección: </b> {{ client.address }} <br />
        <b>Teléfono: </b> {{ client.phone }} <br />
        <b>Ciudad y Departamento: </b> {{ client.city.name }} -
        {{ client.city.departament.name }}<br />
      </p>
      <br />

      <el-collapse accordion>
        <el-collapse-item name="1">
          <template #title>
            <h2>
              <i class="el-icon-user" style="margin-right: 20px"></i>Contactos
            </h2>
          </template>
          <ContactList v-if="client.contacts.length !== 0">
            <ContactCard
              v-for="contact in client.contacts"
              :key="contact.id"
              :contact="contact"
              size="48"
              :onlyRead="true"
            />
          </ContactList>
          <h3 v-else>No hay registros.</h3>
        </el-collapse-item>
        <el-collapse-item name="2">
          <template #title>
            <h2>
              <i class="el-icon-tickets" style="margin-right: 20px"></i
              >Documentos
            </h2>
          </template>
          <div
            v-if="client.documents.length !== 0"
            class="container"
            width="100%"
          >
            <DocumentCard
              v-for="document in client.documents"
              :key="document.id"
              :document="{
                id: document.id,
                type: document.document_type.name,
                description: document.description,
                created_at: document.created_at,
                user_name: document.user_name,
              }"
              size="48"
            />
          </div>
          <h3 v-else>No hay registros.</h3>
        </el-collapse-item>
      </el-collapse>
    </el-dialog>
  </div>
</template>
<script>
import { getData } from "../../request/request.js";
import { ElNotification } from "element-plus";

export default {
  name: "ShowClient",
  components: {},
  beforeCreate() {
    this.$options.components.ContactList =
      require("../contacts/ContactList.vue").default;
    this.$options.components.ContactCard =
      require("../contacts/ContactCard.vue").default;
    this.$options.components.DocumentCard =
      require("../documents/DocumentCard.vue").default;
  },
  data() {
    return {
      modalVisible: false,
      client: {},
    };
  },
  methods: {
    openForm(clientId) {
      getData(`api/v1/clients/${clientId}/all`)
        .then((res) => {
          if (res.error == true || res.error == undefined) {
            ElNotification({
              title: "Error",
              message: res.message,
              type: "error",
            });
          } else {
            this.client = res.data;
            this.modalVisible = true;
          }
        })
        .catch(() => {
          ElNotification({
            title: "Error",
            message: "Ocurrió un error al hacer la petición",
            type: "error",
          });
        });
    },
  },
};
</script>
<style scoped>
.container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-content: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
}

h2 {
  color: var(--blue);
}

b {
  color: black;
}
</style>
