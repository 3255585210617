<template>
  <el-container class="container">
    <el-row>
      <EditUser ref="editModalComponent" />
      <el-col class="paginate animate__animated animate__fadeIn" :span="24">
        <div style="rmargin-top: 15px; margin-bottom: 15px">
          <el-button
            icon="el-icon-arrow-left"
            @click="prev"
            :disabled="prevEnable"
          >
          </el-button>
          <label style="margin: 0 15px 0 15px"> {{ page }} </label>
          <el-button
            icon="el-icon-arrow-right"
            @click="next"
            :disabled="nextEnable"
          >
          </el-button>
        </div>
      </el-col>
      <el-col
        :span="24"
        class="animate__animated animate__fadeIn animate__delay-1s"
      >
        <el-table
          v-loading="loading"
          :data="tableData"
          :empty-text="'No hay registros'"
          style="border-radius: 10px"
          stripe
        >
          <el-table-column prop="full_name" label="Nombre" />
          <el-table-column prop="email" label="Correo" />
          <el-table-column prop="role_name" label="Rol" />
          <el-table-column
            label="Estado"
            align="center"
            width="80"
            min-width="80"
            max-width="80"
            v-if="hasPermits('users.change_status')"
          >
            <template #default="scope">
              <Switch
                :initStatus="scope.row.status === 1"
                :endpoint="`api/v1/users/${scope.row.id}/change-status`"
              />
            </template>
          </el-table-column>
          <el-table-column label="Acciones" align="center">
            <template #default="scope">
              <el-tooltip
                v-if="hasPermits('users.edit')"
                content="Editar"
                placement="top"
                effect="light"
              >
                <i
                  class="el-icon-edit properties"
                  @click="edit(scope.row.id)"
                ></i>
              </el-tooltip>
              <Delete
                v-if="hasPermits('users.delete')"
                :endpoint="'api/v1/users/' + scope.row.id"
                event="reload-list-of-users"
                :key="scope.row.id"
              />
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
  </el-container>
</template>
<script>
import { getData } from "../../request/request.js";
import { hasPermits } from "@/config/Auth.js";
import EditUser from "./EditUser.vue";
import Switch from "@/components/Switch.vue";
import Delete from "../Delete.vue";
import "animate.css";

export default {
  name: "UserList",
  setup() {
    return {
      hasPermits,
    };
  },
  components: { Delete, EditUser, Switch },
  data() {
    return {
      tableData: [],
      loading: false,
      prevEnable: true,
      nextEnable: false,
      page: 1,
    };
  },
  methods: {
    loadData() {
      this.loading = true;
      getData("api/v1/users", {
        page: this.page,
      })
        .then((res) => {
          this.tableData = res.data;
          this.page = res.current_page;
          this.prevEnable = res.prev_page_url == null;
          this.nextEnable = res.next_page_url == null;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    prev() {
      this.page--;
      this.loadData();
    },
    next() {
      this.page++;
      this.loadData();
    },
    edit(id) {
      this.$refs.editModalComponent.openForm(id);
    },
  },
  mounted() {
    this.emitter.off("reload-list-of-users");
    this.emitter.on("reload-list-of-users", () => {
      this.page = 1;
      this.loadData();
    });

    this.loadData();
  },
};
</script>
<style scoped>
.container {
  width: -webkit-fill-available;
  height: fit-content !important;
}

.el-table {
  border-radius: 10px;
}

.el-icon-view {
  color: var(--blue);
  margin-right: 5px;
  font-size: 19px;
  cursor: pointer;
}

.paginate {
  margin: auto;
  text-align: end;
}

.el-row {
  width: inherit;
}

.el-button {
  background: var(--blue);
  color: white;
}

.is-disabled {
  background: #8080809c;
}

.is-disabled:hover {
  background: #8080809c;
}

::v-deep(.el-table thead) {
  color: var(--blue) !important;
}

.el-select {
  background: var(--blue);
  color: white;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.el-select-dropdown__item.selected {
  color: var(--blue);
}

.properties {
  color: darkorange;
  margin: 0 10px 0 10px;
  font-size: 20px;
  cursor: pointer;
}
</style>
