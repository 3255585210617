<template>
  <el-container class="container">
    <el-row>
      <el-col :span="20" class="animate__animated animate__fadeIn">
        <div style="margin-top: 15px; margin-bottom: 15px">
          <el-input
            v-model="query"
            :placeholder="placeHolderValues[type]"
            class="input-with-select"
            v-on:keyup.enter="search"
          >
            <template #prepend>
              <el-select
                v-model="type"
                placeholder="Select"
                style="width: 150px"
              >
                <el-option label="Nombre" value="name"></el-option>
                <el-option label="Categoría" value="category"></el-option>
              </el-select>
            </template>
            <template #append>
              <el-button @click="search" icon="el-icon-search"></el-button>
            </template>
          </el-input>
        </div>
      </el-col>
      <el-col class="paginate animate__animated animate__fadeIn" :span="4">
        <div style="margin-top: 15px; margin-bottom: 15px">
          <el-button
            icon="el-icon-arrow-left"
            @click="prev"
            :disabled="prevEnable"
          >
          </el-button>
          <label style="margin: 0 15px 0 15px"> {{ page }} </label>
          <el-button
            icon="el-icon-arrow-right"
            @click="next"
            :disabled="nextEnable"
          >
          </el-button>
        </div>
      </el-col>
      <el-col
        :span="24"
        class="animate__animated animate__fadeIn animate__delay-1s"
      >
        <el-table
          v-loading="loading"
          :data="tableData"
          :empty-text="'No hay registros'"
          style="border-radius: 10px"
          stripe
          @sort-change="sort"
        >
          <el-table-column sortable prop="name" label="Nombre" />
          <el-table-column prop="category" label="Categoría" />
          <el-table-column label="IVA">
            <template #default="scope">
              {{ scope.row.percent_iva }} %
            </template>
          </el-table-column>
          <el-table-column label="Acciones" align="center">
            <template #default="scope">
              <el-tooltip
                v-if="hasPermits('supplies.edit')"
                content="Editar"
                placement="top"
                effect="light"
              >
                <i
                  class="el-icon-edit properties"
                  @click="edit(scope.row.id)"
                ></i>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
  </el-container>
</template>
<script>
import { getData } from "../../request/request.js";
import { hasPermits } from "@/config/Auth.js";

export default {
  name: "ListSupply",
  setup() {
    return {
      hasPermits,
    };
  },
  components: {},
  data() {
    return {
      placeHolderValues: {
        "": "Seleciona opción de búsqueda",
        name: "Ingresa el nombre del insumo",
        category: "Ingresa la categoría del insumo",
      },
      query: "",
      type: "name",
      tableData: [],
      loading: false,
      prevEnable: true,
      nextEnable: false,
      page: 1,
      sortConfig: {},
    };
  },
  methods: {
    async loadData() {
      this.loading = true;
      await getData("api/v1/supplies", {
        page: this.page,
        type: this.type,
        query: this.query,
        sort: JSON.stringify(this.sortConfig),
      })
        .then((res) => {
          this.tableData = res.data;
          this.page = res.current_page;
          this.prevEnable = res.prev_page_url == null;
          this.nextEnable = res.next_page_url == null;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    search() {
      this.page = 1;
      this.loadData();
    },
    prev() {
      this.page--;
      this.loadData();
    },
    next() {
      this.page++;
      this.loadData();
    },
    edit(id) {
      this.emitter.emit("open-modal-edit-supply", id);
    },
    async sort(data) {
      if (data.prop === null) {
        this.sortConfig = {};
        await this.loadData();
      }

      this.sortConfig = {
        column: data.prop,
        order: data.order,
      };
      await this.loadData();
    }
  },
  mounted() {
    this.emitter.off("reload-list-of-supplies");
    this.emitter.on("reload-list-of-supplies", () => {
      this.page = 1;
      this.loadData();
    });

    this.loadData();
  },
};
</script>
<style scoped>
.container {
  width: -webkit-fill-available;
  height: fit-content !important;
}

.el-table {
  border-radius: 10px;
}

.el-icon-view {
  color: var(--blue);
  margin-right: 5px;
  font-size: 19px;
  cursor: pointer;
}

.paginate {
  margin: auto;
  text-align: end;
}

.el-row {
  width: inherit;
}

.el-button {
  background: var(--blue);
  color: white;
}

.is-disabled {
  background: #8080809c;
}

.is-disabled:hover {
  background: #8080809c;
}

::v-deep(.el-table thead) {
  color: var(--blue) !important;
}

.el-select {
  background: var(--blue);
  color: white;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.el-select-dropdown__item.selected {
  color: var(--blue);
}

.properties {
  color: darkorange;
  margin: 0 10px 0 10px;
  font-size: 20px;
  cursor: pointer;
}
</style>
