<template>
  <AuthLayout>
    <div class="restore">
      <h2>Restablecer la Contraseña</h2>
      <el-form
        v-on:submit.prevent="restore"
        class="restore-form"
        ref="form"
        :rules="rules"
        :model="model"
      >
        <el-form-item prop="newpassword">
          <el-input
            class="border"
            v-model="model.newpassword"
            placeholder="Nueva contraseña"
            type="password"
            prefix-icon="fas fa-user"
          ></el-input>
        </el-form-item>
        <el-form-item prop="newpassword_confirmation">
          <el-input
            class="border"
            v-model="model.newpassword_confirmation"
            placeholder="Verifique la contraseña"
            type="password"
            prefix-icon="fas fa-user"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            :loading="loading"
            class="restore-button"
            type="primary"
            native-type="submit"
            block
            >Restablecer Contraseña</el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </AuthLayout>
</template>
<script>
import { postData } from "@/request/request.js";
import { ElNotification } from "element-plus";
import AuthLayout from "../../components/AuthLayout.vue";

export default {
  name: "Restore",
  components: {
    AuthLayout,
  },
  data() {
    return {
      model: {
        newpassword: "",
        newpassword_confirmation:''
      },
      loading: false,
      rules: {
        newpassword: [
          {
            required: true,
            message: "La Contraseña es requerida",
            trigger: "blur",
          }
        ],
        newpassword_confirmation: [
          {
            required: true,
            message: "Debe confirmar la contraseña",
            trigger: "blur",
          }
        ],

      },
    };
  },
  methods: {
    async restore() {
      let valid = await this.$refs.form.validate();
      if (valid) {
        let token = this.$route.params.token;
        this.loading = true;
        await postData("api/v1/auth/restorepassword/"+token, this.model)
          .then((data) => {
            if (data.error == true) {
              ElNotification({
                title: "Error",
                message: data.message,
                type: "error",
              });
            } else {
              ElNotification({
                title: "Contraseña Cambiada",
                message: "Ya puede iniciar sesión con su nueva contraseña",
                type: "success",
              });
              this.$router.push({ name: "Login" });
            }
          })
          .catch(() => {
            ElNotification({
              title: "Error",
              message: "Ocurrió un error al hacer la petición",
              type: "error",
            });
          });
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.restore {
  position: relative;
  right: 0;
  width: 41%;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-direction: column;
  text-align: center;
}

.restore-button {
  width: 100%;
  margin-top: 40px;
  background: #bc0404;
}
.restore-form {
  width: 290px;
  color: #bc0404;
}

.border > input:focus {
  outline: 0;
  border-color: white !important;
}
</style>
