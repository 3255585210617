<template>
  <div>
    <el-dialog
      v-model="modalVisible"
      title="Información del Proveedor"
      width="60%"
    >
      <p>
        <b>Identificación: </b> {{ provider.identification }} <br />
        <b>Tipo de identificación: </b> {{ provider.identification_type.name }}
        <br />
        <b>Nombre completo: </b> {{ provider.full_name }} <br />
        <b>Tipo de proveedor: </b>
        {{
          provider.provider_type_id == null
            ? provider.other_provider_type
            : provider.type.name
        }}
        <br />
        <b>Dirección: </b> {{ provider.address }} <br />
        <b>Teléfono: </b> {{ provider.phone }} <br />
        <b>Tipo de pago acordado: </b> {{ provider.payment_type }} <br />
        <b>Número de cuenta: </b> {{ provider.account_number }} <br />
        <b>Banco: </b> {{ provider.bank }} <br />
        <b>Tipo de cuenta: </b> {{ provider.account_type }} <br />
        <b>Nombre del beneficiario: </b> {{ provider.beneficiary_name }} <br />
        <b>Identificación del beneficiario: </b>
        {{ provider.account_identification }} <br />
        <b>Retención: </b> {{ provider.retention }}%<br />
        <b>Ciudades:</b><br />
        <span :key="i" v-for="(city, i) in provider.cities">
          {{ city.name }} - {{ city.departament.name }}<br />
        </span>
      </p>
      <br />

      <el-collapse accordion>
        <el-collapse-item name="1">
          <template #title>
            <h2>
              <i class="el-icon-user" style="margin-right: 20px"></i>Contactos
            </h2>
          </template>
          <ContactList v-if="provider.contacts.length !== 0">
            <ContactCard
              v-for="contact in provider.contacts"
              :key="contact.id"
              :contact="contact"
              size="48"
              :onlyRead="true"
            />
          </ContactList>
          <h3 v-else>No hay registros.</h3>
        </el-collapse-item>
        <el-collapse-item name="2">
          <template #title>
            <h2>
              <i class="el-icon-tickets" style="margin-right: 20px"></i
              >Documentos
            </h2>
          </template>
          <div
            v-if="provider.documents.length !== 0"
            class="container"
            width="100%"
          >
            <DocumentCard
              v-for="document in provider.documents"
              :key="document.id"
              :document="{
                id: document.id,
                type: document.document_type.name,
                description: document.description,
                created_at: document.created_at,
                user_name: document.user_name,
              }"
              size="48"
            />
          </div>
          <h3 v-else>No hay registros.</h3>
        </el-collapse-item>
      </el-collapse>
    </el-dialog>
  </div>
</template>
<script>
import { getData } from "../../request/request.js";
import { ElNotification } from "element-plus";

export default {
  name: "ShowProvider",
  components: {},
  beforeCreate() {
    this.$options.components.ContactList =
      require("../contacts/ContactList.vue").default;
    this.$options.components.ContactCard =
      require("../contacts/ContactCard.vue").default;
    this.$options.components.DocumentCard =
      require("../documents/DocumentCard.vue").default;
  },
  data() {
    return {
      modalVisible: false,
      provider: {},
    };
  },
  methods: {
    openForm(providerId) {
      getData(`api/v1/providers/${providerId}/all`)
        .then((res) => {
          if (res.error == true || res.error == undefined) {
            ElNotification({
              title: "Error",
              message: res.message,
              type: "error",
            });
          } else {
            this.provider = res.data;
            this.modalVisible = true;
          }
        })
        .catch(() => {
          ElNotification({
            title: "Error",
            message: "Ocurrió un error al hacer la petición",
            type: "error",
          });
        });
    },
  },
};
</script>
<style scoped>
.container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-content: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
}

h2 {
  color: var(--blue);
}

b {
  color: black;
}
</style>
