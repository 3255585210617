<template>
  <el-container class="content">
    <el-row>
      <el-col :span="24" class="animate__animated animate__fadeInRight">
        <el-table
          v-loading="loading"
          :data="tableData"
          :empty-text="'No hay registros'"
          style="border-radius: 10px; display: contents"
          stripe
        >
          <el-table-column
            v-if="city == undefined"
            prop="city.name"
            label="Ciudad"
            width="180"
          />
          <!-- </template> -->
          <el-table-column prop="supply.name" label="Insumo" width="180" />
          <el-table-column label="Tarifa" width="180">
            <template #default="scope">
              {{ toCurrency(parseInt(scope.row.price)) }}
            </template>
          </el-table-column>
          <el-table-column label="Acciones" align="center" width="180">
            <template #default="scope">
              <el-tooltip v-if="city == undefined" content="Copiar" placement="top" effect="light">
                <ion-icon
                  class="icons"
                  name="copy-outline"
                  @click="duplicate(scope.row.uid)"
                ></ion-icon>
              </el-tooltip>
              <el-tooltip
                v-if="hasPermits('prices.edit')"
                content="Editar"
                placement="top"
                effect="light"
              >
                <i
                  class="el-icon-edit properties"
                  @click="edit(scope.row.uid)"
                ></i>
              </el-tooltip>
              <el-tooltip
                v-if="hasPermits('prices.delete')"
                content="Eliminar"
                placement="top"
                effect="light"
              >
                <el-popconfirm
                  confirm-button-text="si"
                  cancel-button-text="no"
                  icon="el-icon-warning"
                  icon-color="red"
                  title="¿Seguro de querer eliminar la tarifa?"
                  @confirm="remove(scope.row.uid)"
                >
                  <template #reference>
                    <i class="el-icon-delete"></i>
                  </template>
                </el-popconfirm>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
  </el-container>
</template>

<script>
import { hasPermits } from "@/config/Auth.js";
import { getData } from "../../request/request.js";
import { ElNotification } from "element-plus";
import { toCurrency } from "@/config/Money.js";
export default {
  name: "ListPrice",
  props: {
    receibeForTable: Array,
    city: Number,
  },
  updated() {
    this.tableData = this.receibeForTable;
  },
  setup() {
    return {
      hasPermits,
      toCurrency,
    };
  },
  data() {
    return {
      loading: false,
      tableData: [],
    };
  },
  methods: {
    edit(idPrice) {
      this.emitter.emit("edit-price", idPrice);
    },

    duplicate(idPrice) {
      this.emitter.emit("duplicate-price", idPrice);
    },

    remove(idPrice) {
      this.emitter.emit("remove-price", idPrice);
    },
  },
};
</script>

<style scoped>
.content {
  width: -webkit-fill-available;
  height: fit-content !important;
  display: block !important;
}

::v-deep(.el-table thead) {
  color: var(--blue) !important;
}

.icons{
  font-size: 20px;
  cursor: pointer;
}

.properties {
  color: darkorange;
  margin: 0 10px 0 10px;
  font-size: 20px;
  cursor: pointer;
}
.el-icon-delete {
  color: red;
  position: absolute;
  cursor: pointer;
  right: 50px;
  font-size: 20px;
}
</style>
