<template>
  <el-container>
    <br /><br />
    <el-header>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/reports' }"
          ><h2 class="title">Reportes</h2></el-breadcrumb-item
        >
      </el-breadcrumb>
    </el-header>
    <el-main>
      <el-container>
        <el-row class="center animate__animated animate__fadeIn">
          <el-col
            v-for="report in reports"
            :key="report.type_report"
            :span="7"
            class="column"
          >
            <template v-if="report.hasPermits">
              <el-card
                class="card"
                shadow="hover"
                @click="this.$router.push({ path: `${report.route}` })"
              >
                <div class="icon"><i :class="report.icon"></i></div>
                <br /><br />
                <div>{{ report.type_report }}</div>
              </el-card>
            </template>
          </el-col>
        </el-row>
      </el-container>
    </el-main>
  </el-container>
</template>

<script>
import { hasPermits } from "@/config/Auth.js";
import { REPORTS_PATH_PREFIX } from "../config/constants.js";
export default {
  setup() {
    return {
      hasPermits,
      reports: [
        {
          hasPermits: hasPermits("reports.list_general_budget_report"),
          icon: "fa-solid fa-chart-simple",
          type_report: "Reporte presupuestal general",
          route: `/${REPORTS_PATH_PREFIX}/general-budget`,
        },
        {
          hasPermits: hasPermits("reports.list_report_events"),
          icon: "fa-solid fa-bullhorn",
          type_report: "Reporte de eventos",
          route: `/${REPORTS_PATH_PREFIX}/events`,
        },
        {
          hasPermits: hasPermits("reports.list_report_asset_availability"),
          icon: "fa-solid fa-sack-dollar",
          type_report: "Reporte disponibilidad de recursos",
          route: `/${REPORTS_PATH_PREFIX}/AssetAvailability`,
        },
        {
          hasPermits: hasPermits("reports.list_status_report"),
          icon: "fa-solid fa-check-to-slot",
          type_report: "Reporte de estados",
          route: `/${REPORTS_PATH_PREFIX}/states`,
        },
        {
          hasPermits: hasPermits("reports.list_report_by_advance"),
          icon: "fa-solid fa-file-invoice-dollar",
          type_report: "Reporte de pagos",
          route: `/${REPORTS_PATH_PREFIX}/advances`
        },
        {
          hasPermits: hasPermits("reports.list_graphic"),
          icon: "fa-solid fa-chart-column",
          type_report: "Gráficas",
          route: `/${REPORTS_PATH_PREFIX}/graphics`,
        },
      ],
    };
  },
  name: "Reports",
};
</script>

<style scoped>
.title {
  color: black;
  text-align: left;
}
.card {
  text-align: center;
  font-size: 2vh;
  border-radius: 10px;
  width: 40vh;
  height: 30vh;
  cursor: pointer;
}
i {
  font-size: 12vh;
  color: #083249;
}
.center {
  margin-top: 5%;
  justify-content: center;
}
.column {
  display: flex;
  justify-content: center;
  min-width: 40vh;
}
</style>
