<template>
  <el-container class="container">
    <ShowContract ref="showModalComponent" />
    <el-row>
      <el-col :span="20" class="animate__animated animate__fadeIn">
        <div style="margin-top: 15px; margin-bottom: 15px">
          <el-input
            v-model="query"
            placeholder="Escriba la búsqueda"
            class="input-with-select"
            v-on:keyup.enter="search"
          >
            <template #prepend>
              <el-select
                v-model="type"
                placeholder="Select"
                style="width: 180px"
              >
                <el-option
                  label="Nombre proyecto"
                  value="name"
                  selected
                ></el-option>
                <el-option
                  label="Nombre cliente"
                  value="client_name"
                ></el-option>
                <el-option
                  label="Identificación cliente"
                  value="client_identification"
                ></el-option>
                <el-option label="Tipo cliente" value="client_type"></el-option>
              </el-select>
            </template>
            <template #append>
              <el-button @click="search" icon="el-icon-search"></el-button>
            </template>
          </el-input>
        </div>
      </el-col>
      <el-col class="paginate animate__animated animate__fadeIn" :span="4">
        <el-button
          icon="el-icon-arrow-left"
          @click="prev"
          :disabled="prevEnable"
        >
        </el-button>
        <label style="margin: 0 15px 0 15px"> {{ page }} </label>
        <el-button
          icon="el-icon-arrow-right"
          @click="next"
          :disabled="nextEnable"
        >
        </el-button>
      </el-col>

      <el-col
        :span="24"
        class="animate__animated animate__fadeIn animate__delay-1s"
      >
        <el-table
          v-loading="loading"
          :data="tableData"
          :empty-text="'No hay registros'"
          style="border-radius: 10px"
          stripe
          border
        >
          <el-table-column prop="number" label="Número" width="135" />
          <el-table-column prop="name" label="Nombre" />
          <el-table-column label="Cliente" min-width="200">
            <template #default="scope">
              <b>{{ scope.row.identification }}</b
              ><br />
              {{ scope.row.full_name }}<br />
              {{ scope.row.client_type }}<br />
              {{ scope.row.client_status == 1 ? "Activo" : "Inactivo" }}<br />
            </template>
          </el-table-column>
          <el-table-column prop="start_date" label="Inicio" width="150" />
          <el-table-column prop="sign_date" label="Suscripción" width="150" />
          <el-table-column prop="finish_date" label="Fin" width="150" />
          <el-table-column label="Valor" width="135">
            <template #default="scope">
              {{ toCurrency(parseInt(scope.row.price)) }}
            </template>
          </el-table-column>
          <el-table-column
            label="Estado"
            align="center"
            width="80"
            min-width="80"
            max-width="80"
            v-if="hasPermits('contracts.change_status')"
          >
            <template #default="scope">
              <Switch
                :initStatus="scope.row.status === 1"
                :endpoint="`api/v1/contracts/${scope.row.id}/change-status`"
              />
            </template>
          </el-table-column>
          <el-table-column label="Acciones" align="center" width="160">
            <template #default="scope">
              <el-tooltip
                v-if="hasPermits('contracts.show')"
                content="Mostrar proyecto"
                placement="left"
                effect="light"
              >
                <i @click="showContract(scope.row.id)" class="el-icon-view"></i>
              </el-tooltip>
              <el-tooltip
                v-if="hasPermits('contracts.edit')"
                content="Editar"
                placement="top"
                effect="light"
              >
                <i
                  class="el-icon-edit properties"
                  @click="edit(scope.row.id)"
                ></i>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
  </el-container>
</template>
<script>
import { getData } from "../../request/request.js";
import { hasPermits } from "@/config/Auth.js";
import ShowContract from "./ShowContract.vue";
import Switch from "@/components/Switch.vue";
import { toCurrency } from "@/config/Money.js";
import "animate.css";

export default {
  name: "ClientList",
  setup() {
    return {
      hasPermits,
      toCurrency,
    };
  },
  components: { ShowContract, Switch },
  data() {
    return {
      tableData: [],
      loading: false,
      prevEnable: true,
      nextEnable: false,
      query: "",
      type: "name",
      page: 1,
    };
  },
  methods: {
    loadData() {
      this.loading = true;
      getData("api/v1/contracts", {
        page: this.page,
        type: this.type,
        query: this.query,
      })
        .then((res) => {
          this.tableData = res.data;
          this.page = res.current_page;
          this.prevEnable = res.prev_page_url == null;
          this.nextEnable = res.next_page_url == null;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    search() {
      this.page = 1;
      this.loadData();
    },
    prev() {
      this.page--;
      this.loadData();
    },
    next() {
      this.page++;
      this.loadData();
    },
    edit(id) {
      this.emitter.emit("open-modal-edit-contract", id);
    },
    showContract(id) {
      this.$refs.showModalComponent.openForm(id);
    },
  },
  mounted() {
    this.emitter.off("reload-list-of-contracts");
    this.emitter.on("reload-list-of-contracts", () => {
      this.page = 1;
      this.loadData();
    });

    this.loadData();
  },
};
</script>
<style scoped>
.container {
  width: -webkit-fill-available;
  height: fit-content !important;
}

.el-table {
  border-radius: 10px;
}

.el-icon-view {
  color: var(--blue);
  margin-right: 5px;
  font-size: 19px;
  cursor: pointer;
}

.paginate {
  margin: auto;
  text-align: end;
}

.el-row {
  width: inherit;
}

.el-button {
  background: var(--blue);
  color: white;
}

.is-disabled {
  background: #8080809c;
}

.is-disabled:hover {
  background: #8080809c;
}

::v-deep(.el-table thead) {
  color: var(--blue) !important;
}

.el-select {
  background: var(--blue);
  color: white;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.el-select-dropdown__item.selected {
  color: var(--blue);
}
.properties {
  color: darkorange;
  margin: 0 10px 0 10px;
  font-size: 20px;
  cursor: pointer;
}
</style>
