<template>
  <AuthLayout>
    <div class="forgot">
      <h2>¿Olvido su contraseña?</h2>
      <el-form
        v-on:submit.prevent="forgot"
        class="forgot-form"
        ref="form"
        :rules="rules"
        :model="model"
      >
        <el-form-item prop="email">
          <el-input
            class="border"
            v-model="model.email"
            placeholder="Correo"
            prefix-icon="fas fa-user"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            :loading="loading"
            class="forgot-button"
            type="primary"
            native-type="submit"
            block
            >Restablecer Contraseña</el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </AuthLayout>
</template>
<script>
import { postData } from "@/request/request.js";
import { ElNotification } from "element-plus";
import AuthLayout from "../../components/AuthLayout.vue";

export default {
  name: "Forgot",
  components: {
    AuthLayout,
  },
  data() {
    return {
      model: {
        email: "",
      },
      loading: false,
      rules: {
        email: [
          {
            email: true,
            message: "Se debe ingresar un correo válido",
            trigger: "blur",
          },
          {
            required: true,
            message: "El correo es requerido",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    async forgot() {
      let valid = await this.$refs.form.validate();
      if (valid) {
        this.loading = true;
        await postData("api/v1/auth/forgotpassword", this.model)
          .then((data) => {
            if (data.error == true) {
              ElNotification({
                title: "Error",
                message: data.message,
                type: "error",
              });
            } else {
              ElNotification({
                title: "Correo electrónico enviado",
                message: "Dirijase a la bandeja de entrada de su correo para restablecer la contraseña",
                type: "success",
              });
              this.$router.push({ name: "Login" });
            }
          })
          .catch(() => {
            ElNotification({
              title: "Error",
              message: "Ocurrió un error al hacer la petición",
              type: "error",
            });
          });
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.forgot {
  position: relative;
  right: 0;
  width: 41%;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-direction: column;
  text-align: center;
}

.forgot-button {
  width: 100%;
  margin-top: 40px;
  background: #bc0404;
}
.forgot-form {
  width: 290px;
  color: #bc0404;
}

.border > input:focus {
  outline: 0;
  border-color: white !important;
}
</style>
