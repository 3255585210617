<template>
  <div class="main" v-loading="loadingGetContacts">
    <el-popover
      placement="right-start"
      title="Crear contacto"
      :width="200"
      trigger="hover"
      content="Abrir formulario para agregar un contacto"
    >
      <template #reference>
        <el-button class="btn-plus" type="text" @click="openForm"
          ><ion-icon name="add-outline"></ion-icon
        ></el-button>
      </template>
    </el-popover>

    <el-popover
      placement="right-start"
      title="Guardar cambios"
      :width="250"
      trigger="hover"
      content="Presiona acá si deseas guardar todos los cambios."
    >
      <template #reference>
        <el-button class="btn-save" type="text" @click="save"
          ><ion-icon name="save-outline"></ion-icon
        ></el-button>
      </template>
    </el-popover>

    <ContactFormData ref="modalContacto" />
    <ContactList>
      <ContactCard
        v-for="contact in contacts"
        :contact="contact"
        v-bind:size="48"
        v-bind:key="contact.uid"
        :onlyRead="false"
      />
    </ContactList>
  </div>
</template>
<script>
import ContactCard from "../components/contacts/ContactCard.vue";
import ContactFormData from "../components/contacts/ContactFormData.vue";
import ContactList from "../components/contacts/ContactList.vue";
import { ElLoading } from "element-plus";
import { postData, getData, putData, deleteData } from "../request/request.js";
import { ElNotification } from "element-plus";

export default {
  name: "Contacts",
  props: {
    endpoint: String,
    edit: Boolean,
  },
  components: {
    ContactFormData,
    ContactCard,
    ContactList,
  },
  mounted() {
    this.$options.components.ContactList = require("../components/contacts/ContactList.vue").default;
    this.$options.components.ContactCard = require("../components/contacts/ContactCard.vue").default;
    this.$options.components.ContactFormData = require("../components/contacts/ContactFormData.vue").default;
    this.emitter.off("save-contact-form");
    this.emitter.on("save-contact-form", (model) => {
      if (model.uid === undefined) {
        model.uid = this.nextUid++;
      }

      let key = "id";
      if (Object.prototype.hasOwnProperty.call(model, key)) {
        this.contacts[model.id] = Object.assign({}, model);
      } else {
        this.contacts[model.uid] = Object.assign({}, model);
      }
    });
    this.emitter.off("edit-contact-form");
    this.emitter.on("edit-contact-form", (model) => {
      this.$refs.modalContacto.showModal(Object.assign({}, model));
    });
    this.emitter.off("remove-contact-form");
    this.emitter.on("remove-contact-form", (model) => {
      let key = "id";
      if (Object.prototype.hasOwnProperty.call(model, key)) {
        this.deleteContacts.push(model);
        delete this.contacts[model.id];
      } else {
        delete this.contacts[model.uid];
      }
    });
    if (this.endpoint !== undefined) {
      this.getContacts();
    }
  },
  data() {
    return {
      modalContacto: {},
      contacts: {},
      dinamicEndpoint: "",
      deleteContacts: [],
      nextUid: 0,
      loading: null,
      loadingGetContacts: false,
    };
  },
  methods: {
    openForm() {
      this.$refs.modalContacto.showModal({
        full_name: "",
        email: "",
        phone: "",
        area: "",
        observations: "",
      });
    },

    setEndpoint(id) {
      this.dinamicEndpoint = "api/v1/contacts/client/" + id;
    },

    async getContacts() {
      this.loadingGetContacts = true;
      if (this.endpoint !== undefined) {
        this.dinamicEndpoint = this.endpoint;
      }
      await getData(this.dinamicEndpoint)
        .then((contacts) => {
          if (contacts.error == true) {
            ElNotification({
              title: "Error",
              message: "Ocurrió un error al solicitar los contactos",
              type: "error",
            });
          } else {
            contacts.data.forEach((res) => {
              this.contacts[res.id] = Object.assign({}, res);
            });
          }
        })
        .catch(() => {
          ElNotification({
            title: "Error",
            message: "Ocurrió un error al hacer la petición",
            type: "error",
          });
        });
      this.loadingGetContacts = false;
    },

    save() {
      let contactsArray = Object.values(this.contacts);

      if (contactsArray.length == 0) {
        ElNotification({
          title: "Agregue contactos",
          message: "Debe agregar al menos un contacto.",
          type: "warning",
        });

        return;
      }
      let key = "id";
      let newFileList = [];
      let oldFileList = [];
      contactsArray.forEach((contact) => {
        if (Object.prototype.hasOwnProperty.call(contact, key)) {
          oldFileList.push(contact);
        } else {
          newFileList.push(contact);
        }
      });

      this.loading = ElLoading.service();
      if (this.endpoint !== undefined) {
        this.dinamicEndpoint = this.endpoint;
      }

      putData(this.dinamicEndpoint, oldFileList)
        .then((res) => {
          if (res.error == true) {
            ElNotification({
              title: "Error",
              message: res.message,
              type: "error",
            });
          }
        })
        .catch(() => {
          ElNotification({
            title: "Error",
            message: "Ocurrió un error al hacer la petición",
            type: "error",
          });
        }),
      deleteData(this.dinamicEndpoint, this.deleteContacts)
        .then((res) => {
          if (res.error == true) {
            ElNotification({
              title: "Error",
              message: res.message,
              type: "error",
            });
          }
        })
        .catch(() => {
          ElNotification({
            title: "Error",
            message: "Ocurrió un error al hacer la petición",
            type: "error",
          });
        }),
      postData(this.dinamicEndpoint, newFileList, true)
        .then((res) => {
          if (res.error) {
            ElNotification({
              title: "Error",
              message: res.message,
              type: "error",
            });
            this.loading.close();

            return;
          }

          ElNotification({
            title: "Contactos guardados",
            message: res.message,
            type: "success",
          });
          this.loading.close();
          this.contacts = {};
          this.getContacts();

          if (this.edit != true) {
            this.emitter.emit("save-contacts-success", this.model);
          }
        })
        .catch(() => {
          this.loading.close();
        });
    },
  },
};
</script>
<style scoped>
.main {
  height: 100%;
  width: 100%;
}

ion-icon {
  pointer-events: none;
}

.btn-plus {
  width: 55px;
  height: 55px;
  color: #003149;
  font-weight: bold !important;
  font-size: 32px;
  text-align: center;
}


.btn-save {
  width: 55px;
  height: 55px;
  padding-bottom: 5px;
  height: 50px;
  color: #003149;
  font-weight: bold !important;
  font-size: 32px;
  text-align: center;
}
</style>
