<template>
  <el-container>
    <el-row>
      <el-col class="header" :span="24">
        <br /><br />
        <el-header>
          <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/reports' }">
              Reportes
            </el-breadcrumb-item>
            <el-breadcrumb-item
              :to="{ path: `/${REPORTS_PATH_PREFIX}/graphics` }"
            >
              Gráficas
            </el-breadcrumb-item>
            <el-breadcrumb-item> Proyecto </el-breadcrumb-item>
          </el-breadcrumb>
        </el-header>
      </el-col>
    </el-row>

    <el-form ref="model" :model="model">
      <el-row class="form">
        <el-col :span="7">
          <el-form-item label="Fecha de inicio">
            <el-date-picker
              v-model="model.start_date"
              type="date"
              format="YYYY-MM-DD"
              value-format="YYYY-MM-DD"
              placeholder="Selecciona una fecha"
            />
          </el-form-item>
        </el-col>

        <el-col :span="7">
          <el-form-item label="Fecha de fin">
            <el-date-picker
              v-model="model.finish_date"
              type="date"
              format="YYYY-MM-DD"
              value-format="YYYY-MM-DD"
              placeholder="Selecciona una fecha"
            />
          </el-form-item>
        </el-col>
        <el-col :span="1">
          <div v-if="model.start_date != null && model.finish_date != null">
            <el-button @click="getProjects" type="primary">Ver</el-button>
          </div>
        </el-col>
        <el-col :span="24">
          <el-tooltip
            v-if="projects.length > 0"
            content="Generar pdf"
            placement="right"
            effect="light"
          >
            <i @click="generatePDF" class="fa-regular fa-file-pdf icon-pdf"></i>
          </el-tooltip>
        </el-col>
      </el-row>
    </el-form>

    <el-row>
      <el-col :span="24" align="center" v-if="projects.length > 0">
        <h2 align="center">PRESUPUESTO POR PROYECTO</h2>
        <div class="chart">
          <canvas id="myChart"></canvas>
          <div class="total">
            <span>Total {{ toCurrency(total) }} </span>
          </div>
        </div>
      </el-col>
    </el-row>
  </el-container>
</template>

<script>
import Chart from "chart.js/auto";
import { REPORTS_PATH_PREFIX } from "../../../config/constants.js";
import { getData, downloadDataPOST } from "../../../request/request.js";
import { toCurrency } from "../../../config/Money";
import { ElLoading, ElNotification } from "element-plus";
export default {
  name: "ProjectChart",
  setup() {
    return {
      REPORTS_PATH_PREFIX,
      toCurrency,
    };
  },
  data() {
    return {
      projects: [],
      labels: [],
      data: [],
      total: 0,
      loading: false,
      chart: null,
      model: {
        start_date: null,
        finish_date: null,
      },
    };
  },
  methods: {
    async getProjects() {
      this.loading = ElLoading.service();
      await getData("api/v1/charts/projects", this.model)
        .then((res) => {
          this.cleanChartData();
          if (this.validate(res)) {
            this.projects = res.data;
            this.processProjects();
            setTimeout(() => {
              this.draw(this.total);
              this.loading.close();
            }, 1000);
          }
        })
        .catch(() => {
          ElNotification({
            title: "Error",
            message: "Ocurrió un error al hacer la petición",
            type: "error",
          });
          this.cleanChartData();
          this.loading.close();
        });
    },

    validate(res) {
      if (res.error == true || res.error == undefined) {
        ElNotification({
          title: "Error",
          message: res.message,
          type: "error",
        });
        this.loading.close();
        return false;
      }
      if (res.data.length == 0) {
        ElNotification({
          title: "Información",
          message: "No hay proyectos para mostrar en las fechas indicadas.",
          type: "info",
        });
        this.loading.close();
        return false;
      }
      return true;
    },

    generatePDF() {
      this.loading = ElLoading.service();
      let image64 = this.getImage() != null ? this.getImage() : null;
      downloadDataPOST(
        "api/v1/charts/pdf-charts",
        {
          title: "Presupuesto proyectos",
          total: `PRESUPUESTO TOTAL PROYECTOS:${toCurrency(this.total)}`,
          image: image64,
        },
        "Presupuesto proyectos.pdf"
      );
      this.loading.close();
    },

    processProjects() {
      for (let data of this.projects) {
        this.labels.push(data.name + "  " + toCurrency(parseFloat(data.price)));
        this.data.push(parseFloat(data.price));
        this.total += parseFloat(data.price);
      }
    },

    cleanChartData() {
      if (this.chart) {
        this.chart.destroy();
        this.chart.$rendered = false;
      }
      this.labels = [];
      this.data = [];
      this.total = 0;
    },

    draw(total) {
      const ctx = document.getElementById("myChart").getContext("2d");
      const alwasShowTooltip = {
        id: "alwasShowTooltip",
        afterDraw(chart) {
          const { ctx } = chart;
          ctx.save();

          chart.data.datasets.forEach((dataset, i) => {
            chart.getDatasetMeta(i).data.forEach((datapoint, index) => {
              const { x, y } = datapoint.tooltipPosition();
              let percentaje =
                (chart.data.datasets[i].data[index] * 100) / total;
              const text = parseFloat(percentaje.toFixed(2)) + "%";
              let textWidth = ctx.measureText(text).width;

              ctx.fillStyle = "rgba(0,0,0,0.8)";
              ctx.fillRect(
                x - (textWidth + 10) / 2,
                y - 25,
                textWidth + 10,
                20
              );

              //triangle
              ctx.beginPath();
              ctx.moveTo(x, y);
              ctx.lineTo(x - 5, y - 5);
              ctx.lineTo(x + 5, y - 5);
              ctx.fill();
              ctx.restore();

              //text
              ctx.font = "12px";
              ctx.fillStyle = "white";
              ctx.fillText(text, x - textWidth / 2, y - 12);
              ctx.restore();
            });
          });
        },
      };

      this.chart = new Chart(ctx, {
        type: "doughnut",
        data: {
          labels: this.labels,
          datasets: [
            {
              data: this.data,
              backgroundColor: [
                "rgb(47, 164, 255)",
                "rgb(0, 255, 171)",
                "rgb(84, 22, 144)",
                "rgb(255, 73, 73)",
                "rgb(255, 141, 41)",
                "rgb(255, 205, 56)",
                "rgb(47, 164, 255)",
                "rgb(0, 255, 221)",
                "rgb(232, 255, 194)",
                "rgb(14, 24, 95)",
                "rgb(255, 0, 117)",
                "rgb(251, 255, 0)",
              ],
              borderColor: [
                "rgb(47, 164, 255)",
                "rgb(0, 255, 171)",
                "rgb(84, 22, 144)",
                "rgb(255, 73, 73)",
                "rgb(255, 141, 41)",
                "rgb(255, 205, 56)",
                "rgb(47, 164, 255)",
                "rgb(0, 255, 221)",
                "rgb(232, 255, 194)",
                "rgb(14, 24, 95)",
                "rgb(255, 0, 117)",
                "rgb(251, 255, 0)",
              ],
              hoverOffset: 4,
            },
          ],
        },

        options: {
          responsive: true,
          maintainAspectRatio: true,
          plugins: {
            legend: {
              position: "bottom",
              labels: { font: { size: 15.5 } },
            },
            tooltip: {
              enabled: false,
            },
          },
          interaction: {
            intersect: false,
          },
        },
        plugins: [
          {
            afterRender: function (chart) {
              if (chart != null) {
                chart.$rendered = true;
              }
            },
          },
          alwasShowTooltip,
        ],
      });
    },

    getImage() {
      if (this.chart.$rendered) {
        let canvas = document.getElementById("myChart");
        return canvas.toDataURL();
      }
    },
  },
};
</script>
<style scoped>
.el-container {
  display: block;
}
.chart {
  padding: auto;
  height: 40%;
  width: 40%;
  margin-bottom: 3%;
}
.el-breadcrumb {
  font-size: 20px;
}
.total {
  display: flex;
  justify-content: flex-end;
  font-weight: bolder;
}

.form {
  margin: 0 20px;
}

.icon-pdf {
  color: rgb(22, 52, 219);
  margin: 0 10px 0 10px;
  font-size: 20px;
  cursor: pointer;
}
</style>
