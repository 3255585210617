<template>
  <el-container class="container">
    <el-row>
      <el-col :span="24">
        <general-quotation-edit ref="editGeneralQuotation" />
        <general-quotation-show ref="showGeneralQuotation" />

        <CreateGeneralQuotation />
      </el-col>
      <el-col :span="20" class="animate__animated animate__fadeIn">
        <div style="margin-top: 15px; margin-bottom: 15px">
          <el-input
            v-model="query"
            :placeholder="placeHolderValues[type]"
            class="input-with-select"
            v-on:keyup.enter="search"
          >
            <template #prepend>
              <el-select
                v-model="type"
                placeholder="Select"
                style="width: 180px"
              >
                <el-option
                  label="Nombre cliente"
                  value="client_name"
                ></el-option>
                <el-option label="Consecutivo" value="consecutive"></el-option>
                <el-option label="Ciudad" value="city"></el-option>
                <el-option label="Fecha inicio" value="start_date"></el-option>
                <el-option
                  label="Fecha creación"
                  value="created_at"
                ></el-option>
              </el-select>
            </template>
            <template #append>
              <el-button @click="search" icon="el-icon-search"></el-button>
            </template>
          </el-input>
        </div>
      </el-col>
      <el-col class="paginate animate__animated animate__fadeIn" :span="4">
        <el-button
          icon="el-icon-arrow-left"
          @click="prev"
          :disabled="prevEnable"
        >
        </el-button>
        <label style="margin: 0 15px 0 15px"> {{ page }} </label>
        <el-button
          icon="el-icon-arrow-right"
          @click="next"
          :disabled="nextEnable"
        >
        </el-button>
      </el-col>
      <el-col
        :span="24"
        class="animate__animated animate__fadeIn animate__delay-1s"
      >
        <el-table
          v-loading="loading"
          :data="tableData"
          :empty-text="'No hay registros'"
          style="border-radius: 10px"
          stripe
          class="static-tabs"
        >
          <el-table-column prop="consecutive" label="Consecutivo" width="110" />
          <el-table-column label="Cliente">
            <template #default="scope">
              <b>{{ scope.row.identification }}</b
              ><br />
              {{ scope.row.full_name }}<br />
              {{ scope.row.client_type }}<br />
              {{ scope.row.client_status == 1 ? "Activo" : "Inactivo" }}<br />
            </template>
          </el-table-column>
          <el-table-column label="Valor">
            <template #default="scope">
              <b>Cliente:</b><br />
              {{
                scope.row.total_client == null
                  ? "$" + 0
                  : toCurrency(parseInt(scope.row.total_client))
              }}<br />
              <b>Interno:</b><br />
              {{ toCurrency(parseInt(scope.row.total_provider)) }}<br />
            </template>
          </el-table-column>
          <el-table-column label="Ciudad">
            <template #default="scope">
              {{ scope.row.department_name }}
              -
              {{ scope.row.city_name }}
            </template>
          </el-table-column>
          <el-table-column prop="event_place" label="Lugar" width="100" />

          <el-table-column width="150" label="Fecha creación">
            <template #default="scope">{{
              formatDateAndHour(scope.row.created_at)
            }}</template>
          </el-table-column>
          <el-table-column width="150" label="Fecha inicio">
            <template #default="scope">{{
              formatDateAndHour(scope.row.start_date)
            }}</template>
          </el-table-column>
          <el-table-column
            align="center"
            label="Estado"
            width="155"
            v-if="hasPermits('general_quotation.change_status')"
          >
            <template #default="scope">
              <change-status
                :status="scope.row.status"
                :general_quotation_id="scope.row.id"
              />
            </template>
          </el-table-column>

          <el-table-column label="Acciones" align="center" width="180">
            <template #default="scope">
              <el-tooltip
                v-if="
                  hasPermits('general_quotation.edit') &&
                  scope.row.status == 'Cotizado'
                "
                content="Editar"
                placement="top"
                effect="light"
              >
                <i
                  class="el-icon-edit properties"
                  @click="openFormEdit(scope.row.id)"
                ></i>
              </el-tooltip>
              <el-tooltip
                v-if="hasPermits('general_quotation.show')"
                content="Ver"
                placement="top"
                effect="light"
              >
                <i
                  class="el-icon-view properties"
                  @click="openFormShow(scope.row.id)"
                ></i>
              </el-tooltip>
              <el-tooltip
                content="Descargar pdf"
                placement="top"
                effect="light"
              >
                <i
                  @click="
                    downloadData(
                      'api/v1/pdfs/general-quotation/' + scope.row.id,
                      {},
                      'Cotización.pdf'
                    )
                  "
                  class="fa-regular fa-file-pdf icon-download properties"
                ></i>
              </el-tooltip>
              <template v-if="hasPermits('general_quotation.delete')">
                <Delete
                  :endpoint="'api/v1/general-quotations/' + scope.row.id"
                  event="reload-list-of-general-quotations"
                  :key="scope.row.id"
                />
              </template>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
  </el-container>
</template>
<script>
import { getData, downloadData } from "../../request/request.js";
import { hasPermits } from "@/config/Auth.js";
import { formatDateAndHour } from "../../config/Date.js";
import { toCurrency } from "@/config/Money.js";
import CreateGeneralQuotation from "./CreateGeneralQuotation.vue";
import GeneralQuotationEdit from "./GeneralQuotationEdit.vue";
import GeneralQuotationShow from "./GeneralQuotationShow.vue";
import Delete from "../Delete.vue";
import ChangeStatus from "./ChangeStatus.vue";
import "animate.css";
export default {
  name: "GeneralQuotationList",
  setup() {
    return {
      hasPermits,
      formatDateAndHour,
      downloadData,
      toCurrency,
    };
  },
  components: {
    CreateGeneralQuotation,
    GeneralQuotationEdit,
    GeneralQuotationShow,
    Delete,
    ChangeStatus,
  },
  data() {
    return {
      tableData: [],
      loading: false,
      prevEnable: true,
      nextEnable: false,
      page: 1,
      query: "",
      type: "client_name",
      placeHolderValues: {
        "": "Seleciona opción de búsqueda",
        client_name: "Ingresa el nombre del cliente",
        consecutive: "Ingresa el consecutivo",
        city: "Ingresa la ciudad",
        start_date: "Ingresa la fecha, Formato: AAAA-MM-DD",
        created_at: "Ingresa la fecha, Formato: AAAA-MM-DD",
      },
    };
  },
  methods: {
    loadData() {
      this.loading = true;
      getData("api/v1/general-quotations", {
        page: this.page,
        type: this.type,
        query: this.query,
      })
        .then((res) => {
          this.tableData = res.data;
          this.page = res.current_page;
          this.prevEnable = res.prev_page_url == null;
          this.nextEnable = res.next_page_url == null;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    search() {
      this.page = 1;
      this.loadData();
    },
    prev() {
      this.page--;
      this.loadData();
    },
    next() {
      this.page++;
      this.loadData();
    },
    openFormEdit(id) {
      this.$refs.editGeneralQuotation.openForm(id);
    },
    openFormShow(id) {
      this.$refs.showGeneralQuotation.openForm(id);
    },
  },
  mounted() {
    this.emitter.off("reload-list-of-general-quotations");
    this.emitter.on("reload-list-of-general-quotations", () => {
      this.page = 1;
      this.loadData();
    });
    this.loadData();
  },
};
</script>
<style scoped>
.static-tabs {
  border-radius: 5px;
  height: 71vh;
  overflow-y: auto;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 10px;
  height: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: rgb(163, 163, 163);
  border-radius: 5px;
}

.container {
  width: -webkit-fill-available;
  height: fit-content !important;
}

.el-table {
  border-radius: 10px;
}

.el-icon-view {
  color: var(--blue) !important;
  margin-right: 5px;
  font-size: 19px;
  cursor: pointer;
}

.paginate {
  margin: auto;
  text-align: end;
}

.el-row {
  width: inherit;
}

.el-button {
  background: var(--blue);
  color: white;
}

.is-disabled {
  background: #8080809c;
}

.is-disabled:hover {
  background: #8080809c;
}

::v-deep(.el-table thead) {
  color: var(--blue) !important;
}

.el-select {
  background: var(--blue);
  color: white;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.el-select-dropdown__item.selected {
  color: var(--blue);
}

.el-icon-edit {
  color: darkorange;
}

.properties {
  margin: 0 3px 0 3px;
  font-size: 20px;
  cursor: pointer;
}

.icon-download {
  color: var(--blue);
  font-size: 20px;
  cursor: pointer;
  margin-right: 5px;
}
</style>
