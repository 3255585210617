<template>
  <el-container>
    <br /><br />
    <el-header>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/providers' }"
          ><h2 class="title">Proveedores</h2></el-breadcrumb-item
        >
      </el-breadcrumb>
    </el-header>
    <el-main>
      <CreateProvider v-if="hasPermits('providers.create')" />
      <br />
      <ProviderList v-if="hasPermits('providers.list')" />
      <EditProvider v-if="hasPermits('providers.edit')" />
    </el-main>
  </el-container>
</template>
<script>
import ProviderList from "../components/providers/ProviderList.vue";
import EditProvider from "../components/providers/ProviderEdit.vue";
import CreateProvider from "../components/providers/CreateProvider.vue";
import { hasPermits } from "@/config/Auth.js";

export default {
  setup() {
    return {
      hasPermits,
    };
  },
  name: "Provider",
  components: {
    ProviderList,
    EditProvider,
    CreateProvider,
  },
};
</script>

<style scoped>
.title {
  color: black;
  text-align: left;
}
</style>
