<template>
  <el-select
    v-model="provider_id"
    placeholder="Buscar Proveedor"
    no-data-text="No hay coincidencias."
    loading-text="Buscando..."
    filterable
    remote
    reserve-keyword
    :remote-method="searchProvider"
    :loading="loading"
    style="width: 100%"
    v-on:change="this.emitter.emit('update:provider_id', $event)"
  >
    <el-option
      v-for="provider in providers"
      :key="provider.full_name"
      :label="provider.full_name"
      :value="provider.id"
    >
    </el-option>
  </el-select>
</template>
<script>
import { getData } from "../../request/request.js";
import { ElNotification } from "element-plus";

export default {
  name: "SelectProvider",
  data() {
    return {
      providers: [],
      provider_id: "",
      loading: false,
    };
  },
  methods: {
    async getProviders() {
      this.loading = true;
      await getData(
        "api/v1/providers/prefix",
        {
          query: this.query,
        },
        true
      )
        .then((res) => {
          this.loading = false;

          if (res.error == true || res.error == undefined) {
            ElNotification({
              title: "Error",
              message: res.message,
              type: "error",
            });
            return;
          }

          this.providers = res.data;
        })
        .catch(() => {
          ElNotification({
            title: "Error",
            message: "Ocurrió un error al hacer la petición",
            type: "error",
          });
          this.loading = false;
        });
    },
    searchProvider(query) {
      query = query.trim();
      if (query === "" || query.length < 2) {
        return;
      }

      this.query = query;
      this.getProviders();
    },
  },
};
</script>
