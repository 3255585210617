<template>
  <el-container>
    <el-row>
      <el-col>
        <br /><br />
        <el-header>
          <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: `/${REPORTS_PATH_PREFIX}` }"
              >Reportes</el-breadcrumb-item
            >
            <el-breadcrumb-item
              :to="{ path: `/${REPORTS_PATH_PREFIX}/graphics` }"
              >Gráficas</el-breadcrumb-item
            >
            <el-breadcrumb-item
              :to="{ path: `/${REPORTS_PATH_PREFIX}/graphics/cdps-chart` }"
              >Cdps</el-breadcrumb-item
            >
          </el-breadcrumb>
        </el-header>
      </el-col>
    </el-row>

    <el-form
      ref="form"
      :model="query"
      label-position="top"
      :rules="rules"
      class="animate__animated animate__fadeIn"
    >
      <el-row class="form">
        <el-col :span="10">
          <el-form-item label="Selecciona un proyecto" prop="contractID">
            <SelectContract v-model="query.contractID" />
          </el-form-item>
        </el-col>

        <el-col :span="7" :offset="2">
          <el-form-item
            label="Selecciona los estados en ejecución"
            prop="selectedStates"
          >
            <SelectStatus multiple v-model="query.selectedStates" />
          </el-form-item>
        </el-col>

        <el-col :span="4">
          <div class="search-button">
            <el-button type="primary" @click="search()">Ver gráfica</el-button>
          </div>
        </el-col>
        <el-col :span="24">
          <el-tooltip
            v-if="information.length > 0"
            content="Generar pdf"
            placement="right"
            effect="light"
          >
            <i @click="generatePDF" class="fa-regular fa-file-pdf icon-pdf"></i>
          </el-tooltip>
        </el-col>
      </el-row>
    </el-form>
    <el-row v-show="information.length > 0">
      <el-col :xs="0" :sm="1" :md="2" :lg="3" :xl="5"></el-col>
      <el-col :xs="24" :sm="22" :md="20" :lg="18" :xl="14" align="center">
        <h2 align="center">CDPs DEL PROYECTO</h2>
        <div class="chart">
          <canvas id="myChart"></canvas>
          <div class="total">
            <span>Total proyecto: {{ toCurrency(totalContract) }} </span>
            <span>Total ejecutado: {{ toCurrency(totalExecuted) }} </span>
            <span
              >Total disponible:
              {{ toCurrency(totalAvailable) }}
            </span>
          </div>
        </div>
      </el-col>
    </el-row>
  </el-container>
</template>
<script>
import Chart from "chart.js/auto";
import { REPORTS_PATH_PREFIX } from "../../../config/constants.js";
import SelectContract from "../../contracts/SelectContract.vue";
import SelectStatus from "../../events/Status/SelectStatus.vue";
import { ElLoading, ElMessage, ElNotification } from "element-plus";
import { getData, downloadDataPOST } from "@/request/request.js";
import { toCurrency } from "../../../config/Money";

export default {
  name: "CdpChart",
  setup() {
    return {
      REPORTS_PATH_PREFIX,
      toCurrency,
    };
  },
  components: {
    SelectContract,
    SelectStatus,
  },
  data() {
    return {
      ctx: null,
      loading: false,
      totalContract: 0,
      totalExecuted: 0,
      totalAvailible: 0,
      backgroundColor: [
        "rgb(255, 73, 73)",
        "rgb(255, 205, 56)",
        "rgb(47, 164, 255)",
        "rgb(0, 255, 221)",
        "rgb(232, 255, 194)",
        "rgb(14, 24, 95)",
        "rgb(255, 0, 117)",
        "rgb(251, 255, 0)",
        "rgb(47, 164, 255)",
        "rgb(0, 255, 171)",
        "rgb(84, 22, 144)",
        "rgb(255, 141, 41)",
        "rgb(29, 185, 195)",
        "rgb(112, 39, 160)",
        "rgb(195, 43, 173)",
        "rgb(245, 111, 173)",
        "rgb(0, 120, 170)",
        "rgb(58, 180, 242)",
        "rgb(242, 223, 58)",
        "rgb(242, 223, 58)",
      ],
      chart: null,
      labels: [],
      information: [],
      data: [],
      query: {
        contractID: "",
        selectedStates: [],
      },
      rules: {
        contractID: [
          {
            required: true,
            message: "Por favor selecciona un proyecto",
            trigger: "blur",
          },
        ],
        selectedStates: [
          {
            required: true,
            message: "Por favor selecciona por lo menos un estado",
            trigger: "blur",
          },
        ],
      },
    };
  },
  mounted() {
    this.cleanForm("model");
  },
  methods: {
    validateForm(formName) {
      let res;
      this.$refs[formName].validate((valid) => {
        res = valid;
      });
      return res;
    },

    async search() {
      if (!this.validateForm("form")) {
        ElMessage.error("¡ Error !, Por favor verifica todos los campos");
        return;
      }
      this.loading = ElLoading.service();
      await getData(`api/v1/charts/${this.query.contractID}/cdps`, {
        states: this.query.selectedStates,
      })
        .then((res) => {
          this.cleanChartData();
          if (res.error == true || res.error == undefined) {
            ElNotification({
              title: "Error",
              message: res.message,
              type: "error",
            });

            return;
          }

          this.data = res.data;
          this.process();
          setTimeout(() => {
            this.draw();
            this.loading.close();
          }, 1000);
        })
        .catch(() => {
          ElNotification({
            title: "Error",
            message: "Ocurrió un error al hacer la petición.",
            type: "error",
          });
          this.loading.close();
        });
    },

    process() {
      this.totalContract = this.data.contract_price;
      this.totalExecuted = this.data.total_executed;
      this.totalAvailable = this.data.total_available;

      for (let cdp of this.data.cdps) {
        let color = this.randomColor(0,this.backgroundColor.length-1);
        let complementColor = this.complementColor(this.backgroundColor[color]);
        let executed = cdp.executed;
        let amount = cdp.amount;
        let available = cdp.available;
        this.labels.push(cdp.name + " Ejecutado: " + this.toCurrency(executed));
        this.labels.push(
          cdp.name + " Disponible: " + this.toCurrency(available)
        );
        this.information.push({
          backgroundColor: [this.backgroundColor[color], complementColor],
          data: [
            this.calculatePercentage(amount, executed),
            this.calculatePercentage(amount, available),
          ],
        });
      }
    },

    draw() {
      this.ctx = document.getElementById("myChart").getContext("2d");
      const alwasShowTooltip = {
        id: "alwasShowTooltip",
        afterDraw(chart) {
          const { ctx } = chart;
          ctx.save();
          chart.data.datasets.forEach((dataset, i) => {
            chart.getDatasetMeta(i).data.forEach((datapoint, index) => {
              const { x, y } = datapoint.tooltipPosition();
              const text = chart.data.datasets[i].data[index] + "%";
              let textWidth = ctx.measureText(text).width;
              ctx.fillStyle = "rgba(0,0,0,0.8)";
              ctx.fillRect(
                x - (textWidth + 10) / 2,
                y - 25,
                textWidth + 10,
                20
              );
              //triangle
              ctx.beginPath();
              ctx.moveTo(x, y);
              ctx.lineTo(x - 5, y - 5);
              ctx.lineTo(x + 5, y - 5);
              ctx.fill();
              ctx.restore();
              //text
              ctx.font = "5px";
              ctx.fillStyle = "white";
              ctx.fillText(text, x - textWidth / 2, y - 12);
              ctx.restore();
            });
          });
        },
      };

      this.chart = new Chart(this.ctx, {
        type: "pie",
        data: {
          labels: this.labels,
          datasets: this.information,
        },
        options: {
          responsive: true,
          maintainAspectRatio: true,
          plugins: {
            legend: {
              position: "bottom",
              labels: {
                font: { size: 11.5 },
                generateLabels: function (chart) {
                  // Get the default label list
                  const original =
                    Chart.overrides.pie.plugins.legend.labels.generateLabels;
                  const labelsOriginal = original.call(this, chart);
                  // Build an array of colors used in the datasets of the chart
                  let datasetColors = chart.data.datasets.map(function (e) {
                    return e.backgroundColor;
                  });
                  datasetColors = datasetColors.flat();
                  // Modify the color and hide state of each label
                  labelsOriginal.forEach((label) => {
                    // There are twice as many labels as there are datasets. This converts the label index into the corresponding dataset index
                    label.datasetIndex = (label.index - (label.index % 2)) / 2;
                    // The hidden state must match the dataset's hidden state
                    label.hidden = !chart.isDatasetVisible(label.datasetIndex);
                    // Change the color to match the dataset
                    label.fillStyle = datasetColors[label.index];
                  });
                  return labelsOriginal;
                },
              },
              onClick: function (mouseEvent, legendItem, legend) {
                // toggle the visibility of the dataset from what it currently is
                legend.chart.getDatasetMeta(legendItem.datasetIndex).hidden =
                  legend.chart.isDatasetVisible(legendItem.datasetIndex);
                legend.chart.update();
              },
            },
            tooltip: {
              enabled: false,
            },
          },
          interaction: {
            intersect: false,
          },
        },
        plugins: [
          {
            afterRender: function (chart) {
              if (chart != null) {
                chart.$rendered = true;
              }
            },
          },
          alwasShowTooltip,
        ],
      });
    },

    async generatePDF() {
      this.loading = ElLoading.service();
      let image64 = this.getImage() != null ? this.getImage() : null;
      await downloadDataPOST(
        "api/v1/charts/complement-pdf-charts",
        {
          title: "CDPs del proyecto",
          total: `TOTAL PROYECTO: ${toCurrency(this.totalContract)}`,
          executed: `TOTAL EJECUTADO: ${toCurrency(this.totalExecuted)}`,
          available: `TOTAL DISPONIBLE: ${toCurrency(this.totalAvailable)} `,
          image: image64,
        },
        "CDPs proyecto.pdf"
      );
      this.loading.close();
    },

    getImage() {
      if (this.chart.$rendered) {
        let canvas = document.getElementById("myChart");
        return canvas.toDataURL();
      }
    },

    randomColor(min = 0, max = 20) {
      return Math.floor(Math.random() * (max - min + 1) + min);
    },
    complementColor(color) {
      let slice = color.slice(4, color.length - 1);
      let rgb = slice.split(",");
      return `rgb(${rgb[0]},${rgb[1]},${rgb[2]},0.4)`;
    },

    cleanForm(formName) {
      if (this.$refs[formName] != undefined) {
        this.$refs[formName].resetFields();
      }
    },
    cleanChartData() {
      if (this.chart) {
        this.chart.destroy();
        this.chart.$rendered = false;
      }

      this.labels = [];
      this.information = [];
      this.totalContract = 0;
      this.totalExecuted = 0;
      this.totalAvailable = 0;
    },

    calculatePercentage(total, part) {
      let percentage = parseFloat((part * 100) / total);
      return percentage.toFixed(3);
    },
  },
};
</script>
<style scoped>
.el-container {
  display: block;
}
.chart {
  padding: auto;
  height: 100%;
  width: 100%;
  margin-bottom: 3%;
}
.el-breadcrumb {
  font-size: 20px;
}
.total {
  display: flex;
  justify-content: space-between;
  font-weight: bolder;
}

.total span {
  margin: 0 15px;
}

.form {
  margin: 0 20px;
}

.icon-pdf {
  color: rgb(22, 52, 219);
  margin: 0 10px 0 10px;
  font-size: 20px;
  cursor: pointer;
}

.show-button {
  margin: 50px 27px;
}

.search-button {
  margin-left: 13px;
  margin-top: 50px;
}
</style>
