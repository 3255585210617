<template>
  <el-container class="container">
    <el-row>
      <el-col :span="20">
        <div
          style="margin-top: 15px; margin-bottom: 15px"
          class="animate__animated animate__fadeIn"
        >
          <el-input
            v-model="query"
            :placeholder="placeHolderValues[type]"
            class="input-with-select"
            v-on:keyup.enter="search"
          >
            <template #prepend>
              <el-select
                v-model="type"
                placeholder="Select"
                style="width: 150px"
              >
                <el-option label="Nombre" value="name"></el-option>
                <el-option label="ID" value="consecutive"></el-option>
                <el-option label="Proyecto" value="contract"></el-option>
                <el-option label="Ciudad" value="city"></el-option>
                <el-option label="Fecha inicio" value="start_date"></el-option>
              </el-select>
            </template>
            <template #append>
              <el-button @click="search" icon="el-icon-search"></el-button>
            </template>
          </el-input>
        </div>
      </el-col>
      <el-col class="paginate animate__animated animate__fadeIn" :span="4">
        <el-button
          icon="el-icon-arrow-left"
          @click="prev"
          :disabled="prevEnable"
        >
        </el-button>
        <label style="margin: 0 15px 0 15px"> {{ page }} </label>
        <el-button
          icon="el-icon-arrow-right"
          @click="next"
          :disabled="nextEnable"
        >
        </el-button>
      </el-col>
      <el-col
        :span="24"
        class="animate__animated animate__fadeIn animate__delay-1s"
      >
        <add-status :showButton="false" />
        <el-table
          v-loading="loading"
          :data="tableData"
          :empty-text="'No hay registros'"
          style="border-radius: 10px"
          @sort-change="sort"
          stripe
        >
          <el-table-column sortable prop="consecutive" label="ID" width="60" />
          <el-table-column
            sortable
            :sort-method="sortNumber('application_number')"
            prop="application_number"
            label="No. solicitud"
            min-width="95"
          />
          <el-table-column
            sortable
            prop="contract"
            label="Proyecto"
            min-width="300"
          />
          <el-table-column
            sortable
            prop="name"
            label="Nombre"
            min-width="300"
          />
          <el-table-column
            sortable
            prop="start_date"
            label="Fecha inicio"
            min-width="100"
          >
            <template #default="scope">
              {{ formatDateAndHour(scope.row.start_date) }}
            </template>
          </el-table-column>
          <el-table-column
            sortable
            prop="finish_date"
            label="Fecha fin"
            min-width="100"
          >
            <template #default="scope">
              {{ formatDateAndHour(scope.row.finish_date) }}
            </template>
          </el-table-column>
          <el-table-column label="Lugar" width="150">
            <template #default="scope">
              {{ scope.row.depatament_name }}
              -
              {{ scope.row.city_name }}
            </template>
          </el-table-column>
          <el-table-column
            prop="assistants_number"
            label="Asistentes"
            width="88"
          />
          <el-table-column
            sortable
            prop="dependency"
            label="Dependencia"
            min-width="115"
          />
          <el-table-column
            sortable
            prop="user"
            label="Ejecutivo"
            min-width="100"
          />
          <el-table-column
            sortable
            :sort-method="sortText('status_name')"
            prop="status"
            align="center"
            label="Estado"
            width="155"
          >
            <template #default="scope">
              <change-status
                :key="scope.row.id"
                :status="scope.row.status_id"
                :event_id="scope.row.id"
              />
            </template>
          </el-table-column>
          <el-table-column label="" align="center" width="95">
            <template #default="scope">
              <el-popover
                placement="left"
                :width="10"
                trigger="hover"
                content="Administrar"
              >
                <template #reference>
                  <ion-icon
                    title=""
                    @click="this.$router.push('/manage_event/' + scope.row.id)"
                    name="hammer-outline"
                  ></ion-icon>
                </template>
              </el-popover>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
  </el-container>
</template>

<script>
import { getData } from "../../request/request.js";
import { hasPermits } from "@/config/Auth.js";
import { diffDate } from "@/config/Helper.js";
import { formatDateAndHour } from "../../config/Date.js";
import ChangeStatus from "./Status/ChangeStatus.vue";
import AddStatus from "./Status/AddStatus.vue";
import "animate.css";

export default {
  name: "EventList",
  setup() {
    return {
      hasPermits,
      diffDate,
      formatDateAndHour,
    };
  },
  components: { ChangeStatus, AddStatus },
  data() {
    return {
      tableData: [],
      loading: false,
      prevEnable: true,
      nextEnable: false,
      placeHolderValues: {
        "": "Seleciona opción de búsqueda",
        name: "Ingresa el nombre del evento",
        consecutive: "Ingresa el ID del evento",
        contract: "Ingresa el nombre del proyecto",
        city: "Ingresa la ciudad",
        start_date: "Ingresa la fecha, Formato: AAAA-MM-DD",
      },
      query: "",
      type: "name",
      page: 1,
      sortConfig: {},
    };
  },
  methods: {
    async loadData() {
      this.loading = true;
      await getData("api/v1/events", {
        page: this.page,
        type: this.type,
        query: this.query,
        sort: JSON.stringify(this.sortConfig),
      })
        .then((res) => {
          this.tableData = res.data;
          this.page = res.current_page;
          this.prevEnable = res.prev_page_url == null;
          this.nextEnable = res.next_page_url == null;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    search() {
      this.page = 1;
      this.loadData();
    },
    prev() {
      this.page--;
      this.loadData();
    },
    next() {
      this.page++;
      this.loadData();
    },

    async sort(data) {
      if (data.prop === null) {
        this.sortConfig = {};
        await this.loadData();
      }

      this.sortConfig = {
        column: data.prop,
        order: data.order,
      };

      await this.loadData();
    },

    sortText(prop) {
      return function (a, b) {
        return a[prop].localeCompare(b[prop]);
      };
    },
    sortNumber(prop) {
      return function (a, b) {
        return a[prop].localeCompare(b[prop], undefined, { numeric: true });
      };
    },
  },
  mounted() {
    this.loadData();
    this.emitter.off("reload-list-of-events");
    this.emitter.on("reload-list-of-events", () => {
      this.loadData();
    });
  },
};
</script>
<style scoped>
.container {
  width: -webkit-fill-available;
  height: fit-content !important;
}

.el-table {
  border-radius: 10px;
}

.el-icon-view {
  color: var(--blue);
  margin-right: 5px;
  font-size: 19px;
  cursor: pointer;
}

.paginate {
  margin: auto;
  text-align: end;
}

.el-row {
  width: inherit;
}

.el-button {
  background: var(--blue);
  color: white;
}

.is-disabled {
  background: #8080809c;
}

.is-disabled:hover {
  background: #8080809c;
}

::v-deep(.el-table thead) {
  color: var(--blue) !important;
}

.el-select {
  background: var(--blue);
  color: white;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.el-select-dropdown__item.selected {
  color: var(--blue);
}

ion-icon {
  color: darkorange;
  margin: 0 10px 0 10px;
  font-size: 20px;
  cursor: pointer;
}

::v-deep(.ionicon) {
  pointer-events: none !important;
}
.properties {
  color: darkorange;
  margin: 0 10px 0 10px;
  font-size: 20px;
  cursor: pointer;
}

ion-icon ::v-deep(svg) {
  pointer-events: none !important;
}
</style>
