<template>
  <el-dialog
    v-model="dialogVisible"
    title="Información de Comprador"
    width="50%"
  >
    <img class="logo" v-if="url_logo != ''" :src="url_logo" alt=""/>
    <el-row>
      <el-col :span="12">
        <p><b>Identificación </b></p>
        <p><b>Tipo de documento </b></p>
        <p><b>Nombre</b></p>
        <p><b>Correo </b></p>
        <p><b>Teléfono </b></p>
        <p><b>Dirección </b></p>
      </el-col>
      <el-col :span="12">
        <p>{{ identification }}</p>
        <p>{{ documentType }}</p>
        <p>{{ name }}</p>
        <p>{{ email }}</p>
        <p>{{ phone }}</p>
        <p>{{ address }}</p>
      </el-col>
    </el-row>

    <div class="demo-collapse">
      <el-collapse v-model="activeName" accordion>
        <!-- 1 -->
        <el-collapse-item name="1">
          <template #title>
            <h2>Usuarios</h2>
          </template>
          <div>
            <div class="demo-collapse">
              <el-collapse v-model="activeUsuarios" accordion>
                <el-collapse-item
                  v-for="(users, role, index) in roles"
                  :key="role"
                  :title="role"
                  :name="index"
                >
                  <div>
                    <el-row>
                      <!-- un usuario -->
                      <el-col
                        :span="12"
                        v-for="(user, idexUsers) in users"
                        :key="idexUsers"
                      >
                        <el-col :span="12">
                          <p>Nombre Completo</p>
                          <p>Correo</p>
                        </el-col>
                        <el-col :span="12">
                          <p>{{ user.full_name }}</p>
                          <p>{{ user.email }}</p>
                        </el-col>
                      </el-col>
                    </el-row>
                  </div>
                </el-collapse-item>
              </el-collapse>
            </div>
          </div>
        </el-collapse-item>
        <!-- 2 -->
        <el-collapse-item name="2">
          <template #title>
            <h2>
              <i class="el-icon-tickets" style="margin-right: 20px"></i
              >Documentos
            </h2>
          </template>
          <div v-if="documents.length !== 0" class="container" width="100%">
            <DocumentCard
              v-for="document in documents"
              :key="document.id"
              :document="{
                id: document.id,
                type: document.document_type.name,
                description: document.description,
                created_at: document.created_at,
                user_name: document.user_name,
              }"
              size="48"
            />
          </div>
          <h3 v-else>No hay registros.</h3>
        </el-collapse-item>
      </el-collapse>
    </div>
  </el-dialog>
</template>

<script>
import { ref } from "vue";
import { getData, getRawData } from "@/request/request.js";
import { ElNotification } from "element-plus";
export default {
  name: "ShowBuyer",
  components: {},
  beforeCreate() {
    this.$options.components.DocumentCard =
      require("../documents/DocumentCard.vue").default;
  },
  mounted() {
    this.emitter.off("open-modal-show-buyer");
    this.emitter.on("open-modal-show-buyer", (id) => {
      this.getBuyer(id);
    });
  },
  setup() {
    const dialogVisible = ref(false);
    const activeName = ref("0");
    const activeUsuarios = ref("0");
    return {
      dialogVisible,
      activeName,
      activeUsuarios,
    };
  },
  data() {
    return {
      buyer: [],
      name: "",
      email: "",
      identification: "",
      address: "",
      phone: "",
      documentType: "",
      url_logo: "",
      roles: [],
      users: [],
      documents: [],
    };
  },
  methods: {
    async getBuyer(id) {
      this.clear();
      this.loading = true;
      await getData(`api/v1/buyers/${id}/all`)
        .then((data) => {
          if (data.error == true) {
            ElNotification({
              title: "Error",
              message: "Ocurrió un problema al solicitar compradores",
              type: "error",
            });
          }
          this.buyer = data.data;
          this.processBuyer();
        })
        .catch(() => {
          ElNotification({
            title: "Error",
            message: "Ocurrió un error al hacer la petición",
            type: "error",
          });
        });
    },

    processBuyer() {
      this.roles = {};
      for (let user of this.buyer.users) {
        if (this.roles[user.role_name.name] === undefined) {
          this.roles[user.role_name.name] = [user];
        } else {
          this.roles[user.role_name.name].push(user);
        }
      }
      for (let item of this.buyer.documents) {
        this.documents.push(item);
      }
      this.name = this.buyer.name;
      this.email = this.buyer.email;
      this.identification = this.buyer.identification;
      this.address = this.buyer.address;
      this.phone = this.buyer.phone;
      this.documentType = this.buyer.identification_type.name;
      this.dialogVisible = true;

      if (this.buyer.url_logo) {
        this.url_logo = this.buyer.url_logo.original;
      }
    },
    checkRole(role) {
      for (let item of this.roles) {
        if (item === role) {
          return false;
        }
      }
      return true;
    },
    clear() {
      (this.buyer = []),
      (this.name = ""),
      (this.email = ""),
      (this.identification = ""),
      (this.address = ""),
      (this.phone = ""),
      (this.documentType = ""),
      (this.roles = {}),
      (this.documents = []);
    },
    async getDocument(event) {
      let id = event.currentTarget.id;
      await getRawData(`api/v1/documents/getUrl/${id}`)
        .then((data) => {
          if (data === "") {
            ElNotification({
              title: "Error",
              message: "Ocurrió un problema al solicitar el documento",
              type: "error",
            });
          }
          window.open(data, "_blank");
        })
        .catch(() => {
          ElNotification({
            title: "Error",
            message: "Ocurrió un error al hacer la petición",
            type: "error",
          });
        });
    },
  },
};
</script>

<style scoped>
.el-button--danger {
  background: #bc0304;
  border: none;
}

.el-button--primary {
  background: var(--blue) !important;
  border: 1px solid var(--blue) !important;
}

.el-button--text {
  color: var(--blue) !important;
  font-weight: bold;
  font-size: 25px;
}
ion-icon {
  font-weight: bold;
  font-size: 25px;
}
p {
  margin: 7px;
}
.icon {
  width: 25px;
  margin: 9px 10px;
  /* color azul */
  filter: brightness(0) saturate(100%) invert(14%) sepia(29%) saturate(1793%)
    hue-rotate(163deg) brightness(102%) contrast(97%);
}
.box-card p {
  margin: 0px;
  padding: 5px;
}
.title {
  padding: 5px;
}
.box-card {
  margin: 5px;
}

.logo {
  max-width: 100%
}
</style>
