<template>
  <AuthLayout>
    <div class="login">
      <h2>Iniciar Sesión</h2>
      <el-form
        v-on:submit.prevent="login"
        class="login-form"
        ref="form"
        :rules="rules"
        :model="model"
      >
        <el-form-item prop="email">
          <el-input
            class="border"
            v-model="model.email"
            placeholder="Correo"
            prefix-icon="fas fa-user"
            data-cy="email"
          ></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input
            class="border"
            v-model="model.password"
            placeholder="Contraseña"
            type="password"
            prefix-icon="fas fa-lock"
            data-cy="password"
          ></el-input>
        </el-form-item>
        <br />
        <el-form-item prop="checkedTerms">
          <el-checkbox class="checkbox" v-model="model.checkedTerms">
            Acepta los
            <a
              href=""
              @click.prevent="getPolicies('terms-conditions')"
              target="_blank"
              rel="noreferrer noopener"
              >términos</a
            > de servicio, <br /> la
            <a
              href=""
              @click.prevent="getPolicies('privacy')"
              target="_blank"
              rel="noreferrer noopener"
              >política de privacidad</a 
            > y la <br />
            <a
              href=""
              @click.prevent="getPolicies('data_treatment')"
              target="_blank"
              rel="noreferrer noopener"
              >política de de tratamiento de datos</a
            >
          </el-checkbox>
        </el-form-item>

        <el-form-item>
          <el-button
            :loading="loading"
            class="login-button"
            type="primary"
            native-type="submit"
            block
            data-cy="login"
            >Ingresar</el-button
          >
        </el-form-item>
        <router-link tag="a" :to="{ name: 'Forgot' }" class="forgot-password"
          >olvidé contraseña</router-link
        >
      </el-form>
    </div>
  </AuthLayout>
</template>
<script>
import { postData, getRawData } from "@/request/request.js";
import { setModules } from "@/config/Auth.js";
import { ElLoading, ElNotification } from "element-plus";
import AuthLayout from "../../components/AuthLayout.vue";

export default {
  name: "Login",
  components: {
    AuthLayout,
  },
  data() {
    return {
      model: {
        email: "",
        password: "",
        checkedTerms: [],
      },
      loading: false,
      loadingQuery: false,
      rules: {
        email: [
          {
            email: true,
            message: "Se debe ingresar un correo válido",
            trigger: "blur",
          },
          {
            required: true,
            message: "El correo es requerido",
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: "La Contraseña es requerida",
            trigger: "blur",
          },
        ],
        checkedTerms: [
          {
            required: true,
            message: "Debes aceptar términos de servicio",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    async login() {
      if (this.validateForm("form")) {
        this.loading = true;
        await postData("api/v1/auth/login", this.model)
          .then((res) => {
            if (res.error == true) {
              ElNotification({
                title: "Error",
                message: res.message,
                type: "error",
              });
            } else {
              localStorage.setItem("token", res.data.token);
              setModules()
                .then(() => {
                  this.emitter.emit("login-success");
                })
                .catch(() => {
                  ElNotification({
                    title: "Error",
                    message:
                      "Ocurrió un error al obtener los módulos disponibles",
                    type: "error",
                  });
                });
            }
          })
          .catch(() => {
            ElNotification({
              title: "Error",
              message: "Ocurrió un error al hacer la petición",
              type: "error",
            });
          });
        this.loading = false;
      }
    },

    validateForm(formName) {
      let res;
      this.$refs[formName].validate((valid) => {
        res = valid;
      });
      return res;
    },

    async getPolicies(type) {
      this.loadingQuery = ElLoading.service();
      await getRawData(`api/v1/documents/policies/${type}`)
        .then((data) => {
          let res = data.substring(0, 15).includes("<!doctype html>");
          if (res) {
            ElNotification({
              title: "Error",
              message: "Ocurrió un problema al solicitar el documento",
              type: "error",
            });
            this.loadingQuery.close();
            return;
          }
          window.open(data, "_blank");
        })
        .catch(() => {
          ElNotification({
            title: "Error",
            message: "Ocurrió un error al hacer la petición",
            type: "error",
          });
        });
      this.loadingQuery.close();
    },
  },
};
</script>

<style scoped>
.login {
  position: relative;
  right: 0;
  width: 41%;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-direction: column;
  text-align: center;
}

.forgot-password {
  color: #003149;
  text-align: center;
}

.login-button {
  width: 100%;
  background: #bc0404;
}
.login-form {
  width: 290px;
  color: #003149;
}

.checkbox {
  width: 100%;
}

.border > input:focus {
  outline: 0;
  border-color: white !important;
}
</style>
