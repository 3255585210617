<template>
  <el-dialog
    v-model="show"
    title="Editar Tarifa"
    width="40%"
    :before-close="handleClose"
    center
  >
    <span>
      <el-form
        label-position="top"
        ref="model"
        :model="model"
        :rules="rules"
        :loading="loading"
      >
        <SelectDepartmentsCities
          v-if="city == undefined"
          ref="departmentCities"
        />

        <el-row>
          <el-col :span="20">
            <SelectSupply ref="selectSupply" v-model="model.supply_id" />
          </el-col>
          <el-col
            :span="4"
            v-if="hasPermits('supplies.create')"
            class="supply-add"
          >
            <CreateSupply />
          </el-col>
        </el-row>

        <el-form-item label="Tarifa" prop="price">
          <el-input type="number" v-model="model.price"></el-input>
        </el-form-item>
      </el-form>
    </span>

    <template #footer>
      <span class="dialog-footer">
        <el-button type="primary" @click="updatePrice('model')"
          >Actualizar Tarifa</el-button
        >
        <el-button @click="show = false">Cancelar</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { ElMessage } from "element-plus";
import { ElMessageBox } from "element-plus";
import { hasPermits } from "@/config/Auth.js";
import SelectSupply from "../supplies/SelectSupply.vue";
import SelectDepartmentsCities from "../../components/SelectDepartmensCities.vue";
import CreateSupply from "../../components/supplies/CreateSupply.vue";
export default {
  name: "EditPrice",
  props: {
    city: Number,
  },
  components: { SelectDepartmentsCities, CreateSupply, SelectSupply },
  setup() {
    return {
      hasPermits,
    };
  },
  mounted() {
    this.emitter.off("open-modal-edit-price");
    this.emitter.on("open-modal-edit-price", (price) => {
      this.beforeOpenForm("model");
      this.show = true;
      this.model = price;
      this.reset = true;
    });
  },
  updated() {
    if (this.reset) {
      if (this.city == undefined) {
        this.$refs.departmentCities.selectCity(this.model.city_id);
      }

      if (this.model.supply !== undefined) {
        this.$refs.selectSupply.setInformation(this.model.supply.category_id, {
          name: this.model.supply.name,
          id: this.model.supply_id,
        });
      }
    }

    this.reset = false;
  },
  data() {
    return {
      reset: false,
      loading: false,
      show: false,
      supplies: [],
      price_id: "",
      model: {
        contract_id: "",
        supply_id: "",
        price: "",
      },
      rules: {
        supply_id: [
          {
            required: true,
            message: "Por favor selecciona un insumo",
            trigger: "change",
          },
        ],
        price: [
          {
            required: true,
            message: "Por favor agrega un tarifa",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    handleClose(done) {
      ElMessageBox.confirm(
        "¿Estas seguro que deseas salir del formulario de contacto?",
        {
          showCancelButton: true,
          confirmButtonText: "Confirmar",
          cancelButtonText: "Cancelar",
        }
      )
        .then((res) => {
          if (res == "confirm") {
            this.show = false;
            done();
          }
        })
        .catch(() => {}); // this action is call when user click in cancel button
    },
    updatePrice(model) {
      this.loading = true;
      let selectCity =
        this.city != undefined ||
        this.$refs.departmentCities.validateForm(model);
      let selectSupply = this.$refs.selectSupply.validateForm();
      if (this.validateForm("model") && selectCity && selectSupply) {
        this.model.city_id = this.city;
        if (this.city == undefined) {
          this.model.city_id = this.$refs.departmentCities.model.city_id;
        }

        this.model.supply_id = this.$refs.selectSupply.model.supply_id;

        let newModel = Object.assign({}, this.model);
        let supplyName = {
          name: this.$refs.selectSupply.getName(),
          category_id: this.$refs.selectSupply.model.category_id,
        };

        Object.defineProperty(newModel, "supply", {
          value: supplyName,
          configurable: true,
          writable: true,
        });

        if (this.city == undefined) {
          let cityName = {
            name: this.$refs.departmentCities.selectedItem(),
          };
          Object.defineProperty(newModel, "city", {
            value: cityName,
            configurable: true,
            writable: true,
          });
        }
        this.emitter.emit("update-price", newModel);
        this.show = false;
      } else {
        ElMessage.error(
          "¡ Error !, Por favor verifica que todos los campos esten llenos"
        );
        this.loading = false;
      }
      this.beforeOpenForm("model");
    },

    validateForm(formName) {
      let res;
      this.$refs[formName].validate((valid) => {
        res = valid;
      });
      return res;
    },

    beforeOpenForm(formName) {
      let form = this.$refs[formName];
      if (form) {
        form.resetFields();
      }
      this.model = {
        contract_id: "",
        suply_id: "",
        price: "",
      };
    },
  },
};
</script>

<style scoped>
.supply-add {
  display: flex;
  align-content: space-between;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
}
</style>
