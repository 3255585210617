<template>
  <el-container class="container">
    <UploadPayment />
    <el-row>
      <el-col class="paginate" :span="24">
        <div style="margin-top: 15px; margin-bottom: 15px">
          <el-button
            icon="el-icon-arrow-left"
            @click="prev"
            :disabled="prevEnable"
          >
          </el-button>
          <label style="margin: 0 15px 0 15px"> {{ page }} </label>
          <el-button
            icon="el-icon-arrow-right"
            @click="next"
            :disabled="nextEnable"
          >
          </el-button>
        </div>
      </el-col>
      <el-col :span="24" class="animate__animated animate__fadeInRight">
        <el-table
          v-loading="loading"
          :data="tableData"
          :empty-text="'No hay registros'"
        >
          <el-table-column prop="consecutive" label="ID" min-width="45" />
          <el-table-column prop="user_name" label="Usuario" min-width="85" />
          <el-table-column prop="concept" label="Concepto" min-width="90" />
          <el-table-column label="Valor" min-width="80">
            <template #default="scope">
              {{ toCurrency(parseInt(scope.row.amount)) }}
            </template>
          </el-table-column>
          <el-table-column label="Fechas" align="center" min-width="250">
            <el-table-column label="Creación" min-width="100">
              <template #default="scope">
                {{ formatDateAndHour(scope.row.created_at) }}
              </template>
            </el-table-column>
            <el-table-column
              prop="expiration_date"
              label="Vencimiento"
              min-width="105"
            />
            <el-table-column label="Aprobación compras" min-width="105">
              <template #default="scope">
                {{
                  showThisData(
                    formatDateAndHour(scope.row.accept_advance_seller_date),
                    "No aplica"
                  )
                }}
              </template>
            </el-table-column>
            <el-table-column label="Aprobación gerencia" min-width="105">
              <template #default="scope">
                {{
                  showThisData(
                    formatDateAndHour(scope.row.accept_advance_admin_date),
                    "No aplica"
                  )
                }}
              </template>
            </el-table-column>
            <el-table-column label="Carga comprobante" min-width="115">
              <template #default="scope">
                {{
                  showThisData(
                    formatDateAndHour(scope.row.upload_payment_date),
                    "No aplica"
                  )
                }}
              </template>
            </el-table-column>
            <el-table-column label="Carga factura" min-width="115">
              <template #default="scope">
                {{
                  showThisData(
                    formatDateAndHour(scope.row.invoice_upload_date),
                    "No aplica"
                  )
                }}
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column
            prop="observations"
            label="Observaciones"
            min-width="120"
          />
          <el-table-column label="Estado" min-width="95">
            <template #default="scope">
              {{
                scope.row.status === "autorizado vendedor"
                  ? "Aprobado compras"
                  : scope.row.status === "autorizado administrador"
                  ? "Aprobado gerencia "
                  : scope.row.status
              }}
            </template>
          </el-table-column>
          <el-table-column label="Proveedor" min-width="100">
            <template #default="scope">
              {{ scope.row.provider }}
            </template>
          </el-table-column>
          <el-table-column label="Acciones" align="center" min-width="135">
            <template #default="scope">
              <el-dropdown>
                <span class="el-dropdown-link">
                  <i class="el-icon-more"></i>
                </span>
                <template #dropdown>
                  <el-dropdown-menu>
                    <el-dropdown-item
                      v-if="
                        hasPermits('events.register_payment') &&
                        scope.row.status === 'autorizado administrador'
                      "
                      @click="
                        uploadPayment({
                          id: scope.row.id,
                          operation: 'uploadPayment',
                        })
                      "
                    >
                      <ion-icon
                        title=""
                        name="cloud-upload-outline"
                        class="icon-upload"
                      ></ion-icon>
                      Cargar comprobante de pago
                    </el-dropdown-item>

                    <el-dropdown-item
                      v-if="
                        hasPermits('events.update_payment') &&
                        scope.row.status === 'pagado'
                      "
                      @click="
                        uploadPayment({
                          id: scope.row.id,
                          operation: 'updatePayment',
                        })
                      "
                    >
                      <ion-icon
                        title=""
                        name="cloud-upload-outline"
                        class="icon-upload"
                      ></ion-icon>
                      Actualizar comprobante de pago
                    </el-dropdown-item>

                    <el-dropdown-item
                      v-if="
                        hasPermits('events.show') &&
                        scope.row.status === 'pagado'
                      "
                      @click="getDocument(scope.row.document_id)"
                    >
                      <ion-icon
                        title=""
                        name="glasses-outline"
                        class="icon-see"
                      ></ion-icon>
                      Ver comprobante de pago
                    </el-dropdown-item>

                    <el-dropdown-item
                      v-if="
                        hasPermits('events.upload_invoice') &&
                        scope.row.status === 'pagado' &&
                        scope.row.invoice_document_id === null
                      "
                      @click="
                        uploadPayment({
                          id: scope.row.id,
                          operation: 'uploadInvoice',
                        })
                      "
                    >
                      <ion-icon
                        title=""
                        name="cloud-upload-outline"
                        class="icon-invoice"
                      ></ion-icon>
                      Cargar factura
                    </el-dropdown-item>

                    <el-dropdown-item
                      v-if="
                        hasPermits('events.update_payment') &&
                        scope.row.invoice_document_id !== null
                      "
                      @click="
                        uploadPayment({
                          id: scope.row.id,
                          operation: 'updateInvoice',
                        })
                      "
                    >
                      <ion-icon
                        title=""
                        name="cloud-upload-outline"
                        class="icon-invoice"
                      ></ion-icon>
                      Actualizar factura
                    </el-dropdown-item>

                    <el-dropdown-item
                      v-if="
                        hasPermits('events.show') &&
                        scope.row.invoice_document_id !== null
                      "
                      @click="getDocument(scope.row.invoice_document_id)"
                    >
                      <ion-icon
                        title=""
                        name="glasses-outline"
                        class="icon-see-invoice"
                      ></ion-icon>
                      Ver factura
                    </el-dropdown-item>

                    <el-dropdown-item
                      v-if="
                        hasPermits('events.approve_advance_sales') &&
                        scope.row.status === 'solicitado'
                      "
                      @click="
                        acceptAdvance(
                          this.idEvent,
                          scope.row.id,
                          'acceptseller'
                        )
                      "
                    >
                      <ion-icon
                        title=""
                        name="checkmark-circle-outline"
                        class="icon-accept"
                      ></ion-icon>
                      Aprobar pago compras
                    </el-dropdown-item>

                    <el-dropdown-item
                      v-if="
                        hasPermits('events.approve_advance_admin') &&
                        scope.row.status === 'autorizado vendedor'
                      "
                      @click="
                        acceptAdvance(this.idEvent, scope.row.id, 'acceptadmin')
                      "
                    >
                      <ion-icon
                        title=""
                        name="checkmark-circle-outline"
                        class="icon-accept"
                      ></ion-icon>
                      Aprobar pago gerencia
                    </el-dropdown-item>

                    <el-dropdown-item
                      v-if="hasPermits('payment_event.edit_payment')"
                      @click="openForm(scope.row.id)"
                    >
                      <i class="el-icon-edit properties"></i>
                      Editar
                    </el-dropdown-item>

                    <el-dropdown-item>
                      <Delete
                        v-if="hasPermits('payment_event.delete_payment')"
                        :endpoint="`api/v1/events/${this.idEvent}/advances/${scope.row.id}`"
                        event="reload-list-of-advances"
                        :key="scope.row.id"
                        option="true"
                      >
                        Eliminar
                      </Delete>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
  </el-container>
</template>

<script>
import { getData, putData, getRawData } from "@/request/request.js";
import { ElLoading, ElMessageBox, ElNotification } from "element-plus";
import { toCurrency } from "@/config/Money.js";
import Delete from "../../Delete.vue";
import { formatDateAndHour } from "../../../config/Date.js";
import { hasPermits } from "@/config/Auth.js";
import UploadPayment from "../../documents/UploadPayment.vue";
import { showThisData } from "../../../config/Helper.js";

export default {
  name: "AdvanceList",
  props: {
    idEvent: Number,
  },
  components: { Delete, UploadPayment },
  setup() {
    return {
      toCurrency,
      formatDateAndHour,
      hasPermits,
      showThisData,
    };
  },
  data() {
    return {
      tableData: [],
      loading: false,
      loadingPage: false,
      prevEnable: true,
      nextEnable: false,
      page: 1,
      advanceID: "",
      rol: {
        acceptseller: "aprobado compras",
        acceptadmin: "aprobado gerencia",
      },
    };
  },
  mounted() {
    this.emitter.off("reload-list-of-advances");
    this.emitter.on("reload-list-of-advances", () => {
      this.page = 1;
      this.getAdvances();
      this.emitter.emit("reload-list-of-notes");
    });
    this.getAdvances();
  },
  methods: {
    async getAdvances() {
      this.loading = true;
      await getData("api/v1/events/" + this.idEvent + "/advances", {
        page: this.page,
      })
        .then((data) => {
          if (data.error == true) {
            ElNotification({
              title: "Error",
              message:
                "Ocurrió un error al solicitar los anticipos de este evento",
              type: "error",
            });
          } else {
            this.tableData = data.data;
            this.page = data.current_page;
            this.prevEnable = data.prev_page_url == null;
            this.nextEnable = data.next_page_url == null;
          }
        })
        .catch(() => {
          ElNotification({
            title: "Error",
            message: "Ocurrió un error al hacer la petición",
            type: "error",
          });
        });
      this.loading = false;
    },
    prev() {
      this.page--;
      this.getAdvances();
    },
    next() {
      this.page++;
      this.getAdvances();
    },
    openForm(id) {
      this.emitter.emit("open-modal-edit-advance", id);
    },
    async acceptAdvance(eventID, advanceID, rolType) {
      ElMessageBox.confirm(
        "¿Seguro desea cambiar el estado del pago a " + this.rol[rolType] + "?",
        "Advertencia",
        {
          confirmButtonText: "Si",
          cancelButtonText: "No",
          type: "warning",
        }
      )
        .then(() => {
          this.loading = true;
          putData(`api/v1/events/${eventID}/advances/${advanceID}/${rolType}`)
            .then((res) => {
              if (res.error == true || res.error == undefined) {
                ElNotification({
                  title: "Error",
                  message: res.message,
                  type: "error",
                });

                return;
              }
              ElNotification({
                title: "Pago actualizado",
                message: res.message,
                type: "success",
              });
              this.getAdvances();
              this.emitter.emit("reload-list-of-notes");
            })
            .catch(() => {
              ElNotification({
                title: "Error",
                message: "Ocurrió un error al hacer la petición",
                type: "error",
              });
            });
        })
        .catch(() => {});
    },

    uploadPayment(pay) {
      this.emitter.emit("advance-upload-payment", pay);
    },

    async getDocument(documentID) {
      this.loadingPage = ElLoading.service();
      await getRawData(`api/v1/documents/getUrl/${documentID}`)
        .then((data) => {
          if (data === "") {
            ElNotification({
              title: "Error",
              message: "Ocurrió un problema al solicitar el documento",
              type: "error",
            });
          }
          window.open(data, "_blank");
        })
        .catch(() => {
          ElNotification({
            title: "Error",
            message: "Ocurrió un error al hacer la petición",
            type: "error",
          });
        });
      this.loadingPage.close();
    },
  },
};
</script>
<style scoped>
.container {
  width: -webkit-fill-available;
  height: fit-content !important;
}

.el-table {
  border-radius: 10px;
}

.el-icon-view {
  color: var(--blue);
  margin-right: 5px;
  font-size: 19px;
  cursor: pointer;
}

.paginate {
  margin: auto;
  text-align: end;
}

.el-row {
  width: inherit;
}

.el-button {
  background: var(--blue);
  color: white;
}

.is-disabled {
  background: #8080809c;
}

.is-disabled:hover {
  background: #8080809c;
}

::v-deep(.el-table thead) {
  color: var(--blue) !important;
}

.el-select {
  background: var(--blue);
  color: white;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.el-select-dropdown__item.selected {
  color: var(--blue);
}
.properties {
  color: darkorange;
  margin: 0 10px 0 10px;
  font-size: 20px;
}

.icon-accept {
  color: rgb(2, 138, 13);
  margin: 0 10px 0 10px;
  font-size: 20px;
  cursor: pointer;
}

.icon-upload {
  color: rgb(22, 52, 219);
  margin: 0 10px 0 10px;
  font-size: 20px;
  cursor: pointer;
}

.icon-invoice {
  color: rgb(2, 138, 13);
  margin: 0 10px 0 10px;
  font-size: 20px;
  cursor: pointer;
}

.icon-see {
  color: rgb(22, 52, 219);
  margin: 0 10px 0 10px;
  font-size: 20px;
  cursor: pointer;
}

.icon-see-invoice {
  color: rgb(2, 138, 13);
  margin: 0 10px 0 10px;
  font-size: 20px;
  cursor: pointer;
}

ion-icon {
  color: darkorange;
  margin: 0 10px 0 10px;
  font-size: 20px;
  cursor: pointer;
}

::v-deep(.ionicon) {
  pointer-events: none !important;
}

.el-select {
  background: var(--blue);
  color: white;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.el-select-dropdown__item.selected {
  color: var(--blue);
}

.icon,
i {
  cursor: pointer;
  font-size: 28px;
}

.items {
  display: inline;
}

.el-dropdown-menu__item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
</style>
