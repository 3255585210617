<template>
  <el-container v-loading="loadingInformation">
    <br /><br />
    <edit-event />
    <el-header>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/events' }"
          >Eventos
        </el-breadcrumb-item>
        <el-breadcrumb-item>Administrar evento </el-breadcrumb-item>
      </el-breadcrumb>
    </el-header>
    <div class="info-event" v-if="dataIsReady">
      <span><b>ID evento:</b> {{ event.consecutive }}</span>
      <span
        ><b>Ubicación:</b> {{ event.city.name }} -
        {{ event.city.departament.name }}
      </span>
      <span
        ><b>Inicio:</b>
        {{ formatDateAndHour(event.start_date) }}
      </span>
      <span><b>Fin:</b> {{ formatDateAndHour(event.finish_date) }} </span>
      <span><b>Estado:</b> {{ event.status.name }} </span>
    </div>
    <el-main>
      <div v-if="dataIsReady" class="header-icons">
        <el-tooltip
          content="Descargar recibo a satisfacción"
          placement="top"
          effect="light"
        >
          <i
            @click="
              downloadData(
                'api/v1/pdfs/receipt-to-satisfaction/' + event.id,
                {},
                'Recibo a satisfacción.pdf'
              )
            "
            class="fa-solid fa-file-circle-check icon-receipt"
          ></i>
        </el-tooltip>
        <el-tooltip
          v-if="hasPermits('events.download_final_internal_budget')"
          content="Descargar presupuesto interno final"
          placement="top"
          effect="light"
        >
          <i
            @click="
              downloadDataNew(
                `api/v1/pdfs/final-internal-budget/${this.event.id}`,
                {},
                'Presupuesto interno final.pdf'
              )
            "
            class="fa-solid fa-money-check-dollar icon-budget"
          ></i>
        </el-tooltip>
      </div>
      <el-tabs v-model="activeName">
        <el-tab-pane
          label="Datos"
          name="data"
          class="animate__animated animate__backInDown static-tabs"
        >
          <template v-if="dataIsReady">
            <show-event :event="event">
              <div class="options">
                <el-tooltip
                  v-if="hasPermits('events.edit')"
                  content="Editar"
                  placement="top"
                  effect="light"
                >
                  <i
                    class="el-icon-edit properties"
                    @click="edit(event.id)"
                  ></i>
                </el-tooltip>
              </div>
            </show-event>
          </template>
        </el-tab-pane>
        <el-tab-pane class="static-tabs" label="Consumos" name="usages">
          <el-row>
            <el-col :span="24" v-if="dataIsReady">
              <ListUsages :event="event">
                <el-tooltip
                  content="Descargar presupuesto"
                  placement="top"
                  effect="light"
                >
                  <i
                    @click="
                      downloadData(
                        'api/v1/pdfs/costs/' + event.id,
                        {},
                        'Presupuesto.pdf'
                      )
                    "
                    class="fa-regular fa-rectangle-list icon-budget"
                  ></i>
                </el-tooltip>
              </ListUsages>
              <br />
              <EditBudgetObservation :event="event" />
            </el-col>
          </el-row>
        </el-tab-pane>
        <el-tab-pane class="static-tabs" label="Tarifas" name="prices">
          <template v-if="dataIsReady">
            <Price :endpoint="pricesEndPoint" :city="event.city_id" />
          </template>
        </el-tab-pane>
        <el-tab-pane class="static-tabs" label="Cotizaciones" name="quotations">
          <template v-if="dataIsReady">
            <QuotationList :event="event" />
          </template>
        </el-tab-pane>
        <el-tab-pane class="static-tabs" label="Pagos" name="advances">
          <template v-if="dataIsReady">
            <CreateAdvance :idEvent="event.id" />
            <EditAdvance :idEvent="event.id" />
            <AdvanceList :idEvent="event.id" />
          </template>
        </el-tab-pane>
        <el-tab-pane class="static-tabs" label="Bitácoras" name="logs">
          <template v-if="dataIsReady">
            <NotesCreate :idEvent="event.id" />
            <NotesList :event="event" />
          </template>
        </el-tab-pane>
        <el-tab-pane class="static-tabs" label="Documentos" name="documents">
          <el-cotainer>
            <el-row>
              <el-col
                :span="1"
                :offset="23"
                align="right"
                v-if="dataIsReady"
                class="animate__animated animate__fadeInRight"
              >
                <template v-if="!editDocuments">
                  <el-tooltip content="Editar" placement="top" effect="light">
                    <i
                      @click="editDocuments = !editDocuments"
                      class="el-icon-edit icon"
                    ></i>
                  </el-tooltip>
                </template>
                <template v-else>
                  <el-tooltip
                    v-if="hasPermits('documents.show_file')"
                    content="Ver"
                    placement="top"
                    effect="light"
                  >
                    <i
                      @click="editDocuments = !editDocuments"
                      class="el-icon-view icon"
                    ></i>
                  </el-tooltip>
                </template>
                <br />
              </el-col>
              <el-col :span="24" :offset="0" align="center" v-if="dataIsReady">
                <EditDocument
                  v-if="editDocuments"
                  :endpoint="documentsEndpoint"
                  deletePermit="delete_file_event"
                />
                <template v-else>
                  <div
                    v-if="event.documents.length !== 0"
                    class="container_documents"
                    width="100%"
                  >
                    <DocumentCard
                      v-for="document in event.documents"
                      :key="document.id"
                      :document="{
                        id: document.id,
                        type: document.document_type.name,
                        description: document.description,
                        created_at: document.created_at,
                        user_name: document.user_name,
                      }"
                      size="33.33"
                    />
                  </div>
                  <h3 v-else>No hay registros.</h3>
                </template>
              </el-col>
            </el-row>
          </el-cotainer>
        </el-tab-pane>
      </el-tabs>
    </el-main>
  </el-container>
</template>
<script>
import ListUsages from "../Usages/ListUsages.vue";
import {
  getData,
  downloadData,
  downloadDataNew,
} from "../../request/request.js";
import { ElNotification } from "element-plus";
import EditDocument from "../documents/EditDocument.vue";
import DocumentCard from "../documents/DocumentCard.vue";
import { hasPermits } from "@/config/Auth.js";
import QuotationList from "./QuotationList.vue";
import CreateAdvance from "./advance/CreateAdvance.vue";
import AdvanceList from "./advance/AdvanceList.vue";
import EditAdvance from "./advance/EditAdvance.vue";
import NotesList from "./NotesList.vue";
import EditEvent from "./EditEvent.vue";
import NotesCreate from "./NotesCreate.vue";
import EditBudgetObservation from "../events/EditBudgetObservation.vue";
import { formatDateAndHour } from "../../config/Date.js";
import Price from "../../views/Price.vue";

export default {
  components: {
    EditDocument,
    DocumentCard,
    QuotationList,
    CreateAdvance,
    AdvanceList,
    EditAdvance,
    NotesList,
    ListUsages,
    EditEvent,
    NotesCreate,
    EditBudgetObservation,
    Price,
  },
  name: "ManageEvent",

  setup() {
    return {
      hasPermits,
      downloadData,
      formatDateAndHour,
      downloadDataNew,
    };
  },
  beforeCreate() {
    this.$options.components.ShowEvent = require("./ShowEvent.vue").default;
  },
  data() {
    return {
      activeName: "data",
      dataIsReady: false,
      loadingInformation: true,
      contract_id: "",
      documentsEndpoint: "api/v1/documents/events/" + this.getIdFromParams(),
      editDocuments: false,
      event: {},
    };
  },
  mounted() {
    this.getEvent();

    this.emitter.off("document-update-success");
    this.emitter.on("document-update-success", () => {
      this.editDocuments = false;
      this.getEvent();
    });
    this.emitter.off("reload-list-of-events");
    this.emitter.on("reload-list-of-events", () => {
      this.getEvent();
    });
  },
  methods: {
    getEvent() {
      this.dataIsReady = false;
      this.loadingInformation = true;
      getData("api/v1/events/" + this.getIdFromParams())
        .then((res) => {
          if (res.error == true || res.error == undefined) {
            ElNotification({
              title: "Error",
              message: res.message,
              type: "error",
            });

            return;
          }
          this.pricesEndPoint = "api/v1/prices/" + res.data.contract_id;
          this.event = res.data;
          this.contract_id = res.data.contract_id;
          this.loadingInformation = false;
          this.dataIsReady = true;
        })
        .catch(() => {
          ElNotification({
            title: "Error",
            message: "Ocurrió un error al hacer la petición",
            type: "error",
          });
        });
    },
    getIdFromParams() {
      return this.$route.params.id;
    },
    edit(id) {
      this.emitter.emit("open-modal-edit-event", id);
    },
  },
};
</script>
<style scoped>
.el-header {
  --el-header-height: 30px;
}

.icon {
  cursor: pointer;
  font-size: 28px;
}

.icon-budget {
  color: var(--blue);
  cursor: pointer;
  font-size: 20px;
  margin-left: 5px;
}
.items {
  display: inline;
}

.el-icon-edit {
  color: darkorange;
  font-size: 20px;
  cursor: pointer;
}

.icon-receipt {
  font-size: 20px;
  cursor: pointer;
}

.info-event {
  margin: 0 1.1%;
  padding: 5px;
  border-radius: 5px;
  font-size: 15px;
}

.info-event span {
  margin: 2px;
  margin-right: 10px;
  padding: 1px;
}

.static-tabs {
  border-radius: 5px;
  height: 74vh;
  overflow-y: auto;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 10px;
  height: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: rgb(163, 163, 163);
  border-radius: 5px;
}

.el-main {
  margin-top: -20px;
}

.container_documents {
  display: flex;
  flex-wrap: wrap;
}

.options {
  display: flex;
  float: right;
}

.header-icons {
  text-align: end;
  margin: 0px;
  padding: 0px;
}

.header-icons i {
  padding: 2px;
  /* margin: 0 10px; */
}
</style>
