<template>
  <div>
    <el-popover
      placement="right-start"
      title="Agregar concepto"
      :width="200"
      trigger="hover"
      content="Abrir formulario para agregar un concepto"
    >
      <template #reference>
        <el-button class="btn-plus" type="text" @click="dialogVisible = true"
          ><i class="el-icon-plus"></i
        ></el-button>
      </template>
    </el-popover>
    <el-dialog v-model="dialogVisible" width="65%" title="Seleccionar concepto">
      <SelectSupply ref="selectSupply" v-model="model.supply_id" />

      <el-form label-position="top" ref="model" :model="model" :rules="rules">
        <el-row>
          <el-col :span="6">
            <el-form-item label="Cantidad" prop="amount">
              <el-input type="number" v-model="model.amount"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="3" />
          <el-col :span="6">
            <el-form-item label="Días" prop="days">
              <el-input type="number" v-model="model.days"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="3" />
          <el-col :span="6">
            <el-form-item label="IVA (%)" prop="iva">
              <el-input type="number" v-model="model.iva"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="11">
            <el-form-item label="Valor unitario cliente">
              <el-input
                type="number"
                v-model="model.unit_price_client"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="2" />
          <el-col :span="11">
            <el-form-item
              label="Valor unitario interno"
              prop="unit_price_provider"
            >
              <el-input
                type="number"
                v-model="model.unit_price_provider"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="Descripción" prop="description">
          <el-input type="textarea" v-model="model.description"></el-input>
        </el-form-item>
      </el-form>

      <br />
      <div class="button" align="center">
        <el-button type="primary" @click="getDetails()"
          >Cargar concepto</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import SelectSupply from "../supplies/SelectSupply.vue";
import { ElMessage } from "element-plus";

export default {
  name: "SelectSupplyDetails",
  props: { event: String },
  components: { SelectSupply },
  data() {
    return {
      loading: false,
      dialogVisible: false,
      model: {
        supply_id: "",
        description: "",
        amount: "",
        unit_price_client: "",
        days: "",
        unit_price_provider: "",
        iva: "",
      },
      rules: {
        supply_id: {
          required: true,
          message: "Por favor selecciona un insumo",
          trigger: "change",
        },
        description: {
          required: true,
          message: "Por favor ingresa un descripción",
          trigger: "change",
        },
        amount: {
          required: true,
          message: "Por favor ingresa una cantidad",
          trigger: "change",
        },
        days: {
          required: true,
          message: "Por favor ingresa la cantidad de días",
          trigger: "blur",
        },
        unit_price_provider: {
          required: true,
          message: "Por favor ingresa el valor unitario interno",
          trigger: "blur",
        },
        iva: {
          required: true,
          message: "Por favor ingresa el IVA",
          trigger: "blur",
        },
      },
    };
  },
  mounted() {},
  methods: {
    cleanForm() {
      this.$refs["model"].resetFields();
      this.$refs.selectSupply.cleanForm();
      this.model = {
        supply_id: "",
        description: "",
        amount: "",
        unit_price_client: "",
        days: "",
        unit_price_provider: "",
        iva: "",
      };
    },
    validateForm() {
      let res;
      this.$refs["model"].validate((valid) => {
        res = valid;
      });
      return res;
    },
    getDetails() {
      if (!this.validateForm()) {
        ElMessage.error("Ingrese los datos requeridos.");
        return;
      }

      this.model.supply_id = this.$refs.selectSupply.model.supply_id;
      this.dialogVisible = false;

      this.emitter.emit(this.event, {
        description: this.model.description,
        amount: this.model.amount,
        unit_price_client: this.model.unit_price_client,
        days: this.model.days,
        unit_price_provider: this.model.unit_price_provider,
        iva: this.model.iva,
        supply: {
          id: this.$refs.selectSupply.model.supply_id,
          name: this.$refs.selectSupply.getName(),
        },
      });
      this.cleanForm();
      this.loading = false;
      this.dialogVisible = false;
    },
  },
};
</script>

<style scoped></style>
