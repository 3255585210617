<template>
  <div>
    <el-popover
      placement="right-start"
      title="Crear cliente"
      :width="200"
      trigger="hover"
      content="Abrir formulario para agregar un cliente"
    >
      <template #reference>
        <el-button class="btn-plus" type="text" @click="openModal()"
          ><i class="el-icon-plus add-icon"></i
        ></el-button>
      </template>
    </el-popover>

    <el-dialog
      v-model="dialogVisible"
      width="55%"
      :before-close="handleClose"
      title="Crear cliente"
    >
      <!-- steps -->
      <span>
        <el-steps :active="active" finish-status="success" simple>
          <el-step title="Cliente" icon="el-icon-circle-plus"> </el-step>
          <el-step title="Contactos" icon="el-icon-user"></el-step>
          <el-step title="Documentos" icon="el-icon-document-add"></el-step>
        </el-steps>
      </span>

      <el-row>
        <el-col>
          <div class="content">
            <!-- step 1 -->
            <div v-if="active == 0">
              <el-row>
                <el-col :span="16" :offset="4">
                  <el-form
                    ref="model"
                    label-position="top"
                    :model="model"
                    :rules="rules"
                  >
                    <el-form-item
                      label="Tipo de identifición"
                      prop="identification_type_id"
                    >
                      <SelectIdentification
                        v-model="model.identification_type_id"
                      />
                    </el-form-item>

                    <el-form-item label="Identificación" prop="identification">
                      <el-input v-model="model.identification"></el-input>
                    </el-form-item>
                    <el-form-item label="Nombre Completo" prop="full_name">
                      <el-input v-model="model.full_name"></el-input>
                    </el-form-item>
                    <el-form-item label="Razón Social" prop="company_name">
                      <el-input v-model="model.company_name"></el-input>
                    </el-form-item>
                    <el-form-item label="Tipo de cliente" prop="type_id">
                      <SelectClientType v-model="model.type_id" />
                    </el-form-item>

                    <el-form-item label="Dirección" prop="address">
                      <el-input v-model="model.address"></el-input>
                    </el-form-item>
                    <el-form-item label="Teléfono" prop="phone">
                      <el-input v-model="model.phone"></el-input>
                    </el-form-item>
                    <el-form-item label="Correo" prop="email">
                      <el-input v-model="model.email"></el-input>
                    </el-form-item>

                    <SelectDepartmentCity ref="departmentCities" />
                  </el-form>
                  <div class="button" align="center">
                    <el-button type="primary" @click="saveClient('model')"
                      >Crear Cliente</el-button
                    >
                  </div>
                </el-col>
              </el-row>
            </div>
            <!-- step 2 -->
            <div v-if="active == 1" align="left">
              <Contacts ref="contacts" :endpoint="contactsEndPoint" />
              <div id="boton" justify="end" align="center">
                <br />
                <el-button id="steps-button" @click="next" type="success"
                  >Agregar documentos</el-button
                >
              </div>
            </div>

            <!-- step 3 -->
            <div v-if="active == 2 && hasPermits('clients.upload_file')">
              <CreateDocument :endpoint="documentsEndpoint" />
            </div>
          </div>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import SelectClientType from "./SelectClientType.vue";
import SelectIdentification from "../SelectIdentification.vue";
import Contacts from "../../views/Contacts.vue";
import { defineComponent } from "vue";
import { ElMessageBox } from "element-plus";
import { ElMessage } from "element-plus";
import { hasPermits } from "@/config/Auth.js";
import { ElNotification, ElLoading } from "element-plus";
import { postData } from "../../request/request.js";
import SelectDepartmentCity from "../SelectDepartmensCities.vue";
export default defineComponent({
  name: "CreateClient",
  components: {
    Contacts,
    SelectIdentification,
    SelectClientType,
    SelectDepartmentCity,
  },
  beforeCreate() {
    this.$options.components.CreateDocument =
      require("../documents/CreateDocument.vue").default;
  },
  mounted() {
    this.emitter.off("save-contacts-success");
    this.emitter.on("save-contacts-success", () => {
      this.next();
    });
    this.emitter.off("save-documents-success");
    this.emitter.on("save-documents-success", () => {
      this.active = 0;
      this.cleanForm();
      this.dialogVisible = false;
    });
  },
  setup() {
    const handleClose = (done) => {
      ElMessageBox.confirm(
        "¿Está Seguro que quiere salir de la creación de cliente?",
        {
          showCancelButton: true,
          confirmButtonText: "Confirmar",
          cancelButtonText: "Cancelar",
        }
      )
        .then(() => {
          done();
          this.cleanForm();
        })
        .catch(() => {
          // catch error
        });
    };
    return {
      handleClose,
      hasPermits,
    };
  },
  data() {
    return {
      dialogVisible: false,
      departments: [],
      cities: [],
      clientId: 0,
      model: {
        identification: "",
        identification_type_id: "",
        full_name: "",
        company_name: "",
        type_id: "",
        address: "",
        phone: "",
        email: "",
        city_id: "",
        depto: "",
      },
      active: 0,
      contactsEndPoint: "",
      documentsEndPoint: "",
      rules: {
        identification: [
          {
            required: true,
            message: "Por favor ingresa una identificación",
            trigger: "blur",
          },
        ],
        identification_type_id: [
          {
            required: true,
            message: "Por favor ingresa el tipo de identificación",
            trigger: "change",
          },
        ],
        company_name: [
          {
            required: true,
            message: "Por favor ingresa la razón social",
            trigger: "blur",
          },
        ],
        full_name: [
          {
            required: true,
            message: "Por favor ingresa un nombre",
            trigger: "blur",
          },
        ],
        type_id: [
          {
            required: true,
            message: "Por favor ingresa el tipo de cliente",
            trigger: "change",
          },
        ],
        email: [
          {
            required: true,
            message: "Por favor ingresa un correo",
            trigger: "blur",
          },
          {
            type: "email",
            message: "Por favor ingresa una dirección de correo válido",
            trigger: "blur",
          },
        ],
        address: [
          {
            required: true,
            message: "Por favor ingresa una dirección",
            trigger: "blur",
          },
        ],
        phone: [
          {
            required: true,
            message: "Por favor ingresa un número de teléfono",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    openModal() {
      this.dialogVisible = true;
      this.active = 0;
      this.cleanForm();
    },
    next() {
      if (this.active++ > 2) this.active = 0;
      this.contactsEndPoint = "api/v1/contacts/client/" + this.clientId;
      this.documentsEndpoint = "api/v1/documents/client/" + this.clientId;
    },
    back() {
      if (this.active-- < 0) this.active = 0;
    },
    validateForm(formName) {
      let res;
      this.$refs[formName].validate((valid) => {
        res = valid;
      });
      return res;
    },
    async saveClient(formName) {
      let validateCity = this.$refs.departmentCities.validateForm(formName);
      if (this.validateForm(formName) && validateCity) {
        this.model.city_id = this.$refs.departmentCities.model.city_id;
        let loading = ElLoading.service();
        await postData("api/v1/clients", this.model, true)
          .then((res) => {
            if (res.error == true || res.error == undefined) {
              ElNotification({
                title: "Error",
                message: res.message,
                type: "error",
              });
            } else {
              ElNotification({
                title: "Cliente registrado",
                message: res.message,
                type: "success",
              });

              this.clientId = res.data.id;
              this.next();

              this.emitter.emit("reload-list-of-clients");
            }
          })
          .catch(() => {
            ElNotification({
              title: "Error",
              message: "Ocurrió un error al hacer la petición",
              type: "error",
            });
          });
        loading.close();
      } else {
        ElMessage.error("¡ Error !, Por favor verifica todos los campos");
      }
    },

    cleanForm() {
      this.active = 0;
      let form = this.$refs["model"];
      if (form) {
        form.resetFields();
      }

      if (this.$refs.departmentCities) {
        this.$refs.departmentCities.cleanForm("model");
      }

      this.model.identification = "";
      this.model.identification_type_id = "";
      this.model.full_name = "";
      this.model.company_name = "";
      this.model.type_id = "";
      this.model.address = "";
      this.model.phone = "";
      this.model.email = "";
      this.model.city_id = "";
      this.model.depto = "";
      this.cities = [];
    },
  },
});
</script>

<style scoped>
.el-button--danger {
  background: #bc0304;
  border: none;
}
.add-icon {
  color: rgb(2, 138, 13);
}
.el-button--success {
  background: #003149;
  border: none;
}

.el-button--primary {
  background: var(--blue) !important;
  border: 1px solid var(--blue) !important;
}

.el-button--text {
  color: var(--blue) !important;
  font-weight: bold;
  font-size: 25px;
}
.content {
  margin-top: 30px;
}
.icon-step {
  width: 20px;
}

::v-deep(.el-dialog__body) {
  text-align: center;
}

::v-deep(.el-form--label-top .el-form-item__label) {
  padding: 0;
}
</style>
