<template>
  <el-dialog
    v-model="show"
    title="Información de contacto"
    width="40%"
    :before-close="handleClose"
  >
    <span>
      <el-form
        label-position="top"
        class="form-document"
        ref="form"
        :rules="rules"
        :model="model"
      >
        <el-form-item prop="full_name" label="Nombre completo">
          <el-input
            class="border"
            v-model="model.full_name"
            placeholder="Ingrese una nombre"
          ></el-input>
        </el-form-item>

        <el-form-item prop="email" label="Correo">
          <el-input
            class="border"
            v-model="model.email"
            placeholder="Ingrese una correo"
          ></el-input>
        </el-form-item>

        <el-form-item prop="phone" label="Teléfono">
          <el-input
            class="border"
            v-model="model.phone"
            placeholder="Ingrese una teléfono"
          ></el-input>
        </el-form-item>

        <el-form-item prop="area" label="Área">
          <el-input
            class="border"
            v-model="model.area"
            placeholder="Ingrese el área de la empresa"
          ></el-input>
        </el-form-item>

        <el-form-item prop="observations" label="Observaciones">
          <el-input
            class="border"
            v-model="model.observations"
            placeholder="Ingrese sus observaciones"
            type="textarea"
          ></el-input>
        </el-form-item>
      </el-form>
    </span>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="cancel">Cancelar</el-button>
        <el-button type="primary" @click="save">Guardar</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script>
import { ElMessageBox } from "element-plus";
export default {
  name: "FormDataDocument",
  data() {
    return {
      type: null,
      show: false,
      model: {},
      rules: {
        full_name: [
          {
            required: true,
            message: "Se debe ingresar un nombre",
            trigger: "blur",
          },
        ],
        email: [
          {
            type: "email",
            message: "Se debe ingresar un correo",
            trigger: "blur",
          },
        ],
        phone: [
          {
            required: true,
            message: "Debe de ingresar un teléfono",
            trigger: "blur",
          },
        ],
        area: [
          {
            required: true,
            message: "Se debe ingresar el área del contacto",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    handleClose(done) {
      ElMessageBox.confirm(
        "¿Estas seguro que deseas salir del formulario de contacto?",
        {
          showCancelButton: true,
          confirmButtonText: "Confirmar",
          cancelButtonText: "Cancelar",
        }
      )
        .then((res) => {
          if (res == "confirm") {
            this.show = false;
            done();
          }
        })
        .catch(() => {}); // this action is call when user click in cancel button
    },
    showModal(model) {
      let form = this.$refs.form;
      if (form) {
        form.resetFields();
      }
      this.model = model;
      this.show = true;
    },
    save() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.model.email === "") {
            delete this.model.email;
          }

          this.emitter.emit("save-contact-form", this.model);
          this.show = false;
        }
      });
    },
    cancel() {
      this.show = false;
    },
  },
};
</script>
<style scoped>
::v-deep(.el-form--label-top .el-form-item__label) {
  padding: 0;
}
</style>
