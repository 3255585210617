<template>
  <el-form ref="model" label-position="top" :model="model" :rules="rules">
    <el-form-item prop="provider_type_id" label="Tipo de proveedor">
      <el-select
        v-model="model.provider_type_id"
        placeholder="Selecciona un tipo de proveedor"
        style="width: 100%"
      >
        <el-option
          v-for="provider_type in provider_types"
          :key="provider_type.id"
          :label="provider_type.name"
          :value="provider_type.id"
        ></el-option>
        <el-option :value="1" label="Otro"></el-option>
      </el-select>
    </el-form-item>

    <el-form-item
      prop="other_provider_type"
      label="Otro tipo de proveedor"
      v-if="model.provider_type_id == 1"
    >
      <el-input v-model="model.other_provider_type"></el-input>
    </el-form-item>
  </el-form>
</template>
<script>
import { getData } from "../../request/request.js";
import { ElNotification } from "element-plus";

export default {
  name: "SelectProviderType",
  setup() {},
  data() {
    return {
      model: {
        provider_type_id: "",
        other_provider_type: "",
      },
      rules: {
        provider_type_id: [
          {
            required: true,
            message: "Por favor ingresa el tipo de proveedor",
            trigger: "change",
          },
        ],
        other_provider_type: [
          {
            min: 3,
            message:
              "El otro tipo de proveedor debe contener minimo 3 caracteres",
            trigger: "change",
          },
        ],
      },
      provider_types: [],
    };
  },
  mounted() {
    this.getProviderTypes();
  },
  methods: {
    async getProviderTypes() {
      await getData("api/v1/providerTypes", {}, true)
        .then((res) => {
          if (res.error == true || res.error == undefined) {
            ElNotification({
              title: "Error",
              message: res.message,
              type: "error",
            });
          } else {
            this.provider_types = res.data;
          }
        })
        .catch(() => {
          ElNotification({
            title: "Error",
            message: "Ocurrió un error al hacer la petición",
            type: "error",
          });
        });
    },
    validateForm() {
      if (this.model.provider_type_id === 1) {
        this.rules.other_provider_type.push({
          required: true,
          message: "Por favor ingresa el otro tipo de proveedor",
          trigger: "change",
        });
      } else {
        this.rules.other_provider_type = [
          {
            min: 3,
            message:
              "El otro tipo de proveedor debe contener minimo 3 caracteres",
            trigger: "blur",
          },
        ];
      }

      let res;
      this.$refs["model"].validate((valid) => {
        res = valid;
      });
      return res;
    },
    getInformation() {
      if (!this.validateForm()) {
        throw "Informacón de tipo de proveedor inválida";
      }

      return this.model;
    },
    setInformation(id,other){
      this.model.provider_type_id = id;
      this.model.other_provider_type = other;
    }
  },
};
</script>
