<template>
  <div>
    <el-popover
      placement="right-start"
      title="Crear proveedor"
      :width="200"
      trigger="hover"
      content="Abrir formulario para agregar un proveedor"
    >
      <template #reference>
        <el-button class="btn-plus" type="text" @click="openModal()"
          ><i class="el-icon-plus add"></i
        ></el-button>
      </template>
    </el-popover>

    <el-dialog
      v-model="dialogVisible"
      width="55%"
      :before-close="handleClose"
      title="Crear proveedor"
    >
      <!-- <el-icon><box /></el-icon> -->
      <!-- steps -->
      <span>
        <el-steps :active="step" finish-status="success" simple>
          <el-step title="Proveedor" icon="el-icon-box"> </el-step>
          <el-step title="Contactos" icon="el-icon-user"></el-step>
          <el-step title="Documentos" icon="el-icon-document-add"></el-step>
        </el-steps>
      </span>

      <el-row>
        <el-col>
          <div class="content">
            <!-- step 1 -->
            <div v-if="step == 0">
              <el-row>
                <el-col :span="16" :offset="4">
                  <el-form
                    ref="model"
                    label-position="top"
                    :model="model"
                    :rules="rules"
                  >
                    <el-form-item
                      label="Tipo de identifición"
                      prop="identification_type_id"
                    >
                      <SelectIdentification
                        v-model="model.identification_type_id"
                      />
                    </el-form-item>

                    <el-form-item label="Identificación" prop="identification">
                      <el-input v-model="model.identification"></el-input>
                    </el-form-item>

                    <el-form-item label="Nombre Completo" prop="full_name">
                      <el-input v-model="model.full_name"></el-input>
                    </el-form-item>

                    <SelectProviderType ref="provider_type" />

                    <el-form-item label="Dirección" prop="address">
                      <el-input v-model="model.address"></el-input>
                    </el-form-item>
                    <el-form-item label="Teléfono" prop="phone">
                      <el-input v-model="model.phone"></el-input>
                    </el-form-item>
                    <el-form-item label="Correo" prop="email">
                      <el-input v-model="model.email"></el-input>
                    </el-form-item>

                    <subsidiaries ref="subsidiaries" />

                    <h3 align="left">Información financiera</h3>
                    <el-form-item
                      label="Tipo de pago acordado"
                      prop="payment_type"
                    >
                      <el-input v-model="model.payment_type"></el-input>
                    </el-form-item>
                    <el-form-item
                      label="Número de cuenta"
                      prop="account_number"
                    >
                      <el-input v-model="model.account_number"></el-input>
                    </el-form-item>
                    <el-form-item label="Banco" prop="bank">
                      <select-bank v-model="model.bank" />
                    </el-form-item>
                    <el-form-item label="Tipo de cuenta" prop="account_type">
                      <el-select
                        v-model="model.account_type"
                        class="m-2"
                        style="width: 100%"
                        placeholder="Selecciona un tipo de cuenta"
                      >
                        <el-option
                          v-for="item in this.accountTypeOptions"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item
                      label="Nombre del beneficiario"
                      prop="beneficiary_name"
                    >
                      <el-input v-model="model.beneficiary_name"></el-input>
                    </el-form-item>
                    <el-form-item
                      label="Identificación del beneficiario"
                      prop="account_identification"
                    >
                      <el-input
                        v-model="model.account_identification"
                      ></el-input>
                    </el-form-item>
                    <el-form-item label="Retención" prop="retention">
                      <el-input-number
                        v-model="model.retention"
                        :precision="2"
                        :step="0.1"
                        :min="0"
                        :max="100"
                      />
                    </el-form-item>
                  </el-form>
                  <div class="button" align="center">
                    <el-button
                      :loading="loadingCreate"
                      type="primary"
                      @click="saveProvider('model')"
                      >Crear proveedor</el-button
                    >
                  </div>
                </el-col>
              </el-row>
            </div>
            <!-- step 2 -->
            <div v-if="step == 1" align="left">
              <Contacts ref="contacts" :endpoint="contactsEndPoint" />
              <div id="boton" justify="end" align="center">
                <br />
                <el-button id="steps-button" @click="next" type="success"
                  >Agregar documentos</el-button
                >
              </div>
            </div>

            <!-- step 3 -->
            <div v-if="step == 2 && hasPermits('documents.upload_file_provider')">
              <CreateDocument :endpoint="documentsEndpoint" />
            </div>
          </div>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import SelectIdentification from "../SelectIdentification.vue";
import Contacts from "../../views/Contacts.vue";
import SelectProviderType from "./SelectProviderType.vue";
import { defineComponent } from "vue";
import { ElMessageBox } from "element-plus";
import { ElMessage } from "element-plus";
import { hasPermits } from "@/config/Auth.js";
import { ElNotification } from "element-plus";
import { postData } from "../../request/request.js";
import Subsidiaries from "./Subsidiaries.vue";
import SelectBank from "./SelectBank.vue";
export default defineComponent({
  name: "CreateProvider",
  components: {
    Contacts,
    SelectProviderType,
    SelectIdentification,
    Subsidiaries,
    SelectBank,
  },
  beforeCreate() {
    this.$options.components.CreateDocument =
      require("../documents/CreateDocument.vue").default;
  },
  mounted() {
    this.emitter.off("save-contacts-success");
    this.emitter.on("save-contacts-success", () => {
      this.next();
    });
    this.emitter.off("save-documents-success");
    this.emitter.on("save-documents-success", () => {
      this.step = 0;
      this.dialogVisible = false;
    });
  },
  setup() {
    const handleClose = (done) => {
      ElMessageBox.confirm(
        "¿Está Seguro que quiere salir de la creación de proveedor?",
        {
          showCancelButton: true,
          confirmButtonText: "Confirmar",
          cancelButtonText: "Cancelar",
        }
      )
        .then(() => {
          done();
        })
        .catch(() => {
          // catch error
        });
    };
    return {
      handleClose,
      hasPermits,
    };
  },
  data() {
    return {
      dialogVisible: false,
      loadingCreate: false,
      providerId: 0,
      accountTypeOptions: [
        {
          value: null,
          label: "",
        },
        {
          value: "ahorros",
          label: "Ahorros",
        },
        {
          value: "corriente",
          label: "Corriente",
        },
      ],
      model: {
        identification: "",
        identification_type_id: "",
        full_name: "",
        address: "",
        phone: "",
        email: "",
        payment_type: "",
        account_number: "",
        retention: "",
        account_identification: "",
        bank: "",
        account_type: "",
        beneficiary_name: "",
      },
      step: 0,
      contactsEndPoint: "",
      documentsEndPoint: "",
      rules: {
        identification: [
          {
            required: true,
            message: "Por favor ingresa una identificación",
            trigger: "blur",
          },
        ],
        identification_type_id: [
          {
            required: true,
            message: "Por favor ingresa el tipo de identificación",
            trigger: "change",
          },
        ],
        full_name: [
          {
            required: true,
            message: "Por favor ingresa un nombre",
            trigger: "blur",
          },
        ],
        email: [
          {
            type: "email",
            message: "Por favor ingresa una dirección de correo válido",
            trigger: "blur",
          },
          {
            required: true,
            message: "Por favor ingresa una dirección de correo",
            trigger: "blur",
          },
        ],
        address: [
          {
            required: true,
            message: "Por favor ingresa una dirección",
            trigger: "blur",
          },
        ],
        phone: [
          {
            required: true,
            message: "Por favor ingresa un número de teléfono",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    openModal() {
      this.dialogVisible = true;
      this.step = 0;
      this.cleanForm();
    },
    next() {
      if (this.step++ > 2) this.step = 0;
      this.contactsEndPoint = "api/v1/contacts/provider/" + this.providerId;
      this.documentsEndpoint = "api/v1/documents/provider/" + this.providerId;
    },
    back() {
      if (this.step-- < 0) this.step = 0;
    },
    validateForm(formName) {
      let res;
      this.$refs[formName].validate((valid) => {
        res = valid;
      });
      return res;
    },
    saveProvider(formName) {
      this.loadingCreate = true;

      let v1 = this.validateForm(formName);
      let v2 = this.$refs.provider_type.validateForm();
      let v3 = this.$refs.subsidiaries.validate();

      if (!v1 || !v2 || !v3) {
        ElMessage.error("¡ Error !, Por favor verifica todos los campos");
        this.loadingCreate = false;

        return;
      }

      let providerInformation = this.$refs.provider_type.getInformation();
      this.model.provider_type_id = providerInformation.provider_type_id;
      this.model.other_provider_type = providerInformation.other_provider_type;
      this.model.cities = this.$refs.subsidiaries.getIds();

      postData("api/v1/providers", this.model, true)
        .then((res) => {
          if (res.error == true || res.error == undefined) {
            ElNotification({
              title: "Error",
              message: res.message,
              type: "error",
            });
          } else {
            ElNotification({
              title: "Proveedor registrado",
              message: res.message,
              type: "success",
            });

            this.providerId = res.data.id;
            this.next();

            this.emitter.emit("reload-list-of-providers");
          }
          this.loadingCreate = false;
        })
        .catch(() => {
          ElNotification({
            title: "Error",
            message: "Ocurrió un error al hacer la petición",
            type: "error",
          });
          this.loadingCreate = false;
        });
    },

    cleanForm() {
      this.step = 0;
      let form = this.$refs["model"];
      if (form) {
        form.resetFields();
      }

      if (this.$refs.subsidiaries) {
        this.$refs.subsidiaries.reset();
      }

      this.model = {
        identification: "",
        identification_type_id: "",
        full_name: "",
        address: "",
        phone: "",
        email: "",
        payment_type: "",
        account_number: "",
        retention: "",
        account_identification: "",
        bank: "",
        account_type: "",
        beneficiary_name: "",
      };
    },
  },
});
</script>

<style scoped>
.el-button--danger {
  background: #bc0304;
  border: none;
}

.el-button--success {
  background: #003149;
  border: none;
}

.add {
  color: rgb(2, 138, 13);
}

.el-button--primary {
  background: var(--blue) !important;
  border: 1px solid var(--blue) !important;
}

.el-button--text {
  color: var(--blue) !important;
  font-weight: bold;
  font-size: 25px;
}
.content {
  margin-top: 30px;
}
.icon-step {
  width: 20px;
}

::v-deep(.el-dialog__body) {
  text-align: center;
}

::v-deep(.el-form--label-top .el-form-item__label) {
  padding: 0;
}
</style>
