<template>
  <div>
    <!-- modal -->
    <el-dialog
      v-model="dialogVisible"
      title="Editar"
      width="60%"
      :before-close="handleClose"
    >
      <!-- steps -->
      <el-steps :action="step" finish-status="success" simple>
        <el-step title="Cliente" icon="el-icon-edit"></el-step>
        <el-step title="Contacto" icon="el-icon-edit"></el-step>
        <el-step title="Documentos" icon="el-icon-upload"></el-step>
      </el-steps>
      <!-- content step 1 -->
      <el-form v-show="step === 0">
        <el-row>
          <el-col :span="24">
            <div class="grid-content bg-purple-dark">
              <el-form
                v-loading="loadingGetInformation"
                ref="model"
                :model="model"
                label-position="top"
                :rules="rules"
              >
                <br /><br />
                <el-row>
                  <el-col :span="18" :offset="3">
                    <el-form-item
                      label="Tipo de identificación"
                      prop="identification_type_id"
                    >
                      <SelectIdentification
                        v-model="model.identification_type_id"
                      />
                    </el-form-item>

                    <el-form-item
                      label="Número de identificación"
                      prop="identification"
                    >
                      <el-input
                        v-model="model.identification"
                        :controls="false"
                      ></el-input>
                    </el-form-item>

                    <el-form-item
                      class="item-form"
                      label="Nombre Completo"
                      prop="full_name"
                    >
                      <el-input v-model="model.full_name"></el-input>
                    </el-form-item>

                    <el-form-item label="Razón Social" prop="company_name">
                      <el-input v-model="model.company_name"></el-input>
                    </el-form-item>

                    <el-form-item label="Tipo de cliente" prop="type_id">
                      <SelectClientType v-model="model.type_id" />
                    </el-form-item>

                    <SelectDepartmentCity ref="departmentCities" />

                    <el-form-item class="item-form" label="Correo" prop="email">
                      <el-input v-model="model.email"></el-input>
                    </el-form-item>

                    <el-form-item
                      class="item-form"
                      label="Dirección"
                      prop="address"
                    >
                      <el-input v-model="model.address"></el-input>
                    </el-form-item>

                    <el-form-item
                      class="item-form"
                      label="Teléfono"
                      prop="phone"
                    >
                      <el-input v-model="model.phone"></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form>

              <div id="boton" justify="end">
                <el-button
                  :loading="loadingUpdate"
                  id="steps-button"
                  @click="updateClient('model')"
                  type="success"
                  >Guardar cambios</el-button
                >
                <el-button
                  id="steps-button"
                  @click="editContacts"
                  type="success"
                  >Editar contactos</el-button
                >
                <el-button
                  id="steps-button"
                  @click="editDocuments"
                  type="success"
                  >Editar documentos</el-button
                >
              </div>
            </div>
          </el-col>
        </el-row>
      </el-form>
      <el-form v-if="step === 1" align="left">
        <Contacts :endpoint="contactsEndpoint" :edit="true" />
        <div id="boton" justify="end" align="center">
          <el-button id="steps-button" @click="editClient" type="success"
            >Editar Cliente</el-button
          >
          <el-button id="steps-button" @click="editDocuments" type="success"
            >Editar documentos</el-button
          >
        </div>
      </el-form>
      <el-form v-if="step === 2">
        <EditDocument :endpoint="documentsEndpoint"  deletePermit="delete_file_client" />
        <div id="boton" justify="end">
          <el-button id="steps-button" @click="editClient" type="success"
            >Editar cliente</el-button
          >
          <el-button id="steps-button" @click="editContacts" type="success"
            >Editar contactos</el-button
          >
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import SelectClientType from "./SelectClientType.vue";
import SelectIdentification from "../SelectIdentification.vue";
import { ref } from "vue";
import { ElMessage, ElMessageBox } from "element-plus";
import { putData, getData } from "@/request/request.js";
import { ElNotification } from "element-plus";
import SelectDepartmentCity from "../SelectDepartmensCities.vue";

export default {
  name: "EditClient",
  components: { SelectIdentification, SelectClientType, SelectDepartmentCity },
  beforeCreate() {
    this.$options.components.EditDocument =
      require("../documents/EditDocument.vue").default;
    this.$options.components.Contacts =
      require("../../views/Contacts.vue").default;
  },
  setup() {
    const dialogVisible = ref(false);

    const handleClose = (done) => {
      ElMessageBox.confirm(
        "¿Estas seguro que deseas salir de la actualización del cliente?",
        {
          showCancelButton: true,
          confirmButtonText: "Confirmar",
          cancelButtonText: "Cancelar",
        }
      )
        .then((res) => {
          if (res == "confirm") {
            done();
            this.cleanForm();
          }
        })
        .catch(() => {}); // this action is call when user click in cancel button
    };
    return {
      departaments: ref([]),
      cities: ref([]),
      departament: ref(""),
      value: ref(""),
      dialogVisible,
      handleClose,
    };
  },
  data() {
    return {
      model: {
        email: "",
        full_name: "",
        company_name: "",
        identification: "",
        client_type: "",
        address: "",
        phone: "",
        city_id: "",
        departament_id: "",
        identification_type_id: "",
      },

      documentsEndpoint: "",
      contactsEndpoint: "",
      step: 0,
      idClient: 0,
      loadingGetInformation: false,
      loadingUpdate: false,

      rules: {
        identification: [
          {
            required: true,
            message: "Por favor ingresa una identificación",
            trigger: "blur",
          },
        ],
        identification_type_id: [
          {
            required: true,
            message: "Por favor ingresa el tipo de identificación",
            trigger: "change",
          },
        ],
        company_name: [
          {
            required: true,
            message: "Por favor ingresa la razón social",
            trigger: "blur",
          },
        ],
        full_name: [
          {
            required: true,
            message: "Por favor ingresa un nombre",
            trigger: "blur",
          },
        ],
        client_type: [
          {
            required: true,
            message: "Por favor ingresa el tipo de cliente",
            trigger: "change",
          },
        ],
        email: [
          {
            required: true,
            message: "Por favor ingresa un correo",
            trigger: "blur",
          },
          {
            type: "email",
            message: "Por favor ingresa una dirección de correo válido",
            trigger: "blur",
          },
        ],
        address: [
          {
            required: true,
            message: "Por favor ingresa una dirección",
            trigger: "blur",
          },
        ],
        phone: [
          {
            required: true,
            message: "Por favor ingresa un número de teléfono",
            trigger: "blur",
          },
        ],
      },
    };
  },
  mounted() {
    this.emitter.off("open-modal-edit-client");
    this.emitter.on("open-modal-edit-client", (id) => {
      this.dialogVisible = true;
      this.idClient = id;
      this.beforeOpenForm();
      this.getClient();
    });
    this.emitter.off("document-update-success");
    this.emitter.on("document-update-success", () => {
      this.dialogVisible = false;
    });
  },
  methods: {
    validateForm(formName) {
      let res;
      this.$refs[formName].validate((valid) => {
        res = valid;
      });
      return res;
    },
    editClient() {
      this.step = 0;
    },
    editContacts() {
      this.step = 1;
      this.contactsEndpoint = "api/v1/contacts/client/" + this.idClient;
    },
    editDocuments() {
      this.step = 2;
      this.documentsEndpoint = "api/v1/documents/client/" + this.idClient;
    },

    async getClient() {
      this.loadingGetInformation = true;
      await getData("api/v1/clients/" + this.idClient)
        .then((data) => {
          if (data.error == true || data.error == undefined) {
            ElNotification({
              title: "Error",
              message: "Ocurrió un error al solicitar el cliente",
              type: "error",
            });
            return;
          }
          this.model = data.data;
          this.$refs.departmentCities.selectCity(this.model.city_id);
        })
        .catch(() => {
          ElNotification({
            title: "Error",
            message: "Ocurrió un error al hacer la petición",
            type: "error",
          });
        });
      this.loadingGetInformation = false;
    },

    async updateClient(formName) {
      let validateCity = this.$refs.departmentCities.validateForm(formName);
      if (this.validateForm(formName) && validateCity) {
        this.model.city_id = this.$refs.departmentCities.model.city_id;
        this.loadingUpdate = true;
        await putData("api/v1/clients/edit/" + this.idClient, this.model, true)
          .then((res) => {
            if (res.error == true || res.error == undefined) {
              ElNotification({
                title: "Error",
                message: res.message,
                type: "error",
              });
              return;
            }
            ElNotification({
              title: "Cliente Actualizado",
              message: "Cliente actualizado correctamente",
              type: "success",
            });
            this.emitter.emit("reload-list-of-clients");
            this.editContacts();
          })
          .catch((err) => {
            ElNotification({
              title: "Error",
              message: err,
              type: "error",
            });
          });
        this.loadingUpdate = false;
      } else {
        ElMessage.error("¡ Error !, Por favor verifica todos los campos");
      }
    },
    beforeOpenForm() {
      this.step = 0;
      let form = this.$refs["model"];
      if (form) {
        form.resetFields();
      }

      this.model = {
        email: "",
        name: "",
        identification: "",
        address: "",
        phone: "",
        identification_type_id: "",
      };
    },
  },
};
</script>

<style scoped>
html body {
  background: white;
}

.text {
  font-size: 14px;
}

.item {
  padding: 18px 0;
}

.box-card {
  margin: 5px;
}

#steps-button {
  margin: 10px;
}

label {
  margin-left: 50px;
  padding: 50px;
}

h1 {
  color: white;
}

.btn-plus {
  width: 55px;
  height: 55px;
  color: #003149;
  font-weight: bold !important;
  font-size: 32px;
  border-radius: 50%;
  text-align: center;
}

.el-form-item {
  margin-bottom: 15px;
}

::v-deep(.el-dialog__body) {
  text-align: center;
}

::v-deep(.el-form--label-top .el-form-item__label) {
  padding: 0;
}

.el-button--success {
  background: #003149;
  border: none;
}

.el-button--primary {
  background: var(--blue) !important;
  border: 1px solid var(--blue) !important;
}
</style>
