<template>
  <el-dialog
    v-model="showModal"
    v-loading="loadingInformation"
    width="95%"
    :before-close="handleClose"
    title="Editar orden de compra"
  >
    <br />
    <h3>Orden de compra</h3>
    <SelectSupplyUsage event="add-detail" :idEvent="this.eventID" />

    <table class="table" v-if="!loadingInformation">
      <thead>
        <tr>
          <th :key="i" v-for="(header, i) in headers">
            {{ header.title }}
          </th>
        </tr>
      </thead>

      <tbody>
        <tr :key="i" v-for="(row, i) in tableData">
          <td align="center" :key="j" v-for="(header, j) in headers">
            <template v-if="header.name === 'total_price'">
              {{ toCurrency(row[header.name]) }}
            </template>
            <template v-else-if="header.name === 'description'">
              <input
                class="description_field"
                @change="changeAmount(row)"
                type="text"
                v-model="row[header.name]"
              />
            </template>
            <template v-else-if="header.name === 'price'">
              <input
                class="amount_input"
                @change="changeAmount(row)"
                type="number"
                v-model="row[header.name]"
              />
            </template>
            <template v-else-if="header.name.includes('-')">
              <input
                class="amount_input"
                @change="changeAmount(row)"
                type="number"
                v-model="row[header.name]"
              />
            </template>
            <template v-else-if="header.name === 'supply_name'">
              <div class="supply">
                <section>
                  {{ row[header.name] }}
                </section>
                <section>
                  <el-tooltip content="Eliminar" placement="top" effect="light">
                    <i
                      @click="deleteDetail(row)"
                      class="el-icon-delete delete"
                    ></i>
                  </el-tooltip>
                </section>
              </div>
            </template>
            <template v-else>
              {{ row[header.name] == undefined ? "" : row[header.name] }}
            </template>
          </td>
        </tr>
      </tbody>
    </table>
    <br /><br />
    <el-form ref="model" label-position="top" :model="model">
      <el-form-item label="Observaciones" prop="observations">
        <el-input type="textarea" v-model="model.observations"></el-input>
      </el-form-item>
    </el-form>
    <div class="button" align="center">
      <el-button :loading="loadingSave" type="primary" @click="processToSave"
        >Actualizar orden de compra</el-button
      >
    </div>
  </el-dialog>
</template>

<script>
import { putData, deleteData } from "../../request/request.js";
import { toCurrency } from "../../config/Money.js";
import {
  ElLoading,
  ElMessage,
  ElMessageBox,
  ElNotification,
} from "element-plus";
import { generateDates } from "@/config/Helper.js";
import SelectSupplyUsage from "./SelectSupplyUsage.vue";
export default {
  name: "EditPurcharseOrder",
  setup() {
    return {
      toCurrency,
    };
  },
  components: {
    SelectSupplyUsage,
  },
  mounted() {
    this.emitter.off("add-detail");
    this.emitter.on("add-detail", (newDetail) => {
      if (this.existDetail(newDetail.supply.name)) {
        ElMessage.error("El insumo ya fue agregado.");
        return;
      }
      this.processDetailAdded(newDetail);
    });
  },
  data() {
    return {
      showModal: false,
      loadingInformation: false,
      loadingUpdate: false,
      headers: [
        {
          name: "supply_name",
          title: "Insumo",
        },
      ],
      model: {
        observations: "",
      },
      purcharseOrderId: -1,
      details: [],
      tableData: [],
      dates: [],
      modifiedDetails: [],
      uid: 0,
      eventID: -1,
    };
  },
  methods: {
    openModal(
      purcharOrder,
      eventId,
      start_date,
      end_date,
      previous_day,
      next_day
    ) {
      this.headers = [
        {
          name: "supply_name",
          title: "Insumo",
        },
        {
          name: "description",
          title: "Descripción",
        },
        {
          name: "price",
          title: "Precio",
        },
      ];
      this.tableData = [];
      this.eventID = eventId;

      this.dates = generateDates(start_date, end_date, previous_day, next_day);

      this.dates.forEach((date) => {
        this.headers.push({
          name: date,
          title: date.substring(5),
        });
      });

      this.headers.push(
        ...[
          {
            name: "total_amount",
            title: "Cantidad total",
          },
          {
            name: "total_price",
            title: "Valor total",
          },
        ]
      );

      this.addDetails = [];
      this.editDetails = [];
      this.loadData(purcharOrder);
      this.showModal = true;
    },

    loadData(purchaseOrder) {
      this.loadingInformation = true;
      this.purcharseOrderId = purchaseOrder.id;
      this.details = purchaseOrder.purcharse_order_detail;
      this.manageInformation();
      this.model.observations = purchaseOrder.observations;
      this.loadingInformation = false;
    },

    existDetail(detailName) {
      let exist = false;
      this.tableData.forEach((row) => {
        if (row.supply_name.toUpperCase() === detailName.toUpperCase()) {
          exist = true;
          return;
        }
      });
      return exist;
    },

    manageInformation() {
      this.details.forEach((detail) => {
        let row = [];
        row["total_amount"] = 0;
        row["id"] = detail.id;
        row["description"] = detail.description;
        row["price"] = detail.price;

        detail.purcharse_order_detail_date.forEach((date) => {
          row[date.date] = date.amount;
          row["total_amount"] += date.amount;
        });

        row["total_price"] = row["total_amount"] * row["price"];
        row.supply_name = detail.supply;
        this.tableData.push(row);
      });
    },

    changeAmount(row) {
      if (row.id !== undefined) {
        row.edited = true;
      }
      let total = 0;
      for (let k in row) {
        if (k.includes("-")) {
          total += row[k];
        }
      }
      row.total_amount = total;
      row.total_price = row.price * row.total_amount;
    },

    processToSave() {
      this.tableData.forEach((row) => {
        let detail = Object.fromEntries(
          Object.entries(row).filter(
            ([key]) => !["total_price", "total_amount"].includes(key)
          )
        );

        if (row.id !== undefined && row.edited !== undefined) {
          this.editDetails.push(detail);
        }
        if (row.uid !== undefined) {
          this.addDetails.push(detail);
        }
      });
      this.updatePurcharseOrder();
    },

    updatePurcharseOrder() {
      this.loadingUpdate = true;
      putData(
        `api/v1/purchase-order/${this.purcharseOrderId}`,
        {
          add: this.addDetails,
          edit: this.editDetails,
          observations: this.model.observations,
        },
        true
      )
        .then((res) => {
          this.loadingUpdate = false;
          if (res.error == true || res.error == undefined) {
            ElNotification({
              title: "Error",
              message: res.message,
              type: "error",
            });
            return;
          }

          ElNotification({
            title: "Orden de compra actualizada.",
            message: res.message,
            type: "success",
          });

          this.emitter.emit("reload-list-of-quotations");
          this.showModal = false;
        })
        .catch(() => {
          ElNotification({
            title: "Error",
            message: "Ocurrió un error al hacer la petición",
            type: "error",
          });
        });
      this.loadingUpdate = false;
    },

    deleteDetail(detail) {
      if (detail.id !== undefined) {
        ElMessageBox.confirm(
          `¿Seguro de querer eliminar ${detail.supply_name}?`,
          "Advertencia",
          {
            confirmButtonText: "Si",
            cancelButtonText: "No",
            type: "warning",
          }
        )
          .then(() => {
            this.loadingUpdate = ElLoading.service();
            deleteData(
              `api/v1/purchase-order/delete-detail/${detail.id}`,
              {},
              true
            )
              .then((res) => {
                if (res.error) {
                  ElNotification.error({
                    title: "Error",
                    message: res.message,
                  });
                  this.loadingUpdate.close();
                  return;
                }
                ElNotification.success({
                  title: "Eliminado",
                  message: res.message,
                });
                this.deleteDetailLocal(detail);
                this.emitter.emit("reload-list-of-quotations");
                this.loadingUpdate.close();
              })
              .catch(() => {
                this.loadingUpdate.close();
                ElNotification.error({
                  title: "Error",
                  message: "No se logró hacer la petición",
                });
              });
          })
          .catch(() => {});
      } else {
        this.deleteDetailLocal(detail);
      }
    },

    deleteDetailLocal(detail) {
      let detailId = -1;
      let property = "";
      property = detail !== undefined ? "id" : "uid";
      detailId = detail !== undefined ? detail.id : detail.uid;
      this.tableData = this.tableData.filter(
        (detail) => detail[property] !== detailId
      );
    },

    processDetailAdded(newDetail) {
      let index = 0;
      let detail = {};
      detail["uid"] = this.uid;
      this.uid++;
      detail["supply_name"] = newDetail.supply.name;
      detail["description"] = "";
      detail["total_amount"] = 0;
      newDetail.usages.forEach((element) => {
        if (index == 0) {
          let price = parseInt(element.event_price.price.price);
          detail["price"] = price;
        }
        detail[element.date] = element.amount;
        detail["total_amount"] += element.amount;
        index++;
      });
      detail["total_price"] = detail["total_amount"] * detail["price"];
      this.tableData.push(detail);
    },
  },
};
</script>

<style scoped>
.table {
  width: -webkit-fill-available;
  font-size: 16px;
}

.table th {
  color: var(--blue);
  padding: 3px;
  border: 1px solid gray;
}

.table td {
  margin: 8px;
  border: 1px solid gray;
}

.amount_input {
  text-align: center;
  font-size: 16px;
  width: 60px;
  border: none;
  outline: none;
}

.description_field {
  text-align: center;
  font-size: 16px;
  width: 75%;
  border: none;
  outline: none;
}

h3 {
  color: var(--blue);
}

.supply {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

i.delete {
  color: red;
  cursor: pointer;
  font-size: 20px;
  padding-right: 5px;
  padding-top: 2px;
}
</style>
