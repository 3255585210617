<template>
  <div>
    <el-popover
      placement="right-start"
      title="Crear insumo"
      :width="200"
      trigger="hover"
      content="Abrir formulario para agregar un insumo"
    >
      <template #reference>
        <el-button class="btn-plus" type="text" @click="openModal()"
          ><i class="el-icon-plus add-icon"></i
        ></el-button>
      </template>
    </el-popover>

    <el-dialog
      v-model="dialogVisible"
      width="50%"
      :before-close="handleClose"
      title="Crear insumo"
    >
      <el-row>
        <el-col>
          <div class="content">
            <el-row>
              <el-col :span="16" :offset="4">
                <el-form
                  ref="model"
                  label-position="top"
                  :model="model"
                  :rules="rules"
                >
                  <el-form-item label="Nombre" prop="name">
                    <el-input v-model="model.name"></el-input>
                  </el-form-item>

                  <el-form-item label="Categoría" prop="category_id">
                    <SelectCategory v-model="model.category_id" />
                  </el-form-item>

                  <el-form-item label="Porcentaje IVA" prop="percent_iva">
                    <el-input
                      type="number"
                      v-model="model.percent_iva"
                    ></el-input>
                  </el-form-item>
                </el-form>
                <div class="button" align="center">
                  <el-button
                    :loading="loadingCreate"
                    type="primary"
                    @click="save('model')"
                    >Guardar insumo</el-button
                  >
                </div>
              </el-col>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import SelectCategory from "./SelectCategory.vue";
import { defineComponent } from "vue";
import { ElMessageBox } from "element-plus";
import { ElMessage } from "element-plus";
import { hasPermits } from "@/config/Auth.js";
import { ElNotification } from "element-plus";
import { postData } from "../../request/request.js";
export default defineComponent({
  name: "CreateSupply",
  components: { SelectCategory },

  setup() {
    const handleClose = (done) => {
      ElMessageBox.confirm(
        "¿Está seguro que quiere salir de la creación de insumo?",
        {
          showCancelButton: true,
          confirmButtonText: "Confirmar",
          cancelButtonText: "Cancelar",
        }
      )
        .then(() => {
          done();
        })
        .catch(() => {
          // catch error
        });
    };
    return {
      handleClose,
      hasPermits,
    };
  },
  data() {
    return {
      dialogVisible: false,
      loadingCreate: false,
      model: {
        name: "",
        category_id: "",
        percent_iva: "",
      },
      rules: {
        name: [
          {
            required: true,
            message: "Por favor ingresa el nombre",
            trigger: "blur",
          },
        ],
        percent_iva: [
          {
            required: true,
            message: "Por favor ingresa el porcentaje de IVA",
            trigger: "blur",
          },
        ],
        category_id: [
          {
            required: true,
            message: "Por favor selecciona una categoría",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    openModal() {
      this.dialogVisible = true;
      this.cleanForm();
    },
    validateForm(formName) {
      let res;
      this.$refs[formName].validate((valid) => {
        res = valid;
      });
      return res;
    },
    save(formName) {
      this.loadingCreate = true;
      if (this.validateForm(formName)) {
        postData("api/v1/supplies", this.model, true)
          .then((res) => {
            if (res.error == true || res.error == undefined) {
              ElNotification({
                title: "Error",
                message: res.message,
                type: "error",
              });
            } else {
              ElNotification({
                title: "Insumo registrado",
                message: res.message,
                type: "success",
              });

              this.dialogVisible = false;
              this.emitter.emit("reload-list-of-supplies");
            }
            this.loadingCreate = false;
          })
          .catch(() => {
            ElNotification({
              title: "Error",
              message: "Ocurrió un error al hacer la petición",
              type: "error",
            });
            this.loadingCreate = false;
          });
      } else {
        ElMessage.error("¡ Error !, Por favor verifica todos los campos");
        this.loadingCreate = false;
      }
    },
    cleanForm() {
      let form = this.$refs["model"];
      if (form) {
        form.resetFields();
      }

      this.model = {
        name: "",
        category_id: "",
        percent_iva: "",
      };
    },
  },
});
</script>

<style scoped>
.el-button--danger {
  background: #bc0304;
  border: none;
}

.el-button--success {
  background: #003149;
  border: none;
}

.add-icon {
  color: rgb(2, 138, 13);
}

.el-button--primary {
  background: var(--blue) !important;
  border: 1px solid var(--blue) !important;
}

.el-button--text {
  color: var(--blue) !important;
  font-weight: bold;
  font-size: 25px;
}
.content {
  margin-top: 30px;
}
.icon-step {
  width: 20px;
}

::v-deep(.el-dialog__body) {
  text-align: center;
}

::v-deep(.el-form--label-top .el-form-item__label) {
  padding: 0;
}
</style>
